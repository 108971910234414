import { useCallback, useState } from 'react';
import { ProColumns } from '@ant-design/pro-table';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import { usePermissionsList } from 'services/api/useSettings';
import { GTable } from 'components';
import { UserPermissionItem } from './typings';

type ColumnType = {
  userPermissionSearchFilters: any;
};

const columns = ({
  userPermissionSearchFilters,
}: ColumnType): Array<ProColumns<UserPermissionItem>> => [
  {
    title: 'Permissions',
    dataIndex: 'permission',
    ellipsis: true,
    ...userPermissionSearchFilters,
    onFilter: (value: any, record: any) =>
      record.permission
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
  },
];

const PermissionsTable = () => {
  const [params, setParams] = useState({
    page: '1',
    itemsPerPage: 20,
    search: '',
  });

  const onSearch = (text: string) => {
    setParams({
      ...params,
      search: text,
    });
  };

  const { filters: userPermissionSearchFilters } = useTableSearchFilter({
    onSearch,
  });
  const { data: getPermissionsListResponse, isLoading } = usePermissionsList();
  const rolesArray: Array<UserPermissionItem> =
    getPermissionsListResponse?.permissions?.map((item) => ({
      id: item.permission,
      permission: item.name,
      desc: item.description,
    })) || [];

  const expandedRowRender = useCallback(
    (rowData: UserPermissionItem) => (
      <div
        style={{
          paddingLeft: '30px',
        }}
      >
        {rowData.desc}
      </div>
    ),
    [],
  );

  const rowExpandable = useCallback(() => true, []);
  return (
    <GTable<UserPermissionItem>
      columns={columns({ userPermissionSearchFilters })}
      loading={isLoading}
      value={rolesArray}
      options={{
        setting: false,
        reload: false,
      }}
      expandable={{ expandedRowRender, rowExpandable, columnWidth: 30 }}
      pagination={{
        defaultPageSize: 20,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Permissions`,
      }}
    />
  );
};

export default PermissionsTable;
