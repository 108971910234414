/* eslint-disable no-nested-ternary */
import { ProPageHeader } from '@ant-design/pro-layout';
import { Col, Row, Space, Skeleton } from 'antd';
import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import BSPLogo from 'assets/images/BSP.svg';
import BAPLogo from 'assets/images/BAP.svg';
import { yearFirstFormat } from 'utils/helpers';
import EndorserTitlebar from 'components/TitleBar/EndorserTitlebar';
import { useUserInfo } from 'services/api/useSettings';
import { ListMyEndorserAccessItemsResponse } from 'services/api/client/src';
import { useEndorserById } from 'services/api/useEndorsers';
import styles from '../index.module.less';
import Invite, { GrantAccess, RequestAccess, GSAInviteModal, EditAccessModal } from '../Modal';
import { PageTitleProps, TopHeaderTitleBarProps } from './typings';

type ActionItemsProps = {
  t: TFunction<'pages', 'endorsers_partners.view_company.top_header'>;
  from?: string;
  myEndorsersListResponse?: ListMyEndorserAccessItemsResponse;
  accountID?: any;
};

const mainActionItems = ({ t, from, myEndorsersListResponse, accountID }: ActionItemsProps) => [
  from !== 'endorsersMain'
    ? {
        key:
          from === 'profile' || from === 'endorsersTradingPartners'
            ? myEndorsersListResponse?.results?.find((item) => item.endorserId === accountID)
              ? 'manageAccess'
              : 'requestAccess'
            : 'grantAccess',
        label:
          from === 'profile' || from === 'endorsersTradingPartners'
            ? myEndorsersListResponse?.results?.find((item) => item.endorserId === accountID)
              ? 'Manage Access'
              : t('main_actions.request_access')
            : t('main_actions.grant_access'),
        disabled: !!(from === 'profile' || from === 'endorsersTradingPartners'),
      }
    : null,
  {
    key: 'invite',
    label: t('main_actions.invite'),
  },
];

const TitleBar = ({
  onEndorserTitleActionItemClick,
  backButton,
  ownCompany,
  from,
  companyName,
  loggedInUserData,
  myEndorsersListResponse,
}: TopHeaderTitleBarProps) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.top_header',
  });

  const navigate = useNavigate();
  const { accountID } = useParams();

  return (
    <EndorserTitlebar
      title={
        ownCompany
          ? `${loggedInUserData?.endorser?.facility}`
          : t(
              from === 'profile'
                ? 'gsa_ecosystem_title'
                : from === 'staffTradingPartners' ||
                  from === 'endorsersTradingPartners' ||
                  from === 'profileTradingPartners'
                ? 'trading_partners_title'
                : 'title',
              {
                companyName: `${companyName}`,
              },
            )
      }
      onBack={
        backButton
          ? () => {
              if (from === 'staffTradingPartners') {
                navigate('/endorsers_partners');
              } else {
                window.history.back();
              }
            }
          : undefined
      }
      actionItems={mainActionItems({ t, from, myEndorsersListResponse, accountID })}
      onActionItemClick={onEndorserTitleActionItemClick}
      actionButtonColor={{
        color: '#0a5f7a',
        borderColor: '#bcd530',
        background: '#bcd530',
        textShadow: '0px 0px',
        boxShadow: '0px 0px',
      }}
      fontSize
    />
  );
};

const TopHeader: FC<PageTitleProps> = ({
  className,
  onEndorserTitleActionItemClick,
  manageAccessModal,
  locationMenuItem,
  backButton = false,
  ownCompany = true,
  inviteModal,
  grantAccessModal,
  setTab,
  from,
  myEndorsersListResponse,
}) => {
  const { data: loggedInUserData } = useUserInfo();

  const { accountID } = useParams();
  const { data: endorserByIdResponse, isLoading } = useEndorserById(
    accountID || loggedInUserData?.endorser?.accountID || '',
  );

  const companyName = useMemo(
    () => endorserByIdResponse?.endorser?.facility,
    [endorserByIdResponse],
  );

  const titleComponents = [];
  const extraComponents = [];

  titleComponents.push(
    <Space size="small">
      <Row align="top" gutter={[32, 12]}>
        <Col>
          {endorserByIdResponse?.endorser?.partnerType && (
            <Row>
              <div style={{ fontSize: 14, fontWeight: '400' }}>
                Partner Type : {endorserByIdResponse?.endorser?.partnerType}
              </div>
            </Row>
          )}
          {endorserByIdResponse?.endorser?.facilityType?.includes('Supplier') ||
          endorserByIdResponse?.endorser?.facilityType?.includes('Retail') ||
          endorserByIdResponse?.endorser?.facilityType?.includes('Corporation') ||
          endorserByIdResponse?.endorser?.facilityType?.includes('Foodservice') ||
          !endorserByIdResponse?.endorser?.facilityType ? null : (
            <Row>
              <div style={{ fontSize: 14, fontWeight: '400' }}>
                BAP No. : {endorserByIdResponse?.endorser?.bapNumber}
              </div>
            </Row>
          )}
          {endorserByIdResponse?.endorser?.country && (
            <Row>
              <div style={{ fontSize: 14, fontWeight: '400' }}>
                Country : {endorserByIdResponse?.endorser?.country}
              </div>
            </Row>
          )}
        </Col>
        <Col>
          {endorserByIdResponse?.endorser?.expiration ? (
            <Row>
              <div style={{ fontSize: 14, fontWeight: '400' }}>
                Expiration : {yearFirstFormat(endorserByIdResponse?.endorser?.expiration)}
              </div>
            </Row>
          ) : null}
          {endorserByIdResponse?.endorser?.species?.length !== 0 ? (
            <Row>
              <div style={{ fontSize: 14, fontWeight: '400' }}>
                {endorserByIdResponse?.endorser?.species?.length === 1
                  ? `Species : ${endorserByIdResponse?.endorser?.species?.[0]?.category}`
                  : `Species : ${endorserByIdResponse?.endorser?.species?.length} Species`}
              </div>
            </Row>
          ) : null}
        </Col>
      </Row>
    </Space>,
  );

  extraComponents.push(
    <Row gutter={30}>
      <Col>
        {endorserByIdResponse?.endorser?.bapNumber ? (
          <img src={BSPLogo} alt="BSP Logo" width="104" height="47" />
        ) : null}
      </Col>
      <Col>
        {endorserByIdResponse?.endorser?.bapNumber ? (
          <div style={{ marginTop: '5px' }}>
            <img src={BAPLogo} alt="BAP Logo" width="104" height="35" />
          </div>
        ) : null}
      </Col>
    </Row>,
  );

  return (
    <div>
      {(from === 'profile' || from === 'endorsersMain') && inviteModal.visible && (
        <Invite modal={inviteModal} companyName={companyName} from={from} />
      )}
      {from === 'staffEndorsers' && inviteModal.visible && (
        <GSAInviteModal modal={inviteModal} companyName={companyName} from={from} />
      )}
      {from === 'profile' || from === 'endorsersTradingPartners'
        ? grantAccessModal &&
          grantAccessModal.visible && (
            <RequestAccess modal={grantAccessModal} companyName={companyName} />
          )
        : grantAccessModal &&
          grantAccessModal.visible && (
            <GrantAccess
              modal={grantAccessModal}
              grantAccessCompanyName={companyName}
              accountID={accountID}
            />
          )}
      {manageAccessModal && manageAccessModal.visible && (
        <EditAccessModal
          modal={manageAccessModal}
          myAccessListResponse={myEndorsersListResponse}
          selectedRowData={{
            id: endorserByIdResponse?.endorser?.accountID,
            producer: endorserByIdResponse?.endorser?.facility,
          }}
        />
      )}
      {isLoading ? (
        <div style={{ height: '167px', background: 'white', padding: '24px' }}>
          <Skeleton active />
        </div>
      ) : (
        <>
          <TitleBar
            onEndorserTitleActionItemClick={onEndorserTitleActionItemClick}
            locationMenuItem={locationMenuItem}
            backButton={backButton}
            ownCompany={ownCompany}
            setTab={setTab}
            from={from}
            companyName={companyName}
            loggedInUserData={loggedInUserData}
            isLoading={isLoading}
            myEndorsersListResponse={myEndorsersListResponse}
          />
          <ProPageHeader
            className={classNames(styles.titlecontainer, className)}
            title={titleComponents}
            prefixedClassName="titlebar"
            extra={extraComponents}
          />
        </>
      )}
    </div>
  );
};

export default TopHeader;
