import { FC, useState, useCallback, useEffect } from 'react';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { RadioChangeEvent } from 'antd';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Typography, message, Space, Checkbox, Radio, Col, Row } from 'antd';
import { NewEndorserSelect } from 'components/GSelect';
import errorHandler from 'utils/errorHandler';
import { useGrantAccess } from 'services/api/Endorsers/useAccess';
import { GrantAccessProps } from './typings';

const GrantAccessForm: FC<GrantAccessProps> = ({
  modal,
  grantAccessCompanyName,
  selecteMainTableData,
  setManageModalMaskShow,
}) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.grant_access_modal',
  });
  const queryClient = useQueryClient();
  const grantAccess = useGrantAccess(queryClient);
  const [isLoading, setIsLoading] = useState(false);
  const [levelValue, setLevelValue] = useState('view');
  const [categoryValue, setCategoryValue] = useState<CheckboxValueType[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedProducerData, setSelectedProducerData] = useState<any>({});
  const [endorsersList, setEndorsersList] = useState<string>();

  const closeModal = useCallback(() => {
    setShowOptions(false);
    setCategoryValue([]);
    setLevelValue('view');
    setManageModalMaskShow(true);
    setEndorsersList(undefined);
    modal.hide();
  }, [modal, setManageModalMaskShow]);

  useEffect(() => {
    setCategoryValue([]);
    setLevelValue('view');
  }, [showOptions]);

  const onLevelChange = (e: RadioChangeEvent) => {
    setLevelValue(e.target.value);
  };

  const onChangeGroup = (checkedValues: CheckboxValueType[]) => {
    setCategoryValue(checkedValues);
  };

  const onSuccess = async () => {
    if (showOptions) {
      if (levelValue === '' && categoryValue.length === 0) {
        message.error(t('level_category_not_selected'));
      } else if (levelValue === '') {
        message.error(t('level_not_selected'));
      } else if (categoryValue.length === 0) {
        message.error(t('category_not_selected'));
      } else {
        setIsLoading(true);
        try {
          setIsLoading(true);
          await grantAccess.mutateAsync({
            params: {
              requestedEndorserId: selecteMainTableData?.accountID,
              accessRequests: [
                {
                  endorserId: selectedProducerData?.accountID,
                  auditReports: true,
                },
              ],
            },
          });
          setIsLoading(false);
          message.success(
            t('access_granted_success_message', { grantAccessCompanyName: selectedCompany }),
          );
          closeModal();
        } catch (error) {
          message.error(errorHandler(error));
          setIsLoading(false);
        }
      }
    } else {
      message.error(t('nothing_is_selected'));
    }
  };

  return (
    <Modal
      visible={modal.visible}
      title={<Typography.Title level={4}>{t('grant_access_heading')}</Typography.Title>}
      closable
      onCancel={closeModal}
      footer={
        <Button
          onClick={onSuccess}
          type="primary"
          shape="round"
          style={{
            color: '#0a5f7a',
            borderColor: '#bcd530',
            background: '#bcd530',
            textShadow: '0px 0px',
            boxShadow: '0px 0px',
          }}
          loading={isLoading}
        >
          {t('submit_btn_text')}
        </Button>
      }
      width="38%"
      bodyStyle={{ height: '60%', borderRadius: '0px' }}
      centered
    >
      <Space direction="vertical">
        <Row>
          <Col span={24}>
            <Typography.Text>{t('select_endorser', { grantAccessCompanyName })}</Typography.Text>
          </Col>
          <Col span={24} style={{ marginTop: '8px' }}>
            <NewEndorserSelect
              style={{ width: '100%' }}
              setShowOptions={setShowOptions}
              setSelectedCompany={setSelectedCompany}
              setSelectedProducerData={setSelectedProducerData}
              endorsersList={endorsersList}
              setEndorsersList={setEndorsersList}
              selecteMainTableData={selecteMainTableData}
            />
          </Col>
        </Row>

        {showOptions ? (
          <Space direction="vertical" size="small" style={{ marginTop: '8px' }}>
            <Typography.Text>
              Select desired{' '}
              <Typography.Text strong underline>
                categories
              </Typography.Text>{' '}
              of access
            </Typography.Text>
            <Checkbox.Group onChange={onChangeGroup} value={categoryValue}>
              <Checkbox value="auditInformation">{t('audit_information')}</Checkbox>
            </Checkbox.Group>
          </Space>
        ) : null}
        {showOptions ? (
          <Space direction="vertical" size="small" style={{ marginTop: '24px' }}>
            <Typography.Text>
              Select desired{' '}
              <Typography.Text strong underline>
                level
              </Typography.Text>{' '}
              of access
            </Typography.Text>
            <Radio.Group onChange={onLevelChange} value={levelValue}>
              <Space direction="vertical" size="small">
                <Radio value="view">{t('view_only')}</Radio>
              </Space>
            </Radio.Group>
          </Space>
        ) : null}
      </Space>
    </Modal>
  );
};

export default GrantAccessForm;
