import create from 'zustand';
import { InviteStore } from './typings';

const useInviteStore = create<InviteStore>((set) => ({
  emails: [],
  role: undefined,
  setRole: (role) => set({ role }),
  setEmails: (emails) => set({ emails }),
}));

export default useInviteStore;
