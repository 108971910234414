import { useState, useEffect } from 'react';
import { useTableSearchFilter } from 'hooks';
import { MapOptionalParams } from 'services/api/client/src';
import { useMapLocation } from 'services/api/useTrace';

const useMapLocationFilters = (initParams?: MapOptionalParams) => {
  const [eventMapParams, setEventMapParams] = useState<MapOptionalParams>({
    ...initParams,
  });
  const [eventMapLocation, setEventMapLocation] = useState<any>([]);

  const { data: eventMapLocationResponse, isLoading: isEventMpaLocationLoading } =
    useMapLocation(eventMapParams);

  useEffect(() => {
    setEventMapLocation(eventMapLocationResponse || []);
  }, [eventMapLocationResponse]);

  const { filters: facilityNameSearchFilter } = useTableSearchFilter({
    title: 'Facility Name',
  });

  const { filters: bapNumberSearchFilter } = useTableSearchFilter({
    title: 'BAP Number',
  });

  return {
    eventMapLocation,
    isEventMpaLocationLoading,
    eventMapParams,
    setEventMapParams,
    filters: [facilityNameSearchFilter, bapNumberSearchFilter],
  };
};

export default useMapLocationFilters;
