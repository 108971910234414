import { useState, useEffect } from 'react';
import { useTableSearchFilter } from 'hooks';
import { ListEventsOptionalParams, EventDTO } from 'services/api/client/src';
import { useAllEventsList } from 'services/api/useTrace';

const useAllEventsListFilters = (initParams?: ListEventsOptionalParams) => {
  const [eventsListParams, setEventsListParams] = useState<ListEventsOptionalParams>({
    ...initParams,
  });
  const [allEventsList, setAllEventsList] = useState<EventDTO[]>([]);

  const { data: allEventsListResponse, isLoading: isAllEventsListLoading } =
    useAllEventsList(eventsListParams);

  useEffect(() => {
    setAllEventsList(allEventsListResponse?.events || []);
  }, [allEventsListResponse?.events]);

  const { filters: facilityNameSearchFilter } = useTableSearchFilter({
    title: 'Facility Name',
  });

  const { filters: bapNumberSearchFilter } = useTableSearchFilter({
    title: 'BAP Number',
  });

  return {
    allEventsList,
    isAllEventsListLoading,
    eventsListParams,
    setEventsListParams,
    filters: [facilityNameSearchFilter, bapNumberSearchFilter],
  };
};

export default useAllEventsListFilters;
