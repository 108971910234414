import { Tabs, Space, Badge } from 'antd';
import { FC, useState } from 'react';
import { useRolesList, usePermissionsList } from 'services/api/useSettings';
import RolesTable from './RolesTable';
import PermissionsTable from './PermissionsTable';
import usePermissionStore from '../hooks';
import styles from './index.less';
import { TabTitleProps } from './typings';

const { TabPane } = Tabs;

const TabTitle: FC<TabTitleProps> = ({ title, count, active }) => (
  <Space>
    {title}
    <Badge
      count={count}
      style={{
        color: active ? '#0a5f7a' : 'black',
        backgroundColor: active ? '#E6EFF2' : '#F4f4f4',
      }}
    />
  </Space>
);

const RolesSection = () => {
  const [activeKey, setActiveKey] = useState('rolesTab');
  const setSelectedTab = usePermissionStore((state) => state.setTab);
  const onChange = (key: string) => {
    setActiveKey(key);
    setSelectedTab(key);
  };
  const { data: getRolesListResponse } = useRolesList();
  const { data: getPermissionsListResponse } = usePermissionsList();
  return (
    <Tabs onChange={onChange} className={styles.tabs}>
      <TabPane
        tab={
          <TabTitle
            title="Roles"
            count={getRolesListResponse?.roles?.length || 0}
            active={activeKey === 'rolesTab'}
          />
        }
        key="rolesTab"
      >
        <RolesTable />
      </TabPane>
      <TabPane
        tab={
          <TabTitle
            title="Permissions"
            count={getPermissionsListResponse?.permissions?.length || 0}
            active={activeKey === 'permissionsTab'}
          />
        }
        key="permissionsTab"
      >
        <PermissionsTable />
      </TabPane>
    </Tabs>
  );
};

export default RolesSection;
