/* eslint-disable @typescript-eslint/no-unused-vars */
import { InfoCircleOutlined } from '@ant-design/icons';
import { ProPageHeader } from '@ant-design/pro-layout';
import classNames from 'classnames';
import Actions from 'components/Actions';
import { FC } from 'react';
import styles from '../../index.module.less';
import { PageTitleInfoProps, PageTitleProps } from './typings';

const TracteTitleInfo: FC<PageTitleInfoProps> = ({ onClick }) => (
  <InfoCircleOutlined className={styles.info} onClick={onClick} size={12} />
);

const TraceTitle: FC<PageTitleProps> = ({
  actionItems,
  className,
  extra,
  extraTitle,
  onActionItemClick,
  onInfoClick,
  title,
  ...props
}) => {
  const titleComponents = [title];

  const handleActionClick = (actionItemKey: string) => {
    if (onActionItemClick) {
      onActionItemClick(actionItemKey);
    }
  };

  // if (onInfoClick) {
  //   titleComponents.push(<TracteTitleInfo key="info" onClick={onInfoClick} />);
  // }
  // if (extraTitle) {
  //   titleComponents.push(extraTitle);
  // }
  // const extraComponents = [extra];
  // if (actionItems && actionItems.length > 0) {
  //   extraComponents.push(<Actions key="actions" items={actionItems} onClick={handleActionClick} />);
  // }

  return (
    <ProPageHeader
      className={classNames(styles.container, className)}
      title={titleComponents}
      prefixedClassName="titlebar"
      // extra={extraComponents}
      {...props}
    />
  );
};

export default TraceTitle;
