import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  // request access params
  RequestEndorserAccessResponse,
  AccessRequest,
  // grant access params,
  GrantAccessRequest,
  GrantEndorserAccessResponse,
  // accept request params
  AcceptInviteOptionalParams,
  AcceptInviteResponse,
  // reject request params
  RejectInviteOptionalParams,
  RejectInviteResponse,
  // list access list by endorser id
  ListEndorserAccessItemsOptionalParams,
  ListEndorserAccessItemsResponse,
  // revoke access params
  RevokeAccessOperationResponse,
  RevokeAccessRequest,
  // my endorsers list
  ListMyEndorserAccessItemsOptionalParams,
  ListMyEndorserAccessItemsResponse,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

// Original API
// request access post query
const requestAccess = async (params?: AccessRequest): Promise<RequestEndorserAccessResponse> =>
  HttpClient.requestEndorserAccess({ body: params });

export const useRequestAccess = (queryClient: QueryClient) =>
  useMutation(({ params }: { params: AccessRequest }) => requestAccess(params), {
    onSuccess: () => {
      queryClient.refetchQueries('endorsersAccessList');
      queryClient.refetchQueries('accessListById');
      queryClient.refetchQueries('accountAccessList');
      queryClient.refetchQueries('allAccessList');
      queryClient.refetchQueries('networkList');
    },
  });

// grant access post query
const grantAccess = async (params?: GrantAccessRequest): Promise<GrantEndorserAccessResponse> =>
  HttpClient.grantEndorserAccess({ body: params });

export const useGrantAccess = (queryClient: QueryClient) =>
  useMutation(({ params }: { params: GrantAccessRequest }) => grantAccess(params), {
    onSuccess: () => {
      queryClient.refetchQueries('endorsersAccessList');
      queryClient.refetchQueries('accessListById');
      queryClient.refetchQueries('accountAccessList');
      queryClient.refetchQueries('allAccessList');
      queryClient.refetchQueries('networkList');
    },
  });

const acceptRequestAccess = async (
  endorserId: string,
  params?: AcceptInviteOptionalParams,
): Promise<AcceptInviteResponse> => HttpClient.acceptInvite(endorserId, params);

// revoke access
const revokeAccess = async (params?: RevokeAccessRequest): Promise<RevokeAccessOperationResponse> =>
  HttpClient.revokeAccess({ body: params });

/* revoke access query */
export const useRevokeAccess = (queryClient: QueryClient) =>
  useMutation(({ params }: { params?: RevokeAccessRequest }) => revokeAccess(params), {
    onSuccess: () => {
      queryClient.refetchQueries('endorsersAccessList');
      queryClient.refetchQueries('accessListById');
      queryClient.refetchQueries('accountAccessList');
      queryClient.refetchQueries('allAccessList');
      queryClient.refetchQueries('networkList');
    },
  });

/* accept access request query */
export const useAcceptRequest = (queryClient: QueryClient) =>
  useMutation(
    ({ endorserId, params }: { endorserId: string; params?: AcceptInviteOptionalParams }) =>
      acceptRequestAccess(endorserId, params),
    {
      onSuccess: () => {
        queryClient.refetchQueries('endorsersAccessList');
        queryClient.refetchQueries('accessListById');
        queryClient.refetchQueries('accountAccessList');
        queryClient.refetchQueries('allAccessList');
        queryClient.refetchQueries('networkList');
      },
    },
  );

const rejectRequestAccess = async (
  endorserId: string,
  params?: RejectInviteOptionalParams,
): Promise<RejectInviteResponse> => HttpClient.rejectInvite(endorserId, params);

/* reject access request query */
export const useRejectRequest = (queryClient: QueryClient) =>
  useMutation(
    ({ endorserId, params }: { endorserId: string; params?: RejectInviteOptionalParams }) =>
      rejectRequestAccess(endorserId, params),
    {
      onSuccess: () => {
        queryClient.refetchQueries('endorsersAccessList');
        queryClient.refetchQueries('accessListById');
        queryClient.refetchQueries('accountAccessList');
        queryClient.refetchQueries('allAccessList');
        queryClient.refetchQueries('networkList');
      },
    },
  );

const getAccessListByEndorserId = async (
  endorserId: string,
  params?: ListEndorserAccessItemsOptionalParams,
): Promise<ListEndorserAccessItemsResponse> =>
  HttpClient.listEndorserAccessItems(endorserId, params);

/* get access list by id query */
export const useGetAccessListByEndorserId = (
  endorserId: string,
  params?: ListEndorserAccessItemsOptionalParams,
) =>
  useQuery(['accessListById', endorserId, params], () => {
    if (endorserId !== '') {
      return getAccessListByEndorserId(endorserId, params);
    }
    return undefined;
  });

/* get access list by id new mutated query */
export const useGetExpandableAccessList = (queryClient: QueryClient) =>
  useMutation(
    ({
      endorserId,
      params,
    }: {
      endorserId: string;
      params?: ListEndorserAccessItemsOptionalParams;
    }) => getAccessListByEndorserId(endorserId, params),
    {
      onSuccess: () => {
        queryClient.refetchQueries('endorsersAccessList');
        queryClient.refetchQueries('accessListById');
        queryClient.refetchQueries('accountAccessList');
        queryClient.refetchQueries('allAccessList');
        queryClient.refetchQueries('networkList');
      },
    },
  );

/* get all endorsers access list */
const getMyAccessList = async (
  params?: ListMyEndorserAccessItemsOptionalParams,
): Promise<ListMyEndorserAccessItemsResponse> => HttpClient.listMyEndorserAccessItems(params);

export const useGetMyAllAccessList = (
  params?: ListMyEndorserAccessItemsOptionalParams,
  endorserSide?: boolean,
) =>
  useQuery(['allAccessList', params], () => getMyAccessList(params), {
    enabled: endorserSide,
  });

export const useMyEndorsersList = (queryClient: QueryClient) =>
  useMutation(
    ({ params }: { params?: ListMyEndorserAccessItemsOptionalParams }) => getMyAccessList(params),
    {
      onSuccess: () => {
        queryClient.refetchQueries('endorsersAccessList');
        queryClient.refetchQueries('accessListById');
        queryClient.refetchQueries('accountAccessList');
        queryClient.refetchQueries('allAccessList');
        queryClient.refetchQueries('networkList');
      },
    },
  );
