/* eslint-disable no-nested-ternary */
import { useQueryClient } from 'react-query';
import { Typography, Space, Button, message } from 'antd';
import { yearFirstFormat } from 'utils/helpers';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import NoData from 'assets/images/no_data.svg';
import { errorHandler } from 'utils';
import { useClearNotifications } from 'services/api/useNotifications';
import useNotificationsFilters from '../hooks/useNotificationsFilters';
import { NotificationsTabProps, NotificationProps } from '../typings';

const Notifications: FC<NotificationsTabProps> = ({
  setVisible,
  viewDetailsHandler,
  setNotificationData,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const clearNotifications = useClearNotifications(queryClient);

  // api data
  const { notificationsRecords } = useNotificationsFilters();

  const notifications: Array<NotificationProps> = useMemo(
    () =>
      notificationsRecords?.map((item) => ({
        id: item.id,
        title: item.title,
        date: yearFirstFormat(item.createdDate || ''),
        read: item.read,
        type: item.type,
        auditId: item.auditId,
        bapNumber: item.bapNumber,
        accountId: item.accountId,
      })) || [],
    [notificationsRecords],
  );

  const clearReadStateById = async (notificationId: any) => {
    try {
      await clearNotifications.mutateAsync({
        params: { notificationIds: [notificationId] },
      });
    } catch (error) {
      message.error(errorHandler(error));
    }
  };

  return notifications.length !== 0 ? (
    <Space
      direction="vertical"
      size="middle"
      style={{ padding: '0px 15px', height: '290px', overflow: 'auto' }}
    >
      {notifications?.map((notification) => (
        <Space size={0} direction="vertical">
          {notification.type === 'AUDIT_UPDATE' ? (
            <Space
              size="small"
              style={{ cursor: 'pointer', alignItems: 'flex-start' }}
              onClick={() => {
                clearReadStateById(notification.id);
              }}
            >
              {notification?.read ? (
                <Typography.Text type="secondary" style={{ opacity: '0.5' }}>
                  {notification?.title}
                </Typography.Text>
              ) : (
                <Typography.Text strong>{notification?.title}</Typography.Text>
              )}
              <Button
                type="link"
                onClick={() => {
                  setVisible(false);
                  clearReadStateById(notification.id);
                  navigate(
                    `/discover/${notification?.accountId}/audit_report/${notification?.bapNumber}/${notification?.auditId}`,
                  );
                }}
                disabled={notification?.read}
              >
                View Report
              </Button>
            </Space>
          ) : null}
          {notification.type === 'ENDORSER_UPDATE' ? (
            <Space
              size="small"
              style={{ cursor: 'pointer', alignItems: 'flex-start' }}
              onClick={() => {
                clearReadStateById(notification.id);
              }}
            >
              {notification?.read ? (
                <Typography.Text type="secondary" style={{ opacity: '0.5' }}>
                  {notification?.title}
                </Typography.Text>
              ) : (
                <Typography.Text strong>{notification?.title}</Typography.Text>
              )}
              <Button
                type="link"
                onClick={() => {
                  setVisible(false);
                  clearReadStateById(notification.id);
                  navigate(`/discover/${notification?.accountId}`);
                }}
                disabled={notification?.read}
              >
                View Profile
              </Button>
            </Space>
          ) : null}
          {notification.type === 'ENDORSER_INVITE' ? (
            <Space size="small" style={{ cursor: 'pointer', alignItems: 'flex-start' }}>
              {notification?.read ? (
                <Typography.Text type="secondary" style={{ opacity: '0.5' }}>
                  {notification?.title}
                </Typography.Text>
              ) : (
                <Typography.Text strong>{notification?.title}</Typography.Text>
              )}
              <Button
                type="link"
                onClick={() => {
                  setVisible(false);
                  setNotificationData(notification);
                  clearReadStateById(notification.id);
                  viewDetailsHandler();
                }}
                style={notification?.read ? { opacity: '0.5' } : undefined}
                disabled={notification?.read}
              >
                View Request
              </Button>
            </Space>
          ) : null}
        </Space>
      ))}
    </Space>
  ) : (
    <div
      style={{
        height: '200px',
        display: 'block',
        margin: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '50px',
        marginBottom: '41px',
        width: '40%',
      }}
    >
      <img src={NoData} alt="No Data" />
    </div>
  );
};

export default Notifications;
