import { Card, Typography } from 'antd';
import pluralize from 'pluralize';

const { Text } = Typography;

const Box = ({ style, children, timeCount }: any) => (
  <>
    <Text style={{ fontSize: '14px', fontWeight: 'bold', paddingBottom: '30px' }}>
      Total Lifecycle: {timeCount} {pluralize(' day', timeCount)}
    </Text>
    <Card
      bodyStyle={{ ...(style || {}) }}
      style={{
        width: '100%',
        margin: '8px 0px 10px',
      }}
    >
      {children}
    </Card>
  </>
);

export default Box;
