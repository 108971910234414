import create from 'zustand';
import { GetAssessmentAnswersOptionalParams } from 'services/api/client/src';
import { SelectTabStore } from './typings';

const useAuditReportStore = create<SelectTabStore>((set) => ({
  modalVisible: false,
  setModalVisible: (modalVisible) => set(() => ({ modalVisible })),
  checkedBox: ['summary'],
  setCheckedBox: (checkedBox) => set(() => ({ checkedBox })),
  auditReportId: '',
  setAuditReportId: (auditReportId) => set(() => ({ auditReportId })),
  auditReportBAP: '',
  setAuditReportBAP: (auditReportBAP) => set(() => ({ auditReportBAP })),
  auditReportParams: {
    pageNumber: 1,
    pageSize: 900,
  },
  setAuditReportParams: (auditReportParams: GetAssessmentAnswersOptionalParams) =>
    set(() => ({ auditReportParams })),
  backFrom: 'network',
  setBackFrom: (backFrom) => set(() => ({ backFrom })),
}));

export default useAuditReportStore;
