import ProLayout, { MenuDataItem } from '@ant-design/pro-layout';
import Logo from 'assets/images/gsa-svg.svg';
import endorsersMenuData from 'config/endorsersMenuData';
import { ReactNode, useEffect, useCallback } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useReportTabsContext } from 'contexts/ReportTabsProvider';
import { useGetEndorsersReports } from 'services/api/useDashboard';
import useAuditReportStore from 'components/GAuditReport/hooks';
import { Protected } from 'components';
import { Footer } from './components';
import RightContent from './components/RightContent';

const settings = {
  primaryColor: '#f47b20;',
  contentWidth: 'Fluid',
  fixSiderbar: true,
  fixedHeader: true,
  iconfontUrl: '',
  layout: 'top',
  menu: { locale: true },
  navTheme: 'dark',
  title: 'Data Center',
};

const menuItemRender = (menuItem: MenuDataItem, defaultDom: ReactNode) => {
  if (menuItem.isUrl || menuItem.children || !menuItem.path) {
    return defaultDom;
  }
  return <Link to={menuItem.path}>{defaultDom}</Link>;
};

const rightContentRender = () => <RightContent settings={{ navTheme: 'dark', layout: 'side' }} />;

const EndorsersLayout = () => {
  const location = useLocation();
  const setBackFrom = useAuditReportStore((state) => state.setBackFrom);
  const { endorsersReportsData, setEndorsersReportsData } = useReportTabsContext();
  const { data: reportTabsData } = useGetEndorsersReports({}, true);

  useEffect(() => {
    setBackFrom('network');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  useEffect(() => {
    setEndorsersReportsData(reportTabsData);
  }, [reportTabsData, setEndorsersReportsData]);

  const menuDataRender = useCallback(
    () => [
      ...endorsersMenuData,
      ...(endorsersReportsData?.reports?.map((tab) => ({
        path: `/${tab?.tabName?.toLowerCase()}`,
        name: tab?.tabName || '',
      })) || []),
    ],
    [endorsersReportsData?.reports],
  );

  return (
    <Protected>
      <ProLayout
        logo={Logo}
        location={location}
        menuDataRender={menuDataRender}
        menuItemRender={menuItemRender}
        itemRender={() => null}
        footerRender={Footer}
        rightContentRender={rightContentRender}
        contentStyle={{
          backgroundColor: '#F4F4F4',
        }}
        {...settings}
      >
        <Outlet />
      </ProLayout>
    </Protected>
  );
};

export default EndorsersLayout;
