import { DownOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Dropdown,
  Menu,
  Modal,
  Row,
  Segmented,
  Space,
  Switch,
  Typography,
} from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { SegmentedValue } from 'antd/lib/segmented';
import type { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { useCallback, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { attributes, documentJSONtoXML, downloadJsonToCSV } from 'utils/helpers';
import { EPCISContent, EventDetailsContent } from './Sections';
import { NodeModalProps } from './typings';

const { Title } = Typography;

type ActionItemsProps = {
  activeSection?: string | number;
  t: TFunction<'pages', 'trace.trace_diagram'>;
};

const actionItems = ({ activeSection, t }: ActionItemsProps): ItemType[] => {
  switch (activeSection) {
    case 'Event Details':
      return [
        {
          key: 'downloadCSV',
          label: t('modal.download_csv'),
        },
      ];
    case 'EPCIS':
      return [
        {
          key: 'downloadXML',
          label: t('modal.download_xml'),
        },
      ];
    default:
      return [
        {
          key: 'downloadCSV',
          label: t('modal.download_csv'),
        },
      ];
  }
};

const NodeModal = ({ modal, selectedNode }: NodeModalProps) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'trace.trace_diagram',
  });
  const sections = ['Event Details'];
  const [isPretty, setPretty] = useState<boolean>(true);
  const [activeSection, setActiveSection] = useState<string | number>(sections[0]);

  const onTemplateTitleChange = (value: SegmentedValue) => {
    setActiveSection(value);
  };

  const onCloseModal = () => {
    modal.hide();
  };

  const renderContent = useCallback(() => {
    switch (activeSection) {
      case 'Event Details':
        return <EventDetailsContent selectedNode={selectedNode} />;
      case 'EPCIS':
        return <EPCISContent activeEvent={selectedNode} isPretty={isPretty} />;
      default:
        return <EventDetailsContent selectedNode={selectedNode} />;
    }
  }, [activeSection, isPretty, selectedNode]);

  const onDownloadNodeXML = useCallback(() => {
    documentJSONtoXML(selectedNode?.eventData);
  }, [selectedNode?.eventData]);

  const onDiagramCsvDownloadClick = useCallback(() => {
    const values = [
      selectedNode?.eventData?.accountName,
      selectedNode?.created,
      selectedNode?.eventData?.timeZoneOffset,
      selectedNode?.eventData?.urn,
      selectedNode?.products?.[selectedNode.products.length - 1]?.shortDescription,
      selectedNode?.products?.[selectedNode.products.length - 1]?.lotId,
      selectedNode?.products?.[selectedNode.products.length - 1]?.quantity,
      selectedNode?.location?.name,
      String(selectedNode?.eventData?.location?.latitude).concat(
        `, ${String(selectedNode?.eventData?.location?.longitude)}`,
      ),
      '',
    ];
    const data: any = attributes.map((item, idx) => ({
      id: idx.toString(),
      attribute: item,
      values: values[idx],
    }));
    downloadJsonToCSV(data, 'event_details');
  }, [selectedNode]);

  const onNodeModalItemClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case 'downloadCSV':
        onDiagramCsvDownloadClick();
        break;
      case 'downloadXML':
        onDownloadNodeXML();
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      visible={modal.visible}
      title={
        <Row gutter={0}>
          <Col style={{ padding: '4px 2px' }}>
            <Title level={4}>{selectedNode?.products?.[0]?.shortDescription}</Title>
          </Col>
          <Col style={{ padding: '4px 2px' }}>
            <Segmented options={sections} value={activeSection} onChange={onTemplateTitleChange} />
          </Col>
        </Row>
      }
      closable
      onCancel={onCloseModal}
      footer={
        <>
          {activeSection === 'EPCIS' && (
            <span
              style={{
                float: 'left',
              }}
            >
              Beautify:&nbsp;
              <Switch
                defaultChecked
                size="small"
                checked={isPretty}
                style={{
                  zIndex: 1200,
                }}
                onChange={setPretty}
              />
            </span>
          )}
          <Space>
            <Dropdown
              overlay={
                <Menu onClick={onNodeModalItemClick} items={actionItems({ activeSection, t })} />
              }
              placement="top"
              overlayStyle={{ zIndex: '1500' }}
            >
              <Button type="primary" ghost shape="round">
                <Space>
                  {t('modal.action_button_text')}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Button
              onClick={onCloseModal}
              type="primary"
              shape="round"
              style={{
                color: '#0a5f7a',
                borderColor: '#bcd530',
                background: '#bcd530',
              }}
            >
              {t('modal.done_button_text')}
            </Button>
          </Space>
        </>
      }
      width="58%"
      bodyStyle={{ height: '60%' }}
      centered
    >
      {renderContent()}
    </Modal>
  );
};

export default NodeModal;
