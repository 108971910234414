import type { ActionType } from '@ant-design/pro-table';
import { ProColumns } from '@ant-design/pro-table';
import { Space, Tag } from 'antd';
import GTable from 'components/GTable';
import { FC, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { dateFormat } from 'utils/helpers';
import { ModalContentProps, ModalItem, ModalTitleProp } from '../typings';
import styles from './index.module.less';

type ActionItemsProps = {
  t: TFunction<'pages', 'trace.trace_diagram'>;
};
const columns = ({ t }: ActionItemsProps): ProColumns<ModalItem>[] => [
  {
    title: t('modal.attributes'),
    dataIndex: 'attributes',
    width: '30%',
  },
  {
    title: t('modal.values'),
    dataIndex: 'values',
    render: (text) => text,
  },
];

const ModalTitle: FC<ModalTitleProp> = ({ selectedMarker, activeEvent }) => (
  <Space size={0}>
    <Tag className={styles.headertag}>{selectedMarker?.events?.[activeEvent]?.eventType}</Tag>
    <Tag className={styles.headertag}>
      {`Lot: ${
        selectedMarker?.events?.[activeEvent]?.eventType?.includes('Transform')
          ? selectedMarker?.events?.[activeEvent]?.products?.filter(
              (item: any) => item.direction === 'OUTPUT',
            )?.[0]?.lotId
          : selectedMarker?.events?.[activeEvent]?.products?.[0]?.lotId
      }`}
    </Tag>
  </Space>
);

const EventDetailsContent = ({ selectedMarker, activeEvent }: ModalContentProps) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'trace.trace_diagram',
  });
  const actionRef = useRef<ActionType>();
  const attributesArray: any = [
    t('modal.account_name'),
    // t('modal.account_id'),
    t('modal.time_date'),
    t('modal.timezone'),
    t('modal.urn'),
    t('modal.product'),
    t('modal.lot'),
    t('modal.quantity'),
    t('modal.event_location'),
    t('modal.event_geolocation'),
    t('modal.purchase_order'),
  ];

  const valuesArray: any = [
    selectedMarker?.events?.[activeEvent]?.company,
    selectedMarker?.events?.[activeEvent]?.products &&
      selectedMarker?.events?.[activeEvent]?.products?.[
        selectedMarker.events[activeEvent].products.length - 1
      ]?.accountID,
    dateFormat(selectedMarker?.events?.[activeEvent]?.eventTime || ''),
    selectedMarker?.events?.[activeEvent]?.timeZoneOffset,
    selectedMarker?.events?.[activeEvent]?.urn,
    selectedMarker?.events?.[activeEvent]?.products?.[
      selectedMarker.events[activeEvent].products.length - 1
    ]?.shortDescription,
    selectedMarker?.events?.[activeEvent]?.products?.[
      selectedMarker.events[activeEvent].products.length - 1
    ]?.lotId,
    selectedMarker?.events?.[activeEvent]?.products?.[
      selectedMarker.events[activeEvent].products.length - 1
    ]?.quantity,
    selectedMarker?.events?.[activeEvent]?.location?.name,
    String(selectedMarker?.events?.[activeEvent]?.location?.latitude).concat(
      `, ${String(selectedMarker?.events?.[activeEvent]?.location?.longitude)}`,
    ),
    '',
  ];

  const eventData: any = attributesArray.map((item: string, idx: number) => ({
    id: idx.toString(),
    attributes: item,
    values: valuesArray[idx],
  }));

  return (
    <div style={{ height: '500px', overflow: 'y' }}>
      <GTable<ModalItem>
        columns={columns({ t })}
        actionRef={actionRef}
        headerTitle={<ModalTitle selectedMarker={selectedMarker} activeEvent={activeEvent} />}
        value={eventData}
        options={{
          setting: false,
          reload: false,
        }}
        scroll={{ y: 410, x: 500 }}
      />
    </div>
  );
};

export default EventDetailsContent;
