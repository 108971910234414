import { InfoCircleOutlined } from '@ant-design/icons';
import { Select, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { isInvalidEmail } from 'utils/helpers';
import styles from './index.module.less';
import { TagInputProp, TagProp } from './typings';

const TagRender = ({ prop }: TagProp) => {
  const { label, closable, onClose } = prop;

  return (
    <Tag
      {...prop}
      key={String(`key${label}`)}
      icon={
        isInvalidEmail(String(label)) && (
          <Tooltip title="Invalid Email">
            <InfoCircleOutlined className={styles.alerticon} />
          </Tooltip>
        )
      }
      closable={closable}
      onClose={onClose}
      className={styles.emailtag}
    >
      {label}
    </Tag>
  );
};

const TagInput = ({ placeholder, emails, setEmails }: TagInputProp) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation('pages', { keyPrefix: 'company.invite' });
  return (
    <Select
      placeholder={placeholder}
      mode="tags"
      onChange={setEmails}
      value={emails}
      tokenSeparators={[',']}
      open={false}
      tagRender={(prop) => TagRender({ prop })}
    />
  );
};

export default TagInput;
