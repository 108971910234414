import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';

const { Footer } = Layout;

const Foot = () => {
  const location = useLocation();

  return (
    <Footer
      style={{
        textAlign: 'center',
        padding: '15px',
        backgroundColor: location.pathname.includes('/trace') ? '#fff' : '#F4F4F4',
        color: '#8C8C8C',
      }}
    >
      Copyright © 2023 Global Seafood Alliance, All rights reserved. Privacy
    </Footer>
  );
};

export default Foot;
