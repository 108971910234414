import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { useTableSearchFilter } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAPIAuditReports } from 'services/api/Endorsers';
import { AuditReportItem, ListAuditReportsOptionalParams } from 'services/api/client/src';
import useTabStore from './useTabStore';

const useAuditReportsFilters = (initParams?: ListAuditReportsOptionalParams) => {
  const search = useTabStore((state) => state.auditSearch);
  const [auditReportsParams, setAuditReportsParams] = useState<ListAuditReportsOptionalParams>({
    pageSize: 50,
    pageNumber: 1,
    ...initParams,
  });
  const [auditReports, setAuditReports] = useState<AuditReportItem[]>([]);

  const { data: auditReportsResponse, isLoading: isAuditReportsLoading } =
    useAPIAuditReports(auditReportsParams);

  const totalItems = useMemo(() => auditReportsResponse?.totalItems || 0, [auditReportsResponse]);
  useEffect(() => {
    setAuditReports(
      auditReportsResponse?.results?.map((item) => ({
        ...item,
        id: item.reportId,
      })) || [],
    );
  }, [auditReportsResponse?.results]);

  useEffect(() => {
    setAuditReportsParams({
      ...auditReportsParams,
      facilityName: search?.facilityName,
      // completedDate: search?.date,
      bapNumber: initParams?.bapNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { filters: facilityNameSearchFilter } = useTableSearchFilter({
    title: 'Facility Name',
  });

  const { filters: bapNumberSearchFilter } = useTableSearchFilter({
    title: 'BAP Number',
  });

  const onAuditReportsTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: any,
    ) => {
      const paginate = pagination?.current
        ? {
            pageNumber: pagination?.current || 1,
            pageSize: pagination?.pageSize || 20,
          }
        : {};
      const facilityNameFilter = filters?.facilityName
        ? {
            facilityName: filters?.facilityName?.[0] as string,
          }
        : {};
      const statusFilter = filters?.status
        ? {
            status: filters?.status as string[],
          }
        : {};
      const sortDirection =
        sorter?.field && sorter?.order
          ? {
              orderBy: sorter?.field === 'auditEndDate' ? 'AuditEndDate' : 'SubmittedDate',
              direction: sorter?.order === 'ascend' ? 'Asc' : 'Desc',
            }
          : {};

      setAuditReportsParams({
        ...paginate,
        ...facilityNameFilter,
        ...statusFilter,
        ...sortDirection,
        bapNumber: initParams?.bapNumber,
      });
    },
    [initParams?.bapNumber],
  );

  return {
    auditReports,
    isAuditReportsLoading,
    auditReportsParams,
    setAuditReportsParams,
    totalItems,
    onAuditReportsTableChange,
    filters: [facilityNameSearchFilter, bapNumberSearchFilter],
  };
};

export default useAuditReportsFilters;
