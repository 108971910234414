import { FC, useState, useEffect, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Card, Space, Segmented, Spin } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { useParams, useNavigate } from 'react-router-dom';
import { Loading } from 'components';
import GTable from 'components/GTable';
import { ProColumns } from '@ant-design/pro-table';
import EndorserTitlebar from 'components/TitleBar/EndorserTitlebar';
import { GetAssessmentAnswersResponse } from 'services/api/client/src';
import { useUserInfo } from 'services/api/useSettings';
import { useAuditReportById, AuditReportProps } from 'services/api/Endorsers';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { yearFirstFormat } from 'utils/helpers';
import DownloadModal from './Modal/DownloadModal';
import useAuditReportStore, { useAuditReportFilters } from './hooks';
import styles from './index.module.less';
import { GAuditReportTitleProps, GAuditReportProps } from './typings';

type ActionItemsProps = {
  t: TFunction<'pages', 'endorsers_partners.view_company.audit_report'>;
};

type filterType = {
  text: string;
  value: string;
};
const mainActionItems = ({ t }: ActionItemsProps): Array<ItemType> => [
  {
    key: 'download',
    label: t('main_actions.download_report'),
  },
];

type ColumnsType = {
  referenceSearchFilter: ProColumns;
  causeSearchFilter: ProColumns;
  onRowActionClick?: (actionItemKey: string, actionPayload?: AuditReportProps) => void;
  t: TFunction<'pages', 'endorsers_partners.view_company.audit_report'>;
  auditReport?: Array<AuditReportProps>;
  auditReportResponse?: GetAssessmentAnswersResponse;
  bapNumberFilter: Array<filterType>;
  isGroup?: boolean;
};

const columns = ({
  referenceSearchFilter,
  causeSearchFilter,
  t,
  // auditReportResponse,
  bapNumberFilter,
  isGroup,
}: ColumnsType): Array<ProColumns<AuditReportProps>> => [
  {
    title: 'Phase',
    dataIndex: 'phase',
    width: '12%',
    ellipsis: true,
    hideInTable: !isGroup,
    filters: [
      {
        text: 'Phase 1',
        value: 'Phase 1',
      },
      {
        text: 'Phase 2',
        value: 'Phase 2',
      },
      {
        text: 'Phase 3',
        value: 'Phase 3',
      },
      {
        text: 'On Site Audit',
        value: 'On Site Audit',
      },
    ],
    onFilter: (value: any, record) => record?.phase?.includes(value) as any,
  },
  {
    title: 'BAP Number',
    dataIndex: 'bapNumber',
    width: '12%',
    ellipsis: true,
    hideInTable: !isGroup,
    filters: bapNumberFilter,
    onFilter: (value: any, record) => record?.bapNumber?.includes(value) as any,
  },
  {
    title: t('table.pillar'),
    dataIndex: 'pillar',
    width: '12%',
    ellipsis: true,
    filters: [
      {
        text: 'Social Accountability',
        value: 'Social Accountability',
      },
      {
        text: 'Environmental',
        value: 'Environmental',
      },
      {
        text: 'Food Safety',
        value: 'Food Safety',
      },
      {
        text: 'Animal Welfare',
        value: 'Animal Welfare',
      },
    ],
    hideInTable: isGroup,
  },
  {
    title: t('table.reference'),
    dataIndex: 'reference',
    width: '12%',
    ellipsis: true,
    ...referenceSearchFilter,
  },
  {
    title: t('table.cause_and_explaination'),
    dataIndex: 'cause',
    width: '52%',
    ellipsis: false,
    render: (text: any, data: any) => (
      <>
        <div> Clause :</div>
        <div>{data?.cause?.cause}</div>
        <br />
        <div> Explanation :</div>
        <div>{data?.cause?.explanation}</div>
      </>
    ),
    ...causeSearchFilter,
  },
  {
    title: t('table.conforming'),
    dataIndex: 'conforming',
    width: '12%',
    ellipsis: true,
    filters: [
      {
        text: 'Yes',
        value: 'Yes',
      },
      {
        text: 'N/A',
        value: 'N/A',
      },
      {
        text: 'No - Minor',
        value: 'No - Minor',
      },
      {
        text: 'No - Major',
        value: 'No - Major',
      },
      {
        text: 'No - Critical',
        value: 'No - Critical',
      },
    ],
  },
];

const TableTitle = ({
  setTab,
  toggleAuditReportShow,
  loggedInUserData,
  from,
}: GAuditReportTitleProps) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.audit_report',
  });

  const navigate = useNavigate();

  const { reportId, bapNumber } = useParams();
  const { data: auditReportByIdResponse, isLoading } = useAuditReportById(
    reportId || '',
    bapNumber || '',
  );
  const setModalVisible = useAuditReportStore((state) => state.setModalVisible);
  const setCheckedBox = useAuditReportStore((state) => state.setCheckedBox);
  const setBackFrom = useAuditReportStore((state) => state.setBackFrom);
  const setAuditReportParams = useAuditReportStore((state) => state.setAuditReportParams);
  const sections = ['Summary', 'Audit Report'];
  const [section, setSection] = useState<string | number>(sections[1]);

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'download':
        setModalVisible(true);
        break;

      default:
        break;
    }
  };

  const onSegmentedButtonClick = (value: SegmentedValue) => {
    setSection(value);
    switch (value) {
      case 'Summary':
        setCheckedBox(['summary']);
        setTimeout(() => {
          toggleAuditReportShow(false);
        }, 300);
        break;
      case 'Audit Report':
        break;

      default:
        break;
    }
  };
  const backPress = () => {
    toggleAuditReportShow(false);
    setTab('auditReports');
    setBackFrom('auditReport');
    setAuditReportParams({ pageSize: 600, pageNumber: 1 });
    navigate(-1);
  };

  return (
    <EndorserTitlebar
      title={
        <Space>
          {isLoading ? (
            <Spin size="small" />
          ) : (
            String(auditReportByIdResponse?.auditReport?.auditKpi?.orgName).concat(
              ` (${auditReportByIdResponse?.auditReport?.bapNumber}) `.concat(
                String(
                  yearFirstFormat(
                    auditReportByIdResponse?.auditReport?.auditKpi?.submittedDate || '',
                  ),
                ),
              ),
            )
          )}
          <Segmented value={section} options={sections} onChange={onSegmentedButtonClick} />
        </Space>
      }
      onBack={backPress}
      actionItems={
        (from !== 'profile' &&
          from !== 'endorsersMain' &&
          loggedInUserData?.user?.role?.permission?.includes('DOWNLOADING_AUDIT_REPORTS')) ||
        from === 'profile' ||
        from === 'endorsersMain'
          ? mainActionItems({ t })
          : undefined
      }
      onActionItemClick={onActionItemClick}
    />
  );
};

const GAuditReport: FC<GAuditReportProps> = ({
  setTab,
  toggleAuditReportShow,
  setAuditSearch,
  from,
}) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.audit_report',
  });
  const { data: loggedInUserData } = useUserInfo();

  const auditReportParams = useAuditReportStore((state) => state.auditReportParams);
  const setAuditReportParams = useAuditReportStore((state) => state.setAuditReportParams);

  const {
    auditReport,
    onAuditReportTableChange,
    filters: [referenceSearchFilter, causeSearchFilter],
    isAuditReportLoading,
    firstLoading,
    auditReportResponse,
  } = useAuditReportFilters();

  const [isGroup, setIsGroup] = useState(false);
  useEffect(() => {
    setIsGroup(auditReportResponse?.assessmentType === 'Group');
  }, [auditReportResponse]);

  useEffect(
    () => () => {
      setAuditReportParams({
        ...auditReportParams,
        pillars: undefined,
        referenceNumber: undefined,
        causeExplanation: undefined,
        answers: undefined,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAuditReportParams],
  );

  const onRowActionClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'view_product':
        break;

      default:
        break;
    }
  };

  const setModalVisible = useAuditReportStore((state) => state.setModalVisible);
  const setCheckedBox = useAuditReportStore((state) => state.setCheckedBox);
  setCheckedBox(['auditReport']);
  const onCloseModal = () => {
    setCheckedBox(['auditReport']);
    setModalVisible(false);
  };

  // remove duplicates
  const removeDuplicates = (value: any, index: any, self: any) =>
    index === self.findIndex((val: any) => val.bapNumber === value.bapNumber);

  const bapNumberFilter: filterType[] = useMemo(
    () =>
      auditReport?.filter(removeDuplicates)?.map((item) => ({
        text: item.bapNumber || '',
        value: item.bapNumber || '',
      })) || [],
    [auditReport],
  );

  return (
    <>
      <DownloadModal auditReportRecord={auditReport} onCloseModal={onCloseModal} />
      <div className={styles.cardwrapper}>
        {firstLoading ? (
          <Loading />
        ) : (
          <Card bordered={false} bodyStyle={{ padding: '5px 15px', paddingBottom: '24px' }}>
            <div className={styles.table}>
              <GTable<AuditReportProps>
                columns={columns({
                  referenceSearchFilter,
                  causeSearchFilter,
                  onRowActionClick,
                  t,
                  auditReport,
                  auditReportResponse,
                  bapNumberFilter,
                  isGroup,
                })}
                value={auditReport}
                headerTitle={
                  <TableTitle
                    setTab={setTab}
                    toggleAuditReportShow={toggleAuditReportShow}
                    setAuditSearch={setAuditSearch}
                    loggedInUserData={loggedInUserData}
                    from={from}
                  />
                }
                options={{
                  setting: false,
                  reload: false,
                }}
                loading={isAuditReportLoading}
                onTableChange={onAuditReportTableChange}
                recordCreatorProps={false}
                scroll={{ x: 900, y: 700 }}
              />
            </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default GAuditReport;
