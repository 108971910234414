import { getVariableFromEnv } from 'utils/config';

export const scopes = [getVariableFromEnv('AZURE_AD_SCOPE')];
export const msalConfig = {
  auth: {
    authority: getVariableFromEnv('IDP_AUTHORITY'),
    clientId: getVariableFromEnv('IDP_CLIENT_ID'),
    knownAuthorities: [getVariableFromEnv('AZURE_AD_KNOWN_AUTHORITY')],
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: '/',
    scopes,
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const signinRequest = {
  scopes,
};

export const signupRequest = {
  scopes: [...scopes, 'openid'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

// get redirect url from host
