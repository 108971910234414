import { BellOutlined } from '@ant-design/icons';
import { Badge, Dropdown } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import { useModalVisibility } from 'hooks';
import useNotificationsFilters from './hooks/useNotificationsFilters';
import styles from './index.module.less';
import NotificationBox from './NotificationBox';

const NotificationDropdown = () => {
  const { visible, setVisible } = useModalVisibility(false);
  const [activeKey, setActiveKey] = useState('notification');
  const { notificationListLength, recentActivityListLength } = useNotificationsFilters();

  return (
    <Dropdown
      placement="bottomRight"
      overlay={
        <NotificationBox
          setVisible={setVisible}
          notificationListLength={notificationListLength}
          recentActivityListLength={recentActivityListLength}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
        />
      }
      overlayClassName={styles.popover}
      overlayStyle={{
        background: 'white',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        width: '300px',
        height: '400px',
        position: 'fixed',
      }}
      trigger={['click']}
      visible={visible}
      onVisibleChange={setVisible}
    >
      <span className={classNames(styles.noticeButton)}>
        <Badge
          count={notificationListLength}
          size="small"
          style={{ boxShadow: '0 0 0 1px #ff4d4f' }}
        >
          <BellOutlined
            style={{
              display: 'inlineBlock',
              cursor: 'pointer',
              transition: 'all 0.3s',
              fontSize: '14px',
              margin: '0px 8px',
              marginRight: '12px',
              color: 'white',
            }}
          />
        </Badge>
      </span>
    </Dropdown>
  );
};
export default NotificationDropdown;
