/* eslint-disable no-nested-ternary */
import { ProColumns } from '@ant-design/pro-table';
import { Rate, Tag, Typography, Tooltip, Empty } from 'antd';
import pluralize from 'pluralize';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import EndorserActions from 'components/Actions/EndorserActions';
import GTable from 'components/GTable';
import { useGTableContext } from 'components/GTable/contexts';
import EndorserTitlebar from 'components/TitleBar/EndorserTitlebar';
import { FC, useState, useEffect, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useModalVisibility from 'hooks/useModalVisibility';
import { yearFirstFormat } from 'utils/helpers';
import { useGetMyAllAccessList } from 'services/api/Endorsers/useAccess';
import { ListMyEndorserAccessItemsResponse } from 'services/api/client/src';
import RequestAccess, { EditAccessModal } from './Modal';
import useDiscoverListFilters from './hooks';
import styles from './index.module.less';
import { ActionMenu, DiscoverTitleProps, UpdatedEndorserDTO, FilterArrayProp } from './typings';

type ActionItemsProps = {
  t: TFunction<'pages', 'discover'>;
  record: UpdatedEndorserDTO;
  myEndorsersListResponse?: ListMyEndorserAccessItemsResponse;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mainActionItems = ({ selectedRowKeys, t }: ActionMenu): Array<ItemType> => [
  {
    key: 'requestAccess',
    label: t('main_actions.request_access'),
    disabled: true,
  },
];

const discoverActionItems = ({
  t,
  record,
  myEndorsersListResponse,
}: ActionItemsProps): Array<ItemType> => [
  {
    key: 'viewDashboard',
    label: t('item_actions.view_dashboard'),
  },
  myEndorsersListResponse?.results?.find((item) => item.endorserId === record?.id)
    ? { key: 'manageAccess', label: t('main_actions.manage_access'), disabled: true }
    : {
        key: 'requestAccess',
        label: t('main_actions.request_access'),
        disabled: true,
      },
];

type ColumnsType = {
  producerSearchFilter: ProColumns;
  bapNumberSearchFilter: ProColumns;
  onRowActionClick: (actionItemKey: string, actionPayload?: UpdatedEndorserDTO) => void;
  t: TFunction<'pages', 'discover'>;
  speciesListFilter: Array<FilterArrayProp>;
  countriesListFilter: Array<FilterArrayProp>;
  myEndorsersListResponse?: ListMyEndorserAccessItemsResponse;
};

const columns = ({
  producerSearchFilter,
  bapNumberSearchFilter,
  onRowActionClick,
  t,
  speciesListFilter,
  countriesListFilter,
  myEndorsersListResponse,
}: ColumnsType): Array<ProColumns<UpdatedEndorserDTO>> => [
  {
    title: t('table.producer'),
    dataIndex: 'producer',
    ellipsis: true,
    ...producerSearchFilter,
    render: (text, record) => (
      <Link className="text-link" to={`/discover/${record.accountID}`} title={`View ${text}`}>
        {text}
      </Link>
    ),
    width: 270,
  },
  {
    title: t('table.species'),
    dataIndex: 'species',
    filters: speciesListFilter,
    render: (text, records) =>
      records?.id && records?.species?.length === 1 ? (
        <Tag
          style={{
            borderRadius: '15px',
            fontWeight: '500',
            color: '#0A5F7A',
            border: '1.3px solid #0A5F7A',
            backgroundColor: '#E7F0F2',
          }}
        >
          {records?.species?.[0]?.category}
        </Tag>
      ) : records?.id && records?.bap ? (
        <Tooltip
          placement="bottom"
          title={
            records?.species?.length === 0 ? (
              <div>No Species.</div>
            ) : (
              <div>
                {records?.species?.map((item) => (
                  <div>{item?.category}</div>
                ))}
              </div>
            )
          }
        >
          <Tag
            style={{
              borderRadius: '15px',
              fontWeight: '500',
              color: '#0A5F7A',
              border: '1.3px solid #0A5F7A',
              backgroundColor: '#E7F0F2',
            }}
          >
            {records?.species?.length} Species
          </Tag>
        </Tooltip>
      ) : !records?.id ? (
        <div>
          {records?.species?.[0]?.category}{' '}
          <Rate
            disabled
            count={records?.species?.[0]?.stars}
            defaultValue={records?.species?.[0]?.stars}
            style={{ fontSize: '16px' }}
          />
        </div>
      ) : null,
  },
  {
    title: t('table.producer_type'),
    dataIndex: 'producerType',
    ellipsis: true,
    filters: [
      {
        text: 'Farm',
        value: 'Farm',
      },
      {
        text: 'Hatchery',
        value: 'Hatchery',
      },
      {
        text: 'Feed Mill',
        value: 'Feed Mill',
      },
      {
        text: 'Supplier',
        value: 'Supplier (manf, importer)',
      },
      {
        text: 'Retail',
        value: 'Retail (supermarket, FDM, e-commerce, specialty)',
      },
      {
        text: 'Corporation',
        value: 'Corporation',
      },
      {
        text: 'Foodservice',
        value: 'Foodservice (dist, merch, rest, hotel, entertnmt, health, airline)',
      },
      {
        text: 'Processor',
        value: 'Processor',
      },
      {
        text: 'Re-processor',
        value: 'Re-processor',
      },
    ],
  },
  {
    title: t('table.country'),
    dataIndex: 'country',
    ellipsis: true,
    filters: countriesListFilter,
  },
  {
    title: t('table.bap_number'),
    dataIndex: 'bap',
    ellipsis: true,
    ...bapNumberSearchFilter,
  },
  {
    title: t('table.expiration'),
    dataIndex: 'expiration',
    ellipsis: true,
    render: (text, record) => (record?.id ? yearFirstFormat(String(record?.expiration)) : ' '),
    sorter: true,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    render: (text, record) =>
      record?.id ? (
        <EndorserActions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
          }}
          size="small"
          items={discoverActionItems({ t, record, myEndorsersListResponse })}
          onClick={onRowActionClick}
          actionButtonColor={{
            color: '#0a5f7a',
            borderColor: '#0a5f7a',
            background: 'transparent',
            textShadow: '0px 0px',
            boxShadow: '0px 0px',
          }}
          actionPayload={record}
        />
      ) : null,
    fixed: 'right',
    width: 120,
  },
];

const TableTitle = ({ modal, setSelectedRow, setSelectedRowsId }: DiscoverTitleProps) => {
  const { selectedRowKeys } = useGTableContext();
  const { t } = useTranslation('pages', { keyPrefix: 'discover' });

  useEffect(() => {
    setSelectedRow(
      selectedRowKeys.length === 1
        ? String(selectedRowKeys[0])
        : String(`${selectedRowKeys.length}-`),
    );
    setSelectedRowsId(selectedRowKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowKeys]);

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'requestAccess':
        modal.show();
        break;

      default:
        break;
    }
  };

  return (
    <EndorserTitlebar
      title={t('table.title')}
      extra={
        selectedRowKeys.length > 0 && (
          <Typography.Text className="selectiontext">
            {`${selectedRowKeys.length} ${pluralize('Producer', selectedRowKeys.length)} Selected`}
          </Typography.Text>
        )
      }
      actionItems={mainActionItems({ selectedRowKeys, t })}
      onActionItemClick={onActionItemClick}
      fontSize
      className={styles.discover}
    />
  );
};

const Discover: FC = () => {
  const { selectedRowKeys, onRowSelectionChange } = useGTableContext();
  const navigate = useNavigate();
  const { data: myEndorsersListResponse } = useGetMyAllAccessList({}, true);
  const requestAccessModal = useModalVisibility(false);
  const manageAccessModal = useModalVisibility(false);
  const [selectedRowData, setSelectedRowData] = useState<UpdatedEndorserDTO>();
  const [selectedRowsId, setSelectedRowsId] = useState<any>([]);
  const [companyName, setCompanyName] = useState('');
  const [selectedRow, setSelectedRow] = useState('');

  const { t } = useTranslation('pages', { keyPrefix: 'discover' });
  // Discover List
  const {
    discoverList,
    isDiscoverListLoading,
    discoverListParams,
    totalItems,
    onDiscoverListTableChange,
    filters: [producerSearchFilter, bapNumberSearchFilter],
    speciesListResponse,
    countriesListResponse,
    selectedFilter,
  } = useDiscoverListFilters({ partnerTypes: ['Facility'] });

  // species list response
  const speciesListFilter: Array<FilterArrayProp> = useMemo(
    () =>
      speciesListResponse?.species?.map((item: string) => ({
        text: item || '',
        value: item || '',
      })) || [],
    [speciesListResponse],
  );

  // countries list response
  const countriesListFilter: Array<FilterArrayProp> = useMemo(
    () =>
      countriesListResponse?.countries?.map((item: string) => ({
        text: item || '',
        value: item || '',
      })) || [],
    [countriesListResponse],
  );

  const ecosystem: Array<UpdatedEndorserDTO> = discoverList.map((item) => ({
    ...item,
  }));

  useEffect(() => {
    if (selectedRow.charAt(selectedRow.length - 1) === '-') {
      setCompanyName(`${selectedRow.replace('-', '')} Producers`);
    } else {
      const payload = ecosystem.find((key) => String(key.id) === String(selectedRow));
      setCompanyName(String(payload?.producer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  const onRowActionClick = (actionItemKey: string, actionPayload?: UpdatedEndorserDTO) => {
    switch (actionItemKey) {
      case 'viewDashboard':
        navigate(`${actionPayload?.accountID}`);
        break;
      case 'requestAccess':
        setCompanyName(actionPayload?.producer || '');
        setSelectedRowsId([actionPayload?.accountID]);
        requestAccessModal.show();
        break;
      case 'manageAccess':
        setSelectedRowData(actionPayload);
        manageAccessModal.show();
        break;

      default:
        break;
    }
  };

  return (
    <div className={styles.container}>
      {requestAccessModal && requestAccessModal.visible && (
        <RequestAccess
          modal={requestAccessModal}
          companyName={companyName}
          selectedRowsId={selectedRowsId}
        />
      )}
      {manageAccessModal && manageAccessModal.visible && (
        <EditAccessModal
          modal={manageAccessModal}
          selectedRowData={selectedRowData}
          setSelectedRowData={setSelectedRowData}
          myEndorsersListResponse={myEndorsersListResponse}
        />
      )}
      <GTable<UpdatedEndorserDTO>
        columns={columns({
          producerSearchFilter,
          bapNumberSearchFilter,
          onRowActionClick,
          t,
          speciesListFilter,
          countriesListFilter,
          myEndorsersListResponse,
        })}
        headerTitle={
          <TableTitle
            modal={requestAccessModal}
            setSelectedRow={setSelectedRow}
            setSelectedRowsId={setSelectedRowsId}
          />
        }
        value={ecosystem}
        enableRowSelection
        options={{
          setting: false,
          reload: false,
        }}
        loading={isDiscoverListLoading}
        onTableChange={onDiscoverListTableChange}
        pagination={{
          defaultPageSize: discoverListParams?.pageSize,
          total: totalItems,
          current: discoverListParams?.pageNumber,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Producers`,
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: onRowSelectionChange,
          columnWidth: 30,
          getCheckboxProps: (record) => ({
            disabled: !record.id,
          }),
        }}
        scroll={{ x: 1800 }}
        noDataContent={
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className={styles.sktcontainer}
            description={
              <span>No Result{`${selectedFilter}`}. Please search for something else</span>
            }
          />
        }
      />
    </div>
  );
};

export default Discover;
