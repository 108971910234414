import { Card } from 'antd';
import { FC } from 'react';
import styles from './index.module.less';
import { DetailsEpcisProps } from '../typings';

const EPCISContent: FC<DetailsEpcisProps> = ({ activeEvent, isPretty, eventIdx }) => (
  <Card className={styles.mrt10}>
    {isPretty && (
      <pre className={styles.jsonbox}>
        {JSON.stringify(activeEvent?.events?.[eventIdx], null, 2)}
      </pre>
    )}
    {!isPretty && (
      <p className={styles.jsonbox}>{JSON.stringify(activeEvent?.events?.[eventIdx], null, 2)}</p>
    )}
  </Card>
);

export default EPCISContent;
