import { FC } from 'react';
import { Button, Empty, Typography } from 'antd';
import { noAccessIcon } from 'assets';
import { ManageAccessProps } from './typings';
import styles from './index.module.less';

const ManageAccessStateDashboard: FC<ManageAccessProps> = ({
  companyName,
  onManageAccessButtonClick,
}) => (
  <Empty
    image={noAccessIcon}
    description={
      <>
        <Typography.Text>Manage access to view information from</Typography.Text>
        <br />
        <Typography.Text>{companyName}</Typography.Text>
      </>
    }
    className={styles.emptycontainer}
  >
    <Button type="primary" shape="round" ghost onClick={onManageAccessButtonClick} disabled>
      Manage Access
    </Button>
  </Empty>
);

export default ManageAccessStateDashboard;
