import { Dropdown } from 'antd';
import classNames from 'classnames';
import React from 'react';
import styles from './HeaderDropdown.less';
import { HeaderDropdownProps } from './typings';

const HeaderDropdown: React.FC<HeaderDropdownProps> = ({
  overlayClassName: cls,
  placement = 'bottom',
  ...restProps
}) => (
  <Dropdown
    overlayClassName={classNames(styles.container, cls)}
    placement={placement}
    {...restProps}
  />
);

export default HeaderDropdown;
