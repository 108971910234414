import { FC, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Typography, message } from 'antd';
import errorHandler from 'utils/errorHandler';
import { useAcceptRequest, useRejectRequest } from 'services/api/Endorsers/useAccess';
import { InviteModalProps } from '../typings';

const { Title } = Typography;

const InviteModal: FC<InviteModalProps> = ({ modal, notificationData }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'notifications.view_invite_modal' });

  const [isDenyLoading, setIsDenyLoading] = useState(false);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const queryClient = useQueryClient();
  const acceptAccess = useAcceptRequest(queryClient);
  const rejectAccess = useRejectRequest(queryClient);

  const onCloseModal = () => {
    modal.hide();
  };

  const onDeny = async () => {
    setIsDenyLoading(true);
    try {
      await rejectAccess.mutateAsync({ endorserId: notificationData?.accountId || '' });
      setIsDenyLoading(false);
      modal.hide();
      message.success(t('deny_success', { companyName: notificationData?.title?.split(' ')?.[0] }));
    } catch (error) {
      setIsDenyLoading(false);
      message.error(errorHandler(error));
    }
  };

  const onSuccess = async () => {
    setIsAcceptLoading(true);
    try {
      await acceptAccess.mutateAsync({ endorserId: notificationData?.accountId || '' });
      setIsAcceptLoading(false);
      modal.hide();
      message.success(
        t('accept_success', { companyName: notificationData?.title?.split(' ')?.[0] }),
      );
    } catch (error) {
      setIsAcceptLoading(false);
      message.error(errorHandler(error));
    }
  };

  return (
    <Modal
      visible={modal.visible}
      title={
        <Title level={5}>
          {t('title', { companyName: notificationData?.title?.split(' ')?.[0] })}
        </Title>
      }
      closable
      onCancel={onCloseModal}
      footer={
        <>
          <Button
            onClick={onDeny}
            type="primary"
            shape="round"
            style={{
              color: '#0a5f7a',
              borderColor: '#0a5f7a',
              background: 'white',
              textShadow: '0px 0px',
              boxShadow: '0px 0px',
            }}
            loading={isDenyLoading}
          >
            {t('deny_button')}
          </Button>
          <Button
            onClick={onSuccess}
            type="primary"
            shape="round"
            style={{
              color: '#0a5f7a',
              borderColor: '#bcd530',
              background: '#bcd530',
              textShadow: '0px 0px',
              boxShadow: '0px 0px',
            }}
            loading={isAcceptLoading}
          >
            {t('accept_button')}
          </Button>
        </>
      }
      width="28%"
      bodyStyle={{ height: '60%', borderRadius: '0px' }}
      centered
    >
      <p>
        {notificationData?.title}
        <br />
        <br />
        <Typography.Text style={{ fontWeight: '500' }}>{t('content_second')}</Typography.Text>
        <div style={{ marginLeft: '10px', marginTop: '10px' }}>
          <li>&#8226; &nbsp; {t('audit_information')}</li>
        </div>
      </p>
    </Modal>
  );
};

export default InviteModal;
