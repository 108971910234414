/* eslint-disable no-nested-ternary */
import { useQueryClient } from 'react-query';
import { Typography, Space, message, Avatar } from 'antd';
import { yearFirstFormat } from 'utils/helpers';
import { FC, useMemo } from 'react';
import NoData from 'assets/images/no_data.svg';
import { errorHandler } from 'utils';
import { useClearNotifications } from 'services/api/useNotifications';
import useNotificationsFilters from '../hooks/useNotificationsFilters';
import { RecentTabProps, NotificationProps } from '../typings';

const RecentActivity: FC<RecentTabProps> = () => {
  const queryClient = useQueryClient();
  const clearNotifications = useClearNotifications(queryClient);

  // api data
  const { recentsRecords } = useNotificationsFilters();

  const recentActivity: Array<NotificationProps> = useMemo(
    () =>
      recentsRecords?.map((item) => ({
        id: item.id,
        title: item.title,
        description: item.description?.split(/\r\n/),
        date: yearFirstFormat(item.createdDate || ''),
        read: item.read,
        type: item.type,
        auditId: item.auditId,
        bapNumber: item.bapNumber,
        pictureUrl: item.pictureUrl,
      })) || [],
    [recentsRecords],
  );

  const clearReadStateById = async (notificationId: any) => {
    try {
      await clearNotifications.mutateAsync({
        params: { notificationIds: [notificationId] },
      });
    } catch (error) {
      message.error(errorHandler(error));
    }
  };

  return recentActivity.length !== 0 ? (
    <Space direction="vertical" style={{ padding: '0px', height: '290px', overflow: 'auto' }}>
      {recentActivity?.map((recent) => (
        <Space size={0} direction="vertical">
          {recent?.type === 'AUDIT_DOWNLOAD' ? (
            <Space
              size="small"
              style={{ cursor: 'pointer', alignItems: 'flex-start' }}
              onClick={() => {
                clearReadStateById(recent.id);
              }}
            >
              <Avatar
                style={
                  !recent.read
                    ? {
                        color: '#0a5f7a',
                        margin: '8px',
                        background: '#e7f0f2',
                      }
                    : {
                        color: '#0a5f7a',
                        margin: '8px',
                        background: '#e7f0f2',
                        opacity: '0.5',
                      }
                }
                src={recent?.pictureUrl ? recent?.pictureUrl : undefined}
              >
                {recent?.title?.[0].toUpperCase() || 'U'}
              </Avatar>
              <Space size={0} direction="vertical">
                {recent?.read ? (
                  <Typography.Text type="secondary" style={{ opacity: '0.5' }}>
                    {recent?.title}
                  </Typography.Text>
                ) : (
                  <Typography.Text strong>{recent?.title}</Typography.Text>
                )}
                <div
                  style={{
                    fontSize: '12px',
                    color: recent?.read ? 'rgba(0, 0, 0, 0.25)' : 'grey',
                  }}
                >
                  {`${recent?.description?.[0]}`}
                </div>
                <div
                  style={{
                    fontSize: '12px',
                    color: recent?.read ? 'rgba(0, 0, 0, 0.25)' : 'grey',
                  }}
                >{`(${recent?.description?.[1]})`}</div>
                <div
                  style={{
                    fontSize: '12px',
                    color: recent?.read ? 'rgba(0, 0, 0, 0.25)' : 'grey',
                  }}
                >
                  {`${recent?.description?.[2]}`}
                </div>
                <div
                  style={{
                    fontSize: '12px',
                    color: recent?.read ? 'rgba(0, 0, 0, 0.25)' : 'grey',
                  }}
                >
                  {`${recent?.description?.[3]}`}
                </div>
              </Space>
            </Space>
          ) : null}
          {recent?.type === 'USER_INVITE' || recent?.type === 'ENDORSER_ACCESS' ? (
            <Space
              size="small"
              style={{ cursor: 'pointer', alignItems: 'flex-start' }}
              onClick={() => {
                clearReadStateById(recent.id);
              }}
            >
              <Avatar
                style={
                  !recent.read
                    ? {
                        color: '#0a5f7a',
                        margin: '8px',
                        background: '#e7f0f2',
                      }
                    : {
                        color: '#0a5f7a',
                        margin: '8px',
                        background: '#e7f0f2',
                        opacity: '0.5',
                      }
                }
                src={recent?.pictureUrl ? recent?.pictureUrl : undefined}
              >
                {recent?.title?.[0].toUpperCase() || 'U'}
              </Avatar>
              <Space size={0} direction="vertical">
                {recent?.read ? (
                  <Typography.Text type="secondary" style={{ opacity: '0.4' }}>
                    {recent?.title}
                  </Typography.Text>
                ) : (
                  <Typography.Text strong>{recent?.title}</Typography.Text>
                )}
                <div
                  style={{
                    fontSize: '12px',
                    color: recent?.read ? 'rgba(0, 0, 0, 0.25)' : 'grey',
                  }}
                >
                  {`${recent?.description?.[0]}`}
                </div>
                <div
                  style={{
                    fontSize: '12px',
                    color: recent?.read ? 'rgba(0, 0, 0, 0.25)' : 'grey',
                  }}
                >
                  {recent?.date}
                </div>
              </Space>
            </Space>
          ) : null}
        </Space>
      ))}
    </Space>
  ) : (
    <div
      style={{
        height: '200px',
        display: 'block',
        margin: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '50px',
        marginBottom: '41px',
        width: '40%',
      }}
    >
      <img src={NoData} alt="No Data" />
    </div>
  );
};

export default RecentActivity;
