import type { ProFormColumnsType } from '@ant-design/pro-form';
import { Input, ColProps, Grid } from 'antd';
import { EmailTagInput } from 'components';
import RoleSelect from 'components/GSelect/RoleSelect';
import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import styles from './index.module.less';

const { TextArea } = Input;
const { useBreakpoint } = Grid;

const rowProps = { gutter: 32 };
const colProps18: ColProps = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 16 };
const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
const colProps6: ColProps = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 8 };

export type DataItem = {
  name: string | ReactNode;
  state: string;
};

type ColumnsType = {
  emailCount: number;
  setEmails: (prop: Array<string>) => void;
  emails: Array<string>;
  t: TFunction<'pages', 'endorsers_partners.view_company.invite_users_modal'>;
};

export const InviteColumns = ({
  emailCount,
  setEmails,
  emails,
  t,
}: ColumnsType): ProFormColumnsType<DataItem>[] => {
  const screens = useBreakpoint();
  return [
    {
      rowProps,
      renderFormItem: () => t('title'),
    },
    {
      dataIndex: 'email',
      rowProps,
      fieldProps: {
        style: {
          marginBottom: screens?.xxl ? '8px' : '16px',
        },
      },
      valueType: 'select',
      renderFormItem: () => (
        <EmailTagInput placeholder={t('email_placeholder')} setEmails={setEmails} emails={emails} />
      ),
      colProps: emails?.length >= 1 ? colProps18 : colProps24,
    },
    {
      dataIndex: 'role',
      valueType: 'select',
      fieldProps: {
        allowClear: false,
        style: {
          width: '100%',
          marginTop: screens?.xxl ? '0px' : '16px',
        },
        placeholder: 'Role',
      },
      formItemProps: {
        rules: [
          {
            required: true,
            message: 'Please Select Role',
          },
        ],
      },
      hideInForm: emails?.length === 0,
      renderFormItem: () => <RoleSelect />,
      colProps: colProps6,
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['email'],
      },
      columns: () => [
        {
          dataIndex: 'Value',
          valueType: 'text',
          renderFormItem: () =>
            emailCount === 0 ? null : (
              <div style={{ marginTop: '16px' }}>
                <TextArea
                  className={styles.textinput}
                  rows={7}
                  placeholder={t('textarea_placeholder')}
                />
              </div>
            ),
          fieldProps: {
            placeholder: t('textarea_placeholder'),
          },
        },
      ],
    },
  ];
};
