import { Select, Typography, Spin } from 'antd';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useAPIAuditReports } from 'services/api/Endorsers';
import { CompletedDateSelectProps } from './typings';
import styles from './index.module.less';

const CompletedDateSelect = ({
  auditSearch: search,
  setSearch,
  auditReportsParams,
  setAuditReportsParams,
}: CompletedDateSelectProps) => {
  const [submittedDateList, setSubmittedDateList] = useState<string>();
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.tables_container',
  });
  const { data, isLoading } = useAPIAuditReports({
    bapNumber: auditReportsParams.bapNumber,
    pageSize: auditReportsParams.pageSize,
    pageNumber: Number(auditReportsParams.pageNumber),
  });

  const removeDuplicates = (value: any, index: any, self: any) =>
    index ===
    self.findIndex(
      (val: any) => val.status === value.status && val.submittedDate === value.submittedDate,
    );

  // we create here the list with the shape the select is expecting for
  const selectOptions: Array<DefaultOptionType> =
    data?.results?.filter(removeDuplicates).map((item: any) => ({
      label: String(item.status).concat(
        item.submittedDate
          ? ` / ${moment(item.submittedDate).format('yyyy-MM-DDTHH:mm:ss')}`
          : ' / -',
      ),
      value: String(item.reportId),
    })) || [];

  useEffect(() => {
    if (search.date === undefined) {
      setSubmittedDateList(undefined);
    }
  }, [search]);

  const onSubmittedDateChange = (e: any, record: any) => {
    setSubmittedDateList(e);
    setAuditReportsParams({
      ...auditReportsParams,
      pageNumber: 1,
    });

    if (record?.label) {
      const arr = record.label.split(' / ');
      setSearch({ ...search, date: arr[1], status: arr[0] });
    }
  };

  const onClearButtonClick = () => {
    setSearch({ ...search, date: undefined, status: '' });
  };

  return (
    <Select
      size="middle"
      options={selectOptions}
      className={styles.container}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      placeholder={
        <Typography.Text style={{ opacity: '0.3', fontWeight: '400' }}>
          {t('date_select_placeholder')}
        </Typography.Text>
      }
      allowClear
      showSearch
      value={submittedDateList}
      filterOption={(input, option) =>
        (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      onChange={onSubmittedDateChange}
      onClear={onClearButtonClick}
    />
  );
};

export default CompletedDateSelect;
