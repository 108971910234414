import { Select, Typography } from 'antd';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { useEffect, useState } from 'react';
import { useProductIds } from 'services/api/useTrace';
import styles from './index.module.less';
import { ProductIDSelectProps } from './typings';

const ProductIDSelect = ({ search, setSearch, style }: ProductIDSelectProps) => {
  const [productIdList, setProductIdList] = useState<any>();
  const { data: productIdListResponse, isLoading } = useProductIds();

  // we create here the list with the shape the select is expecting for
  const selectOptions: Array<DefaultOptionType> =
    productIdListResponse?.products?.map((item) => ({
      label: String(item?.shortDescription),
      value: String(item?.productId),
      itemProps: item,
    })) || [];

  useEffect(() => {
    if (search?.productIdentifier?.length === 0) {
      setProductIdList(undefined);
    }
  }, [search]);

  const onProductIdChange = (e: any, record: any) => {
    setProductIdList(e);
    if (record.length === 0) {
      setSearch({ ...search, productIdentifier: [] });
    } else {
      setSearch({
        ...search,
        productIdentifier: record.map((item: any) => item?.itemProps?.shortDescription),
      });
    }
  };

  const onClearButtonClick = () => {
    setSearch({ ...search, productIdentifier: [] });
  };

  return (
    <Select
      key="prodIDselect"
      style={style}
      maxTagCount="responsive"
      mode="multiple"
      size="middle"
      loading={isLoading}
      options={selectOptions}
      className={styles.container}
      placeholder={
        <Typography.Text style={{ opacity: '0.3', fontWeight: '400' }}>
          Product Identifier
        </Typography.Text>
      }
      allowClear
      showSearch
      value={productIdList}
      filterOption={(input, option) =>
        (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      onChange={onProductIdChange}
      onClear={onClearButtonClick}
    />
  );
};

export default ProductIDSelect;
