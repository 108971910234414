import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Typography } from 'antd';
import { useDrawerVisibility } from 'hooks';
import React from 'react';
import Settings from './Settings';
import { drawerBodyStyle, drawerContentWrapper } from './common';
import { SettingsDrawerProps } from './typings';

// declare const window: any;

const SettingsDrawer: React.FC<SettingsDrawerProps> = () => {
  const drawerTitle = 'Settings';
  const { closeDrawer, visible, ...restDrawerVisibility } = useDrawerVisibility(true);

  return (
    <Drawer
      {...restDrawerVisibility}
      title={
        <Typography.Title level={4} className="mr0">
          {drawerTitle}
        </Typography.Title>
      }
      placement="top"
      visible={visible}
      closable={false}
      size="large"
      height="100%"
      bodyStyle={drawerBodyStyle}
      extra={
        // <Space>
        //   <Button type="text" onClick={() => window.Beacon('toggle')}>
        //     <Space>
        //       <QuestionCircleOutlined />
        //       Help
        //     </Space>
        //   </Button>
        <Button type="link" onClick={closeDrawer}>
          <CloseOutlined />
        </Button>
        // </Space>
      }
      contentWrapperStyle={drawerContentWrapper}
    >
      <Settings />
    </Drawer>
  );
};

export default SettingsDrawer;
