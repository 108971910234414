import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Space, Typography, Select, Spin, Empty } from 'antd';
import React, { useRef, useState, useCallback } from 'react';
import { useFullSearch } from 'services/api/useDashboard';
import { useTraceStore } from 'pages/Trace/hooks';

import classNames from 'classnames';
import styles from './index.module.less';

export type UniversalSearchProps = {
  className: string;
  placeholder: string;
};

const UniversalSearch: React.FC<UniversalSearchProps> = ({ className, placeholder }) => {
  const inputRef = useRef<any>(null);
  const navigate = useNavigate();
  const search = useTraceStore((state) => state.search);
  const setSearch = useTraceStore((state) => state.setSearch);
  const [params, setParams] = useState({
    term: '',
    pageNumber: 1,
    pageSize: 50,
  });
  const [value, setValue] = useState('');
  const [searchMode, setSearchMode] = useState(false);

  const { data: searchList, isLoading } = useFullSearch(params);

  const options: Array<any> =
    searchList?.results
      ?.filter((i) => i.resultType === 'ENDORSER')
      ?.map((item, index) => ({
        label: (
          <Space size="small">
            <Typography.Text>{item?.endorserName || ''}</Typography.Text>
            <Typography.Text type="secondary">{`(${item?.partnerType})`}</Typography.Text>
          </Space>
        ),
        value: String(index),
        itemProps: item,
      })) || [];

  const handleSearch = useCallback((completeValue: string) => {
    setValue(completeValue);
    setParams((oldParams) => ({
      ...oldParams,
      term: completeValue,
    }));
  }, []);

  const handleChange = useCallback(
    (val: string, newValue: any) => {
      if (newValue?.itemProps?.resultType === 'ENDORSER') {
        setValue(newValue?.itemProps?.endorserName);
        navigate(`/endorsers_partners/${newValue?.itemProps?.endorserId}`);
      } else if (newValue?.itemProps?.resultType === 'PRODUCT') {
        setValue(newValue?.itemProps?.productDescription);
        navigate('/trace');
      } else if (newValue?.itemProps?.resultType === 'LOT') {
        setValue(newValue?.itemProps?.lotId);
        setSearch({
          ...search,
          lot: [newValue?.itemProps?.lotURN],
        });
        navigate('/trace');
      } else if (newValue?.itemProps?.resultType === 'COUNTRY') {
        setValue(newValue?.itemProps?.country);
        setSearch({
          ...search,
          country: [newValue?.itemProps?.country],
        });
        navigate('/trace');
      } else if (newValue?.itemProps?.resultType === 'SPECIES') {
        setValue(newValue?.itemProps?.species);
        setSearch({
          ...search,
          species: [newValue?.itemProps?.species],
        });
        navigate('/trace');
      }
    },
    [navigate, search, setSearch],
  );

  const handleBlur = useCallback(() => {
    setSearchMode(false);
    setValue('');
    setParams((oldParams) => ({
      ...oldParams,
      term: '',
    }));
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames(className, styles.universalsearch)}
      onClick={() => {
        setSearchMode(true);
        if (!searchMode && inputRef.current) {
          inputRef.current.focus();
        }
      }}
      onTransitionEnd={({ propertyName }) => {
        if (propertyName === 'width' && !searchMode) {
          setSearchMode(false);
        }
      }}
    >
      <SearchOutlined
        key="Icon"
        style={{
          cursor: 'pointer',
        }}
      />
      <Select
        ref={inputRef}
        loading={isLoading}
        showSearch
        size="small"
        style={
          !searchMode
            ? {
                width: '0',
                minWidth: '0',
                overflow: 'hidden',
                background: 'transparent',
                borderRadius: '2',
                transition: 'width 0.3s, marginLeft 0.3s',
                marginRight: '0px',
              }
            : {
                width: '700px',
                marginLeft: '8px',
                transition: 'width 0.3s, marginLeft 0.3s',
              }
        }
        value={value}
        options={options}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        onBlur={handleBlur}
        notFoundContent={
          isLoading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
        dropdownStyle={{
          position: 'fixed',
        }}
      />
    </div>
  );
};

export default UniversalSearch;
