import { Button, Typography, Input, Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { gsaLogoSvg } from 'assets';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import errorHandler from 'utils/errorHandler';
import { UpdateUserRequest } from 'services/api/client/src';
import { useUpdateUser } from 'services/api/useSettings';
import styles from './index.module.less';

const RedeemInvite = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const updateUser = useUpdateUser(queryClient);
  const { t } = useTranslation('pages', { keyPrefix: 'redeem_invite' });

  const onFinish = async (values: any) => {
    try {
      const reqData: UpdateUserRequest = {
        name: `${values?.firstname} ${values?.lastname}`,
        displayName: `${values?.firstname} ${values?.lastname}`,
        password: values?.confirm,
      };
      await updateUser.mutateAsync({
        params: reqData,
      });
      message.success('Successfully updated data');
      navigate('/');
    } catch (error: any) {
      message.error(errorHandler(error));
    }
  };

  return (
    <div className={styles.cnt}>
      <div className={styles.imgcontainer}>
        <img src={gsaLogoSvg} alt="" style={{ width: '100px' }} />
      </div>
      <Typography.Title level={2}>{t('title')}</Typography.Title>
      <div className={styles.description}>
        <Typography.Text>{t('redeem_invite_msg')}</Typography.Text>
      </div>
      <Form name="basic" onFinish={onFinish}>
        <Form.Item
          name="firstname"
          rules={[{ required: true, message: 'Please input first name!' }]}
        >
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item name="lastname" rules={[{ required: true, message: 'Please input last name!' }]}>
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            htmlType="submit"
            type="primary"
            style={{
              color: '#0a5f7a',
              borderColor: '#bcd530',
              background: '#bcd530',
              textShadow: '0px 0px',
              boxShadow: '0px 0px',
            }}
          >
            {t('continue')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default RedeemInvite;
