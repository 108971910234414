import React, { useMemo } from 'react';
import { Table, Empty, Skeleton } from 'antd';
import { AuditReportsProps } from 'services/api/Endorsers';
import type { ColumnsType } from 'antd/es/table';
import styles from './index.module.less';
import { MainAuditReportsProps } from './typings';

const columns: ColumnsType<AuditReportsProps> = [
  {
    title: 'Facility Name',
    dataIndex: 'facilityName',
    width: '30%',
    ellipsis: true,
  },
  {
    title: 'BAP Number',
    dataIndex: 'bapNumber',
    width: '20%',
    ellipsis: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '20%',
    ellipsis: true,
  },
  {
    title: 'Submitted Date',
    dataIndex: 'completedDate',
    ellipsis: true,
    width: '25%',
  },
];

const AuditReports: React.FC<MainAuditReportsProps> = ({
  auditReports,
  isAuditReportsLoading,
  setSelectedReportId,
}) => {
  const initLocale = useMemo(() => {
    const emptyComp = (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.sktcontainer} />
    );

    return {
      emptyText: isAuditReportsLoading ? (
        <Skeleton
          active
          className={styles.sktcontainermain}
          title={false}
          paragraph={{ rows: 4, width: '100%' }}
        />
      ) : (
        emptyComp
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuditReportsLoading]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: AuditReportsProps[]) => {
      setSelectedReportId(selectedRows?.[0]?.reportId || '');
    },
  };
  return (
    <Table
      rowSelection={{
        type: 'radio',
        ...rowSelection,
      }}
      columns={columns}
      dataSource={auditReports}
      size="small"
      locale={initLocale}
      pagination={false}
    />
  );
};

export default AuditReports;
