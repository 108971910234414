import { ArrowLeftOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { message, Space, Typography } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { RadioChangeEvent } from 'antd';
import { FC, ReactElement, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import errorHandler from 'utils/errorHandler';
import { useRequestAccess } from 'services/api/Endorsers/useAccess';
import { AccessRequest } from 'services/api/client/src';
import styles from './index.module.less';
import { DataItem, RequestAccessColumns } from './RequestAccess.fields';
import { RequestAccessProps } from './typings';

const RequestAccessForm: FC<RequestAccessProps> = ({ modal, companyName }) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.grant_access_modal',
  });
  const [emails, setEmails] = useState<Array<string>>([]);
  const [levelValue, setLevelValue] = useState('view');
  const [categoryValue, setCategoryValue] = useState<CheckboxValueType[]>([]);
  const modalFormRef = useRef<ProFormInstance>();

  const queryClient = useQueryClient();
  const requestAccess = useRequestAccess(queryClient);

  const { accountID } = useParams();

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    setEmails([]);
    modal.hide();
  }, [modal]);

  const Title: ReactElement = (
    <Space>
      {emails.length === 0 ? null : (
        <ArrowLeftOutlined
          className={styles.arrow}
          onClick={() => {
            setEmails([]);
          }}
        />
      )}
      <Typography.Title level={4} className="mr0">
        {t('request_access_modal_title')}
      </Typography.Title>
    </Space>
  );

  const onLevelChange = (e: RadioChangeEvent) => {
    setLevelValue(e.target.value);
  };

  const onChangeGroup = (checkedValues: CheckboxValueType[]) => {
    setCategoryValue(checkedValues);
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      // @ts-ignore
      title={Title}
      formRef={modalFormRef}
      columns={RequestAccessColumns({
        t,
        onChangeGroup,
        onLevelChange,
        levelValue,
        categoryValue,
        companyName,
      })}
      grid
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        width: '40%',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('request_btn_text'),
        },
        submitButtonProps: {
          shape: 'round',
          style: {
            color: '#0a5f7a',
            background: '#bcd530',
            border: '1px solid #bcd530',
          },
        },
        render: (_, defaultDoms) => defaultDoms[1],
      }}
      initialValues={{
        connectionType: 'Buyer',
      }}
      submitTimeout={2000}
      onFinish={async () => {
        if (levelValue === '' && categoryValue.length === 0) {
          message.error(t('request_level_category_not_selected'));
        } else if (levelValue === '') {
          message.error(t('request_level_not_selected'));
        } else if (categoryValue.length === 0) {
          message.error(t('request_category_not_selected'));
        } else {
          try {
            const reqBody: AccessRequest = {
              endorserId: accountID,
              auditReports: true,
            };
            await requestAccess.mutateAsync({ params: reqBody });
            message.success(t('request_access_success_message', { companyName }));
            closeModal();
          } catch (error) {
            message.error(errorHandler(error));
          }
        }
      }}
    />
  );
};

export default RequestAccessForm;
