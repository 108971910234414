import { MenuDataItem } from '@ant-design/pro-layout';

const endorsersMenuData: Array<MenuDataItem> = [
  {
    path: '/',
    name: 'Dashboard',
    exact: true,
  },
  {
    path: '/discover',
    name: 'Discover',
  },
];

export default endorsersMenuData;
