import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { useTableSearchFilter } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { EndorserAccess, ListNetworkOptionalParams } from 'services/api/client/src';
import { useCountriesList, useNetworkList, useSpeciesList } from 'services/api/useEndorsers';
import { useUserInfo } from 'services/api/useSettings';

export interface SpeciesProps {
  category?: string;
  stars?: number;
}

export interface UpdatedEndorserDTO {
  id?: string;
  bap?: string;
  producer?: string;
  producerType?: string;
  country?: string;
  expiration?: Date;
  species?: SpeciesProps[];
  stars?: number;
  accountID?: string;
  access?: number;
  accessFields?: Array<EndorserAccess>;
  esa?: string;
  annouceType?: string;
}

const useNetworkListFilters = (initParams?: ListNetworkOptionalParams) => {
  const [networkListParams, setNetworkListParams] = useState<ListNetworkOptionalParams>({
    pageSize: 50,
    pageNumber: 1,
    ...initParams,
  });
  const { data: loggedInUserData } = useUserInfo();

  const { data: networkListResponse, isLoading: iseNetworkListLoading } = useNetworkList(
    networkListParams,
    !!loggedInUserData?.user?.endorserAccountId,
  );

  const { data: speciesListResponse } = useSpeciesList();

  const { data: countriesListResponse } = useCountriesList();

  const networkTotalItems = useMemo(
    () => networkListResponse?.totalItems || 0,
    [networkListResponse],
  );

  const handleESA = (esa?: boolean) => {
    switch (esa) {
      case true:
        return 'Yes';
      case false:
        return 'No';
      default:
        return '-';
    }
  };

  const networkList: UpdatedEndorserDTO[] = useMemo(
    () =>
      networkListResponse?.results?.map((item, index) => ({
        id: item.accountID,
        producer: item.facility,
        producerType: item.facilityType === 'Plant' ? item.plantType : item.facilityType,
        country: item.country,
        bap: item.bapNumber,
        expiration: item.expiration,
        species: item.species,
        stars: item.species?.[index]?.stars,
        access: item.accessCount,
        accessFields: item.access,
        esa: handleESA(item.enhancedSocialAccountabilityDone),
        announceType: item.announceType,
        ...(item?.access?.[0]?.accessItems?.length && {
          children: [
            ...(item?.access?.[0]?.accessItems?.map((i) => ({
              producer: ' ',
              producerType: ' ',
              country: ' ',
              bap: ' ',
              expiration: ' ',
              species: ' ',
              stars: ' ',
              access: item.accessCount,
              accessFields: i,
              esa: ' ',
              announceTyoe: ' ',
            })) || []),
          ],
        }),
      })) || [],
    [networkListResponse],
  );

  const { filters: networkProducerSearchFilter } = useTableSearchFilter({
    title: 'Producer',
  });

  const { filters: networkBapSearchFilter } = useTableSearchFilter({
    title: 'BAP Number',
  });

  const onNetworkListChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: any,
    ) => {
      const paginate = pagination?.current
        ? {
            pageNumber: pagination?.current || 1,
            pageSize: pagination?.pageSize || 50,
          }
        : {};
      const producerFilter = filters?.producer
        ? {
            facility: filters?.producer?.[0] as string,
          }
        : {};
      const producerTypeFilter = filters?.producerType
        ? {
            facilityTypes: filters?.producerType as string[],
          }
        : {};
      const countriesFilter = filters?.country
        ? {
            countries: filters?.country as string[],
          }
        : {};
      const bapNumberFilter = filters?.bap
        ? {
            bapNumber: (filters?.bap?.[0] as string).toUpperCase(),
          }
        : {};
      const speciesFilter = filters?.species
        ? {
            species: filters?.species as string[],
          }
        : {};
      const esaFilter = filters?.esa
        ? {
            eSA: filters?.esa as boolean[],
          }
        : {};
      const announceTypeFilter = filters?.announceType
        ? {
            announceType: filters?.announceType as string[],
          }
        : {};
      const sortDirection =
        sorter?.field && sorter?.order
          ? {
              order: 'Expiration',
              direction: sorter?.order === 'ascend' ? 'Asc' : 'Desc',
            }
          : {};

      setNetworkListParams({
        ...paginate,
        ...producerFilter,
        ...producerTypeFilter,
        ...countriesFilter,
        ...bapNumberFilter,
        ...speciesFilter,
        ...sortDirection,
        ...esaFilter,
        ...announceTypeFilter,
      });
    },
    [],
  );

  return {
    networkList,
    iseNetworkListLoading,
    networkListParams,
    setNetworkListParams,
    networkTotalItems,
    onNetworkListChange,
    filters: [networkProducerSearchFilter, networkBapSearchFilter],
    speciesListResponse,
    countriesListResponse,
  };
};

export default useNetworkListFilters;
