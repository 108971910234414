import create from 'zustand';
import { SelectUserStore } from './typings';

const usePermissionStore = create<SelectUserStore>((set) => ({
  tab: 'users',
  setTab: (tab: string) =>
    set(() => ({
      tab,
    })),
  selectedUserData: {},
  setSelectedUserData: (selectedUserData) => set({ selectedUserData }),
  selectedRoleData: {},
  setSelectedRoleData: (selectedRoleData) => set({ selectedRoleData }),
}));

export default usePermissionStore;
