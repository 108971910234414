/* eslint-disable no-nested-ternary */
import { FC, useState, useEffect, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Card, Space, Segmented, Typography, Spin } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { useParams, useNavigate } from 'react-router-dom';
import {
  SpeciesDetailDTO,
  AuditPersonnelDTO,
  AuditKpidto,
  AuditFacilityWorkforceDTO,
  AssessmentTotals,
} from 'services/api/client/src';
import { Loading } from 'components';
import { useUserInfo } from 'services/api/useSettings';
import EndorserTitlebar from 'components/TitleBar/EndorserTitlebar';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useAuditReportById } from 'services/api/Endorsers';
import { yearFirstFormat } from 'utils/helpers';
import DownloadModal from './Modal/DownloadModal';
import useAuditReportStore from './hooks';
import styles from './index.module.less';
import GroupSummary, { StandaloneSummary } from './components';
import {
  GAuditReportTitleProps,
  GAuditReportProps,
  WildvsFarmedProps,
  GroupMemberTableProps,
  GroupNonConformanceTotalProps,
  OnsiteNonConformanceTotalProps,
} from './typings';

type ActionItemsProps = {
  t: TFunction<'pages', 'endorsers_partners.view_company.audit_report'>;
};
const mainActionItems = ({ t }: ActionItemsProps): Array<ItemType> => [
  {
    key: 'download',
    label: t('main_actions.download_report'),
  },
];

const SummaryTitle = ({
  setTab,
  toggleAuditReportShow,
  loggedInUserData,
  from,
}: GAuditReportTitleProps) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.audit_report',
  });
  const navigate = useNavigate();

  const { reportId, bapNumber } = useParams();

  const { data: auditReportByIdResponse, isLoading } = useAuditReportById(
    reportId || '',
    bapNumber || '',
  );

  const setModalVisible = useAuditReportStore((state) => state.setModalVisible);
  const setCheckedBox = useAuditReportStore((state) => state.setCheckedBox);
  const setBackFrom = useAuditReportStore((state) => state.setBackFrom);
  const setAuditReportParams = useAuditReportStore((state) => state.setAuditReportParams);
  const sections = ['Summary', 'Audit Report'];
  const [section, setSection] = useState<string | number>(sections[0]);

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'download':
        setModalVisible(true);
        break;

      default:
        break;
    }
  };

  const onSegmentedButtonClick = (value: SegmentedValue) => {
    setSection(value);
    switch (value) {
      case 'Summary':
        break;
      case 'Audit Report':
        setCheckedBox(['auditReport']);
        setTimeout(() => {
          toggleAuditReportShow(true);
        }, 300);
        break;

      default:
        break;
    }
  };
  const backPress = () => {
    setTab('auditReports');
    toggleAuditReportShow(false);
    setBackFrom('auditReport');
    setAuditReportParams({ pageSize: 50, pageNumber: 1 });
    navigate(-1);
  };

  return (
    <EndorserTitlebar
      title={
        <Space>
          {isLoading ? (
            <Spin size="small" />
          ) : (
            String(auditReportByIdResponse?.auditReport?.auditKpi?.orgName).concat(
              ` (${auditReportByIdResponse?.auditReport?.bapNumber}) `.concat(
                String(
                  yearFirstFormat(
                    auditReportByIdResponse?.auditReport?.auditKpi?.submittedDate || '',
                  ),
                ),
              ),
            )
          )}
          <Segmented value={section} options={sections} onChange={onSegmentedButtonClick} />
        </Space>
      }
      onBack={backPress}
      actionItems={
        (from !== 'profile' &&
          from !== 'endorsersMain' &&
          loggedInUserData?.user?.role?.permission?.includes('DOWNLOADING_AUDIT_REPORTS')) ||
        from === 'profile' ||
        from === 'endorsersMain'
          ? mainActionItems({ t })
          : undefined
      }
      onActionItemClick={onActionItemClick}
      className={styles.summarytitlestyles}
    />
  );
};

const notZero = (n: number) => {
  // eslint-disable-next-line no-param-reassign
  n = +n;
  if (!n) {
    // Matches +0, -0, NaN
    return 1;
  }
  return n;
};

const AuditReportSummary: FC<GAuditReportProps> = ({
  setTab,
  toggleAuditReportShow,
  setAuditSearch,
  from,
}) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.audit_summary',
  });
  const { data: loggedInUserData } = useUserInfo();
  window.scrollTo(0, 0);

  const { reportId, bapNumber } = useParams();
  const { data: auditReportByIdResponse, isLoading: isSummaryLoading } = useAuditReportById(
    reportId || '',
    bapNumber || '',
  );

  const [wildVsFarmed, setWildVsFarmed] = useState<Array<WildvsFarmedProps>>([]);
  const [totalProduction, setTotalProduction] = useState<number | undefined>();
  const [wildVsFarmedSecond, setWildVsFarmedSecond] = useState<SpeciesDetailDTO[]>([]);
  const [keyPersonnelList, setKeyPersonnelList] = useState<AuditPersonnelDTO[]>([]);
  const [auditKPI, setAuditKPI] = useState<AuditKpidto>({});
  const [workForceDetails, setWorkForceDetails] = useState<AuditFacilityWorkforceDTO>({});
  const [nonConformanceTotals, setNonConformanceTotals] = useState<AssessmentTotals>({});

  useEffect(() => {
    setWildVsFarmed([
      {
        productFormSource: 'Farmed',
        totalProduction: auditReportByIdResponse?.auditReport?.speciesTotals?.farmed,
        percentTotal: `${
          ((auditReportByIdResponse?.auditReport?.speciesTotals?.farmed || 0) /
            notZero(
              (auditReportByIdResponse?.auditReport?.speciesTotals?.farmed || 0) +
                (auditReportByIdResponse?.auditReport?.speciesTotals?.wildCaught || 0),
            )) *
          100
        }%`,
      },
      {
        productFormSource: 'Wild Caught',
        totalProduction: auditReportByIdResponse?.auditReport?.speciesTotals?.wildCaught,
        percentTotal: `${
          ((auditReportByIdResponse?.auditReport?.speciesTotals?.wildCaught || 0) /
            notZero(
              (auditReportByIdResponse?.auditReport?.speciesTotals?.farmed || 0) +
                (auditReportByIdResponse?.auditReport?.speciesTotals?.wildCaught || 0),
            )) *
          100
        }%`,
      },
    ]);
    setTotalProduction(auditReportByIdResponse?.auditReport?.speciesTotals?.totalProduction);
    setWildVsFarmedSecond(
      auditReportByIdResponse?.auditReport?.speciesDetails?.map((item) => ({
        ...item,
        newTotalProduction:
          // @ts-ignore
          item?.numberOfAnimals > 0 ? item?.numberOfAnimals : item?.totalProduction,
      })) || [],
    );
    setKeyPersonnelList(auditReportByIdResponse?.auditReport?.auditPersonnel || []);
    setAuditKPI(auditReportByIdResponse?.auditReport?.auditKpi || {});
    setWorkForceDetails(auditReportByIdResponse?.auditReport?.facilityWorkforce || {});
    setNonConformanceTotals(auditReportByIdResponse?.auditReport?.assessmentTotals || {});
  }, [auditReportByIdResponse]);

  const setModalVisible = useAuditReportStore((state) => state.setModalVisible);
  const setCheckedBox = useAuditReportStore((state) => state.setCheckedBox);
  setCheckedBox(['summary']);

  const onCloseModal = () => {
    setCheckedBox(['summary']);
    setModalVisible(false);
  };

  const groupNonConformanceTotalDetails: Array<GroupNonConformanceTotalProps> = useMemo(
    () =>
      auditReportByIdResponse?.auditReport?.groupAssessmentTotals?.map((item) => ({
        phase: item.phase,
        noCritical: item.critical,
        noMajor: item.major,
        noMinor: item.minor,
        yes: item.yes,
        nothing: item.notAvailable,
      })) || [],
    [auditReportByIdResponse],
  );

  const onsiteNonConformanceTotalDetails: Array<OnsiteNonConformanceTotalProps> = useMemo(
    () =>
      auditReportByIdResponse?.auditReport?.onSiteAssessmentTotals?.map((item) => ({
        groupBapNumber: item.bapNumber,
        noCritical: item.critical,
        noMajor: item.major,
        noMinor: item.minor,
        yes: item.yes,
        nothing: item.notAvailable,
      })) || [],
    [auditReportByIdResponse],
  );

  const groupMemberDetails: Array<GroupMemberTableProps> = useMemo(
    () =>
      auditReportByIdResponse?.auditReport?.memberSpeciesDetails?.map((item) => ({
        groupBapNumber: item.bapNumber,
        facilityName: item.accountName,
        latitude: item.latitude,
        longitude: item.longitude,
        auditType: item.auditType,
        onsiteHousing: item.memberOnSiteHousing ? 'Yes' : 'No',
        speciesScientificName: item.speciesProduction?.map((val) => ({
          speciesNames: val.scientificName,
        })),
        // @ts-ignore
        totalProduction: item.speciesProduction?.map((val) => ({
          productionValue:
            (item?.numberOfAnimals || 0) > 0 ? item?.numberOfAnimals : val.totalProduction,
        })),
      })) || [],
    [auditReportByIdResponse],
  );

  return (
    <>
      <DownloadModal onCloseModal={onCloseModal} />
      <div className={styles.cardwrapper}>
        <Card bordered={false} bodyStyle={{ padding: '0px' }}>
          <SummaryTitle
            setTab={setTab}
            toggleAuditReportShow={toggleAuditReportShow}
            setAuditSearch={setAuditSearch}
            loggedInUserData={loggedInUserData}
            from={from}
          />
          <Typography.Title level={3} style={{ padding: '24px' }}>
            {auditKPI?.issueName}
          </Typography.Title>
          {isSummaryLoading ? (
            <Loading />
          ) : auditReportByIdResponse?.auditReport?.reportType === 'Group' ? (
            <GroupSummary
              auditReportByIdResponse={auditReportByIdResponse}
              auditKPI={auditKPI}
              t={t}
              nonConformanceTotals={nonConformanceTotals}
              groupNonConformanceTotalDetails={groupNonConformanceTotalDetails}
              onsiteNonConformanceTotalDetails={onsiteNonConformanceTotalDetails}
              groupMemberDetails={groupMemberDetails}
            />
          ) : (
            <StandaloneSummary
              auditReportByIdResponse={auditReportByIdResponse}
              auditKPI={auditKPI}
              t={t}
              nonConformanceTotals={nonConformanceTotals}
              workForceDetails={workForceDetails}
              wildVsFarmed={wildVsFarmed}
              wildVsFarmedSecond={wildVsFarmedSecond}
              totalProduction={totalProduction}
              keyPersonnelList={keyPersonnelList}
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default AuditReportSummary;
