import { useCallback, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TablePaginationConfig } from 'antd';
import { useTableSearchFilter } from 'hooks';
import { ListAuditReportsOptionalParams, AuditReportItem } from 'services/api/client/src';
import { useGetMyAllAccessList } from 'services/api/Endorsers/useAccess';
import { useAPIAuditReports } from 'services/api/Endorsers';
import { FilterValue } from 'antd/lib/table/interface';

const useAuditReportsFilters = (initParams?: ListAuditReportsOptionalParams) => {
  const [auditReportsParams, setAuditReportsParams] = useState<ListAuditReportsOptionalParams>({
    pageNumber: 1,
    ...initParams,
  });
  const [auditReports, setAuditReports] = useState<AuditReportItem[]>([]);

  const { accountID } = useParams();

  const { data: myEndorsersListResponse } = useGetMyAllAccessList({}, true);
  const hasProfileAccess = useMemo(
    () => !myEndorsersListResponse?.results?.find((item) => item.endorserId === accountID),
    [accountID, myEndorsersListResponse?.results],
  );

  const { data: auditReportsResponse, isLoading: isAuditReportsLoading } =
    useAPIAuditReports(auditReportsParams);

  const totalItems = useMemo(() => auditReportsResponse?.totalItems || 0, [auditReportsResponse]);
  useEffect(() => {
    setAuditReports(
      auditReportsResponse?.results?.map((item) => ({
        ...item,
        id: item.reportId,
      })) || [],
    );
  }, [auditReportsResponse]);

  useEffect(() => {
    if (initParams?.bapNumber) {
      setAuditReportsParams({
        ...auditReportsParams,
        pageSize: 50,
        bapNumber: initParams?.bapNumber,
      });
    } else {
      setAuditReportsParams({
        ...auditReportsParams,
        pageSize: undefined,
        bapNumber: initParams?.bapNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initParams?.bapNumber]);

  const { filters: facilityNameSearchFilter } = useTableSearchFilter({
    title: 'Facility Name',
  });

  const { filters: bapNumberSearchFilter } = useTableSearchFilter({
    title: 'BAP Number',
  });

  const onAuditReportsTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: any,
    ) => {
      const paginate = pagination?.current
        ? {
            pageNumber: pagination?.current || 1,
            pageSize: pagination?.pageSize || 20,
          }
        : {};
      const facilityNameFilter = filters?.facilityName
        ? {
            facilityName: filters?.facilityName?.[0] as string,
          }
        : {};
      const statusFilter = filters?.status
        ? {
            status: filters?.status as string[],
          }
        : {};
      const sortDirection =
        sorter?.field && sorter?.order
          ? {
              orderBy: 'SubmittedDate',
              direction: sorter?.order === 'ascend' ? 'Asc' : 'Desc',
            }
          : {};

      setAuditReportsParams({
        ...paginate,
        ...facilityNameFilter,
        ...statusFilter,
        ...sortDirection,
        bapNumber: initParams?.bapNumber,
      });
    },
    [initParams?.bapNumber],
  );

  return {
    auditReports,
    isAuditReportsLoading,
    auditReportsParams,
    setAuditReportsParams,
    totalItems,
    onAuditReportsTableChange,
    filters: [facilityNameSearchFilter, bapNumberSearchFilter],
    hasProfileAccess,
  };
};

export default useAuditReportsFilters;
