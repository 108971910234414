import { ProFormColumnsType } from '@ant-design/pro-form';
import { useMemo } from 'react';
import { useRolesList } from 'services/api/useSettings';
import { DataItem } from './typings';

const ChangeRoleFormFields = (): ProFormColumnsType<DataItem>[] => {
  const { data } = useRolesList();
  const rolesList = useMemo(
    () =>
      data?.roles?.reduce((acc: any, val: any) => {
        const obj = {
          [String(val?.roleId).concat(` - ${val?.roleName}`) as string]: {
            text: [val.roleName],
            value: [val.roleId || ''],
          },
        };
        return { ...acc, ...obj };
      }, {}),
    [data],
  );

  return [
    {
      dataIndex: 'roleValue',
      valueType: 'select',
      title: 'Role',
      formItemProps: {
        rules: [
          {
            required: true,
            message: 'Please Select Role',
          },
        ],
      },
      fieldProps: {
        placeholder: 'Role',
      },
      valueEnum: rolesList,
    },
  ];
};

export default ChangeRoleFormFields;
