import { useMemo, useState } from 'react';
import moment from 'moment';
import { ListNotificationsOptionalParams } from 'services/api/client/src';
import { useInfiniteNotifications, useNotificationsList } from 'services/api/useNotifications';
import { NotificationItem } from '../typings';

const useNotificationFilters = (initParams?: ListNotificationsOptionalParams) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notificationsParams, setNotificationsParams] = useState<ListNotificationsOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
    recentActivity: false,
    ...initParams,
  });

  const [recentParams, setRecentParams] = useState<ListNotificationsOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
    recentActivity: true,
    ...initParams,
  });

  const format = 'YYYY-MM-DD';

  const [dashboardRecentParams, setDashboardRecentParams] =
    useState<ListNotificationsOptionalParams>({
      recentActivity: true,
      from: moment().subtract(30, 'days').format(format) as any,
      to: moment().format(format) as any,
    });

  const { data: dashboardRecent, isLoading: isDashboardRecentLoading } =
    useNotificationsList(dashboardRecentParams);

  // api notifications
  const {
    data: notificationsListResponse,
    fetchNextPage: fetchNotificationNextPage,
    hasNextPage: hasNotificationsNextPage,
    isLoading: notificationsIsLoading,
    isFetchingNextPage: isFetchingNotificationsNextPage,
  } = useInfiniteNotifications(notificationsParams);

  // api recent activity
  const {
    data: recentActivityListResponse,
    fetchNextPage: fetchRecentNextPage,
    hasNextPage: hasRecentsNextPage,
    isLoading: recentsIsLoading,
    isFetchingNextPage: isFetchingRecentNextPage,
  } = useInfiniteNotifications(recentParams);

  const disableNotificationsSeeMore = useMemo(
    () => !hasNotificationsNextPage || notificationsIsLoading,
    [notificationsIsLoading, hasNotificationsNextPage],
  );

  const disableRecentsSeeMore = useMemo(
    () => !hasRecentsNextPage || recentsIsLoading,
    [recentsIsLoading, hasRecentsNextPage],
  );

  const notificationsRecords: Array<NotificationItem> = useMemo(
    () =>
      notificationsListResponse?.pages?.reduce(
        (acc, page) => [...acc, ...(page?.results || [])],
        [] as Array<NotificationItem>,
      ) || [],
    [notificationsListResponse],
  );

  const recentsRecords: Array<NotificationItem> = useMemo(
    () =>
      recentActivityListResponse?.pages?.reduce(
        (acc, page) => [...acc, ...(page?.results || [])],
        [] as Array<NotificationItem>,
      ) || [],
    [recentActivityListResponse],
  );

  const notificationListLength = useMemo(
    () => notificationsListResponse?.pages?.[0]?.unreadCount || 0,
    [notificationsListResponse?.pages],
  );

  const recentActivityListLength = useMemo(
    () => recentActivityListResponse?.pages?.[0]?.unreadCount || 0,
    [recentActivityListResponse?.pages],
  );

  return {
    notificationsRecords,
    recentsRecords,
    notificationListLength,
    recentActivityListLength,
    disableNotificationsSeeMore,
    disableRecentsSeeMore,
    fetchNotificationNextPage,
    fetchRecentNextPage,
    isFetchingNotificationsNextPage,
    isFetchingRecentNextPage,
    recentParams,
    setRecentParams,
    recentsIsLoading,
    // dashboard recent activity
    setDashboardRecentParams,
    dashboardRecent,
    isDashboardRecentLoading,
  };
};
export default useNotificationFilters;
