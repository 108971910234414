import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { useTableSearchFilter } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { ListEndorsersOptionalParams } from 'services/api/client/src';
import { useCountriesList, useEndorsersList, useSpeciesList } from 'services/api/useEndorsers';
import { UpdatedEndorserDTO } from '../typings';

const useEndorsersListFilters = (initParams?: ListEndorsersOptionalParams) => {
  const [endorsersListParams, setEndorsersListParams] = useState<ListEndorsersOptionalParams>({
    pageSize: 50,
    pageNumber: 1,
    ...initParams,
  });

  const { data: endorsersListResponse, isLoading: isEndorsersListLoading } =
    useEndorsersList(endorsersListParams);

  const [selectedFilter, setSelectedFilter] = useState<string | undefined>();

  const { data: speciesListResponse } = useSpeciesList();
  const { data: countriesListResponse } = useCountriesList();

  const totalItems = useMemo(() => endorsersListResponse?.totalItems || 0, [endorsersListResponse]);

  const handleESA = (esa?: boolean) => {
    switch (esa) {
      case true:
        return 'Yes';
      case false:
        return 'No';
      default:
        return '-';
    }
  };

  const endorsersList: UpdatedEndorserDTO[] = useMemo(
    () =>
      endorsersListResponse?.results?.map((item, index) => ({
        id: item.accountID,
        producer: item.facility,
        producerType: item.facilityType === 'Plant' ? item.plantType : item.facilityType,
        country: item.country,
        bap: item.bapNumber,
        expiration: item.expiration,
        species: item.species,
        stars: item.species?.[index]?.stars,
        accountID: item.accountID,
        endorserType: item.partnerType === 'Group' ? 'GROUP' : 'ALONE',
        esa: handleESA(item.enhancedSocialAccountabilityDone),
        announceType: item.announceType,
        ...(item?.species?.length && {
          children: [
            ...(item?.species?.map((i) => ({
              producer: ' ',
              producerType: ' ',
              country: ' ',
              bap: ' ',
              expiration: ' ',
              species: [
                {
                  category: i?.category,
                  stars: i?.stars,
                },
              ],
              stars: i?.stars,
              accountID: ' ',
              endorserType: ' ',
              announceType: ' ',
              esa: ' ',
            })) || []),
          ],
        }),
      })) || [],
    [endorsersListResponse],
  );

  const { filters: producerSearchFilter } = useTableSearchFilter({
    title: 'Producer',
  });

  const { filters: bapNumberSearchFilter } = useTableSearchFilter({
    title: 'BAP Number',
  });

  const { filters: accessSearchFilter } = useTableSearchFilter({
    title: 'Access',
  });

  const onEndorsersListTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: any,
    ) => {
      if (filters?.producer && totalItems !== 0) {
        setSelectedFilter(' for "Producer"');
      } else if (filters?.producerType && totalItems !== 0) {
        setSelectedFilter(' for "Producer Type"');
      } else if (filters?.country && totalItems !== 0) {
        setSelectedFilter(' for "Country"');
      } else if (filters?.bap && totalItems !== 0) {
        setSelectedFilter(' for "BAP Number"');
      } else if (filters?.species && totalItems !== 0) {
        setSelectedFilter(' for "Species & Star Status Capability"');
      } else {
        setSelectedFilter('');
      }
      const paginate = pagination?.current
        ? {
            pageNumber: pagination?.current || 1,
            pageSize: pagination?.pageSize || 50,
          }
        : {};
      const producerFilter = filters?.producer
        ? {
            facility: filters?.producer?.[0] as string,
          }
        : {};
      const accessFilter = filters?.access
        ? {
            childAccessFacility: filters?.access?.[0] as string,
          }
        : {};
      const producerTypeFilter = filters?.producerType
        ? {
            facilityTypes: filters?.producerType as string[],
          }
        : {};
      const countriesFilter = filters?.country
        ? {
            countries: filters?.country as string[],
          }
        : {};
      const bapNumberFilter = filters?.bap
        ? {
            bapNumber: (filters?.bap?.[0] as string).toUpperCase(),
          }
        : {};
      const speciesFilter = filters?.species
        ? {
            species: filters?.species as string[],
          }
        : {};
      const esaFilter = filters?.esa
        ? {
            eSA: filters?.esa as boolean[],
          }
        : {};
      const announceTypeFilter = filters?.announceType
        ? {
            announceType: filters?.announceType as string[],
          }
        : {};
      const sortDirection =
        sorter?.field && sorter?.order
          ? {
              order: 'Expiration',
              direction: sorter?.order === 'ascend' ? 'Asc' : 'Desc',
            }
          : {};

      setEndorsersListParams({
        ...paginate,
        ...producerFilter,
        ...producerTypeFilter,
        ...countriesFilter,
        ...bapNumberFilter,
        ...speciesFilter,
        ...sortDirection,
        ...accessFilter,
        ...esaFilter,
        ...announceTypeFilter,
      });
    },
    [totalItems],
  );

  return {
    endorsersList,
    isEndorsersListLoading,
    endorsersListParams,
    setEndorsersListParams,
    totalItems,
    onEndorsersListTableChange,
    filters: [producerSearchFilter, bapNumberSearchFilter, accessSearchFilter],
    speciesListResponse,
    countriesListResponse,
    selectedFilter,
  };
};

export default useEndorsersListFilters;
