import { useState, useEffect } from 'react';
import { useTableSearchFilter } from 'hooks';
import { DiagramOptionalParams } from 'services/api/client/src';
import { useDiagram } from 'services/api/useTrace';

const useDiagramFilters = (initParams?: DiagramOptionalParams) => {
  const [eventDiagramParams, setEventDiagramParams] = useState<DiagramOptionalParams>({
    ...initParams,
  });
  const [eventDiagramNodes, setEventDiagramNodes] = useState<any>([]);

  const { data: eventDiagramResponse, isLoading: isEventDiagramLoading } =
    useDiagram(eventDiagramParams);

  useEffect(() => {
    setEventDiagramNodes(eventDiagramResponse || []);
  }, [eventDiagramResponse]);

  const { filters: facilityNameSearchFilter } = useTableSearchFilter({
    title: 'Facility Name',
  });

  const { filters: bapNumberSearchFilter } = useTableSearchFilter({
    title: 'BAP Number',
  });

  return {
    eventDiagramNodes,
    isEventDiagramLoading,
    eventDiagramParams,
    setEventDiagramParams,
    filters: [facilityNameSearchFilter, bapNumberSearchFilter],
  };
};

export default useDiagramFilters;
