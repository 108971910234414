import type { ProFormColumnsType } from '@ant-design/pro-form';
import { Checkbox, Typography, Space, Radio } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { RadioChangeEvent } from 'antd';
import { EndorserDTO } from 'services/api/client/src';
import { EndorserSelect } from 'components/GSelect';
import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';

const rowProps = { gutter: 32 };

export type DataItem = {
  name: string | ReactNode;
  state: string;
};

type ColumnsType = {
  t: TFunction<'pages', 'endorsers_partners.view_company.grant_access_modal'>;
  onChangeGroup: (checkedValues: CheckboxValueType[]) => void;
  onLevelChange: (e: RadioChangeEvent) => void;
  levelValue: string;
  categoryValue: Array<CheckboxValueType>;
  showOptions: boolean;
  setShowOptions: (showOptions: boolean) => void;
  grantAccessCompanyName?: string;
  setSelectedCompany: (selected: string) => void;
  setSelectedProducerData?: (selectedProducerData: EndorserDTO) => void;
  selectedMainRowData?: any;
};

export const GrantAccessColumns = ({
  t,
  onChangeGroup,
  onLevelChange,
  levelValue,
  categoryValue,
  showOptions,
  setShowOptions,
  grantAccessCompanyName,
  setSelectedCompany,
  setSelectedProducerData,
  selectedMainRowData,
}: ColumnsType): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['connectionType'],
    },
    rowProps,
    columns: () => [
      {
        title: t('select_endorser', { grantAccessCompanyName }),
        dataIndex: 'email',
        valueType: 'select',
        renderFormItem: () => (
          <EndorserSelect
            setShowOptions={setShowOptions}
            setSelectedCompany={setSelectedCompany}
            setSelectedProducerData={setSelectedProducerData}
            selectedMainRowData={selectedMainRowData}
          />
        ),
      },
    ],
  },
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['email'],
    },
    columns: () => [
      {
        dataIndex: 'desiredCategory',
        valueType: 'text',
        renderFormItem: () =>
          showOptions ? (
            <Space direction="vertical" size="small" style={{ marginTop: '24px' }}>
              <Typography.Text>
                Select desired{' '}
                <Typography.Text strong underline>
                  categories
                </Typography.Text>{' '}
                of access
              </Typography.Text>
              <Checkbox.Group onChange={onChangeGroup} value={categoryValue}>
                <Checkbox value="auditInformation">{t('audit_information')}</Checkbox>
              </Checkbox.Group>
            </Space>
          ) : null,
      },
      {
        dataIndex: 'desiredLevel',
        valueType: 'text',
        renderFormItem: () =>
          showOptions ? (
            <Space direction="vertical" size="small" style={{ marginTop: '24px' }}>
              <Typography.Text>
                Select desired{' '}
                <Typography.Text strong underline>
                  level
                </Typography.Text>{' '}
                of access
              </Typography.Text>
              <Radio.Group onChange={onLevelChange} value={levelValue}>
                <Space direction="vertical" size="small">
                  <Radio value="view">{t('view_only')}</Radio>
                </Space>
              </Radio.Group>
            </Space>
          ) : null,
      },
    ],
  },
];
