import { useMemo } from 'react';
import type { ParamsType } from '@ant-design/pro-provider';
import { EditableProTable } from '@ant-design/pro-table';
import { Skeleton, Empty } from 'antd';
import type { EditableProTableProps } from '@ant-design/pro-table/lib/components/EditableTable';
import classnames from 'classnames';
import { GTableProvider, useGTableContext } from './contexts';
import styles from './GTable.module.less';
import { GTableProps } from './typings';

const GTable = <
  DataType extends Record<string, any>,
  Params extends ParamsType = ParamsType,
  ValueType = 'text',
>({
  className,
  enableRowSelection,
  options,
  pagination = false,
  recordCreatorProps,
  rowKey = 'id',
  rowSelection,
  size = 'small',
  search = false,
  loading,
  noDataContent = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.sktcontainer} />,
  ...props
}: EditableProTableProps<DataType, Params, ValueType> & GTableProps) => {
  const { actionRef, selectedRowKeys, onRowSelectionChange } = useGTableContext();

  const initLocale = useMemo(
    () => ({
      emptyText: loading ? (
        <Skeleton
          active
          className={styles.sktcontainermain}
          title={false}
          paragraph={{ rows: 4, width: '100%' }}
        />
      ) : (
        noDataContent
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading],
  );

  return (
    <EditableProTable<DataType, Params, ValueType>
      {...props}
      actionRef={actionRef}
      className={classnames(styles.table, className)}
      ghost
      options={{
        density: false,
        reload: true,
        fullScreen: false,
        setting: true,
        ...options,
      }}
      pagination={pagination}
      rowKey={rowKey}
      rowSelection={
        enableRowSelection && {
          ...rowSelection,
          selectedRowKeys,
          onChange: onRowSelectionChange,
        }
      }
      recordCreatorProps={recordCreatorProps || false}
      search={search}
      size={size}
      loading={false}
      locale={initLocale}
    />
  );
};

const GTableContainer = <
  DataType extends Record<string, any>,
  Params extends ParamsType = ParamsType,
  ValueType = 'text',
>({
  actionRef,
  ...props
}: EditableProTableProps<DataType, Params, ValueType> & GTableProps) => (
  <GTableProvider actionRef={actionRef}>
    <GTable<DataType, Params, ValueType> {...props} />
  </GTableProvider>
);

export default GTableContainer;
