import { Select, SelectProps, Typography } from 'antd';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { useState } from 'react';
import { SearchProps } from './typings';

interface MainSelectProps extends SelectProps {
  setMainSelected: (mainSelected: string) => void;
  setSearch: (search: SearchProps) => void;
  setTraceTab: (traceTab: string) => void;
  style: any;
}

const MainSelect = ({
  setMainSelected,
  setSearch,
  style,
  setTraceTab,
  defaultValue,
}: MainSelectProps) => {
  const [mainList, setMainList] = useState<any>([
    {
      label: 'Lot',
      value: '1',
    },
  ]);

  const selectData = [
    {
      option: 'Lot',
      id: '1',
    },
    {
      option: 'SSCC',
      id: '7',
    },
    {
      option: 'Product Identifier',
      id: '8',
    },
  ];

  // we create here the list with the shape the select is expecting for
  const selectOptions: Array<DefaultOptionType> =
    selectData?.map((item) => ({
      label: String(item.option),
      value: String(item.id),
    })) || [];

  const onSelect = (value: any, option: any) => {
    setMainList([option]);
    setMainSelected(option?.label);
    setSearch({
      company: [],
      lot: [],
      location: [],
      state: [],
      country: [],
      sscc: [],
      event: [],
      productIdentifier: [],
      species: [],
    });
    setTraceTab('list');
  };
  const onDeselect = () => {
    setMainSelected('');
  };

  return (
    <Select
      defaultValue={defaultValue}
      key="mainselect"
      style={style}
      size="middle"
      options={selectOptions}
      value={mainList}
      placeholder={
        <Typography.Text style={{ opacity: '0.3', fontWeight: '400' }}>Lot</Typography.Text>
      }
      showSearch
      filterOption={(input, option) =>
        (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      onSelect={onSelect}
      onDeselect={onDeselect}
    />
  );
};

export default MainSelect;
