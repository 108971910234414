import { ArrowLeftOutlined } from '@ant-design/icons';
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { message, Space, Typography } from 'antd';
import { useQueryClient } from 'react-query';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { RadioChangeEvent } from 'antd';
import { FC, ReactElement, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGrantAccess } from 'services/api/Endorsers/useAccess';
import { EndorserDTO } from 'services/api/client/src';
import errorHandler from 'utils/errorHandler';
import styles from '../index.module.less';
import { DataItem, GrantAccessColumns } from './GrantAccess.fields';
import { GrantAccessProps } from './typings';

const GrantAccessForm: FC<GrantAccessProps> = ({ modal, grantAccessCompanyName, accountID }) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.grant_access_modal',
  });
  const [emails, setEmails] = useState<Array<string>>([]);
  const [levelValue, setLevelValue] = useState('view');
  const [categoryValue, setCategoryValue] = useState<CheckboxValueType[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedProducerData, setSelectedProducerData] = useState<EndorserDTO>({});
  const modalFormRef = useRef<ProFormInstance>();

  const queryClient = useQueryClient();
  const grantAccess = useGrantAccess(queryClient);

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    setEmails([]);
    modal.hide();
  }, [modal]);

  const Title: ReactElement = (
    <Space>
      {emails.length === 0 ? null : (
        <ArrowLeftOutlined
          className={styles.arrow}
          onClick={() => {
            setEmails([]);
          }}
        />
      )}
      <Typography.Title level={4} className="mr0">
        {t('grant_access_heading')}
      </Typography.Title>
    </Space>
  );

  const onLevelChange = (e: RadioChangeEvent) => {
    setLevelValue(e.target.value);
  };

  const onChangeGroup = (checkedValues: CheckboxValueType[]) => {
    setCategoryValue(checkedValues);
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      // @ts-ignore
      title={Title}
      formRef={modalFormRef}
      columns={GrantAccessColumns({
        t,
        onChangeGroup,
        onLevelChange,
        levelValue,
        categoryValue,
        showOptions,
        setShowOptions,
        grantAccessCompanyName,
        setSelectedCompany,
        setSelectedProducerData,
        accountID,
      })}
      grid
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        width: '40%',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('submit_btn_text'),
        },
        submitButtonProps: {
          shape: 'round',
          style: {
            color: '#0a5f7a',
            background: '#bcd530',
            border: '1px solid #bcd530',
          },
        },
        render: (_, defaultDoms) => defaultDoms[1],
      }}
      initialValues={{
        connectionType: 'Buyer',
      }}
      submitTimeout={2000}
      onFinish={async () => {
        if (showOptions) {
          if (levelValue === '' && categoryValue.length === 0) {
            message.error(t('level_category_not_selected'));
          } else if (levelValue === '') {
            message.error(t('level_not_selected'));
          } else if (categoryValue.length === 0) {
            message.error(t('category_not_selected'));
          } else {
            try {
              await grantAccess.mutateAsync({
                params: {
                  requestedEndorserId: accountID,
                  accessRequests: [
                    {
                      endorserId: selectedProducerData?.accountID,
                      auditReports: true,
                    },
                  ],
                },
              });
              message.success(
                t('access_granted_success_message', { grantAccessCompanyName: selectedCompany }),
              );
              closeModal();
            } catch (error) {
              message.error(errorHandler(error));
            }
          }
        } else {
          message.error(t('nothing_is_selected'));
        }
      }}
    />
  );
};

export default GrantAccessForm;
