import { Select, Typography } from 'antd';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { useState, useEffect } from 'react';
import { useCompaniesList } from 'services/api/useTrace';
import { CompanySelectProps } from './typings';
import styles from './index.module.less';

const CompanySelect = ({ search, setSearch, style }: CompanySelectProps) => {
  const [companyList, setCompanyList] = useState<any>();

  const { data: companiesListResponse, isLoading } = useCompaniesList();

  // we create here the list with the shape the select is expecting for
  const selectOptions: Array<DefaultOptionType> =
    companiesListResponse?.companies?.map((item) => ({
      label: String(item),
      value: String(item),
    })) || [];

  useEffect(() => {
    if (search?.company?.length === 0) {
      setCompanyList(undefined);
    }
  }, [search]);

  const onCompanyChange = (e: any, record: any) => {
    setCompanyList(e);
    if (record.length === 0) {
      setSearch({ ...search, company: [] });
    } else {
      setSearch({ ...search, company: record.map((item: any) => item.label) });
    }
    setSearch({ ...search, company: record.map((item: any) => item.label) });
  };

  const onClearButtonClick = () => {
    setSearch({ ...search, company: [] });
  };

  return (
    <Select
      key="companyselect"
      maxTagCount="responsive"
      mode="multiple"
      style={style}
      size="middle"
      loading={isLoading}
      options={selectOptions}
      className={styles.container}
      placeholder={
        <Typography.Text style={{ opacity: '0.3', fontWeight: '400' }}>Company</Typography.Text>
      }
      allowClear
      showSearch
      value={companyList}
      filterOption={(input, option) =>
        (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      onChange={onCompanyChange}
      onClear={onClearButtonClick}
    />
  );
};

export default CompanySelect;
