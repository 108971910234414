import { ProDescriptions } from '@ant-design/pro-components';
import { ProColumns } from '@ant-design/pro-table';
import { Anchor, BackTop, Typography } from 'antd';
import GTable from 'components/GTable';
import { FC } from 'react';
import { AuditPersonnelDTO, SpeciesDetailDTO } from 'services/api/client/src';
import styles from '../index.module.less';
import { StandaloneSummaryProps, WildvsFarmedProps } from '../typings';

const { Link } = Anchor;

type HorizontalDescProps = {
  permanent: number | undefined;
  temporary: number | undefined;
  agency: number | undefined;
};

const HorizontalDescription = ({ permanent, temporary, agency }: HorizontalDescProps) => (
  <ProDescriptions
    layout="horizontal"
    column={1}
    dataSource={{
      permanent,
      temporary,
      agency,
    }}
    columns={[
      {
        title: 'Permanent',
        key: 'permanent',
        dataIndex: 'permanent',
      },
      {
        title: 'Temporary',
        key: 'temporary',
        dataIndex: 'temporary',
      },
      {
        title: 'Agency',
        key: 'agency',
        dataIndex: 'agency',
      },
    ]}
  />
);

const columns = (): Array<ProColumns<WildvsFarmedProps>> => [
  {
    title: 'Product Form Source',
    dataIndex: 'productFormSource',
  },
  {
    title: 'Total Production',
    dataIndex: 'totalProduction',
  },
  {
    title: '% of Total',
    dataIndex: 'percentTotal',
    ellipsis: false,
  },
];

const tableColumns = (): Array<ProColumns<SpeciesDetailDTO>> => [
  {
    title: 'Species Scientific Name',
    dataIndex: 'scientificName',
    width: '15%',
  },
  {
    title: 'Species Category',
    dataIndex: 'speciesCategory',
    width: '13%',
  },
  {
    title: 'Product Forms Source',
    dataIndex: 'sourceType',
    ellipsis: false,
    width: '13%',
  },
  {
    title: 'Product Forms',
    dataIndex: 'productForms',
    ellipsis: false,
    width: '13%',
  },
  {
    title: 'Product Details',
    dataIndex: 'productionDetails',
    ellipsis: false,
    width: '35%',
  },
  {
    title: 'Total Production',
    dataIndex: 'newTotalProduction',
    ellipsis: false,
    width: '11%',
  },
];

const keyPersonnelTableColumns = (): Array<ProColumns<AuditPersonnelDTO>> => [
  {
    title: 'Name',
    dataIndex: 'name',
    ellipsis: true,
    width: '24%',
  },
  {
    title: 'Job Title',
    dataIndex: 'jobTitle',
    ellipsis: true,
    width: '24%',
  },
  {
    title: 'Opening Meeting',
    dataIndex: 'openingMeeting',
    ellipsis: false,
    width: '13%',
    render: (value: any) => (value ? 'Yes' : 'No'),
  },
  {
    title: 'Site Inspection',
    dataIndex: 'siteInspection',
    ellipsis: false,
    width: '13%',
    render: (value: any) => (value ? 'Yes' : 'No'),
  },
  {
    title: 'Procedure Review',
    dataIndex: 'procedureReview',
    ellipsis: false,
    width: '13%',
    render: (value: any) => (value ? 'Yes' : 'No'),
  },
  {
    title: 'Closing Meeting',
    dataIndex: 'closingMeeting',
    ellipsis: false,
    width: '13%',
    render: (value: any) => (value ? 'Yes' : 'No'),
  },
];

const StandaloneSummary: FC<StandaloneSummaryProps> = ({
  auditReportByIdResponse,
  auditKPI,
  t,
  nonConformanceTotals,
  workForceDetails,
  wildVsFarmed,
  wildVsFarmedSecond,
  totalProduction,
  keyPersonnelList,
}) => (
  <div className={styles.summarycontainer}>
    <div className={styles.summary}>
      <div id="first">
        <ProDescriptions
          title={<Typography.Title level={4}>{t('basic_facility_title')}</Typography.Title>}
          layout="vertical"
          column={{ xxl: 4, xl: 4, lg: 3, md: 2, sm: 2, xs: 1 }}
          colon={false}
          dataSource={{
            facilityCartNumber: auditReportByIdResponse?.auditReport?.bapNumber,
            facilityName: auditKPI?.orgName,
            certificationBody: auditKPI?.cbName,
            auditor: auditKPI?.auditorName,
            address: auditKPI?.address,
            startDate: auditKPI?.auditStartDate,
            endDate: auditKPI?.auditEndDate,
            lattitude: auditKPI?.latitude,
            longitude: auditKPI?.longitude,
            announceType: auditKPI?.announceType,
            remoteAudit: auditKPI?.remoteDone ? 'Yes' : 'No',
            facilityLicense: auditKPI?.governmentLicenseNumber,
            accountabilityAudit: auditKPI?.esaAuditDone ? 'Yes' : 'No',
            contactName: auditKPI?.auditContactName,
            harvestDesignation: auditKPI?.catchDesignation,
            facilityEmail: auditKPI?.email,
            contactEmail: auditKPI?.auditContactEmail,
            contactPhone: auditKPI?.auditContactPhone,
            facilityWebsite: auditKPI?.website,
            plantType: auditKPI?.plantType,
          }}
          columns={[
            {
              title: <Typography.Text strong>{t('facility_cart_number')}</Typography.Text>,
              key: 'text',
              dataIndex: 'facilityCartNumber',
            },
            {
              title: <Typography.Text strong>{t('facility_name')}</Typography.Text>,
              key: 'facilityName',
              dataIndex: 'facilityName',
            },
            {
              title: <Typography.Text strong>{t('name_of_certification_body')}</Typography.Text>,
              key: 'certificationBody',
              dataIndex: 'certificationBody',
            },
            {
              title: <Typography.Text strong>{t('auditor')}</Typography.Text>,
              key: 'auditor',
              dataIndex: 'auditor',
            },
            {
              title: <Typography.Text strong>{t('registered_address')}</Typography.Text>,
              key: 'address',
              dataIndex: 'address',
              span: 2,
            },
            {
              title: <Typography.Text strong>{t('audit_start_date')}</Typography.Text>,
              key: 'startDate',
              dataIndex: 'startDate',
              valueType: 'date',
              fieldProps: {
                format: 'YYYY-MM-DD',
              },
            },
            {
              title: <Typography.Text strong>{t('audit_end_date')}</Typography.Text>,
              key: 'endDate',
              dataIndex: 'endDate',
              valueType: 'date',
              fieldProps: {
                format: 'YYYY-MM-DD',
              },
            },
            {
              title: <Typography.Text strong>{t('lattitude')}</Typography.Text>,
              key: 'lattitude',
              dataIndex: 'lattitude',
            },
            {
              title: <Typography.Text strong>{t('longitude')}</Typography.Text>,
              key: 'longitude',
              dataIndex: 'longitude',
            },
            {
              title: <Typography.Text strong>{t('audit_announce_type')}</Typography.Text>,
              key: 'announceType',
              dataIndex: 'announceType',
            },
            {
              title: <Typography.Text strong>{t('remote_audit')}</Typography.Text>,
              key: 'remoteAudit',
              dataIndex: 'remoteAudit',
            },
            {
              title: <Typography.Text strong>{t('facility_license')}</Typography.Text>,
              key: 'facilityLicense',
              dataIndex: 'facilityLicense',
              span: 2,
            },
            {
              title: <Typography.Text strong>{t('social_accountability')}</Typography.Text>,
              key: 'accountabilityAudit',
              dataIndex: 'accountabilityAudit',
            },
            {
              title: <Typography.Text strong>{t('audit_contact_name')}</Typography.Text>,
              key: 'contactName',
              dataIndex: 'contactName',
            },
            {
              title: <Typography.Text strong>{t('harvest_designation')}</Typography.Text>,
              key: 'harvestDesignation',
              dataIndex: 'harvestDesignation',
            },
            {
              title: <Typography.Text strong>{t('facility_email')}</Typography.Text>,
              key: 'facilityEmail',
              dataIndex: 'facilityEmail',
            },
            {
              title: <Typography.Text strong>{t('audit_contact_email')}</Typography.Text>,
              key: 'contactEmail',
              dataIndex: 'contactEmail',
            },
            {
              title: <Typography.Text strong>{t('audit_contact_phone')}</Typography.Text>,
              key: 'contactPhone',
              dataIndex: 'contactPhone',
            },
            {
              title: <Typography.Text strong>{t('facility_webiste')}</Typography.Text>,
              key: 'facilityWebsite',
              dataIndex: 'facilityWebsite',
            },
            auditKPI?.accountType === 'Plant'
              ? {
                  title: <Typography.Text strong>{t('plant_type')}</Typography.Text>,
                  key: 'plantType',
                  dataIndex: 'plantType',
                }
              : (undefined as any),
          ]}
        />
      </div>
      <div id="second" style={{ marginTop: '64px' }}>
        <ProDescriptions
          title={<Typography.Title level={4}>Audit Details</Typography.Title>}
          layout="vertical"
          column={{ xxl: 4, xl: 4, lg: 3, md: 2, sm: 2, xs: 1 }}
          colon={false}
          dataSource={{
            auditType: auditKPI?.auditType,
            auditDuration: auditKPI?.auditDurationHour,
            secondaryAuditor: auditKPI?.assigned2NdAuditor,
            auditShadower: auditKPI?.shadower,
            auditWitness: auditKPI?.witness,
            socialAccountability: auditKPI?.socialAccountabilityAuditor,
            auditObserver: auditKPI?.observer,
            apscaMember: auditKPI?.apscaFirmNumber,
            apscaAuditor: auditKPI?.apscaAuditorNumber,
            auditNotes: auditKPI?.auditNotes,
            siteVisitNotes: auditKPI?.siteVisitNotes,
            previousAuditDate: auditKPI?.lastAuditDate,
            firstBAPCertification: auditKPI?.certifiedSinceDate,
            animalSurvivalRate:
              auditKPI?.accountType?.includes('Farm') || auditKPI?.accountType?.includes('Hatchery')
                ? auditKPI?.survivalRate
                : undefined,
            feedConverstionRatio: auditKPI?.fcr,
            nonConformanceTotals: (
              <ProDescriptions
                bordered
                size="small"
                column={1}
                colon={false}
                dataSource={{
                  critical: nonConformanceTotals?.critical,
                  major: nonConformanceTotals?.major,
                  minor: nonConformanceTotals?.minor,
                  yes: nonConformanceTotals?.yes,
                  notAvailable: nonConformanceTotals?.notAvailable,
                }}
                columns={[
                  {
                    title: <Typography.Text strong>Critical</Typography.Text>,
                    key: 'critical',
                    dataIndex: 'critical',
                    contentStyle: {
                      width: '105px',
                    },
                    labelStyle: {
                      width: '175px',
                    },
                  },
                  {
                    title: <Typography.Text strong>Major</Typography.Text>,
                    key: 'major',
                    dataIndex: 'major',
                    contentStyle: {
                      width: '105px',
                    },
                    labelStyle: {
                      width: '175px',
                    },
                  },
                  {
                    title: <Typography.Text strong>Minor</Typography.Text>,
                    key: 'minor',
                    dataIndex: 'minor',
                    contentStyle: {
                      width: '105px',
                    },
                    labelStyle: {
                      width: '175px',
                    },
                  },
                  {
                    title: <Typography.Text strong>Yes</Typography.Text>,
                    key: 'yes',
                    dataIndex: 'yes',
                    contentStyle: {
                      width: '105px',
                    },
                    labelStyle: {
                      width: '175px',
                    },
                  },
                  {
                    title: <Typography.Text strong>N/A</Typography.Text>,
                    key: 'notAvailable',
                    dataIndex: 'notAvailable',
                    contentStyle: {
                      width: '105px',
                    },
                    labelStyle: {
                      width: '175px',
                    },
                  },
                ]}
              />
            ),
            auditCompanyProfile: auditKPI?.companyProfile,
          }}
          columns={[
            {
              title: <Typography.Text strong>Audit Type</Typography.Text>,
              key: 'auditType',
              dataIndex: 'auditType',
            },
            {
              title: <Typography.Text strong>Audit Duration</Typography.Text>,
              key: 'auditDuration',
              dataIndex: 'auditDuration',
            },
            {
              title: <Typography.Text strong>Secondary Auditor</Typography.Text>,
              key: 'secondaryAuditor',
              dataIndex: 'secondaryAuditor',
            },
            {
              title: <Typography.Text strong>Audit Shadower</Typography.Text>,
              key: 'auditShadower',
              dataIndex: 'auditShadower',
            },
            {
              title: <Typography.Text strong>Audit Witness</Typography.Text>,
              key: 'auditWitness',
              dataIndex: 'auditWitness',
            },
            {
              title: <Typography.Text strong>Social Accountability Auditor</Typography.Text>,
              key: 'socialAccountability',
              dataIndex: 'socialAccountability',
            },
            {
              title: <Typography.Text strong>Audit Observer</Typography.Text>,
              key: 'auditObserver',
              dataIndex: 'auditObserver',
            },
            {
              title: <Typography.Text strong>APSCA Member Firm Number</Typography.Text>,
              key: 'apscaMember',
              dataIndex: 'apscaMember',
            },
            {
              title: <Typography.Text strong>APSCA Auditor Number</Typography.Text>,
              key: 'apscaAuditor',
              dataIndex: 'apscaAuditor',
              span: 4,
            },
            {
              title: <Typography.Text strong>Audit Notes</Typography.Text>,
              key: 'auditNotes',
              dataIndex: 'auditNotes',
              span: 4,
            },
            {
              title: <Typography.Text strong>Site Visit Notes</Typography.Text>,
              key: 'siteVisitNotes',
              dataIndex: 'siteVisitNotes',
              span: 4,
            },
            {
              title: <Typography.Text strong>Previous Audit Date</Typography.Text>,
              key: 'previousAuditDate',
              dataIndex: 'previousAuditDate',
              valueType: 'date',
              fieldProps: {
                format: 'YYYY-MM-DD',
              },
            },
            {
              title: <Typography.Text strong>Date of First BAP Certification</Typography.Text>,
              key: 'firstBAPCertification',
              dataIndex: 'firstBAPCertification',
              valueType: 'date',
              fieldProps: {
                format: 'YYYY-MM-DD',
              },
            },
            auditKPI?.accountType?.includes('Farm') || auditKPI?.accountType?.includes('Hatchery')
              ? {
                  title: <Typography.Text strong>Animal Survival Rate</Typography.Text>,
                  key: 'animalSurvivalRate',
                  dataIndex: 'animalSurvivalRate',
                }
              : ({
                  contentStyle: {
                    display: 'none',
                  },
                } as any),
            auditKPI?.accountType?.includes('Hatchery') || auditKPI?.accountType?.includes('Farm')
              ? {
                  title: <Typography.Text strong>Feed Conversion Ratio</Typography.Text>,
                  key: 'feedConverstionRatio',
                  dataIndex: 'feedConverstionRatio',
                }
              : ({
                  contentStyle: {
                    display: 'none',
                  },
                } as any),
            {
              title: <Typography.Text strong>Non-Conformance Totals</Typography.Text>,
              key: 'nonConformanceTotals',
              dataIndex: 'nonConformanceTotals',
              span: 4,
            },
            {
              title: <Typography.Text strong>Audit Company Profile</Typography.Text>,
              key: 'auditCompanyProfile',
              dataIndex: 'auditCompanyProfile',
            },
          ]}
        />
      </div>
      <div id="third" style={{ marginTop: '64px' }}>
        <ProDescriptions
          title={<Typography.Title level={4}>Scope of Audit</Typography.Title>}
          layout="vertical"
          column={3}
          colon={false}
          dataSource={{
            wildFarm: (
              <GTable<WildvsFarmedProps>
                columns={columns()}
                value={wildVsFarmed}
                options={{
                  setting: false,
                  reload: false,
                }}
                recordCreatorProps={false}
                scroll={{ x: true }}
              />
            ),
            totalProduction,
          }}
          columns={[
            {
              title: <Typography.Text strong>Total Production (metric tons)</Typography.Text>,
              key: 'totalProduction',
              dataIndex: 'totalProduction',
              span: 3,
            },
            auditKPI?.accountType?.includes('Plant')
              ? {
                  title: <Typography.Text strong>MT Wild vs Farmed</Typography.Text>,
                  key: 'wildFarm',
                  dataIndex: 'wildFarm',
                  valueType: 'number',
                  span: 3,
                }
              : ({
                  contentStyle: {
                    display: 'none',
                  },
                } as any),
          ]}
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        <GTable<SpeciesDetailDTO>
          columns={tableColumns()}
          value={wildVsFarmedSecond}
          options={{
            setting: false,
            reload: false,
          }}
          recordCreatorProps={false}
          scroll={{ x: 940 }}
        />
      </div>
      <div id="fourth" style={{ marginTop: '64px' }}>
        <ProDescriptions
          title={<Typography.Title level={4}>Key Personnel</Typography.Title>}
          layout="vertical"
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
          colon={false}
          dataSource={{
            fullTimeEmployees: auditKPI?.totalEmployees,
          }}
          columns={[
            {
              title: <Typography.Text strong>Total Number of full time employees</Typography.Text>,
              key: 'fullTimeEmployees',
              dataIndex: 'fullTimeEmployees',
            },
          ]}
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        <GTable<AuditPersonnelDTO>
          columns={keyPersonnelTableColumns()}
          value={keyPersonnelList}
          options={{
            setting: false,
            reload: false,
          }}
          recordCreatorProps={false}
          scroll={{ x: true }}
        />
      </div>
      <div id="fifth" style={{ marginTop: '64px' }}>
        <ProDescriptions
          title={<Typography.Title level={4}>Work Force Details</Typography.Title>}
          layout="vertical"
          column={{ xxl: 4, xl: 4, lg: 2, md: 2, sm: 1, xs: 1 }}
          colon={false}
          dataSource={{
            employmentAgenciesUsed: workForceDetails?.employmentAgenciesUsed ? 'Yes' : 'No',
            provideOnsiteHousing: workForceDetails?.onsiteHousing ? 'Yes' : 'No',
            housingIsSubContractedService: workForceDetails?.onsiteHousingSubcontracted
              ? 'Yes'
              : 'No',
            employeeInFacilityHousing: workForceDetails?.totalEmployeesInFacilityHousing,
            femaleProductionSupervisor: `${
              (workForceDetails?.productionSupervisorFemale || 0) * 100
            }%`,
            maleProductionSupervisor: `${(workForceDetails?.productionSupervisorMale || 0) * 100}%`,
            primaryNationalityManagement: (
              <ProDescriptions
                layout="horizontal"
                column={1}
                dataSource={{
                  country1: workForceDetails?.managementNationality1,
                  country2: workForceDetails?.managementNationality2,
                  country3: workForceDetails?.managementNationality3,
                }}
                request={async () =>
                  Promise.resolve({
                    success: true,
                    data: {
                      country1: 'Ecuador',
                    },
                  })
                }
                columns={[
                  {
                    title: 'Country 1',
                    key: 'country1',
                    dataIndex: 'country1',
                  },
                  {
                    title: 'Country 2',
                    key: 'country2',
                    dataIndex: 'country2',
                  },
                  {
                    title: 'Country 3',
                    key: 'country3',
                    dataIndex: 'country3',
                  },
                ]}
              />
            ),
            primaryNationalityWorkers: (
              <ProDescriptions
                layout="horizontal"
                column={1}
                dataSource={{
                  country1: workForceDetails?.workersNationality1,
                  country2: workForceDetails?.workersNationality2,
                  country3: workForceDetails?.workersNationality3,
                  country4: workForceDetails?.workersNationality4,
                  country5: workForceDetails?.workersNationality5,
                }}
                columns={[
                  {
                    title: 'Country 1',
                    key: 'country1',
                    dataIndex: 'country1',
                  },
                  {
                    title: 'Country 2',
                    key: 'country2',
                    dataIndex: 'country2',
                  },
                  {
                    title: 'Country 3',
                    key: 'country3',
                    dataIndex: 'country3',
                  },
                  {
                    title: 'Country 4',
                    key: 'country4',
                    dataIndex: 'country4',
                  },
                  {
                    title: 'Country 5',
                    key: 'country5',
                    dataIndex: 'country5',
                  },
                ]}
              />
            ),
            primaryNationalityWorkersPercentages: (
              <ProDescriptions
                layout="horizontal"
                column={1}
                dataSource={{
                  country1: `${(workForceDetails?.workersNationalityPercentage1 || 0) * 100}%`,
                  country2: `${(workForceDetails?.workersNationalityPercentage2 || 0) * 100}%`,
                  country3: `${(workForceDetails?.workersNationalityPercentage3 || 0) * 100}%`,
                  country4: `${(workForceDetails?.workersNationalityPercentage4 || 0) * 100}%`,
                  country5: `${(workForceDetails?.workersNationalityPercentage5 || 0) * 100}%`,
                }}
                columns={[
                  {
                    title: 'Country 1',
                    key: 'country1',
                    dataIndex: 'country1',
                  },
                  {
                    title: 'Country 2',
                    key: 'country2',
                    dataIndex: 'country2',
                  },
                  {
                    title: 'Country 3',
                    key: 'country3',
                    dataIndex: 'country3',
                  },
                  {
                    title: 'Country 4',
                    key: 'country4',
                    dataIndex: 'country4',
                  },
                  {
                    title: 'Country 5',
                    key: 'country5',
                    dataIndex: 'country5',
                  },
                ]}
              />
            ),
            localMaleWorkers: (
              <HorizontalDescription
                permanent={workForceDetails?.localMaleWorkersPermanent}
                temporary={workForceDetails?.localMaleWorkersTemporary}
                agency={workForceDetails?.localMaleWorkersAgency}
              />
            ),
            migrantMaleWorkers: (
              <HorizontalDescription
                permanent={workForceDetails?.migrantMaleWorkersPermanent}
                temporary={workForceDetails?.migrantMaleWorkersTemporary}
                agency={workForceDetails?.migrantMaleWorkersAgency}
              />
            ),
            localFemaleWorkers: (
              <HorizontalDescription
                permanent={workForceDetails?.localFemaleWorkersPermanent}
                temporary={workForceDetails?.localtFemaleWorkersTemporary}
                agency={workForceDetails?.localFemaleWorkersAgency}
              />
            ),
            migrantFemaleWorkers: (
              <HorizontalDescription
                permanent={workForceDetails?.migrantFemaleWorkersPermanent}
                temporary={workForceDetails?.migrantFemaleWorkersTemporary}
                agency={workForceDetails?.migrantFemaleWorkersAgency}
              />
            ),
            facilityOperatingShift: workForceDetails?.isSecondThirdShift,
            activitiesDuringShifts: workForceDetails?.otherShiftActivities,
            anyOnsiteServiceProviders: workForceDetails?.isOnsiteShiftProviders,
            typeOfService: workForceDetails?.serviceProviderDetails,
          }}
          columns={[
            {
              title: (
                <Typography.Text strong>
                  Does the Applicant use employment agencies or employee leasing companies as a
                  source of workers?
                </Typography.Text>
              ),
              key: 'employmentAgenciesUsed',
              dataIndex: 'employmentAgenciesUsed',
              span: 4,
            },
            {
              title: (
                <Typography.Text strong>
                  Primary Nationality of Management - In Rank Order 1-3
                </Typography.Text>
              ),
              key: 'primaryNationalityManagement',
              dataIndex: 'primaryNationalityManagement',
              span: 1,
            },
            {
              title: (
                <Typography.Text strong>
                  Primary Nationality of Workers - In Rank Order 1-5
                </Typography.Text>
              ),
              key: 'primaryNationalityWorkers',
              dataIndex: 'primaryNationalityWorkers',
              span: 1,
            },
            {
              title: (
                <Typography.Text strong>
                  Primary Nationality of Workers - Percentages
                </Typography.Text>
              ),
              key: 'primaryNationalityWorkersPercentages',
              dataIndex: 'primaryNationalityWorkersPercentages',
              span: 2,
            },
            {
              title: <Typography.Text strong>Local Male Workers</Typography.Text>,
              key: 'localMaleWorkers',
              dataIndex: 'localMaleWorkers',
              span: 1,
            },
            {
              title: <Typography.Text strong>Migrant Male Workers</Typography.Text>,
              key: 'migrantMaleWorkers',
              dataIndex: 'migrantMaleWorkers',
              span: 1,
            },
            {
              title: <Typography.Text strong>Male Production Supervisor</Typography.Text>,
              key: 'maleProductionSupervisor',
              dataIndex: 'maleProductionSupervisor',
              span: 2,
            },
            {
              title: <Typography.Text strong>Local Female Workers</Typography.Text>,
              key: 'localFemaleWorkers',
              dataIndex: 'localFemaleWorkers',
              span: 1,
            },
            {
              title: <Typography.Text strong>Migrant Female Workers</Typography.Text>,
              key: 'migrantFemaleWorkers',
              dataIndex: 'migrantFemaleWorkers',
              span: 1,
            },
            {
              title: <Typography.Text strong>Female Production Supervisor</Typography.Text>,
              key: 'femaleProductionSupervisor',
              dataIndex: 'femaleProductionSupervisor',
              span: 2,
            },
            {
              title: (
                <Typography.Text strong>
                  Does the facility provide on-site housing (dormitory) for workers?
                </Typography.Text>
              ),
              key: 'provideOnsiteHousing',
              dataIndex: 'provideOnsiteHousing',
              span: 1,
            },
            {
              title: (
                <Typography.Text strong>
                  If yes, is the housing a sub-contracted service?
                </Typography.Text>
              ),
              key: 'housingIsSubContractedService',
              dataIndex: 'housingIsSubContractedService',
              span: 1,
            },
            {
              title: (
                <Typography.Text strong>
                  Total Number of employee in facility housing
                </Typography.Text>
              ),
              key: 'employeeInFacilityHousing',
              dataIndex: 'employeeInFacilityHousing',
              span: 2,
            },
            {
              title: (
                <Typography.Text strong>
                  Does your facility operates a 2nd or 3rd shift?
                </Typography.Text>
              ),
              key: 'facilityOperatingShift',
              dataIndex: 'facilityOperatingShift',
              span: 1,
            },
            {
              title: (
                <Typography.Text strong>
                  If so, what production or other activities occur during these shifts?
                </Typography.Text>
              ),
              key: 'activitiesDuringShifts',
              dataIndex: 'activitiesDuringShifts',
              span: 3,
            },
            {
              title: (
                <Typography.Text strong>Are there any onsite service providers?</Typography.Text>
              ),
              key: 'anyOnsiteServiceProviders',
              dataIndex: 'anyOnsiteServiceProviders',
              span: 1,
            },
            {
              title: (
                <Typography.Text strong>
                  If so, please list name, location, type of service provided and during what
                  days/hours it happens.
                </Typography.Text>
              ),
              key: 'typeOfService',
              dataIndex: 'typeOfService',
              span: 3,
            },
          ]}
        />
      </div>
      {auditKPI?.remoteDone ? (
        <div id="sixth" style={{ marginTop: '64px' }}>
          <ProDescriptions
            title={<Typography.Title level={4}>Remote Audit Details</Typography.Title>}
            layout="vertical"
            column={{ xxl: 4, xl: 4, lg: 4, md: 1, sm: 1, xs: 1 }}
            colon={false}
            dataSource={{
              desktopReviewTools: auditKPI?.remoteDesktopReviewTools,
              desktopReviewEffectiveness: auditKPI?.remoteDesktopReviewEffectiveness,
              documentVerificationTools: auditKPI?.remoteDocumentVerificationTools,
              documnetEffectiveness: auditKPI?.remoteDocumentVerificationEffectiveness,
              interviewTools: auditKPI?.remoteInterviewTools,
              interviewEffectiveness: auditKPI?.remoteInterviewEffectiveness,
              meetingTools: auditKPI?.remoteMeetingTools,
              meetingEffectiveness: auditKPI?.remoteMeetingEffectiveness,
              siteTools: auditKPI?.remoteSiteInspectionTools,
              siteEffectiveness: auditKPI?.remoteSiteInspectiongEffectiveness,
            }}
            columns={[
              {
                title: <Typography.Text strong>Desktop Review Tools</Typography.Text>,
                key: 'desktopReviewTools',
                dataIndex: 'desktopReviewTools',
              },
              {
                title: <Typography.Text strong>Desktop Review Effectiveness</Typography.Text>,
                key: 'desktopReviewEffectiveness',
                dataIndex: 'desktopReviewEffectiveness',
                span: 3,
              },
              {
                title: <Typography.Text strong>Document Verification Tools</Typography.Text>,
                key: 'documentVerificationTools',
                dataIndex: 'documentVerificationTools',
              },
              {
                title: (
                  <Typography.Text strong>Document Verification Effectiveness</Typography.Text>
                ),
                key: 'documnetEffectiveness',
                dataIndex: 'documnetEffectiveness',
                span: 3,
              },
              {
                title: <Typography.Text strong>Interview Tools</Typography.Text>,
                key: 'interviewTools',
                dataIndex: 'interviewTools',
              },
              {
                title: <Typography.Text strong>Interview Effectiveness</Typography.Text>,
                key: 'interviewEffectiveness',
                dataIndex: 'interviewEffectiveness',
                span: 3,
              },
              {
                title: <Typography.Text strong>Meeting Tools</Typography.Text>,
                key: 'meetingTools',
                dataIndex: 'meetingTools',
              },
              {
                title: <Typography.Text strong>Meetings Effectiveness</Typography.Text>,
                key: 'meetingEffectiveness',
                dataIndex: 'meetingEffectiveness',
                span: 3,
              },
              {
                title: <Typography.Text strong>Site Inspection Tools</Typography.Text>,
                key: 'siteTools',
                dataIndex: 'siteTools',
              },
              {
                title: <Typography.Text strong>Site Inspection Effectiveness</Typography.Text>,
                key: 'siteEffectiveness',
                dataIndex: 'siteEffectiveness',
                span: 3,
              },
            ]}
          />
        </div>
      ) : undefined}
      <BackTop />
    </div>
    <div className={styles.anchors}>
      <Anchor offsetTop={65}>
        <Link href="#first" title="Basic Facility and Audit Information" />
        <Link href="#second" title="Audit Details" />
        <Link href="#third" title="Scope of Audit" />
        <Link href="#fourth" title="Key Personnel" />
        <Link href="#fifth" title="Work Force Details" />
        {auditKPI?.remoteDone ? <Link href="#sixth" title="Remote Audit Details" /> : undefined}
      </Anchor>
    </div>
  </div>
);

export default StandaloneSummary;
