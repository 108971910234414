import { Select, Typography } from 'antd';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { useEffect, useState } from 'react';
import { useContainerIdsList } from 'services/api/useTrace';
import styles from './index.module.less';
import { SsccSelectProps } from './typings';

const SsccSelect = ({ search, setSearch, style }: SsccSelectProps) => {
  const [ssccList, setSSCCList] = useState<any>();
  const { data: containerIdListResponse, isLoading } = useContainerIdsList();

  // we create here the list with the shape the select is expecting for
  const selectOptions: Array<DefaultOptionType> =
    containerIdListResponse?.containerIds?.map((item) => ({
      label: String(item?.containerId),
      value: String(item?.containerURN),
      itemProps: item,
    })) || [];

  useEffect(() => {
    if (search?.sscc?.length === 0) {
      setSSCCList(undefined);
    }
  }, [search]);

  const onSSCCChange = (e: any, record: any) => {
    setSSCCList(e);
    if (record.length === 0) {
      setSearch({ ...search, sscc: [] });
    } else {
      setSearch({ ...search, sscc: record.map((item: any) => item?.itemProps?.containerId) });
    }
  };

  const onClearButtonClick = () => {
    setSearch({ ...search, sscc: [] });
  };

  return (
    <Select
      key="ssccselect"
      style={style}
      maxTagCount="responsive"
      mode="multiple"
      size="middle"
      loading={isLoading}
      options={selectOptions}
      className={styles.container}
      placeholder={
        <Typography.Text style={{ opacity: '0.3', fontWeight: '400' }}>SSCC</Typography.Text>
      }
      allowClear
      showSearch
      value={ssccList}
      filterOption={(input, option) =>
        (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      onChange={onSSCCChange}
      onClear={onClearButtonClick}
    />
  );
};

export default SsccSelect;
