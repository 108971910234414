import GAuditReport, { GSummary } from 'components/GAuditReport';
import { useTabStore } from './hooks';

const EndorsersAudit = () => {
  const setTab = useTabStore((state) => state.setTab);
  const setAuditSearch = useTabStore((state) => state.setAuditSearch);
  const auditReportShow = useTabStore((state) => state.auditReportShow);
  const toggleAuditReportShow = useTabStore((state) => state.toggleAuditReportShow);
  return auditReportShow ? (
    <GAuditReport
      setTab={setTab}
      toggleAuditReportShow={toggleAuditReportShow}
      setAuditSearch={setAuditSearch}
      from="endorsersMain"
    />
  ) : (
    <GSummary
      setTab={setTab}
      toggleAuditReportShow={toggleAuditReportShow}
      setAuditSearch={setAuditSearch}
      from="endorsersMain"
    />
  );
};

export default EndorsersAudit;
