import type { ActionType } from '@ant-design/pro-table';
import { ProColumns } from '@ant-design/pro-table';
import { Space, Tag } from 'antd';
import GTable from 'components/GTable';
import { FC, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ModalItem, ModalTitleProp, NodeModalContentProps } from '../typings';
import styles from './index.module.less';

type ActionItemsProps = {
  t: TFunction<'pages', 'trace.trace_diagram'>;
};
const columns = ({ t }: ActionItemsProps): ProColumns<ModalItem>[] => [
  {
    title: t('modal.attributes'),
    dataIndex: 'attributes',
    width: '30%',
  },
  {
    title: t('modal.values'),
    dataIndex: 'values',
    render: (text) => text,
  },
];

const ModalTitle: FC<ModalTitleProp> = ({ selectedNode }) => (
  <Space size={0}>
    <Tag className={styles.headertag}>{selectedNode?.eventType}</Tag>
    <Tag className={styles.headertag}>
      {`Lot: ${
        selectedNode?.eventType?.includes('Transform')
          ? selectedNode?.products?.filter((item: any) => item.direction === 'OUTPUT')?.[0]?.lotId
          : selectedNode?.products?.[0]?.lotId
      }`}
    </Tag>
  </Space>
);

const EventDetailsContent = ({ selectedNode }: NodeModalContentProps) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'trace.trace_diagram',
  });
  const actionRef = useRef<ActionType>();
  const attributesArray: any = [
    t('modal.account_name'),
    // t('modal.account_id'),
    t('modal.time_date'),
    t('modal.timezone'),
    t('modal.urn'),
    t('modal.product'),
    t('modal.lot'),
    t('modal.quantity'),
    t('modal.event_location'),
    t('modal.event_geolocation'),
    t('modal.purchase_order'),
  ];

  const valuesArray: any = [
    selectedNode?.eventData?.company,
    // selectedNode?.products && selectedNode?.products?.[selectedNode.products.length - 1]?.accountID,
    selectedNode?.created,
    selectedNode?.eventData?.timeZoneOffset,
    selectedNode?.eventData?.urn,
    selectedNode?.products?.[selectedNode.products.length - 1]?.shortDescription,
    selectedNode?.products?.[selectedNode.products.length - 1]?.lotId,
    selectedNode?.products?.[selectedNode.products.length - 1]?.quantity,
    selectedNode?.location?.name,
    String(selectedNode?.eventData?.location?.latitude).concat(
      `, ${String(selectedNode?.eventData?.location?.longitude)}`,
    ),
    '',
  ];
  const eventData: any = attributesArray.map((item: string, idx: number) => ({
    attributes: item,
    values: valuesArray[idx],
  }));

  return (
    <div style={{ height: '500px', overflow: 'y' }}>
      <GTable<ModalItem>
        columns={columns({ t })}
        actionRef={actionRef}
        headerTitle={<ModalTitle total={eventData.length} selectedNode={selectedNode} />}
        value={eventData}
        options={{
          setting: false,
          reload: false,
        }}
        scroll={{ y: 410, x: 500 }}
      />
    </div>
  );
};

export default EventDetailsContent;
