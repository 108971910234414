import { SelectProps } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback, useState } from 'react';
import { ListRolesOptionalParams } from 'services/api/client/src';
import { usePermissionsList } from 'services/api/useSettings';
import GSelect from './GSelect';
import { FetchDataFn } from './typings';

const PermissionsSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType>,
) => {
  const [params, setParams] = useState<ListRolesOptionalParams>({});

  const { data, isLoading } = usePermissionsList(params);

  const selectOptions: Array<DefaultOptionType> =
    data?.permissions
      ?.filter((item: any) => item.permission !== 'USER_ADMINISTRATION')
      ?.map((item) => ({
        label: String(item?.name),
        value: String(item?.permission),
      })) || [];

  const fetchData: FetchDataFn<ValueType> = useCallback(({ filterText, initialValue }) => {
    if (initialValue) {
      setParams((oldParams) => ({ ...oldParams, url: String(initialValue) }));
    } else {
      setParams((oldParams) => ({ ...oldParams, search: filterText }));
    }
  }, []);

  return (
    <GSelect<ValueType, OptionType>
      placeholder="Please Select Permissions"
      showArrow
      {...props}
      loading={isLoading}
      options={selectOptions as OptionType[]}
      fetchData={fetchData}
    />
  );
};

export default PermissionsSelect;
