/* eslint-disable react/require-default-props */
import { Select, Typography, Spin, Empty } from 'antd';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EndorserDTO } from 'services/api/client/src';
import { useEndorsersList } from 'services/api/useEndorsers';

type EndorsersSelectProps = {
  setShowOptions: (showOptions: boolean) => void;
  setSelectedCompany: (selected: string) => void;
  setSelectedProducerData?: (selectedProducerData: EndorserDTO) => void;
  style?: any;
  selectedMainRowData?: any;
};

const EndorserSelect = ({
  setShowOptions,
  setSelectedCompany,
  setSelectedProducerData,
  style,
  selectedMainRowData,
}: EndorsersSelectProps) => {
  const [endorsersList, setEndorserList] = useState<string>();
  const [facility, setFacility] = useState('');

  const fetchData = (value: string) => {
    setFacility(value);
  };
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.grant_access_modal',
  });

  const { data: endorsersListResponse, isLoading: isNewListLoading } = useEndorsersList({
    facility,
    hideSelf: true,
    excludeIds: selectedMainRowData,
  });

  // we create here the list with the shape the select is expecting for
  const selectOptions: Array<DefaultOptionType> =
    endorsersListResponse?.results?.map((item, index) => ({
      label: String(item.facility).concat(item.bapNumber ? ` (${item.bapNumber})` : ''),
      value: String(index),
      itemProps: item,
    })) || [];

  const onEndorserChange = (e: any, record: any) => {
    setEndorserList(e);
    setSelectedCompany(record?.label);
    if (setSelectedProducerData) {
      setSelectedProducerData(record?.itemProps);
    }
    if (e?.length >= 1) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };

  return (
    <Select
      style={style}
      size="middle"
      options={selectOptions}
      placeholder={
        <Typography.Text style={{ opacity: '0.3', fontWeight: '400' }}>
          {t('select_endorser_placeholder')}
        </Typography.Text>
      }
      notFoundContent={
        isNewListLoading ? (
          <Spin size="small" />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
        )
      }
      allowClear
      showSearch
      value={endorsersList}
      filterOption={(input, option) =>
        (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      onSearch={fetchData}
      onChange={onEndorserChange}
      onClear={() => {
        setShowOptions(false);
      }}
    />
  );
};

export default EndorserSelect;
