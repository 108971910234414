import { FC, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { RadioChangeEvent } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Modal, Button, Typography, message, Space, Checkbox, Radio, Alert } from 'antd';
import errorHandler from 'utils/errorHandler';
import { useRejectRequest, useRequestAccess } from 'services/api/Endorsers/useAccess';
import { AccessRequest } from 'services/api/client/src';
import { EditAccessProps } from './typings';

const EditAccess: FC<EditAccessProps> = ({
  modal,
  selectedRowData,
  setSelectedRowData,
  myEndorsersListResponse,
}) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'settings.account_access_modals',
  });
  const queryClient = useQueryClient();
  const rejectAccess = useRejectRequest(queryClient);
  const requestAccess = useRequestAccess(queryClient);
  const [levelValue, setLevelValue] = useState('view');
  const [isRemoveAccessLoading, setIsRemoveAccessLoading] = useState<boolean>(false);
  const [categoryValue, setCategoryValue] = useState<CheckboxValueType[]>(['auditInformation']);

  const onCloseModal = () => {
    setSelectedRowData({});
    setCategoryValue(['auditInformation']);
    modal.hide();
  };

  const onDeny = () => {
    Modal.confirm({
      title: `Remove Access`,
      icon: <ExclamationCircleOutlined />,
      zIndex: 1800,
      onOk: async () => {
        setIsRemoveAccessLoading(true);
        try {
          await rejectAccess.mutateAsync({ endorserId: selectedRowData?.id || '' });
          setIsRemoveAccessLoading(false);
          message.success('Successfully Removed access');
          onCloseModal();
        } catch (error) {
          message.error(errorHandler(error));
          setIsRemoveAccessLoading(false);
        }
      },
      content: <div>Are you sure you want to remove access to {selectedRowData?.producer} ?</div>,
      cancelText: 'No',
      okText: 'Yes, Remove',
      centered: true,
      okButtonProps: {
        type: 'primary',
        shape: 'round',
        size: 'middle',
        style: {
          color: '#0a5f7a',
          borderColor: '#bcd530',
          background: '#bcd530',
          textShadow: '0px 0px',
          boxShadow: '0px 0px',
        },
        loading: isRemoveAccessLoading,
      },
      cancelButtonProps: {
        shape: 'round',
        size: 'middle',
        style: {
          color: '#0a5f7a',
          borderColor: '#0a5f7a',
          background: 'transparent',
          textShadow: '0px 0px',
          boxShadow: '0px 0px',
        },
      },
    });
  };

  const onSuccess = async () => {
    if (levelValue === '' && categoryValue.length === 0) {
      message.error(t('level_category_not_selected'));
    } else if (levelValue === '') {
      message.error(t('level_not_selected'));
    } else if (categoryValue.length === 0) {
      message.error(t('category_not_selected'));
    } else {
      try {
        const reqBody: AccessRequest = {
          endorserId: selectedRowData?.id,
          auditReports: true,
        };
        await requestAccess.mutateAsync({ params: reqBody });
        message.success('Successfully Updated Request Access');
        onCloseModal();
      } catch (error) {
        message.error(errorHandler(error));
      }
    }
  };

  const onLevelChange = (e: RadioChangeEvent) => {
    setLevelValue(e.target.value);
  };

  const onChangeGroup = (checkedValues: CheckboxValueType[]) => {
    setCategoryValue(checkedValues);
  };

  return (
    <Modal
      visible={modal.visible}
      title={
        <Typography.Title level={4}>
          {t('title', {
            companyName: selectedRowData?.producer,
          })}
        </Typography.Title>
      }
      closable
      onCancel={onCloseModal}
      footer={
        <>
          <Button
            onClick={onDeny}
            type="primary"
            shape="round"
            style={
              myEndorsersListResponse?.results?.find(
                (item) => item.endorserId === selectedRowData?.id,
              )?.accepted
                ? {
                    color: '#0a5f7a',
                    borderColor: '#0a5f7a',
                    background: 'white',
                    textShadow: '0px 0px',
                    boxShadow: '0px 0px',
                  }
                : undefined
            }
            disabled={
              !myEndorsersListResponse?.results?.find(
                (item) => item.endorserId === selectedRowData?.id,
              )?.accepted
            }
          >
            {t('remove_access')}
          </Button>
          <Button
            onClick={onSuccess}
            type="primary"
            shape="round"
            style={{
              color: '#0a5f7a',
              borderColor: '#bcd530',
              background: '#bcd530',
              textShadow: '0px 0px',
              boxShadow: '0px 0px',
            }}
          >
            {t('save')}
          </Button>
        </>
      }
      width="48%"
      bodyStyle={{ height: '60%', borderRadius: '0px' }}
      centered
    >
      <>
        {!myEndorsersListResponse?.results?.find((item) => item.endorserId === selectedRowData?.id)
          ?.accepted ? (
          <Alert
            message="Request Pending"
            description="You have already submitted access changes and cannot make anymore changes until the request has been accepted or denied."
            type="info"
            icon={<ExclamationCircleOutlined />}
            showIcon
            style={{
              marginBottom: '8px',
              background: '#E7F0F2',
              padding: '10px 15px',
              borderRadius: '10px',
            }}
          />
        ) : null}

        <Space direction="vertical">
          <Space direction="vertical" size="small">
            <Typography.Text>
              Select desired{' '}
              <Typography.Text strong underline>
                categories
              </Typography.Text>{' '}
              of access
            </Typography.Text>
            <Checkbox.Group
              onChange={onChangeGroup}
              value={categoryValue}
              disabled={
                !myEndorsersListResponse?.results?.find(
                  (item) => item.endorserId === selectedRowData?.id,
                )?.accepted
              }
            >
              <Checkbox value="auditInformation">{t('audit_information')}</Checkbox>
            </Checkbox.Group>
          </Space>
          <Space direction="vertical" size="small" style={{ marginTop: '24px' }}>
            <Typography.Text>
              Select desired{' '}
              <Typography.Text strong underline>
                level
              </Typography.Text>{' '}
              of access
            </Typography.Text>
            <Radio.Group
              onChange={onLevelChange}
              value={levelValue}
              disabled={
                !myEndorsersListResponse?.results?.find(
                  (item) => item.endorserId === selectedRowData?.id,
                )?.accepted
              }
            >
              <Space direction="vertical" size="small">
                <Radio value="view">{t('view_only')}</Radio>
                {/* <Radio value="share">
                  {t('share')}{' '}
                  <Tooltip title={t('share_tooltip')} placement="bottom">
                    <ExclamationCircleOutlined style={{ fontSize: '14px', opacity: '0.5' }} />
                  </Tooltip>
                </Radio> */}
              </Space>
            </Radio.Group>
          </Space>
        </Space>
      </>
    </Modal>
  );
};

export default EditAccess;
