/* eslint-disable no-console */
import { ArrowLeftOutlined } from '@ant-design/icons';
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { RowProps, Space, Typography, message } from 'antd';
import { FC, useCallback, useRef, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { isInvalidEmail } from 'utils/helpers';
import styles from './Invite.module.less';
import InviteFormFields from './InviteFormFields';
import { DataItem, InviteProps } from './typings';
import useInviteStore from './useInviteStore';

const rowProps: RowProps = { gutter: 16 };

const invalidEmailInList = (emails: Array<string>) => {
  if (emails.length !== 0) {
    const flags = emails.map((item) => {
      if (isInvalidEmail(String(item))) {
        return true;
      }
      return false;
    });
    return flags.includes(true);
  }
  return false;
};

const InviteModal: FC<InviteProps> = ({
  modal,
  userPermissionsList = [],
  selectedUser,
  onRemoveUser,
  onAssignRole,
  onInvite,
  isUsersListLoading,
}) => {
  const editableFormRef = useRef<ProFormInstance<DataItem>>();
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.invite_users_modal',
  });
  const emails = useInviteStore((state) => state.emails);
  const setEmails = useInviteStore((state) => state.setEmails);
  const resetFields = useCallback(() => {
    editableFormRef.current?.resetFields();
    setEmails([]);
  }, [setEmails]);

  const closeModal = useCallback(() => {
    modal.hide();
    resetFields();
  }, [modal, resetFields]);

  const Title: ReactElement = (
    <Space>
      {emails.length === 0 ? null : (
        <ArrowLeftOutlined
          className={styles.arrow}
          onClick={() => {
            setEmails([]);
          }}
        />
      )}
      <Typography.Title level={4} className="mr0">
        Invite
      </Typography.Title>
    </Space>
  );

  return (
    <BetaSchemaForm<DataItem>
      formRef={editableFormRef}
      layoutType="ModalForm"
      columns={InviteFormFields({
        t,
        userPermissionsList,
        selectedUser,
        onRemoveUser,
        onAssignRole,
        isUsersListLoading,
      })}
      grid
      // @ts-ignore
      title={Title}
      visible={modal.visible}
      rowProps={rowProps}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: false,
        width: '40%',
        ...modal,
        onCancel: closeModal,
        className: styles.modal,
      }}
      submitter={{
        searchConfig: {
          submitText: emails.length === 0 ? 'Done' : 'Send',
        },
        submitButtonProps: {
          shape: 'round',
          style: {
            color: '#0a5f7a',
            background: '#bcd530',
            border: '1px solid #bcd530',
          },
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
        render: (_, defaultDoms) => (emails.length === 0 ? defaultDoms[1] : defaultDoms),
      }}
      submitTimeout={2000}
      onFinish={async (formData: any) => {
        if (invalidEmailInList(emails)) {
          message.error(t('invalid_email_message'));
        } else if (emails.length === 0) {
          closeModal();
        } else {
          await onInvite(formData);
          closeModal();
        }
      }}
    />
  );
};
export default InviteModal;
