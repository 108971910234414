import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { RowProps, Space, Typography } from 'antd';
import { FC, useCallback, useRef, useEffect } from 'react';
import { useUserById } from 'services/api/useSettings';
import usePermissionStore from 'layouts/GeneralLayout/components/Settings/UserPermissions/hooks';
import ChangeRoleFields from './ChangeRoleFields';
import { DataItem, ChangeRoleProps } from './typings';

const rowProps: RowProps = { gutter: 16 };

interface TitleProps {
  // eslint-disable-next-line react/require-default-props
  name?: string;
}

const Title: any = ({ name }: TitleProps) => (
  <Space>
    <Typography.Title level={4} className="mr0">
      Change Role - {name}
    </Typography.Title>
  </Space>
);

const ChangeRoleModal: FC<ChangeRoleProps> = ({ modal, name, onAssignRole }) => {
  const editableFormRef = useRef<ProFormInstance<DataItem>>();
  const resetFields = useCallback(() => {
    editableFormRef.current?.resetFields();
  }, []);

  const selectedUserData = usePermissionStore((state) => state.selectedUserData);
  const setSelectedUserData = usePermissionStore((state) => state.setSelectedUserData);
  const { data: userByIdResponse } = useUserById(selectedUserData?.id || '');
  const closeModal = useCallback(() => {
    modal.hide();
    resetFields();
    setSelectedUserData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, resetFields]);

  useEffect(() => {
    if (userByIdResponse?.user?.role?.roleId) {
      editableFormRef.current?.setFieldsValue({
        roleValue: String(userByIdResponse?.user?.role?.roleId).concat(
          ` - ${userByIdResponse?.user?.role?.roleName}`,
        ),
      });
    } else {
      editableFormRef.current?.setFieldsValue({
        roleValue: undefined,
      });
    }
  }, [userByIdResponse?.user?.role?.roleId, userByIdResponse?.user?.role?.roleName]);

  return (
    <BetaSchemaForm<DataItem>
      formRef={editableFormRef}
      layoutType="ModalForm"
      columns={ChangeRoleFields()}
      grid
      // @ts-ignore
      title={<Title name={name} />}
      visible={modal.visible}
      rowProps={rowProps}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: false,
        width: '40%',
        zIndex: 1002,
        ...modal,
        centered: true,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: 'Save',
        },
        submitButtonProps: {
          shape: 'round',
          style: {
            color: '#0a5f7a',
            background: '#bcd530',
            border: '1px solid #bcd530',
          },
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
        render: (_, defaultDoms) => defaultDoms[1],
      }}
      submitTimeout={2000}
      onFinish={async (value: any) => {
        if (onAssignRole) {
          onAssignRole(value);
        }
        closeModal();
      }}
    />
  );
};
export default ChangeRoleModal;
