import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  // account access list params
  ListAccountsOptionalParams,
  ListAccountsOperationResponse,
  // update user api params
  UpdateUserResponse,
  UpdateUserRequest,
  // permissions list params
  ListPermissionsOptionalParams,
  ListPermissionsOperationResponse,
  // users List params
  ListUsersOptionalParams,
  ListUsersOperationResponse,
  // roles list params
  ListRolesOptionalParams,
  ListRolesOperationResponse,
  // my info params
  MeOptionalParams,
  MeOperationResponse,
  // create role params
  CreateRoleRequest,
  CreateRoleResponse,
  // update role params
  UpdateRoleRequest,
  UpdateRoleResponse,
  // assign roles params
  AssignRolesOperationResponse,
  AssignRolesRequest,
  // delete role query
  DeleteRoleOperationResponse,
  DeleteRoleOptionalParams,
  // user by id params
  GetUserByIdOptionalParams,
  GetUserByIdOperationResponse,
  // delete user params
  DeleteUserOptionalParams,
  DeleteUserOperationResponse,
  // roloe by id params
  GetRoleByIdOptionalParams,
  GetRoleByIdOperationResponse,
  // update profile pic params
  UploadProfilePictureOptionalParams,
  UploadProfilePictureOperationResponse,
  // invite users params
  InviteOptionalParams,
  InviteResponse,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

const updateUser = async (params?: UpdateUserRequest): Promise<UpdateUserResponse> =>
  HttpClient.updateUser({ body: params });

export const useUpdateUser = (queryClient: QueryClient) =>
  useMutation(({ params }: { params: UpdateUserRequest }) => updateUser(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('userInfo');
      queryClient.invalidateQueries('userInfoUpdated');
    },
  });

/* update profile picture query */
const updateProfilePicture = async (
  params?: UploadProfilePictureOptionalParams,
): Promise<UploadProfilePictureOperationResponse> => HttpClient.uploadProfilePicture(params);

export const useUploadProfilePicture = (queryClient: QueryClient) =>
  useMutation((params: UploadProfilePictureOptionalParams) => updateProfilePicture(params), {
    onSuccess: () => {
      queryClient.refetchQueries('userInfo');
      queryClient.invalidateQueries('userInfoUpdated');
    },
  });

// Users API
/* get permissions list query */
const permissionsList = async (
  params?: ListPermissionsOptionalParams,
): Promise<ListPermissionsOperationResponse> => HttpClient.listPermissions(params);

export const usePermissionsList = (params?: ListPermissionsOptionalParams) =>
  useQuery(['permissionsList', params], () => permissionsList(params));
/* get user info query */
const getUserInfo = async (params?: MeOptionalParams): Promise<MeOperationResponse> =>
  HttpClient.me(params);

export const useGetUserInfo = (queryClient: QueryClient) =>
  useMutation(({ params }: { params?: MeOptionalParams }) => getUserInfo(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('userInfo');
      queryClient.invalidateQueries('userInfoUpdated');
    },
  });

export const useUserInfo = (params?: MeOptionalParams) =>
  useQuery(['userInfo', params], () => getUserInfo(params));

export const useUserInfoUpdated = (params?: any) =>
  useQuery(['userInfoUpdated', params], () => getUserInfo(params), {
    enabled: params?.inProgress,
  });

// get user by id query
const getUserById = async (
  userId: string,
  params?: GetUserByIdOptionalParams,
): Promise<GetUserByIdOperationResponse> => HttpClient.getUserById(userId, params);

export const useUserById = (userId: string, params?: GetUserByIdOptionalParams) =>
  useQuery(['userById', userId, params], () => getUserById(userId, params));

/* get users list query */
const usersList = async (params?: ListUsersOptionalParams): Promise<ListUsersOperationResponse> =>
  HttpClient.listUsers(params);

export const useUsersList = (params?: ListUsersOptionalParams) =>
  useQuery(['usersList', params], () => usersList(params));

/* delete role query */
const deleteUser = async (
  userId: string,
  params?: DeleteUserOptionalParams,
): Promise<DeleteUserOperationResponse> => HttpClient.deleteUser(userId, params);

export const useDeleteUser = (queryClient: QueryClient) =>
  useMutation(
    ({ userId, params }: { userId: string; params?: DeleteUserOptionalParams }) =>
      deleteUser(userId, params),
    {
      onSuccess: () => {
        queryClient.refetchQueries('usersList');
      },
    },
  );

/* get roles list query */
const getRolesList = async (
  params?: ListRolesOptionalParams,
): Promise<ListRolesOperationResponse> => HttpClient.listRoles(params);

export const useRolesList = (params?: ListRolesOptionalParams) =>
  useQuery(['rolesList', params], () => getRolesList(params));

// get role by id query
const getRoleById = async (
  roleId: string,
  params?: GetRoleByIdOptionalParams,
): Promise<GetRoleByIdOperationResponse> => HttpClient.getRoleById(roleId, params);

export const useRoleById = (roleId: string, params?: GetRoleByIdOptionalParams) =>
  useQuery(['roleById', roleId, params], () => getRoleById(roleId, params));

// create role query
const createRoleRequest = (params: CreateRoleRequest): Promise<CreateRoleResponse> =>
  HttpClient.createRole({ body: params });

export const useCreateRole = (queryClient: QueryClient) =>
  useMutation(({ params }: { params: CreateRoleRequest }) => createRoleRequest(params), {
    onSuccess: () => {
      queryClient.refetchQueries('rolesList');
    },
  });

/* update role query */
const updateRole = async (params?: UpdateRoleRequest): Promise<UpdateRoleResponse> =>
  HttpClient.updateRole({ body: params });

export const useUpdateRole = (queryClient: QueryClient) =>
  useMutation(({ params }: { params: UpdateRoleRequest }) => updateRole(params), {
    onSuccess: () => {
      queryClient.refetchQueries('rolesList');
      queryClient.refetchQueries('usersList');
      queryClient.refetchQueries('roleById');
      queryClient.refetchQueries('userById');
    },
  });

/* change role query */
const assignRole = async (params?: AssignRolesRequest): Promise<AssignRolesOperationResponse> =>
  HttpClient.assignRoles({ body: params });

export const useAssignRole = (queryClient: QueryClient) =>
  useMutation(({ params }: { params: AssignRolesRequest }) => assignRole(params), {
    onSuccess: () => {
      queryClient.refetchQueries('rolesList');
      queryClient.refetchQueries('usersList');
      queryClient.refetchQueries('roleById');
      queryClient.refetchQueries('userById');
    },
  });

/* delete role query */
const deleteRole = async (
  roleId: string,
  params?: DeleteRoleOptionalParams,
): Promise<DeleteRoleOperationResponse> => HttpClient.deleteRole(roleId, params);

export const useDeleteRole = (queryClient: QueryClient) =>
  useMutation(
    ({ roleId, params }: { roleId: string; params?: DeleteRoleOptionalParams }) =>
      deleteRole(roleId, params),
    {
      onSuccess: () => {
        queryClient.refetchQueries('rolesList');
        queryClient.refetchQueries('usersList');
        queryClient.refetchQueries('roleById');
        queryClient.refetchQueries('userById');
      },
    },
  );

/* invite user query */
const inviteUsers = async (params?: InviteOptionalParams): Promise<InviteResponse> =>
  HttpClient.invite(params);

export const useInviteUsers = (queryClient: QueryClient) =>
  useMutation((params: InviteOptionalParams) => inviteUsers(params), {
    onSuccess: () => {
      queryClient.refetchQueries('usersList');
    },
  });

const accountsList = async (
  params?: ListAccountsOptionalParams,
): Promise<ListAccountsOperationResponse> => HttpClient.listAccounts(params);

/* get account access list query */
export const useAccountsList = (params?: ListAccountsOptionalParams) =>
  useQuery(['accountAccessList', params], () => accountsList(params));
