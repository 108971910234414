import {
  OperationParameter,
  OperationURLParameter,
  OperationQueryParameter,
} from "@azure/core-client";
import {
  AccessRequest as AccessRequestMapper,
  GrantAccessRequest as GrantAccessRequestMapper,
  RevokeAccessRequest as RevokeAccessRequestMapper,
  ClearNotificationsRequest as ClearNotificationsRequestMapper,
  NotifyHelpDeskRequest as NotifyHelpDeskRequestMapper,
  CreateRoleRequest as CreateRoleRequestMapper,
  UpdateRoleRequest as UpdateRoleRequestMapper,
  AssignRolesRequest as AssignRolesRequestMapper,
  UpdateUserRequest as UpdateUserRequestMapper,
} from "../models/mappers";

export const contentType: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const body: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: AccessRequestMapper,
};

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json, text/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String",
    },
  },
  skipEncoding: true,
};

export const body1: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: GrantAccessRequestMapper,
};

export const body2: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: RevokeAccessRequestMapper,
};

export const endorserId: OperationURLParameter = {
  parameterPath: "endorserId",
  mapper: {
    serializedName: "endorserId",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const endorserName: OperationQueryParameter = {
  parameterPath: ["options", "endorserName"],
  mapper: {
    serializedName: "endorserName",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const bapNumber: OperationQueryParameter = {
  parameterPath: ["options", "bapNumber"],
  mapper: {
    serializedName: "bapNumber",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const bapNumber1: OperationQueryParameter = {
  parameterPath: ["options", "bapNumber"],
  mapper: {
    serializedName: "bapNumber",
    type: {
      name: "String",
    },
  },
};

export const facility: OperationQueryParameter = {
  parameterPath: ["options", "facility"],
  mapper: {
    serializedName: "facility",
    type: {
      name: "String",
    },
  },
};

export const childAccessFacility: OperationQueryParameter = {
  parameterPath: ["options", "childAccessFacility"],
  mapper: {
    serializedName: "childAccessFacility",
    type: {
      name: "String",
    },
  },
};

export const facilityTypes: OperationQueryParameter = {
  parameterPath: ["options", "facilityTypes"],
  mapper: {
    serializedName: "facilityTypes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const countries: OperationQueryParameter = {
  parameterPath: ["options", "countries"],
  mapper: {
    serializedName: "countries",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const expiration: OperationQueryParameter = {
  parameterPath: ["options", "expiration"],
  mapper: {
    serializedName: "expiration",
    type: {
      name: "DateTime",
    },
  },
};

export const species: OperationQueryParameter = {
  parameterPath: ["options", "species"],
  mapper: {
    serializedName: "species",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const order: OperationQueryParameter = {
  parameterPath: ["options", "order"],
  mapper: {
    serializedName: "order",
    type: {
      name: "String",
    },
  },
};

export const direction: OperationQueryParameter = {
  parameterPath: ["options", "direction"],
  mapper: {
    serializedName: "direction",
    type: {
      name: "String",
    },
  },
};

export const hideSelf: OperationQueryParameter = {
  parameterPath: ["options", "hideSelf"],
  mapper: {
    serializedName: "hideSelf",
    type: {
      name: "Boolean",
    },
  },
};

export const excludeIds: OperationQueryParameter = {
  parameterPath: ["options", "excludeIds"],
  mapper: {
    serializedName: "excludeIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const pageNumber: OperationQueryParameter = {
  parameterPath: ["options", "pageNumber"],
  mapper: {
    serializedName: "pageNumber",
    type: {
      name: "Number",
    },
  },
};

export const pageSize: OperationQueryParameter = {
  parameterPath: ["options", "pageSize"],
  mapper: {
    serializedName: "pageSize",
    type: {
      name: "Number",
    },
  },
};

export const facilityName: OperationQueryParameter = {
  parameterPath: ["options", "facilityName"],
  mapper: {
    serializedName: "facilityName",
    type: {
      name: "String",
    },
  },
};

export const status: OperationQueryParameter = {
  parameterPath: ["options", "status"],
  mapper: {
    serializedName: "status",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const submittedDate: OperationQueryParameter = {
  parameterPath: ["options", "submittedDate"],
  mapper: {
    serializedName: "submittedDate",
    type: {
      name: "String",
    },
  },
};

export const orderBy: OperationQueryParameter = {
  parameterPath: ["options", "orderBy"],
  mapper: {
    serializedName: "orderBy",
    type: {
      name: "String",
    },
  },
};

export const direction1: OperationQueryParameter = {
  parameterPath: ["options", "direction"],
  mapper: {
    serializedName: "direction",
    type: {
      name: "String",
    },
  },
};

export const bapNumber2: OperationURLParameter = {
  parameterPath: "bapNumber",
  mapper: {
    serializedName: "bapNumber",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const reportId: OperationURLParameter = {
  parameterPath: "reportId",
  mapper: {
    serializedName: "reportId",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const referenceNumber: OperationQueryParameter = {
  parameterPath: ["options", "referenceNumber"],
  mapper: {
    serializedName: "referenceNumber",
    type: {
      name: "String",
    },
  },
};

export const pillars: OperationQueryParameter = {
  parameterPath: ["options", "pillars"],
  mapper: {
    serializedName: "pillars",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const bAPNumbers: OperationQueryParameter = {
  parameterPath: ["options", "bAPNumbers"],
  mapper: {
    serializedName: "bAPNumbers",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const phases: OperationQueryParameter = {
  parameterPath: ["options", "phases"],
  mapper: {
    serializedName: "phases",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const answers: OperationQueryParameter = {
  parameterPath: ["options", "answers"],
  mapper: {
    serializedName: "answers",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const causeExplanation: OperationQueryParameter = {
  parameterPath: ["options", "causeExplanation"],
  mapper: {
    serializedName: "causeExplanation",
    type: {
      name: "String",
    },
  },
};

export const order1: OperationQueryParameter = {
  parameterPath: ["options", "order"],
  mapper: {
    serializedName: "order",
    type: {
      name: "String",
    },
  },
};

export const direction2: OperationQueryParameter = {
  parameterPath: ["options", "direction"],
  mapper: {
    serializedName: "direction",
    type: {
      name: "String",
    },
  },
};

export const accept1: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/pdf",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const order2: OperationQueryParameter = {
  parameterPath: ["options", "order"],
  mapper: {
    serializedName: "order",
    type: {
      name: "String",
    },
  },
};

export const direction3: OperationQueryParameter = {
  parameterPath: ["options", "direction"],
  mapper: {
    serializedName: "direction",
    type: {
      name: "String",
    },
  },
};

export const summary: OperationQueryParameter = {
  parameterPath: ["options", "summary"],
  mapper: {
    serializedName: "summary",
    type: {
      name: "Boolean",
    },
  },
};

export const fullReport: OperationQueryParameter = {
  parameterPath: ["options", "fullReport"],
  mapper: {
    serializedName: "fullReport",
    type: {
      name: "Boolean",
    },
  },
};

export const partnerTypes: OperationQueryParameter = {
  parameterPath: ["options", "partnerTypes"],
  mapper: {
    serializedName: "partnerTypes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const eSA: OperationQueryParameter = {
  parameterPath: ["options", "eSA"],
  mapper: {
    serializedName: "eSA",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const announceType: OperationQueryParameter = {
  parameterPath: ["options", "announceType"],
  mapper: {
    serializedName: "announceType",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const order3: OperationQueryParameter = {
  parameterPath: ["options", "order"],
  mapper: {
    serializedName: "order",
    type: {
      name: "String",
    },
  },
};

export const direction4: OperationQueryParameter = {
  parameterPath: ["options", "direction"],
  mapper: {
    serializedName: "direction",
    type: {
      name: "String",
    },
  },
};

export const accountId: OperationURLParameter = {
  parameterPath: "accountId",
  mapper: {
    serializedName: "accountId",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const order4: OperationQueryParameter = {
  parameterPath: ["options", "order"],
  mapper: {
    serializedName: "order",
    type: {
      name: "String",
    },
  },
};

export const direction5: OperationQueryParameter = {
  parameterPath: ["options", "direction"],
  mapper: {
    serializedName: "direction",
    type: {
      name: "String",
    },
  },
};

export const company: OperationQueryParameter = {
  parameterPath: ["options", "company"],
  mapper: {
    serializedName: "company",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const lotIds: OperationQueryParameter = {
  parameterPath: ["options", "lotIds"],
  mapper: {
    serializedName: "lotIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const locationName: OperationQueryParameter = {
  parameterPath: ["options", "locationName"],
  mapper: {
    serializedName: "locationName",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const state: OperationQueryParameter = {
  parameterPath: ["options", "state"],
  mapper: {
    serializedName: "state",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const country: OperationQueryParameter = {
  parameterPath: ["options", "country"],
  mapper: {
    serializedName: "country",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const sscc: OperationQueryParameter = {
  parameterPath: ["options", "sscc"],
  mapper: {
    serializedName: "sscc",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const productIdentifier: OperationQueryParameter = {
  parameterPath: ["options", "productIdentifier"],
  mapper: {
    serializedName: "productIdentifier",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const startDate: OperationQueryParameter = {
  parameterPath: ["options", "startDate"],
  mapper: {
    serializedName: "startDate",
    type: {
      name: "DateTime",
    },
  },
};

export const endDate: OperationQueryParameter = {
  parameterPath: ["options", "endDate"],
  mapper: {
    serializedName: "endDate",
    type: {
      name: "DateTime",
    },
  },
};

export const lotId: OperationQueryParameter = {
  parameterPath: ["options", "lotId"],
  mapper: {
    serializedName: "lotId",
    type: {
      name: "String",
    },
  },
};

export const recentActivity: OperationQueryParameter = {
  parameterPath: ["options", "recentActivity"],
  mapper: {
    serializedName: "recentActivity",
    type: {
      name: "Boolean",
    },
  },
};

export const fromParam: OperationQueryParameter = {
  parameterPath: ["options", "from"],
  mapper: {
    serializedName: "from",
    type: {
      name: "DateTime",
    },
  },
};

export const to: OperationQueryParameter = {
  parameterPath: ["options", "to"],
  mapper: {
    serializedName: "to",
    type: {
      name: "DateTime",
    },
  },
};

export const body3: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ClearNotificationsRequestMapper,
};

export const body4: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: NotifyHelpDeskRequestMapper,
};

export const body5: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateRoleRequestMapper,
};

export const body6: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateRoleRequestMapper,
};

export const roleName: OperationQueryParameter = {
  parameterPath: ["options", "roleName"],
  mapper: {
    serializedName: "roleName",
    type: {
      name: "String",
    },
  },
};

export const permissions: OperationQueryParameter = {
  parameterPath: ["options", "permissions"],
  mapper: {
    serializedName: "permissions",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const roleId: OperationURLParameter = {
  parameterPath: "roleId",
  mapper: {
    serializedName: "roleId",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const body7: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: AssignRolesRequestMapper,
};

export const term: OperationQueryParameter = {
  parameterPath: ["options", "term"],
  mapper: {
    serializedName: "term",
    type: {
      name: "String",
    },
  },
};

export const body8: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateUserRequestMapper,
};

export const email: OperationQueryParameter = {
  parameterPath: ["options", "email"],
  mapper: {
    serializedName: "email",
    type: {
      name: "String",
    },
  },
};

export const name: OperationQueryParameter = {
  parameterPath: ["options", "name"],
  mapper: {
    serializedName: "name",
    type: {
      name: "String",
    },
  },
};

export const roleName1: OperationQueryParameter = {
  parameterPath: ["options", "roleName"],
  mapper: {
    serializedName: "roleName",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const userId: OperationURLParameter = {
  parameterPath: "userId",
  mapper: {
    serializedName: "userId",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const contentType1: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "multipart/form-data",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const picture: OperationParameter = {
  parameterPath: ["options", "picture"],
  mapper: {
    serializedName: "picture",
    type: {
      name: "Stream",
    },
  },
};

export const accept2: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json, text/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const emails: OperationParameter = {
  parameterPath: ["options", "emails"],
  mapper: {
    serializedName: "emails",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const message: OperationParameter = {
  parameterPath: ["options", "message"],
  mapper: {
    serializedName: "message",
    type: {
      name: "String",
    },
  },
};

export const roleId1: OperationParameter = {
  parameterPath: ["options", "roleId"],
  mapper: {
    serializedName: "roleId",
    type: {
      name: "String",
    },
  },
};

export const accountId1: OperationParameter = {
  parameterPath: ["options", "accountId"],
  mapper: {
    serializedName: "accountId",
    type: {
      name: "String",
    },
  },
};

export const endorserRole: OperationParameter = {
  parameterPath: ["options", "endorserRole"],
  mapper: {
    serializedName: "endorserRole",
    type: {
      name: "String",
    },
  },
};
