import { FC } from 'react';
import styles from './components/index.module.less';
import TraceMap from './components/TraceMap';
import SupplychainDiagram from './components/TraceDiagram/SupplychainDiagram';
import TableFiltersRow from './components/TableHeader';
import TraceList from './components/TraceList';
import { GTraceTableProps } from './typings';

const TraceTable: FC<GTraceTableProps> = ({
  traceTab,
  setTraceTab,
  expandedFilters,
  toggleExpandedFilters,
  search,
  mainSelected,
  setMainSelected,
  selectedLotData,
  setSelectedLotData,
  selectedNode,
  setSelectedNode,
  selectedMarker,
  setSelectedMarker,
  selectedLot,
  setSearch,
  setSelectedLot,
  // api data
  allEventsList,
  isAllEventsListLoading,
  // event map api data
  eventMapLocation,
  isEventMpaLocationLoading,
  // event diagram api
  eventDiagramNodes,
  isEventDiagramLoading,
}) => (
  <div className={styles.container}>
    <TableFiltersRow
      // Top Filter Row
      expandedFilters={expandedFilters}
      toggleExpandedFilters={toggleExpandedFilters}
      mainSelected={mainSelected}
      setMainSelected={setMainSelected}
      setSearch={setSearch}
      selectedLot={selectedLot}
      setSelectedLot={setSelectedLot}
      // api data
      isAllEventsListLoading={isAllEventsListLoading}
      // Switch buttons
      search={search}
      traceTab={traceTab}
      setTraceTab={setTraceTab}
    />
    {traceTab === 'list' ? (
      <TraceList
        selectedLotData={selectedLotData}
        setSelectedLotData={setSelectedLotData}
        // api data
        allEventsList={allEventsList}
        isAllEventsListLoading={isAllEventsListLoading}
      />
    ) : null}
    {traceTab === 'map' ? (
      <TraceMap
        selectedLot={selectedLot}
        setTraceTab={setTraceTab}
        selectedMarker={selectedMarker}
        setSelectedMarker={setSelectedMarker}
        // event map api data
        eventMapLocation={eventMapLocation}
        isEventMpaLocationLoading={isEventMpaLocationLoading}
      />
    ) : null}
    {traceTab === 'diagram' ? (
      <SupplychainDiagram
        selectedLot={selectedLot}
        setSelectedNode={setSelectedNode}
        setTraceTab={setTraceTab}
        selectedNode={selectedNode}
        // event diagram data
        eventDiagramNodes={eventDiagramNodes}
        isEventDiagramLoading={isEventDiagramLoading}
      />
    ) : null}
  </div>
);

export default TraceTable;
