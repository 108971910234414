import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import { Row, Tooltip } from 'antd';
import { VerticalAlignMiddleOutlined, FullscreenOutlined } from '@ant-design/icons';
import useModalVisibility from 'hooks/useModalVisibility';
import TraceDiagram from './TraceDiagram';
import NodeModal from './Modal';
import Box from '../Box';
import './diagram.css';
import { SupplychainDiagramProps } from './typings';

const SupplyChainDiagram = ({
  selectedLot,
  setSelectedNode,
  setTraceTab,
  selectedNode,
  // event diagram data
  eventDiagramNodes,
}: SupplychainDiagramProps) => {
  const nodeModal = useModalVisibility(false);
  const { t } = useTranslation('pages', { keyPrefix: 'trace.trace_diagram' });
  const [fullscreenView, setFullscreenView] = useState(false);
  const [mainGraph, setMainGraph] = useState<any>(null);

  const handle = useFullScreenHandle();
  const handleFullscreenChange = (status: any) => {
    setFullscreenView(status);
  };

  const alignGraph = () => mainGraph && mainGraph.fitView();

  return (
    <Box style={{ padding: '15px 0px' }} timeCount={eventDiagramNodes?.totalLifeCycle}>
      <Row justify="end">
        <Tooltip title={t('middle_align_icon_tooltip')} placement="bottomLeft">
          <VerticalAlignMiddleOutlined onClick={alignGraph} style={{ paddingRight: '18px' }} />
        </Tooltip>
        <Tooltip title={t('expand_full_screen_icon_tooltip')} placement="bottomLeft">
          <FullscreenOutlined onClick={handle.enter} style={{ paddingRight: '18px' }} />
        </Tooltip>
      </Row>

      {nodeModal.visible && <NodeModal modal={nodeModal} selectedNode={selectedNode} />}
      <FullScreen handle={handle} onChange={handleFullscreenChange}>
        <TraceDiagram
          fullscreenView={fullscreenView}
          mainGraph={mainGraph}
          setMainGraph={setMainGraph}
          modal={nodeModal}
          setTraceTab={setTraceTab}
          selectedLot={selectedLot}
          setSelectedNode={setSelectedNode}
          // api event diagram
          eventDiagramNodes={eventDiagramNodes}
        />
      </FullScreen>
    </Box>
  );
};

export default SupplyChainDiagram;
