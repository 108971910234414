import { ProFormColumnsType } from '@ant-design/pro-form';
import { useMemo } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Space } from 'antd';
import { usePermissionsList } from 'services/api/useSettings';
import { DataItem } from './typings';

const EditRoleFormFields = (): ProFormColumnsType<DataItem>[] => {
  const { data } = usePermissionsList();
  const permissionsList = useMemo(
    () =>
      data?.permissions
        ?.filter((item: any) => item.permission !== 'USER_ADMINISTRATION')
        .reduce((acc: any, val: any) => {
          const obj = {
            [val?.permission as string]: {
              text: [val.name],
              value: [val.permission || ''],
            },
          };
          return { ...acc, ...obj };
        }, {}),
    [data],
  );

  return [
    {
      dataIndex: 'roleValue',
      title: 'Role Name',
      fieldProps: {
        placeholder: 'Role Name',
      },
      formItemProps: {
        rules: [
          {
            required: true,
            message: 'Please Enter Role Name',
          },
          {
            type: 'string',
            whitespace: true,
          },
        ],
      },
    },
    {
      dataIndex: 'space',
      renderFormItem: () => <div style={{ marginTop: '40px' }} />,
    },
    {
      dataIndex: 'permissionValue',
      valueType: 'select',
      title: (
        <Space size="small">
          Select Permissions
          <Tooltip title="If you do not see a permission you are looking for, please contact us">
            <InfoCircleOutlined style={{ fontSize: '14px' }} />
          </Tooltip>
        </Space>
      ),
      fieldProps: {
        placeholder: 'Permission',
        mode: 'multiple',
      },
      formItemProps: {
        rules: [
          {
            required: true,
            message: 'Please Enter Permission',
          },
        ],
      },
      valueEnum: permissionsList,
    },
  ];
};
export default EditRoleFormFields;
