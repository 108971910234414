/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import { ProColumns } from '@ant-design/pro-table';
import { Tag, Tooltip, Typography, message } from 'antd';
import GTable from 'components/GTable';
import { useGTableContext } from 'components/GTable/contexts';
import EndorserTitlebar from 'components/TitleBar/EndorserTitlebar';
import useModalVisibility from 'hooks/useModalVisibility';
import pluralize from 'pluralize';
import { FC, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useMyEndorsersList } from 'services/api/Endorsers/useAccess';
import { ListMyEndorserAccessItemsResponse } from 'services/api/client/src';
import { useUserInfo } from 'services/api/useSettings';
import errorHandler from 'utils/errorHandler';
import { yearFirstFormat } from 'utils/helpers';
import { UpdatedEndorserDTO } from '../../typings';
import { EditAccessModal } from '../Modal';
import { FilterArrayProp, NetworkTableProps } from './typings';

type ColumnsType = {
  networkProducerSearchFilter?: ProColumns;
  networkBapSearchFilter?: ProColumns;
  onRowActionClick: (actionPayload?: UpdatedEndorserDTO) => void;
  t: TFunction<'pages', 'endorsers_partners'>;
  speciesListFilter: Array<FilterArrayProp>;
  countriesListFilter: Array<FilterArrayProp>;
  loggedInUserData: any;
  isModalLoading?: boolean;
};

const columns = ({
  networkProducerSearchFilter,
  networkBapSearchFilter,
  onRowActionClick,
  t,
  speciesListFilter,
  countriesListFilter,
  isModalLoading,
}: ColumnsType): Array<ProColumns<UpdatedEndorserDTO>> => [
  {
    title: t('endorsers_partners_table.producer'),
    dataIndex: 'producer',
    ellipsis: true,
    ...networkProducerSearchFilter,
    render: (text, record) => (
      <Link className="text-link" to={`../discover/${String(record.id)}`} title={`View ${text}`}>
        {text}
      </Link>
    ),
    width: 270,
  },
  {
    title: t('endorsers_partners_table.access'),
    dataIndex: 'access',
    render: (text, records) =>
      records?.id &&
      records?.accessFields?.[0]?.accessItems?.[0]?.auditReports &&
      records?.access !== 0 ? (
        <Tag
          style={{
            borderRadius: '15px',
            fontWeight: '500',
            color: '#9D9D9D',
            border: '1.3px solid #9D9D9D',
          }}
        >
          Audit Information
        </Tag>
      ) : !records?.id && records?.access !== 0 ? (
        '(View / Audit Information)'
      ) : null,
  },
  {
    title: t('endorsers_partners_table.species'),
    dataIndex: 'species',
    filters: speciesListFilter,
    width: 250,
    render: (text, records) =>
      records?.bap && records?.id ? (
        <Tooltip
          placement="bottom"
          title={
            records?.species?.length === 0 ? (
              <div>No Species.</div>
            ) : (
              <div>
                {records?.species?.map((item) => (
                  <div>{item?.category}</div>
                ))}
              </div>
            )
          }
        >
          <Tag
            style={{
              borderRadius: '15px',
              fontWeight: '500',
              color: '#0A5F7A',
              border: '1.3px solid #0A5F7A',
              backgroundColor: '#E7F0F2',
            }}
          >
            {records?.species?.length} Species
          </Tag>
        </Tooltip>
      ) : null,
  },
  {
    title: t('endorsers_partners_table.producer_type'),
    dataIndex: 'producerType',
    ellipsis: true,
    filters: [
      {
        text: 'Farm',
        value: 'Farm',
      },
      {
        text: 'Hatchery',
        value: 'Hatchery',
      },
      {
        text: 'Feed Mill',
        value: 'Feed Mill',
      },
      {
        text: 'Supplier',
        value: 'Supplier (manf, importer)',
      },
      {
        text: 'Retail',
        value: 'Retail (supermarket, FDM, e-commerce, specialty)',
      },
      {
        text: 'Corporation',
        value: 'Corporation',
      },
      {
        text: 'Foodservice',
        value: 'Foodservice (dist, merch, rest, hotel, entertnmt, health, airline)',
      },
      {
        text: 'Processor',
        value: 'Processor',
      },
      {
        text: 'Re-processor',
        value: 'Re-processor',
      },
    ],
  },
  {
    title: t('endorsers_partners_table.country'),
    dataIndex: 'country',
    ellipsis: true,
    filters: countriesListFilter,
  },
  {
    title: t('endorsers_partners_table.bap_number'),
    dataIndex: 'bap',
    ellipsis: true,
    ...networkBapSearchFilter,
  },
  {
    title: t('endorsers_partners_table.expiration'),
    dataIndex: 'expiration',
    ellipsis: true,
    render: (text, record) => (record?.id ? yearFirstFormat(String(record?.expiration)) : ' '),
    sorter: true,
  },
  {
    title: t('endorsers_partners_table.esa'),
    dataIndex: 'esa',
    ellipsis: true,
    filters: [
      {
        text: 'Yes',
        value: true,
      },
      {
        text: 'No',
        value: false,
      },
    ],
  },
  {
    title: t('endorsers_partners_table.annoucne_type'),
    dataIndex: 'announceType',
    ellipsis: true,
    filters: [
      {
        text: 'Announced',
        value: 'Announced',
      },
      {
        text: 'Unannounced',
        value: 'Unannounced',
      },
    ],
  },
  // {
  //   dataIndex: 'actions',
  //   valueType: 'option',
  //   hideInSetting: true,
  //   render: (text, record) =>
  //     record?.id && record?.access !== 0 ? (
  //       <Button
  //         size="small"
  //         type="primary"
  //         ghost
  //         className="actions"
  //         loading={isModalLoading}
  //         onClick={() => onRowActionClick(record)}
  //         disabled
  //       >
  //         Manage Access
  //       </Button>
  //     ) : null,
  //   fixed: 'right',
  //   width: 160,
  // },
];

const TableTitle = () => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.network',
  });
  const { selectedRowKeys } = useGTableContext();

  return (
    <EndorserTitlebar
      title={t('table.title')}
      extra={
        selectedRowKeys.length > 0 && (
          <Typography.Text className="selectiontext">
            {`${selectedRowKeys.length} ${pluralize('Producer', selectedRowKeys.length)} Selected`}
          </Typography.Text>
        )
      }
    />
  );
};

const Network: FC<NetworkTableProps> = ({
  networkList,
  iseNetworkListLoading,
  networkListParams,
  networkTotalItems,
  onNetworkListChange,
  networkProducerSearchFilter,
  networkBapSearchFilter,
  speciesListResponse,
  countriesListResponse,
}) => {
  const { data: loggedInUserData } = useUserInfo();
  const queryClient = useQueryClient();
  const myEndorsersList = useMyEndorsersList(queryClient);
  const manageAccessModal = useModalVisibility(false);
  const [selectedRowData, setSelectedRowData] = useState<UpdatedEndorserDTO>();
  const [modalData, setModalData] = useState<ListMyEndorserAccessItemsResponse>();
  const [isModalLoading, setIsModalLoading] = useState(false);
  const { t } = useTranslation('pages', { keyPrefix: 'endorsers_partners' });

  // species list response
  const speciesListFilter: Array<FilterArrayProp> = useMemo(
    () =>
      speciesListResponse?.species?.map((item: string) => ({
        text: item || '',
        value: item || '',
      })) || [],
    [speciesListResponse],
  );

  // countries list response
  const countriesListFilter: Array<FilterArrayProp> = useMemo(
    () =>
      countriesListResponse?.countries?.map((item: string) => ({
        text: item || '',
        value: item || '',
      })) || [],
    [countriesListResponse],
  );

  const ecosystem: Array<UpdatedEndorserDTO> = useMemo(
    () =>
      networkList?.map((item) => ({
        ...item,
      })) || [],
    [networkList],
  );

  const onRowActionClick = async (actionPayload?: UpdatedEndorserDTO) => {
    setSelectedRowData(actionPayload);
    setIsModalLoading(true);
    try {
      const myAccessListResponse = await myEndorsersList.mutateAsync({});
      setModalData(myAccessListResponse);
      setIsModalLoading(false);
      manageAccessModal.show();
    } catch (error) {
      message.error(errorHandler(error));
      setIsModalLoading(false);
    }
  };

  return (
    <>
      <EditAccessModal
        modal={manageAccessModal}
        selectedRowData={selectedRowData}
        setSelectedRowData={setSelectedRowData}
        myAccessListResponse={modalData}
        isModalLoading={isModalLoading}
      />
      <GTable<UpdatedEndorserDTO>
        columns={columns({
          networkProducerSearchFilter,
          networkBapSearchFilter,
          onRowActionClick,
          t,
          speciesListFilter,
          countriesListFilter,
          loggedInUserData,
          isModalLoading,
        })}
        value={ecosystem}
        headerTitle={<TableTitle />}
        options={{
          setting: false,
          reload: false,
        }}
        loading={iseNetworkListLoading}
        onTableChange={onNetworkListChange}
        pagination={{
          defaultPageSize: networkListParams?.pageSize,
          total: networkTotalItems,
          current: networkListParams?.pageNumber,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Producers`,
        }}
        scroll={{ x: 1820, y: 700 }}
      />
    </>
  );
};

export default Network;
