import { Layout } from 'antd';

const { Footer } = Layout;

const Foot = () => (
  <Footer
    style={{
      textAlign: 'center',
      padding: '15px',
      backgroundColor: '#F4F4F4',
      color: '#8C8C8C',
    }}
  >
    Copyright © 2023 Global Seafood Alliance, All rights reserved. Privacy
  </Footer>
);

export default Foot;
