import { Col, DatePicker, Row, Space } from 'antd';
import {
  CompanySelect,
  CountrySelect,
  LocationSelect,
  LotSelect,
  MainSelect,
  ProductIDSelect,
  SSCCSelect,
  SpeciesSelect,
  StateSelect,
} from 'components/GSelect';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SwitchTabButtons from './SwitchTabButtons';
import styles from './index.module.less';
import { TableTitileFilterProps } from './typings';

const { RangePicker } = DatePicker;

const TableFilterRow: React.FC<TableTitileFilterProps> = ({
  expandedFilters,
  mainSelected,
  setMainSelected,
  search,
  traceTab,
  setTraceTab,
  setSearch,
  selectedLot,
  setSelectedLot,
  // api data
  isAllEventsListLoading,
}) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'trace.trace_header',
  });

  const onChangeDate: any = (date: any, dateString: any) => {
    setSearch({
      ...search,
      startDate:
        dateString[0] !== '' ? (moment(dateString[0]).format('yyyy-MM-DD') as any) : undefined,
      endDate:
        dateString[1] !== ''
          ? (moment(dateString[1], 'yyyy-MM-DD').add(1, 'days').format('yyyy-MM-DD') as any)
          : undefined,
    });
  };

  return (
    <Space direction="vertical" size={16} style={{ display: 'flex', marginBottom: '16px' }}>
      <div className={styles.maincontainer}>
        <div>
          <Space>
            <MainSelect
              defaultValue="Lot"
              style={{ width: '200px' }}
              placeholder={t('main_select_placeholder')}
              setMainSelected={setMainSelected}
              setSearch={setSearch}
              setTraceTab={setTraceTab}
            />
            {mainSelected === 'Lot' ? (
              <LotSelect
                search={search}
                setSearch={setSearch}
                selectedLot={selectedLot}
                setSelectedLot={setSelectedLot}
                style={{ width: '1000px' }}
              />
            ) : null}
            {mainSelected === 'Company' ? (
              <CompanySelect search={search} setSearch={setSearch} style={{ width: '1000px' }} />
            ) : null}
            {mainSelected === 'Location' ? (
              <LocationSelect search={search} setSearch={setSearch} style={{ width: '1000px' }} />
            ) : null}
            {mainSelected === 'Product Identifier' ? (
              <ProductIDSelect search={search} setSearch={setSearch} style={{ width: '1000px' }} />
            ) : null}
            {mainSelected === 'SSCC' ? (
              <SSCCSelect search={search} setSearch={setSearch} style={{ width: '1000px' }} />
            ) : null}
            {mainSelected === 'State' ? (
              <StateSelect search={search} setSearch={setSearch} style={{ width: '1000px' }} />
            ) : null}
            {mainSelected === 'Country' ? (
              <CountrySelect search={search} setSearch={setSearch} style={{ width: '1000px' }} />
            ) : null}
            {mainSelected === 'Species' ? (
              <SpeciesSelect search={search} setSearch={setSearch} style={{ width: '1000px' }} />
            ) : null}
          </Space>
        </div>
        <div>
          <Space>
            <SwitchTabButtons
              search={search}
              traceTab={traceTab}
              setTraceTab={setTraceTab}
              isAllEventsListLoading={isAllEventsListLoading}
            />
          </Space>
        </div>
      </div>

      {expandedFilters ? (
        <Row gutter={[8, 8]}>
          {mainSelected !== 'Lot' ? (
            <Col xl={3} lg={4} md={6} sm={8} xs={12}>
              <LotSelect
                search={search}
                setSearch={setSearch}
                selectedLot={selectedLot}
                setSelectedLot={setSelectedLot}
              />
            </Col>
          ) : null}
          {mainSelected !== 'Company' ? (
            <Col xl={3} lg={4} md={6} sm={8} xs={12}>
              <CompanySelect search={search} setSearch={setSearch} />
            </Col>
          ) : null}
          {mainSelected !== 'Location' ? (
            <Col xl={3} lg={4} md={6} sm={8} xs={12}>
              <LocationSelect search={search} setSearch={setSearch} />
            </Col>
          ) : null}
          {mainSelected !== 'Product Identifier' ? (
            <Col xl={3} lg={4} md={6} sm={8} xs={12}>
              <ProductIDSelect search={search} setSearch={setSearch} />
            </Col>
          ) : null}
          {mainSelected !== 'SSCC' ? (
            <Col xl={3} lg={4} md={6} sm={8} xs={12}>
              <SSCCSelect search={search} setSearch={setSearch} />
            </Col>
          ) : null}
          {mainSelected !== 'State' ? (
            <Col xl={3} lg={4} md={6} sm={8} xs={12}>
              <StateSelect search={search} setSearch={setSearch} />
            </Col>
          ) : null}
          {mainSelected !== 'Country' ? (
            <Col xl={3} lg={4} md={6} sm={8} xs={12}>
              <CountrySelect search={search} setSearch={setSearch} />
            </Col>
          ) : null}
          {mainSelected !== 'Species' ? (
            <Col xl={3} lg={4} md={6} sm={8} xs={12}>
              <SpeciesSelect search={search} setSearch={setSearch} />
            </Col>
          ) : null}
          <Col xl={3} lg={4} md={6} sm={8} xs={12}>
            <RangePicker key="dateselect" onChange={onChangeDate} allowClear />
          </Col>
        </Row>
      ) : null}
    </Space>
  );
};

export default TableFilterRow;
