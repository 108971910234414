import { useState, useEffect } from 'react';
import moment from 'moment';
import { useTableSearchFilter } from 'hooks';
import { DiagramOptionalParams } from 'services/api/client/src';
import { useDiagram } from 'services/api/useTrace';
import useTraceStore from './useTraceStore';

const useDiagramFilters = (initParams?: DiagramOptionalParams) => {
  const [eventDiagramParams, setEventDiagramParams] = useState<DiagramOptionalParams>({
    ...initParams,
  });
  const { data: eventDiagramNodes, isLoading: isEventDiagramLoading } =
    useDiagram(eventDiagramParams);

  const search = useTraceStore((state) => state.search);

  useEffect(() => {
    const lotId =
      search.lot?.length === 1
        ? {
            lotId:
              search?.lot?.[0] ||
              'urn:gdst:wholechain.com:product:lot:class:ashrimpsupplier.feed.awesoi',
          }
        : {};
    const company = search.company?.length !== 0 ? { company: search.company } : {};
    const locationName = search.location?.length !== 0 ? { locationName: search.location } : {};
    const state = search.state?.length !== 0 ? { state: search.state } : {};
    const country = search.country?.length !== 0 ? { country: search.country } : {};
    const species = search.species?.length !== 0 ? { species: search.species } : {};
    const productIdentifier =
      search.productIdentifier?.length !== 0 ? { productIdentifier: search.productIdentifier } : {};
    setEventDiagramParams({
      ...lotId,
      ...company,
      ...locationName,
      ...state,
      ...country,
      ...species,
      ...productIdentifier,
      startDate:
        search.startDate !== undefined
          ? (moment(search.startDate).format('yyyy-MM-DD') as any)
          : undefined,
      endDate:
        search.endDate !== undefined
          ? (moment(search.endDate).format('yyyy-MM-DD') as any)
          : undefined,
    });
  }, [search]);

  const { filters: facilityNameSearchFilter } = useTableSearchFilter({
    title: 'Facility Name',
  });

  const { filters: bapNumberSearchFilter } = useTableSearchFilter({
    title: 'BAP Number',
  });

  return {
    eventDiagramNodes,
    isEventDiagramLoading,
    eventDiagramParams,
    setEventDiagramParams,
    filters: [facilityNameSearchFilter, bapNumberSearchFilter],
  };
};

export default useDiagramFilters;
