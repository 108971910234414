import { useQuery } from 'react-query';
import {
  // events list
  ListEventsOptionalParams,
  ListEventsOperationResponse,
  // event maps
  MapOptionalParams,
  MapOperationResponse,
  // event diagram
  DiagramOptionalParams,
  DiagramOperationResponse,
  // lots list params
  ListLotsOptionalParams,
  ListLotsOperationResponse,
  // companies list params
  ListCompaniesOptionalParams,
  ListCompaniesOperationResponse,
  // locations list params
  ListLocationsOptionalParams,
  ListLocationsOperationResponse,
  // container id params
  ListContainerIdsOptionalParams,
  ListContainerIdsResponse,
  // product id params
  ListProductIdsOptionalParams,
  ListProductIdsResponse,
  // list states params
  ListStatesOptionalParams,
  ListStatesOperationResponse,
  // species list params
  ListSpeciesOptionalParams,
  ListSpeciesOperationResponse,
  // countries list params
  ListCountriesOptionalParams,
  ListCountriesOperationResponse,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

const getAllEventsList = async (
  params?: ListEventsOptionalParams,
): Promise<ListEventsOperationResponse> => HttpClient.listEvents(params);

/* Get Events List query */
export const useAllEventsList = (params?: ListEventsOptionalParams) =>
  useQuery(['AllEventsList', params], () => getAllEventsList(params));

const getMapLocation = async (params?: MapOptionalParams): Promise<MapOperationResponse> =>
  HttpClient.map(params);

/* Get Events Map query */
export const useMapLocation = (params?: ListEventsOptionalParams) =>
  useQuery(['MapLocationList', params], () => getMapLocation(params));

const getDiagramNodes = async (params?: DiagramOptionalParams): Promise<DiagramOperationResponse> =>
  HttpClient.diagram(params);

/* Get Events Diagram query */
export const useDiagram = (params?: ListEventsOptionalParams) =>
  useQuery(['DiagramNodes', params], () => getDiagramNodes(params));

/* get lots list query */
const lotsList = async (params?: ListLotsOptionalParams): Promise<ListLotsOperationResponse> =>
  HttpClient.listLots(params);

export const useLotsList = (params?: ListLotsOptionalParams) =>
  useQuery(['lotsList', params], () => lotsList(params));

/* get companies list query */
const companiesList = async (
  params?: ListCompaniesOptionalParams,
): Promise<ListCompaniesOperationResponse> => HttpClient.listCompanies(params);

export const useCompaniesList = (params?: ListCompaniesOptionalParams) =>
  useQuery(['companiesList', params], () => companiesList(params));

/* get locations list query */
const locationsList = async (
  params?: ListLocationsOptionalParams,
): Promise<ListLocationsOperationResponse> => HttpClient.listLocations(params);

export const useLocationsList = (params?: ListLocationsOptionalParams) =>
  useQuery(['locationsList', params], () => locationsList(params));

/* get container Ids list query */
const containerIdsList = async (
  params?: ListContainerIdsOptionalParams,
): Promise<ListContainerIdsResponse> => HttpClient.listContainerIds(params);

export const useContainerIdsList = (params?: ListContainerIdsOptionalParams) =>
  useQuery(['containerIdsList', params], () => containerIdsList(params));

/* get Product Ids list query */
const productIdsList = async (
  params?: ListProductIdsOptionalParams,
): Promise<ListProductIdsResponse> => HttpClient.listProductIds(params);

export const useProductIds = (params?: ListContainerIdsOptionalParams) =>
  useQuery(['productIdsList', params], () => productIdsList(params));

/* get states list query */
const statesList = async (
  params?: ListStatesOptionalParams,
): Promise<ListStatesOperationResponse> => HttpClient.listStates(params);

export const useStatesList = (params?: ListStatesOptionalParams) =>
  useQuery(['statesList', params], () => statesList(params));

/* get species list query */
const speciesList = async (
  params?: ListSpeciesOptionalParams,
): Promise<ListSpeciesOperationResponse> => HttpClient.listSpecies(params);

export const useSpeciesList = (params?: ListSpeciesOptionalParams) =>
  useQuery(['SpeciesList', params], () => speciesList(params));

/* get countries list query */
const countriesList = async (
  params?: ListCountriesOptionalParams,
): Promise<ListCountriesOperationResponse> => HttpClient.listCountries(params);

export const useCountriesList = (params?: ListCountriesOptionalParams) =>
  useQuery(['CountriesList', params], () => countriesList(params));
