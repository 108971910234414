import { QueryClient, useMutation, useQuery, useInfiniteQuery } from 'react-query';
import {
  // notifications params
  ListNotificationsOptionalParams,
  ListNotificationsOperationResponse,
  // clear notifications params
  ClearNotificationsOperationResponse,
  ClearNotificationsRequest,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

// Original API

const getNotificationsList = async (
  params?: ListNotificationsOptionalParams,
): Promise<ListNotificationsOperationResponse> => HttpClient.listNotifications(params);

/* get notifications list query */
export const useNotificationsList = (params?: ListNotificationsOptionalParams) =>
  useQuery(['notificationslist', params], () => getNotificationsList(params));

// clear notifications query
const clearNotifications = (
  params: ClearNotificationsRequest,
): Promise<ClearNotificationsOperationResponse> => HttpClient.clearNotifications({ body: params });

export const useClearNotifications = (queryClient: QueryClient) =>
  useMutation(({ params }: { params: ClearNotificationsRequest }) => clearNotifications(params), {
    onSuccess: () => {
      queryClient.refetchQueries('notificationslist');
    },
  });

const getNotificationsByPage = async (
  pageNumber: number,
  params?: ListNotificationsOptionalParams,
) => {
  const res = await HttpClient.listNotifications({ ...params, pageNumber });
  return {
    ...res,
  };
};

/* use infinite query for activity */
export const useInfiniteNotifications = (params?: ListNotificationsOptionalParams) =>
  useInfiniteQuery(
    ['notificationslist', params],
    ({ pageParam = 1 }) => getNotificationsByPage(pageParam, params),
    {
      getNextPageParam: (lastPage) => {
        // if there is no next page, return undefined
        if ((lastPage?.pageNumber || 0) < (lastPage?.totalPages || 0)) {
          const nextPage = (lastPage?.pageNumber || 1) + 1;
          return nextPage;
        }
        return undefined;
      },
    },
  );
