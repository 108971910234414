/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Typography, message, Spin } from 'antd';
import { pdfDownloadCreator } from 'utils';
import errorHandler from 'utils/errorHandler';
import { useDownloadReports, useDownloadFirstPageReport } from 'services/api/useEndorsers';
import { DownloadModalProps } from './typings';
import useAuditReportStore from '../hooks';
import ModalContent from './ModalContent';

import './auditReport.less';

const { Title } = Typography;

const DownloadModal: FC<DownloadModalProps> = ({ onCloseModal }) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.audit_report_download_modal',
  });
  const { reportId, bapNumber } = useParams();
  const queryClient = useQueryClient();
  const downloadReport = useDownloadReports(queryClient);
  const downloadFirstPageReport = useDownloadFirstPageReport(queryClient);
  const [loading, setLoading] = useState(false);
  const modalVisible = useAuditReportStore((state) => state.modalVisible);
  const setModalVisible = useAuditReportStore((state) => state.setModalVisible);
  const auditReportParams = useAuditReportStore((state) => state.auditReportParams);
  const checkedBox = useAuditReportStore((state) => state.checkedBox);
  const auditReportId = useAuditReportStore((state) => state.auditReportId);
  const auditReportBAP = useAuditReportStore((state) => state.auditReportBAP);

  const onSuccess = async () => {
    setLoading(true);
    if (checkedBox.length === 0) {
      message.error(t('no_field_selected_error_message'));
      setLoading(false);
    }
    if (
      checkedBox.includes('summary') &&
      checkedBox.includes('auditReport') &&
      checkedBox.includes('firstPageReport')
    ) {
      try {
        // download complete audit report
        const pdfResponse = await downloadReport.mutateAsync({
          reportId: reportId || auditReportId,
          bapNumber: bapNumber || auditReportBAP,
          params: {
            summary: true,
            fullReport: true,
            ...auditReportParams,
          },
        });
        const blobBody = await pdfResponse?.blobBody;
        pdfDownloadCreator(blobBody, pdfResponse);

        // download firstPage report
        const firstPageReportResponse = await downloadFirstPageReport.mutateAsync({
          reportId: reportId || auditReportId,
          bapNumber: bapNumber || auditReportBAP,
        });
        const firstPageBlobBody = await firstPageReportResponse?.blobBody;
        pdfDownloadCreator(firstPageBlobBody, firstPageReportResponse);

        setModalVisible(false);
        setLoading(false);
      } catch (error: any) {
        message.error(errorHandler(error));
        setLoading(false);
      }
    } else if (checkedBox.includes('summary') && checkedBox.includes('auditReport')) {
      try {
        const pdfResponse = await downloadReport.mutateAsync({
          reportId: reportId || auditReportId,
          bapNumber: bapNumber || auditReportBAP,
          params: {
            summary: true,
            fullReport: true,
            ...auditReportParams,
          },
        });
        const blobBody = await pdfResponse?.blobBody;
        pdfDownloadCreator(blobBody, pdfResponse);

        setModalVisible(false);
        setLoading(false);
      } catch (error: any) {
        message.error(errorHandler(error));
        setLoading(false);
      }
    } else if (checkedBox.includes('summary') && checkedBox.includes('firstPageReport')) {
      try {
        // download summary only
        const pdfResponse = await downloadReport.mutateAsync({
          reportId: reportId || auditReportId,
          bapNumber: bapNumber || auditReportBAP,
          params: {
            summary: true,
          },
        });
        const blobBody = await pdfResponse?.blobBody;
        pdfDownloadCreator(blobBody, pdfResponse);

        // download firstPage report
        const firstPageReportResponse = await downloadFirstPageReport.mutateAsync({
          reportId: reportId || auditReportId,
          bapNumber: bapNumber || auditReportBAP,
        });
        const firstPageBlobBody = await firstPageReportResponse?.blobBody;
        pdfDownloadCreator(firstPageBlobBody, firstPageReportResponse);

        setModalVisible(false);
        setLoading(false);
      } catch (error: any) {
        message.error(errorHandler(error));
        setLoading(false);
      }
    } else if (checkedBox.includes('auditReport') && checkedBox.includes('firstPageReport')) {
      try {
        // download audit report only
        const pdfResponse = await downloadReport.mutateAsync({
          reportId: reportId || auditReportId,
          bapNumber: bapNumber || auditReportBAP,
          params: {
            fullReport: true,
            ...auditReportParams,
          },
        });
        const blobBody = await pdfResponse?.blobBody;
        pdfDownloadCreator(blobBody, pdfResponse);

        // download firstPage report
        const firstPageReportResponse = await downloadFirstPageReport.mutateAsync({
          reportId: reportId || auditReportId,
          bapNumber: bapNumber || auditReportBAP,
        });
        const firstPageBlobBody = await firstPageReportResponse?.blobBody;
        pdfDownloadCreator(firstPageBlobBody, firstPageReportResponse);

        setModalVisible(false);
        setLoading(false);
      } catch (error: any) {
        message.error(errorHandler(error));
        setLoading(false);
      }
    } else if (checkedBox.includes('summary')) {
      try {
        // download summary only
        const pdfResponse = await downloadReport.mutateAsync({
          reportId: reportId || auditReportId,
          bapNumber: bapNumber || auditReportBAP,
          params: {
            summary: true,
          },
        });
        const blobBody = await pdfResponse?.blobBody;
        pdfDownloadCreator(blobBody, pdfResponse);

        setModalVisible(false);
        setLoading(false);
      } catch (error: any) {
        message.error(errorHandler(error));
        setLoading(false);
      }
    } else if (checkedBox.includes('auditReport')) {
      try {
        // download audit report only
        const pdfResponse = await downloadReport.mutateAsync({
          reportId: reportId || auditReportId,
          bapNumber: bapNumber || auditReportBAP,
          params: {
            fullReport: true,
            ...auditReportParams,
          },
        });
        const blobBody = await pdfResponse?.blobBody;
        pdfDownloadCreator(blobBody, pdfResponse);

        setModalVisible(false);
        setLoading(false);
      } catch (error: any) {
        message.error(errorHandler(error));
        setLoading(false);
      }
    } else if (checkedBox.includes('firstPageReport')) {
      try {
        // download firstPage report
        const firstPageReportResponse = await downloadFirstPageReport.mutateAsync({
          reportId: reportId || auditReportId,
          bapNumber: bapNumber || auditReportBAP,
        });
        const firstPageBlobBody = await firstPageReportResponse?.blobBody;
        pdfDownloadCreator(firstPageBlobBody, firstPageReportResponse);

        setModalVisible(false);
        setLoading(false);
      } catch (error: any) {
        message.error(errorHandler(error));
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <div style={{ position: 'fixed', left: '50%', top: '50%', zIndex: '1200' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Modal
      visible={modalVisible}
      title={<Title level={5}>{t('title')}</Title>}
      closable
      onCancel={onCloseModal}
      footer={
        <Button
          onClick={onSuccess}
          type="primary"
          shape="round"
          style={{
            color: '#0a5f7a',
            borderColor: '#bcd530',
            background: '#bcd530',
          }}
        >
          {t('download_button')}
        </Button>
      }
      width="28%"
      bodyStyle={{ height: '60%', borderRadius: '0px' }}
      centered
    >
      <ModalContent />
    </Modal>
  );
};

export default DownloadModal;
