import { useMutation, useQuery } from 'react-query';
import {
  // audit reports list
  ListAuditReportsOperationResponse,
  ListAuditReportsOptionalParams,
  // audit report by id
  GetAuditReportByIdOperationResponse,
  GetAuditReportByIdOptionalParams,
  // audit Report table
  GetAssessmentAnswersOperationResponse,
  GetAssessmentAnswersOptionalParams,
  // help desk mail params
  NotifyHelpDeskResponse,
  NotifyHelpDeskRequest,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

// Original API

const listAuditReports = async (
  params?: ListAuditReportsOptionalParams,
): Promise<ListAuditReportsOperationResponse> => HttpClient.listAuditReports(params);

const getAuditReportById = async (
  reportId: string,
  bapNumber: string,
  options?: GetAuditReportByIdOptionalParams,
): Promise<GetAuditReportByIdOperationResponse> =>
  HttpClient.getAuditReportById(bapNumber, reportId, options);

const getAuditReportTableById = async (
  reportId: string,
  bapNumber: string,
  options?: GetAssessmentAnswersOptionalParams,
): Promise<GetAssessmentAnswersOperationResponse> =>
  HttpClient.getAssessmentAnswers(reportId, bapNumber, options);

/* get audit reports query */
export const useAPIAuditReports = (params?: ListAuditReportsOptionalParams) =>
  useQuery(['AuditReportsAPI', params], () => {
    if (params?.pageSize && params?.bapNumber) {
      return listAuditReports(params);
    }
    return undefined;
  });

/* get Audit report api for specific report */
export const useAuditReportById = (
  reportId: string,
  bapNumber: string,
  options?: GetAuditReportByIdOptionalParams,
) =>
  useQuery(['AuditReportAPI', reportId, bapNumber, options], () =>
    getAuditReportById(reportId, bapNumber, options),
  );

/* get audit report table by id */
export const useAuditReportTableById = (
  reportId: string,
  bapNumber: string,
  options?: GetAuditReportByIdOptionalParams,
) =>
  useQuery(['AuditReportTableAPI', reportId, bapNumber, options], () =>
    getAuditReportTableById(reportId, bapNumber, options),
  );

// help post query
const notifyHelpdesk = async (params?: NotifyHelpDeskRequest): Promise<NotifyHelpDeskResponse> =>
  HttpClient.notifyHelpDesk({ body: params });

export const useNotifyHelpdesk = () =>
  useMutation(({ params }: { params: NotifyHelpDeskRequest }) => notifyHelpdesk(params));
