import { MenuDataItem } from '@ant-design/pro-layout';

export const menuData: Array<MenuDataItem> = [
  {
    path: '/',
    name: 'Dashboard',
    exact: true,
    key: 'staff_dashboard',
  },
  {
    path: '/endorsers_partners',
    name: 'Endorsers & Partners',
    key: 'staff_endorsers',
  },
  {
    path: '/trace',
    name: 'Trace',
    key: 'staff_trace',
  },
  {
    path: '/audits',
    name: 'Audits',
    key: 'staff_audits',
  },
  {
    path: '/reports',
    name: 'Reports',
    key: 'staff_reports',
  },
];

export const restrictedMenuData: Array<MenuDataItem> = [
  {
    path: '/',
    name: 'Dashboard',
    exact: true,
    key: 'staff_dashboard',
  },
  {
    path: '/endorsers_partners',
    name: 'Endorsers & Partners',
    key: 'staff_endorsers',
  },
  {
    path: '/trace',
    name: 'Trace',
    key: 'staff_trace',
  },
  {
    path: '/audits',
    name: 'Audits',
    key: 'staff_audits',
  },
];
