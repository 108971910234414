import { Avatar, Menu, Typography } from 'antd';
import { useAuthenticationContext } from 'contexts';
import type { MenuClickEventHandler } from 'rc-menu/lib/interface';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from 'services/api/useSettings';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.module.less';
import { AvatarDropdownProps } from './typings';

const AvatarDropdown: React.FC<AvatarDropdownProps> = () => {
  const { currentUser, signout, isAuthenticated } = useAuthenticationContext();
  const navigate = useNavigate();
  const { data: loggedInUserData } = useUserInfo();

  const onMenuClick: MenuClickEventHandler = React.useCallback(
    ({ key }) => {
      switch (key) {
        case 'signout':
          signout();
          break;
        default:
          break;
      }
    },
    [signout],
  );

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      {isAuthenticated && (
        <Menu.Item key="profile" className={styles.profile}>
          <span>{currentUser?.mail}</span>
        </Menu.Item>
      )}
      <Menu.Item
        key="setting"
        className={styles.profile}
        onClick={() => {
          navigate('settings');
        }}
      >
        Settings
      </Menu.Item>
      <Menu.Item key="signout" className={styles.signout}>
        Sign Out
      </Menu.Item>
    </Menu>
  );

  const initial = useMemo(
    () => loggedInUserData?.user?.name?.charAt(0).toUpperCase(),
    [loggedInUserData?.user?.name],
  );

  return (
    <HeaderDropdown overlay={menuHeaderDropdown} placement="bottomRight">
      <span className={`${styles.action} ${styles.account}`}>
        <Avatar
          size="small"
          className={styles.avatar}
          alt="avatar"
          src={`${loggedInUserData?.user?.profilePictureUrl}?q=${performance.now()}`}
        >
          {initial}
        </Avatar>
        <Typography.Text style={{ margin: 'auto', color: '#ffffff' }}>
          {loggedInUserData?.user?.name}
        </Typography.Text>
      </span>
    </HeaderDropdown>
  );
};
export default React.memo(AvatarDropdown);
