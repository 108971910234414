import type { SelectProps } from 'antd';
import { Select, Spin } from 'antd';
import classNames from 'classnames';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useEffect, useRef } from 'react';
import styles from './index.module.less';
import { GSelectProps } from './typings';

const GSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  allowSearch = true,
  allowFilterOption,
  className,
  fetchData,
  loading,
  onChange,
  allowClearIcon = true,
  ...props
}: GSelectProps<ValueType> & SelectProps<ValueType, OptionType>) => {
  const valueRef = useRef(props.value);

  useEffect(() => {
    fetchData({ initialValue: valueRef.current });
  }, [fetchData]);

  const handleOnChange = (value: ValueType, option: OptionType | OptionType[]) => {
    if (!value) {
      fetchData({});
    }

    if (onChange) {
      onChange(value, option);
    }
  };

  return (
    <Select<ValueType, OptionType>
      {...props}
      allowClear={allowClearIcon}
      className={classNames(styles.container, className)}
      filterOption={allowFilterOption}
      notFoundContent={loading ? <Spin size="small" /> : null}
      onChange={handleOnChange}
      onSearch={allowSearch ? (searchValue) => fetchData({ filterText: searchValue }) : undefined}
      showSearch
      optionFilterProp="children"
    />
  );
};

export default GSelect;
