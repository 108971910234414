import { Polyline } from '@react-google-maps/api';
import { orderNodesByEdges } from 'utils/helpers';
import { PolylinesProps } from './typings';

const getPolylines = ({ markers, edges }: PolylinesProps) => {
  const iconsetngs = {
    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
  };
  const options = {
    geodesic: true,
    strokeColor: '#0A5F7A',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#0A5F7A',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    icons: [
      {
        icon: iconsetngs,
        offset: '0',
        repeat: '100px',
      },
    ],
    zIndex: 1,
  };

  return (
    <div>
      {orderNodesByEdges(markers || [], edges || [])?.map((polyline: any) => (
        <Polyline path={polyline} options={options} />
      ))}
    </div>
  );
};

export default getPolylines;
