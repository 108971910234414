import create from 'zustand';
import { SelectTabStore } from './typings';

const useTabStore = create<SelectTabStore>((set) => ({
  tab: 'network',
  setTab: (tab) =>
    set(() => ({
      tab,
    })),
  modalVisible: false,
  setModalVisible: (modalVisible) => set(() => ({ modalVisible })),
  auditSearch: {
    facilityName: '',
    date: undefined,
    bapNumber: '',
    status: '',
  },
  setAuditSearch: (auditSearch) =>
    set(() => ({
      auditSearch,
    })),
  checkedBox: [],
  setCheckedBox: (checkedBox) => set(() => ({ checkedBox })),
  auditReportShow: false,
  toggleAuditReportShow: (auditReportShow) =>
    set(() => ({
      auditReportShow,
    })),
}));

export default useTabStore;
