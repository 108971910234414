import { useQuery, QueryClient, useMutation } from 'react-query';
import {
  // endorsers list
  ListEndorsersOperationResponse,
  ListEndorsersOptionalParams,
  // endorser by id
  GetEndorserByIdOptionalParams,
  GetEndorserByIdOperationResponse,
  // latest report by ID
  GetFirstPageByIdOptionalParams,
  GetFirstPageByIdResponse,
  ExportReportOptionalParams,
  ExportReportResponse,
  // species list
  ListSpeciesOptionalParams,
  ListSpeciesOperationResponse,
  // countries list
  ListCountriesOptionalParams,
  ListCountriesOperationResponse,
  // Network list params
  ListNetworkOptionalParams,
  ListNetworkOperationResponse,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

const endorsersList = async (
  params?: ListEndorsersOptionalParams,
): Promise<ListEndorsersOperationResponse> => HttpClient.listEndorsers(params);

const getEndorserById = async (
  bapNumber: string,
  params?: GetEndorserByIdOptionalParams,
): Promise<GetEndorserByIdOperationResponse> => HttpClient.getEndorserById(bapNumber, params);

// download first page report
const downloadFirstPage = async (
  reportId: string,
  bapNumber: string,
  params?: GetFirstPageByIdOptionalParams,
): Promise<GetFirstPageByIdResponse> => HttpClient.getFirstPageById(bapNumber, reportId, params);

const speciesList = async (
  params?: ListSpeciesOptionalParams,
): Promise<ListSpeciesOperationResponse> => HttpClient.listSpecies(params);

const countriesList = async (
  params?: ListCountriesOptionalParams,
): Promise<ListCountriesOperationResponse> => HttpClient.listCountries(params);

// download reports
const downloadReport = async (
  reportId: string,
  bapNumber: string,
  params?: ExportReportOptionalParams,
): Promise<ExportReportResponse> => HttpClient.exportReport(reportId, bapNumber, params);

// get network list
const networkList = async (
  params?: ListNetworkOptionalParams,
): Promise<ListNetworkOperationResponse> => HttpClient.listNetwork(params);

/* get network list query */
export const useNetworkList = (params?: ListNetworkOptionalParams, endorserSide?: boolean) =>
  useQuery(['networkList', params], () => networkList(params), {
    enabled: endorserSide,
  });

/* get endorsers list query */
export const useEndorsersList = (params?: ListEndorsersOptionalParams) =>
  useQuery(['LocationsList', params], () => endorsersList(params));

/* get endorser by id query */
export const useEndorserById = (bapNumber: string, params?: GetEndorserByIdOptionalParams) =>
  useQuery(['EndorsersList', bapNumber, params], () => getEndorserById(bapNumber, params));

/* get latest audit rpeort by id */
export const useDownloadFirstPageReport = (queryClient: QueryClient) =>
  useMutation(
    ({
      reportId,
      bapNumber,
      params,
    }: {
      reportId: string;
      bapNumber: string;
      params?: GetFirstPageByIdOptionalParams;
    }) => downloadFirstPage(reportId, bapNumber, params),
    {
      onSuccess: () => {
        queryClient.refetchQueries('notificationslist');
        queryClient.refetchQueries('latestAuditReportById');
      },
    },
  );

/* get species list query */
export const useSpeciesList = (params?: ListSpeciesOptionalParams) =>
  useQuery(['SpeciesList', params], () => speciesList(params));

/* get countries list query */
export const useCountriesList = (params?: ListCountriesOptionalParams) =>
  useQuery(['CountriesList', params], () => countriesList(params));

/* download reports */
export const useDownloadReports = (queryClient: QueryClient) =>
  useMutation(
    ({
      reportId,
      bapNumber,
      params,
    }: {
      reportId: string;
      bapNumber: string;
      params?: ExportReportOptionalParams;
    }) => downloadReport(reportId, bapNumber, params),
    {
      onSuccess: () => {
        queryClient.refetchQueries('downloadReport');
        queryClient.refetchQueries('notificationslist');
      },
    },
  );
