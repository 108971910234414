import { useQuery } from 'react-query';
import {
  // dashboard location list
  ListFacilityLocationsOperationResponse,
  ListFacilityLocationsOptionalParams,
  // full search params
  SearchOptionalParams,
  SearchOperationResponse,
  // endorsers reports params
  ListReportsOptionalParams,
  ListReportsOperationResponse,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

const dashboardLocationList = async (
  params?: ListFacilityLocationsOptionalParams,
): Promise<ListFacilityLocationsOperationResponse> => HttpClient.listFacilityLocations(params);

/* get dashboard map locations query */
export const useLocationsList = (params?: ListFacilityLocationsOptionalParams) =>
  useQuery(['LocationsList', params], () => dashboardLocationList(params));

const fullSearchList = async (params?: SearchOptionalParams): Promise<SearchOperationResponse> =>
  HttpClient.search(params);

/* get full search list within app query */
export const useFullSearch = (params?: SearchOptionalParams) =>
  useQuery(['searchList', params], () => {
    if (params?.term !== '') {
      return fullSearchList(params);
    }
    return undefined;
  });

/* get all reports list for endorser */
const getEndorsersReportsList = async (
  params?: ListReportsOptionalParams,
): Promise<ListReportsOperationResponse> => HttpClient.listReports(params);

export const useGetEndorsersReports = (
  params?: ListReportsOptionalParams,
  endorserSide?: boolean,
) =>
  useQuery(['getEndorsersReports', params], () => getEndorsersReportsList(params), {
    enabled: endorserSide,
  });
