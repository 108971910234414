import { SelectProps } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback, useState } from 'react';
import { ListRolesOptionalParams } from 'services/api/client/src';
import { useRolesList } from 'services/api/useSettings';
import GSelect from './GSelect';
import { FetchDataFn } from './typings';

const RoleSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType>,
) => {
  const [params, setParams] = useState<ListRolesOptionalParams>({});
  const { data, isLoading } = useRolesList(params);

  const selectOptions: Array<DefaultOptionType> =
    data?.roles?.map((item) => ({
      label: String(item?.roleName),
      value: String(item?.roleId).concat(` - ${item?.roleName}`),
    })) || [];

  const fetchData: FetchDataFn<ValueType> = useCallback(({ filterText, initialValue }) => {
    if (initialValue) {
      setParams((oldParams) => ({ ...oldParams, url: String(initialValue) }));
    } else {
      setParams((oldParams) => ({ ...oldParams, search: filterText }));
    }
  }, []);

  return (
    <GSelect<ValueType, OptionType>
      placeholder="Please Select Role"
      {...props}
      loading={isLoading}
      options={selectOptions as OptionType[]}
      fetchData={fetchData}
    />
  );
};

export default RoleSelect;
