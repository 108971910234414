/* eslint-disable no-console */
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { RowProps, Space, Typography, message } from 'antd';
import { FC, useCallback, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { CreateRoleRequest } from 'services/api/client/src';
import errorHandler from 'utils/errorHandler';
import { useCreateRole } from 'services/api/useSettings';
import { useTranslation } from 'react-i18next';
import AddRoleFields from './AddRoleFields';
import { DataItem, AddRoleProps } from './typings';

const rowProps: RowProps = { gutter: 16 };

const Title: any = () => (
  <Space>
    <Typography.Title level={4} className="mr0">
      Add Role
    </Typography.Title>
  </Space>
);

const AddRoleModal: FC<AddRoleProps> = ({ modal }) => {
  const queryClient = useQueryClient();
  const createRole = useCreateRole(queryClient);
  const editableFormRef = useRef<ProFormInstance<DataItem>>();
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.invite_users_modal',
  });
  const resetFields = useCallback(() => {
    editableFormRef.current?.resetFields();
  }, []);

  const closeModal = useCallback(() => {
    modal.hide();
    resetFields();
  }, [modal, resetFields]);

  return (
    <BetaSchemaForm<DataItem>
      formRef={editableFormRef}
      layoutType="ModalForm"
      columns={AddRoleFields({ t })}
      grid
      // @ts-ignore
      title={<Title />}
      visible={modal.visible}
      rowProps={rowProps}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: false,
        width: '40%',
        ...modal,
        centered: true,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: 'Add',
        },
        submitButtonProps: {
          shape: 'round',
          style: {
            color: '#0a5f7a',
            background: '#bcd530',
            border: '1px solid #bcd530',
          },
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
        render: (_, defaultDoms) => defaultDoms[1],
      }}
      submitTimeout={2000}
      onFinish={async (value: any) => {
        try {
          const reqData: CreateRoleRequest = {
            roleName: value.roleValue,
            permission: value.permissionValue,
          };
          await createRole.mutateAsync({
            params: reqData,
          });
          message.success('Successfully Added Role');
          closeModal();
        } catch (error: any) {
          if (error?.response?.bodyAsText) {
            const errorJson = JSON.parse(error.response.bodyAsText);
            if (errorJson?.status === 403) {
              message.warning(
                'A role with the same name or permissions already exists. Please select different name or permissions to create a role',
              );
            } else {
              message.error(errorHandler(error));
            }
          } else {
            message.error(errorHandler(error));
          }
        }
      }}
    />
  );
};
export default AddRoleModal;
