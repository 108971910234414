/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import { useMemo, FC, useState } from 'react';
import { PageHeader, Button, Tag, Modal, message } from 'antd';
import GTable from 'components/GTable';
import errorHandler from 'utils/errorHandler';
import { ProColumns } from '@ant-design/pro-table';
import { AssignRolesRequest } from 'services/api/client/src';
import { useInviteUsers, useDeleteUser, useAssignRole } from 'services/api/useSettings';
import useModalVisibility from 'hooks/useModalVisibility';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import EndorserActions from 'components/Actions/EndorserActions';
import InviteModal from './components';
import { useUsersListFilters } from './hooks';
import styles from '../index.module.less';
import { InviteStatusProps, UserPermissionsList } from './typings';

type RemoveUserProps = {
  payload: UserPermissionsList;
};

const productActionItems = (): Array<ItemType> => [
  {
    key: 'resendLink',
    label: 'Resend Invite Link',
  },
];

const coreInvitaionStatus = [
  {
    text: 'Pending',
    value: 'Pending',
  },
  {
    text: 'Accepted',
    value: 'Accepted',
  },
];

const InviteStatus: FC<InviteStatusProps> = ({ status }) => {
  const color = useMemo(() => {
    switch (status) {
      case 'Active':
        return 'success';
      case 'Accepted':
        return 'success';
      case 'Pending':
        return 'warning';
      case 'Invited':
        return 'processing';
      case 'Disabled':
        return 'error';
      default:
        return 'default';
    }
  }, [status]);
  const text = useMemo(() => {
    switch (status) {
      case 'Active':
        return 'Active';
      case 'Accepted':
        return 'Accepted';
      case 'Pending':
        return 'Pending';
      case 'Invited':
        return 'Invited';
      case 'Disabled':
        return 'Disabled';
      default:
        return 'default';
    }
  }, [status]);
  const icon = useMemo(() => {
    switch (status) {
      case 'Active':
        return <CheckCircleOutlined />;
      case 'Accepted':
        return <CheckCircleOutlined />;
      case 'Pending':
        return <ClockCircleOutlined />;
      case 'Invited':
        return <ClockCircleOutlined />;
      case 'Disabled':
        return <CloseCircleOutlined />;
      default:
        return <InfoCircleOutlined />;
    }
  }, [status]);
  return (
    <Tag color={color} key={status} icon={icon}>
      {text}
    </Tag>
  );
};

type ColumnsType = {
  onRowActionClick: (actionItemKey: string, actionPayload?: UserPermissionsList) => void;
  nameSearchFilter: ProColumns;
  emailSearchFilter: ProColumns;
};

const columns = ({
  onRowActionClick,
  nameSearchFilter,
  emailSearchFilter,
}: ColumnsType): Array<ProColumns<UserPermissionsList>> => [
  {
    title: 'Name',
    dataIndex: 'name',
    ellipsis: true,
    fixed: 'left',
    ...nameSearchFilter,
    render: (text, record) => record?.name,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    ellipsis: true,
    ...emailSearchFilter,
    render: (text, record) => record?.email,
  },
  {
    title: 'Invitation',
    dataIndex: 'status',
    ellipsis: true,
    filters: coreInvitaionStatus,
    onFilter: (value: any, record) => record?.status?.indexOf(value) === 0,
    render: (text, record) => <InviteStatus status={record.status} />,
  },
];

const UserPermissions = () => {
  const inviteModal = useModalVisibility(false);
  const queryClient = useQueryClient();
  const deleteRole = useDeleteUser(queryClient);
  const inviteUser = useInviteUsers(queryClient);
  const assignRole = useAssignRole(queryClient);
  const [selectedUser, setSelectedUser] = useState<UserPermissionsList | undefined>();

  const onInvite = async (modalValues: any) => {
    try {
      await inviteUser.mutateAsync({
        emails: modalValues.emails,
        message: modalValues?.Value,
        endorserRole: modalValues?.role,
      });
      message.success('Successfully invited user');
    } catch (error) {
      message.error(errorHandler(error));
    }
  };

  const onRowActionClick = (actionItemKey: string, actionPayload?: UserPermissionsList) => {
    setSelectedUser(actionPayload);
    switch (actionItemKey) {
      case 'resendInvite':
        break;

      default:
        break;
    }
  };

  const {
    usersListResponse,
    isUsersListLoading,
    onUsersListTableChange,
    filters: [nameSearchFilter, emailSearchFilter],
  } = useUsersListFilters();

  const userPermissionsList: Array<UserPermissionsList> =
    usersListResponse?.users?.map((user) => ({
      id: user?.userId,
      name: user?.name,
      email: user?.email,
      role: user?.role?.roleName,
      status: user?.firstLogin ? 'Pending' : 'Accepted',
      roleId: user?.role?.roleId,
    })) || [];

  const onInviteClick = () => {
    inviteModal.show();
  };

  const onAssignRole = async (value: any) => {
    const arr = value.roleValue.split(' - ');
    try {
      const reqData: AssignRolesRequest = {
        roleId: arr[0],
        userId: selectedUser?.id,
        endorserRole: arr[0].toUpperCase(),
      };
      await assignRole.mutateAsync({
        params: reqData,
      });
      message.success(`Successfully changed ${selectedUser?.name}'s role to ${arr[1]}`);
    } catch (error: any) {
      message.error(errorHandler(error));
    }
  };

  const onRemoveUser = ({ payload }: RemoveUserProps) => {
    Modal.confirm({
      title: `Remove ${payload?.name}`,
      icon: <ExclamationCircleOutlined />,
      zIndex: 1800,
      onOk: async () => {
        await deleteRole.mutateAsync({
          userId: payload?.id || '',
        });
        message.success('Successfully deleted user');
      },
      content: (
        <div>
          Removing a user will remove their email and account from the Seafood Data Center. To add
          them back you will need to send another invite. Are you sure you want to remove{' '}
          {payload?.name}?
        </div>
      ),
      cancelText: 'Cancel',
      okText: 'Remove',
      centered: true,
      okButtonProps: {
        type: 'primary',
        shape: 'round',
        size: 'middle',
        style: {
          color: '#0a5f7a',
          borderColor: '#bcd530',
          background: '#bcd530',
          textShadow: '0px 0px',
          boxShadow: '0px 0px',
        },
      },
      cancelButtonProps: {
        shape: 'round',
        size: 'middle',
        style: {
          color: '#0a5f7a',
          borderColor: '#0a5f7a',
          background: 'transparent',
          textShadow: '0px 0px',
          boxShadow: '0px 0px',
        },
      },
    });
  };

  return (
    <div className={styles.table}>
      <InviteModal
        modal={inviteModal}
        userPermissionsList={userPermissionsList}
        onRemoveUser={onRemoveUser}
        onAssignRole={onAssignRole}
        onInvite={onInvite}
        from="Endorser"
      />
      <PageHeader
        title={`Users & Permissions (${userPermissionsList.length})`}
        className={styles['page-header']}
        extra={[
          <Button
            type="primary"
            shape="round"
            style={{
              color: '#0a5f7a',
              borderColor: '#bcd530',
              background: '#bcd530',
              textShadow: '0px 0px',
              boxShadow: '0px 0px',
            }}
            onClick={onInviteClick}
          >
            Invite
          </Button>,
        ]}
      />
      <GTable<UserPermissionsList>
        columns={columns({
          onRowActionClick,
          nameSearchFilter,
          emailSearchFilter,
        })}
        value={userPermissionsList}
        options={{
          setting: false,
          reload: false,
        }}
        loading={isUsersListLoading}
        onTableChange={onUsersListTableChange}
        pagination={{
          defaultPageSize: 20,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Users`,
        }}
        recordCreatorProps={false}
        scroll={{ x: 800, y: '65vh' }}
      />
    </div>
  );
};

export default UserPermissions;
