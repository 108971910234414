import { FC, useState, useMemo } from 'react';
import { ProList } from '@ant-design/pro-components';
import { Button, Card, Typography, Space, Menu, Dropdown, Avatar, PageHeader } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { yearFirstFormat } from 'utils/helpers';
import { useLocationsList } from 'services/api/useDashboard';
import GMap from 'components/GMap';
import { MarkerItemProps } from 'components/GMap/typings';
import useNotificationsFilters from 'layouts/GeneralLayout/components/NotificationDropdown/hooks/useNotificationsFilters';
import styles from './index.module.less';
import { NotificationProps } from './typings';

const locationMenuItem = [
  {
    label: 'Last 30 Days',
    key: '0',
  },
  {
    label: 'Last 45 Days',
    key: '1',
  },
  {
    label: 'Last 60 Days',
    key: '2',
  },
];

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const { data: facilityLocationsList } = useLocationsList({ pageSize: 300 });
  const markers: Array<MarkerItemProps> = useMemo(
    () =>
      facilityLocationsList?.results?.map((item: any, index: number | string) => ({
        id: index,
        position: {
          lat: item?.latitude || 47.116386,
          lng: item?.longitude || -101.299591,
        },
        title: item.facility,
        shortDesc: item.facility,
        longDesc: item.country,
        bapNumber: item.bapNumber,
        accountID: item.accountID,
      })) || [],
    [facilityLocationsList],
  );
  const [selectedLocation, setSelectedLocation] = useState('0');
  const [, setSelectedMarker] = useState<MarkerItemProps>();

  // api data
  const { dashboardRecent, setDashboardRecentParams, isDashboardRecentLoading } =
    useNotificationsFilters();

  const recentActivity: Array<NotificationProps> = useMemo(
    () =>
      dashboardRecent?.results?.map((item) => ({
        id: item.id,
        title: item.title,
        description: item.description?.split(/\r\n/),
        date: yearFirstFormat(item.createdDate || ''),
        read: item.read,
        type: item.type,
        auditId: item.auditId,
        bapNumber: item.bapNumber,
        pictureUrl: item.pictureUrl,
        image:
          'https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg',
      })) || [],
    [dashboardRecent],
  );

  const format = 'YYYY-MM-DD';
  const menu = (
    <Menu
      selectable
      defaultSelectedKeys={['0']}
      onSelect={(e) => {
        setSelectedLocation(e.key);
        if (e.key === '0') {
          setDashboardRecentParams({
            pageNumber: 1,
            pageSize: 500,
            recentActivity: true,
            from: moment().subtract(30, 'days').format(format) as any,
            to: moment().format(format) as any,
          });
        } else if (e.key === '1') {
          setDashboardRecentParams({
            pageNumber: 1,
            pageSize: 500,
            recentActivity: true,
            from: moment().subtract(45, 'days').format(format) as any,
            to: moment().format(format) as any,
          });
        } else if (e.key === '2') {
          setDashboardRecentParams({
            pageNumber: 1,
            pageSize: 500,
            recentActivity: true,
            from: moment().subtract(60, 'days').format(format) as any,
            to: moment().format(format) as any,
          });
        }
      }}
      items={locationMenuItem}
    />
  );

  const renderInfoWindow = (activeMarker: MarkerItemProps) => (
    <Space direction="vertical" style={{ width: '250px' }}>
      <Typography.Text
        strong
        className={styles.infotitle}
        onClick={() => {
          navigate(`endorsers_partners/${activeMarker.accountID}`);
        }}
      >
        {activeMarker?.title || ''}
      </Typography.Text>
      <Typography.Text className={styles.infosubtitle}>
        {activeMarker?.shortDesc || ''}
      </Typography.Text>
      <Typography.Text className={styles.infodesc}>{activeMarker?.longDesc || ''}</Typography.Text>
    </Space>
  );

  const viewAllBtnClicked = () => {
    navigate('endorsers_partners');
  };

  return (
    <div className={styles.dashboardcontainer}>
      <Card
        title={<Typography.Title level={5}>GSA Ecosystem</Typography.Title>}
        extra={
          <Button type="link" onClick={viewAllBtnClicked}>
            View List
          </Button>
        }
        headStyle={{
          backgroundColor: 'white',
          padding: '0px 16px',
          border: '0px',
        }}
        bodyStyle={{
          padding: '0px 21px',
          paddingBottom: '16px',
        }}
        style={{ marginBottom: '16px' }}
      >
        <GMap
          markers={markers}
          renderInfoWindow={renderInfoWindow}
          containerStyle={{ borderRadius: '2px', height: '388px' }}
          showPolylines={false}
          setSelectedMarker={setSelectedMarker}
        />
      </Card>
      <PageHeader
        style={{
          padding: '10px 22px',
          background: 'white',
        }}
        ghost={false}
        title={<Typography.Title level={5}>Recent Activity</Typography.Title>}
        backIcon={false}
        extra={[
          <Dropdown placement="bottomRight" overlay={menu}>
            <Space size={0}>
              <Button type="link">{locationMenuItem[Number(selectedLocation)].label}</Button>
              <DownOutlined style={{ color: '#0a5f7a', cursor: 'pointer', fontSize: '11px' }} />
            </Space>
          </Dropdown>,
        ]}
      />

      <div
        style={{
          padding: '22px',
          background: 'white',
          height: '35vh',
          overflow: 'auto',
        }}
      >
        <ProList<any>
          rowKey="name"
          dataSource={recentActivity}
          showActions="hover"
          showExtra="hover"
          loading={isDashboardRecentLoading}
          metas={{
            title: {
              dataIndex: 'title',
              render: (text: any, record: any) => (
                <Typography.Text strong>{record?.title}</Typography.Text>
              ),
            },
            avatar: {
              dataIndex: 'pictureUrl',
              render: (text: any, record: any) => (
                <Avatar
                  className={styles.avatar}
                  src={record?.pictureUrl ? record?.pictureUrl : undefined}
                >
                  {record?.title?.[0].toUpperCase() || 'U'}
                </Avatar>
              ),
            },
            description: {
              dataIndex: 'description',
              render: (text: any, record: any) => (
                <Space>
                  {record.description?.[0] ? (
                    <Typography.Text type="secondary" style={{ opacity: '0.5' }}>
                      {record.description?.[0]}
                    </Typography.Text>
                  ) : null}
                  {record.description?.[1] ? (
                    <Typography.Text type="secondary" style={{ opacity: '0.5' }}>
                      {record.description?.[1]}
                    </Typography.Text>
                  ) : null}
                  {record.description?.[2] ? (
                    <Typography.Text type="secondary" style={{ opacity: '0.5' }}>
                      {record.description?.[2]}
                    </Typography.Text>
                  ) : null}
                  {record.description?.[3] ? (
                    <Typography.Text type="secondary" style={{ opacity: '0.5' }}>
                      {record.description?.[3]}
                    </Typography.Text>
                  ) : (
                    <Typography.Text type="secondary" style={{ opacity: '0.5' }}>
                      {record.date}
                    </Typography.Text>
                  )}
                </Space>
              ),
            },
          }}
        />
      </div>
    </div>
  );
};

export default Dashboard;
