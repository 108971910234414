import { MsalProvider } from '@azure/msal-react';
import { LoadScript } from '@react-google-maps/api';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { AuthenticationProvider } from 'contexts';
import Pages from 'pages';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import ReportTabsProvider from 'contexts/ReportTabsProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { msalInstance } from 'services/utils/security';
import { getVariableFromEnv } from 'utils/config';
import './i18n';
import './index.less';
import reportWebVitals from './reportWebVitals';

const gmapsAPIKey = getVariableFromEnv('GMAPS_API_KEY');

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      // refetchOnReconnect: false,
      // retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <LoadScript googleMapsApiKey={gmapsAPIKey} libraries={['places']}>
          <ConfigProvider locale={enUS}>
            <AuthenticationProvider>
              <ReportTabsProvider>
                <Suspense fallback="loading">
                  <Pages />
                </Suspense>
              </ReportTabsProvider>
            </AuthenticationProvider>
          </ConfigProvider>
        </LoadScript>
      </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
