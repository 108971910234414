import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { FC, useEffect } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTraceStore } from './hooks';
import TraceTitle, { TraceTable } from './components';

type ActionItemsProps = {
  t: TFunction<'pages', 'trace.trace_screen'>;
};

const actionItems = ({ t }: ActionItemsProps): ItemType[] => [
  {
    key: 'view',
    label: t('main_actions.view'),
    disabled: false,
  },
  {
    key: 'download',
    label: t('main_actions.download'),
    disabled: false,
  },
  {
    key: 'share',
    label: t('main_actions.share'),
    disabled: false,
  },
];
const Trace: FC = () => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'trace.trace_screen',
  });

  const setSearch = useTraceStore((state) => state.setSearch);
  const setTraceTab = useTraceStore((state) => state.setTraceTab);

  useEffect(
    () => () => {
      setSearch({
        company: [],
        lot: [],
        location: [],
        state: [],
        country: [],
        sscc: [],
        event: [],
        productIdentifier: [],
        species: [],
        startDate: undefined,
        endDate: undefined,
      });
      setTraceTab('list');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <TraceTitle
        title={t('trace_title')}
        extraTitle={
          <InfoCircleOutlined style={{ fontSize: '12px', opacity: '0.5', marginLeft: '5px' }} />
        }
        actionItems={actionItems({ t })}
      />
      <TraceTable />
    </>
  );
};

export default Trace;
