import { DownOutlined } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Segmented,
  Space,
  Typography,
  Row,
  Col,
  Switch,
} from 'antd';
import type { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { downloadJsonToCSV, documentJSONtoXML, attributes } from 'utils/helpers';
import { SegmentedValue } from 'antd/lib/segmented';
import { useState, useCallback } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { EventDetailsContent, EPCISContent } from './Sections';
import { MarkerModalProps } from './typings';

const { Title } = Typography;

type ActionItemsProps = {
  activeSection?: string | number;
  t: TFunction<'pages', 'trace.trace_diagram'>;
};

const actionItems = ({ activeSection, t }: ActionItemsProps): ItemType[] => {
  switch (activeSection) {
    case 'Event Details':
      return [
        {
          key: 'downloadCSV',
          label: t('modal.download_csv'),
        },
      ];
    case 'EPCIS':
      return [
        {
          key: 'downloadXML',
          label: t('modal.download_xml'),
        },
      ];
    default:
      return [
        {
          key: 'downloadCSV',
          label: t('modal.download_csv'),
        },
      ];
  }
};

const MarkerModal = ({ selectedMarker, modal, activeEvent }: MarkerModalProps) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'trace.trace_diagram',
  });
  const sections = ['Event Details'];
  const [isPretty, setPretty] = useState<boolean>(true);
  const [activeSection, setActiveSection] = useState<string | number>(sections[0]);

  const onTemplateTitleChange = (value: SegmentedValue) => {
    setActiveSection(value);
  };

  const onCloseModal = () => {
    modal.hide();
  };

  const renderSection = useCallback(() => {
    switch (activeSection) {
      case 'Event Details':
        return <EventDetailsContent selectedMarker={selectedMarker} activeEvent={activeEvent} />;
      case 'EPCIS':
        return (
          <EPCISContent activeEvent={selectedMarker} isPretty={isPretty} eventIdx={activeEvent} />
        );
      default:
        return <EventDetailsContent selectedMarker={selectedMarker} activeEvent={activeEvent} />;
    }
  }, [activeEvent, activeSection, isPretty, selectedMarker]);

  const onDownloadMarkerXML = useCallback(() => {
    documentJSONtoXML(selectedMarker?.events?.[activeEvent]);
  }, [activeEvent, selectedMarker?.events]);

  const onMapCsvDownloadClick = useCallback(() => {
    const values = [
      selectedMarker?.events?.[activeEvent]?.productOwner,
      selectedMarker?.events?.[activeEvent]?.products &&
        selectedMarker?.events?.[activeEvent]?.products?.[
          selectedMarker.events[activeEvent].products.length - 1
        ]?.accountID,
      selectedMarker?.events?.[activeEvent]?.eventTime,
      selectedMarker?.events?.[activeEvent]?.timeZoneOffset,
      selectedMarker?.events?.[activeEvent]?.urn,
      selectedMarker?.events?.[activeEvent]?.products?.[
        selectedMarker.events[activeEvent].products.length - 1
      ]?.shortDescription,
      selectedMarker?.events?.[activeEvent]?.products?.[
        selectedMarker.events[activeEvent].products.length - 1
      ]?.lotId,
      selectedMarker?.events?.[activeEvent]?.products?.[
        selectedMarker.events[activeEvent].products.length - 1
      ]?.quantity,
      selectedMarker?.events?.[activeEvent]?.location?.name,
      String(selectedMarker?.events?.[activeEvent]?.location?.latitude).concat(
        `, ${String(selectedMarker?.events?.[activeEvent]?.location?.longitude)}`,
      ),
      '',
    ];
    const data: any = attributes.map((item, idx) => ({
      id: idx.toString(),
      attribute: item,
      values: values[idx],
    }));
    downloadJsonToCSV(data, 'event_details');
  }, [activeEvent, selectedMarker.events]);

  const onMapModalItemClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case 'downloadCSV':
        onMapCsvDownloadClick();
        break;
      case 'downloadXML':
        onDownloadMarkerXML();
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      visible={modal.visible}
      title={
        <Row gutter={0}>
          <Col style={{ padding: '4px 2px' }}>
            <Title level={4}>{selectedMarker?.title}</Title>
          </Col>
          <Col style={{ padding: '4px 2px' }}>
            <Segmented options={sections} value={activeSection} onChange={onTemplateTitleChange} />
          </Col>
        </Row>
      }
      closable
      onCancel={onCloseModal}
      footer={
        <>
          {activeSection === 'EPCIS' && (
            <span
              style={{
                float: 'left',
              }}
            >
              Beautify:&nbsp;
              <Switch
                defaultChecked
                size="small"
                checked={isPretty}
                style={{
                  zIndex: 1200,
                }}
                onChange={setPretty}
              />
            </span>
          )}
          <Space>
            <Dropdown
              overlay={
                <Menu onClick={onMapModalItemClick} items={actionItems({ activeSection, t })} />
              }
              placement="top"
              overlayStyle={{ zIndex: '1500' }}
            >
              <Button type="primary" ghost shape="round">
                <Space>
                  {t('modal.action_button_text')}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Button
              onClick={onCloseModal}
              type="primary"
              shape="round"
              style={{
                color: '#0a5f7a',
                borderColor: '#bcd530',
                background: '#bcd530',
              }}
            >
              {t('modal.done_button_text')}
            </Button>
          </Space>
        </>
      }
      width="58%"
      bodyStyle={{ height: '60%' }}
      centered
    >
      {renderSection()}
    </Modal>
  );
};

export default MarkerModal;
