import { ProList } from '@ant-design/pro-components';
import { useState } from 'react';
import { Typography, Switch, message } from 'antd';
import { useQueryClient } from 'react-query';
import { UpdateUserRequest } from 'services/api/client/src';
import errorHandler from 'utils/errorHandler';
import { useUpdateUser, useUserInfo } from 'services/api/useSettings';

const key = 'updatable';
const Preferences = () => {
  const { data: loggedInUserData } = useUserInfo();

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();
  const updateUser = useUpdateUser(queryClient);

  const onChange = async (checked: boolean) => {
    try {
      setIsLoading(true);
      if (checked) {
        const reqData: UpdateUserRequest = {
          notificationsOn: true,
        };
        await updateUser.mutateAsync({
          params: reqData,
        });
        setIsLoading(false);
        message.info({ content: 'Email Notifications Enabled', key });
      } else {
        const reqData: UpdateUserRequest = {
          notificationsOn: false,
        };
        await updateUser.mutateAsync({
          params: reqData,
        });
        setIsLoading(false);
        message.info({ content: 'Email Notifications Disabled', key });
      }
    } catch (error: any) {
      message.error(errorHandler(error));
      setIsLoading(false);
    }
  };
  const dataSource = [
    {
      title: 'Email Notifications',
      actions: [
        <Switch
          loading={isLoading}
          onChange={onChange}
          checked={loggedInUserData?.notificationsOn}
        />,
      ],
      description:
        'Receive an email to get notified about the activity happening within your account',
    },
  ];

  return (
    <ProList<any>
      metas={{
        title: {
          dataIndex: 'title',
        },
        description: {
          dataIndex: 'description',
        },
        actions: {
          dataIndex: 'actions',
        },
        subTitle: {},
        type: {},
        content: {},
      }}
      headerTitle={<Typography.Title level={4}>Preferences</Typography.Title>}
      dataSource={dataSource}
    />
  );
};

export default Preferences;
