import { createContext, FC, useContext, useMemo, useState } from 'react';
import { ListReportsOperationResponse } from 'services/api/client/src';
import { ReportTabsContextValue, ReportTabsProviderProps } from './typings';

const ReportTabsContext = createContext<ReportTabsContextValue>({
  endorsersReportsData: {},
  setEndorsersReportsData: () => {},
});

const ReportTabsProvider: FC<ReportTabsProviderProps> = ({ children }) => {
  const [endorsersReportsData, setEndorsersReportsData] = useState<ListReportsOperationResponse>();
  const contextValue = useMemo(
    () => ({
      endorsersReportsData,
      setEndorsersReportsData,
    }),
    [endorsersReportsData],
  );
  return <ReportTabsContext.Provider value={contextValue}>{children}</ReportTabsContext.Provider>;
};

export const useReportTabsContext = (): ReportTabsContextValue => {
  const context = useContext(ReportTabsContext);

  if (context === undefined) {
    throw new Error('ReportTabsContext must be used within a ReportTabsContextProvider');
  }

  return context;
};

export default ReportTabsProvider;
