import { useState, useEffect } from 'react';
import EndorserTitlebar from 'components/TitleBar/EndorserTitlebar';
import { Loading } from 'components';
import styles from './index.module.less';

const Reports = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const iframeEle = document.getElementById('iframe');
    iframeEle?.addEventListener('load', () => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById('iframe')]);

  return (
    <>
      <EndorserTitlebar title="Reports" fontSize className={styles.endorsers} />
      {loading ? <Loading /> : null}
      <iframe
        id="iframe"
        src="https://globalseafood.metabaseapp.com"
        title="Reports"
        style={{ border: 'none' }}
        height="810vh"
        width="100%"
      />
    </>
  );
};

export default Reports;
