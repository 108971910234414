/* eslint-disable no-console */
import { AccountInfo } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Loading } from 'components';
import { scopes, signinRequest } from 'config/authConfig';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getErrorJSON } from 'utils/errorHandler';
import { AuthenticationContextValue, AuthenticationProviderProps, CurrentUser } from './typings';

const AuthenticationContextDefault: AuthenticationContextValue = {
  currentUser: undefined,
  isAuthenticated: false,
  isAPIAuthenticated: false,
  setIsAPIAuthenticated: () => {},
  setIsAuthProcess: () => {},
  signin: () => {},
  signout: () => {},
  refreshToken: () => {},
};

const AuthenticationContext = React.createContext<AuthenticationContextValue>(
  AuthenticationContextDefault,
);

export const AuthenticationProvider: FC<AuthenticationProviderProps> = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [isAPIAuthenticated, setIsAPIAuthenticated] = useState<boolean>(true);
  const [isAuthProcess, setIsAuthProcess] = useState<boolean>(true);
  const checkUserAuth = useCallback(
    async (err: any) => {
      const errRes = getErrorJSON(err);
      if (errRes?.status === 401 || errRes?.status === 404) {
        setIsAPIAuthenticated(false);
      } else {
        setIsAPIAuthenticated(true);
      }
    },
    [setIsAPIAuthenticated],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const clearSessions = useCallback(() => {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }, []);
  /**
   *  refresh token method will reset the session refresh token present in the session storage and will call the acquireTokenSilent method to get a new refresh token and access token for the user.
   */
  const refreshToken = useCallback(() => {
    try {
      instance.logout();
      setIsAPIAuthenticated(true);
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  }, [instance, setIsAPIAuthenticated]);

  const acquireToken = useCallback(
    async (accnt: AccountInfo) => {
      try {
        setIsAuthProcess(true);
        const tokenResponse = await instance.acquireTokenSilent({
          scopes,
          account: accnt,
        });
        if (tokenResponse) {
          const tokenDetails = tokenResponse?.account?.idTokenClaims;
          setCurrentUser({
            id: tokenDetails?.sub || '',
            displayName: `${tokenDetails?.name || ''}`,
            mail: String(tokenDetails?.emails?.[0] || ''),
          });
          setIsAPIAuthenticated(true);
        }
        setIsAuthProcess(false);
      } catch (err) {
        console.log('acquire token error: ', err);
        checkUserAuth(err);
        setIsAuthProcess(false);
      }
    },
    [instance, checkUserAuth],
  );

  useEffect(() => {
    if (account) {
      acquireToken(account);
    }
  }, [account, acquireToken]);

  const isAuthenticated = useIsAuthenticated();

  const signin = useCallback(() => {
    if (!isAuthenticated) {
      instance.loginRedirect(signinRequest);
    }
  }, [instance, isAuthenticated]);

  const signout = useCallback(() => {
    instance.logout();
  }, [instance]);

  const contextValue: AuthenticationContextValue = useMemo(
    () => ({
      currentUser,
      isAuthenticated,
      isAPIAuthenticated,
      signin,
      signout,
      setIsAPIAuthenticated,
      setIsAuthProcess,
      refreshToken,
    }),
    [
      currentUser,
      isAuthenticated,
      isAPIAuthenticated,
      signin,
      signout,
      setIsAPIAuthenticated,
      setIsAuthProcess,
      refreshToken,
    ],
  );

  const renderChildren = useCallback(() => {
    if (inProgress === 'login' || inProgress === 'handleRedirect') {
      return <Loading />;
    }
    return children;
  }, [children, inProgress]);

  if (!navigator.cookieEnabled) {
    return (
      <div style={{ margin: '5px' }}>
        <div style={{ fontSize: '24px', fontWeight: '600' }}>We can&apos;t sign you in</div>
        <div>
          Your browser is currently set to block cookies. You need to allow cookies to use this
          service.
        </div>
        <div>
          Cookies are small text files stored on your computer that tell us when you&apos;re signed
          in. To learn how to allow cookies, check the online help in your web browser.
        </div>
      </div>
    );
  }

  return (
    <AuthenticationContext.Provider value={contextValue}>
      {isAuthProcess && <Loading fullOpacity />}
      {renderChildren()}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = (): AuthenticationContextValue => {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error('AuthenticationContext must be used within a AuthenticationContextProvider');
  }

  return context;
};
