/* eslint-disable no-nested-ternary */
import { ProColumns } from '@ant-design/pro-table';
import { Tag, Tooltip, PageHeader, Button, Empty } from 'antd';
import { Link } from 'react-router-dom';
import GTable from 'components/GTable';
import { FC, useState, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { yearFirstFormat } from 'utils/helpers';
import { useUserInfo } from 'services/api/useSettings';
import useModalVisibility from 'hooks/useModalVisibility';
import useAccountsListFilters from './hooks';
import ManageAccessModal from './components';
import styles from './index.module.less';
import { UpdatedEndorserDTO, FilterArrayProp } from './typings';

type ColumnsType = {
  producerSearchFilter: ProColumns;
  bapNumberSearchFilter: ProColumns;
  accessSearchFilter: ProColumns;
  onRowActionClick: (actionPayload?: UpdatedEndorserDTO) => void;
  t: TFunction<'pages', 'endorsers_partners'>;
  speciesListFilter: Array<FilterArrayProp>;
  countriesListFilter: Array<FilterArrayProp>;
  loggedInUserData: any;
};

const columns = ({
  producerSearchFilter,
  bapNumberSearchFilter,
  onRowActionClick,
  t,
  speciesListFilter,
  countriesListFilter,
  accessSearchFilter,
}: ColumnsType): Array<ProColumns<UpdatedEndorserDTO>> => [
  {
    title: t('endorsers_partners_table.producer'),
    dataIndex: 'producer',
    ellipsis: true,
    ...producerSearchFilter,
    render: (text, record) => (
      <Link
        className="text-link"
        to={`../endorsers_partners/${String(record.id)}`}
        title={`View ${text}`}
      >
        {text}
      </Link>
    ),
    width: 270,
  },
  {
    title: 'Access',
    dataIndex: 'access',
    render: (text, records: any) =>
      records?.id && records?.access !== 0 ? (
        <Tag
          style={{
            borderRadius: '15px',
            fontWeight: '500',
            color: '#9D9D9D',
            border: '1.3px solid #9D9D9D',
          }}
        >
          {records?.access} Access
        </Tag>
      ) : !records?.id && records?.access !== 0 && records?.accessFields?.[0]?.accepted ? (
        `${records?.accessFields?.[0]?.endorserName} (View / Audit Information)`
      ) : null,
    ...accessSearchFilter,
  },
  {
    title: t('endorsers_partners_table.species'),
    dataIndex: 'species',
    filters: speciesListFilter,
    width: 250,
    render: (text, records) =>
      records?.bap && records?.id ? (
        <Tooltip
          placement="bottom"
          title={
            records?.species?.length === 0 ? (
              <div>No Species.</div>
            ) : (
              <div>
                {records?.species?.map((item) => (
                  <div>{item?.category}</div>
                ))}
              </div>
            )
          }
        >
          <Tag
            style={{
              borderRadius: '15px',
              fontWeight: '500',
              color: '#0A5F7A',
              border: '1.3px solid #0A5F7A',
              backgroundColor: '#E7F0F2',
            }}
          >
            {records?.species?.length} Species
          </Tag>
        </Tooltip>
      ) : null,
  },
  {
    title: t('endorsers_partners_table.producer_type'),
    dataIndex: 'producerType',
    ellipsis: true,
    filters: [
      {
        text: 'Farm',
        value: 'Farm',
      },
      {
        text: 'Hatchery',
        value: 'Hatchery',
      },
      {
        text: 'Feed Mill',
        value: 'Feed Mill',
      },
      {
        text: 'Supplier',
        value: 'Supplier (manf, importer)',
      },
      {
        text: 'Retail',
        value: 'Retail (supermarket, FDM, e-commerce, specialty)',
      },
      {
        text: 'Corporation',
        value: 'Corporation',
      },
      {
        text: 'Foodservice',
        value: 'Foodservice (dist, merch, rest, hotel, entertnmt, health, airline)',
      },
      {
        text: 'Processor',
        value: 'Processor',
      },
      {
        text: 'Re-processor',
        value: 'Re-processor',
      },
    ],
  },
  {
    title: t('endorsers_partners_table.country'),
    dataIndex: 'country',
    ellipsis: true,
    filters: countriesListFilter,
  },
  {
    title: t('endorsers_partners_table.bap_number'),
    dataIndex: 'bap',
    ellipsis: true,
    ...bapNumberSearchFilter,
  },
  {
    title: t('endorsers_partners_table.expiration'),
    dataIndex: 'expiration',
    ellipsis: true,
    render: (text, record) => (record?.id ? yearFirstFormat(String(record?.expiration)) : null),
    sorter: true,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    render: (text, record) =>
      record?.id ? (
        <Button
          size="small"
          type="primary"
          ghost
          className="actions"
          onClick={() => onRowActionClick(record)}
        >
          Manage Access
        </Button>
      ) : null,
    fixed: 'right',
    width: 140,
  },
];

const AccountAccess: FC = () => {
  const { data: loggedInUserData } = useUserInfo();
  const manageAccessModal = useModalVisibility(false);
  const [selectedRowData, setSelectedRowData] = useState<UpdatedEndorserDTO>();
  const { t } = useTranslation('pages', { keyPrefix: 'endorsers_partners' });

  // Accounts List
  const {
    accountsList,
    isAccountsListLoading,
    accountsListParams,
    totalItems,
    onAccessListTableChange,
    filters: [producerSearchFilter, bapNumberSearchFilter, accessSearchFilter],
    speciesListResponse,
    countriesListResponse,
    selectedFilter,
  } = useAccountsListFilters();

  // species list response
  const speciesListFilter: Array<FilterArrayProp> = useMemo(
    () =>
      speciesListResponse?.species?.map((item: string) => ({
        text: item || '',
        value: item || '',
      })) || [],
    [speciesListResponse],
  );

  // countries list response
  const countriesListFilter: Array<FilterArrayProp> = useMemo(
    () =>
      countriesListResponse?.countries?.map((item: string) => ({
        text: item || '',
        value: item || '',
      })) || [],
    [countriesListResponse],
  );

  const ecosystem: Array<UpdatedEndorserDTO> = accountsList.map((item) => ({
    ...item,
  }));

  const onRowActionClick = (actionPayload?: UpdatedEndorserDTO) => {
    setSelectedRowData(actionPayload);
    manageAccessModal.show();
  };

  return (
    <>
      <ManageAccessModal modal={manageAccessModal} selectedRowData={selectedRowData} />
      <GTable<UpdatedEndorserDTO>
        columns={columns({
          producerSearchFilter,
          bapNumberSearchFilter,
          accessSearchFilter,
          onRowActionClick,
          t,
          speciesListFilter,
          countriesListFilter,
          loggedInUserData,
        })}
        value={ecosystem}
        headerTitle={<PageHeader title="Account Access" className={styles['page-header']} />}
        options={{
          setting: false,
          reload: false,
        }}
        loading={isAccountsListLoading}
        onTableChange={onAccessListTableChange}
        pagination={{
          defaultPageSize: accountsListParams?.pageSize,
          total: totalItems,
          current: accountsListParams?.pageNumber,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Producers`,
        }}
        scroll={{ x: 1720, y: '65vh' }}
        noDataContent={
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className={styles.sktcontainer}
            description={
              <span>No Result{`${selectedFilter}`}. Please search for something else</span>
            }
          />
        }
      />
    </>
  );
};

export default AccountAccess;
