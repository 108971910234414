import React from 'react';
import { useTranslation } from 'react-i18next';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { SwitchButtonProps } from './typings';

const SwitchTabButtons: React.FC<SwitchButtonProps> = ({
  search,
  traceTab,
  setTraceTab,
  isAllEventsListLoading,
}) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'trace.trace_header',
  });

  const handleModeChange = (e: RadioChangeEvent) => {
    setTraceTab(e.target.value);
  };

  return (
    <Radio.Group onChange={handleModeChange} value={traceTab}>
      <Radio.Button value="list" style={{ padding: '0px 15px' }}>
        {t('list_button_text')}
      </Radio.Button>
      <Radio.Button
        value="map"
        style={{ padding: '0px 15px' }}
        disabled={search?.lot?.length !== 1 || isAllEventsListLoading}
      >
        {t('map_button_text')}
      </Radio.Button>
      <Radio.Button
        value="diagram"
        style={{ padding: '0px 15px' }}
        disabled={search?.lot?.length !== 1 || isAllEventsListLoading}
      >
        {t('diagram_button_text')}
      </Radio.Button>
    </Radio.Group>
  );
};

export default SwitchTabButtons;
