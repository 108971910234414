import { RouteComponent } from './typings';

export const routes: RouteComponent[] = [
  {
    key: 'profile',
    name: 'My Profile',
    component: 'Profile',
  },
  {
    key: 'preferences',
    name: 'Preferences',
    component: 'Preferences',
  },
  {
    key: 'users_permission',
    name: 'Users & Permissions',
    component: 'UserPermissions',
  },
  {
    key: 'account_settings',
    name: 'Account Settings',
    component: 'AccountSettings',
  },
];

export const drawerBodyStyle = {
  paddingTop: 0,
  paddingRight: 0,
  paddingLeft: 0,
  paddingBottom: 0,
  overflow: 'hidden',
};
export const drawerContentWrapper = { padding: 0 };
