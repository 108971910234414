import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import useModalVisibility from 'hooks/useModalVisibility';
import HelpDeskModal from './Help';
import HeaderSearch from './HeaderSearch';
import NotificationDropdown from '../NotificationDropdown';
import AvatarDropdown from './AvatarDropdown';
import styles from './index.module.less';
import { RightContentProps } from './typings';

export type SiderTheme = 'light' | 'dark';

const RightContent: React.FC<RightContentProps> = ({ settings }) => {
  const { navTheme, layout } = settings;
  const helpDeskModal = useModalVisibility(false);
  let className = styles.right;

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right} ${styles.dark}`;
  }

  return (
    <div className={className}>
      {helpDeskModal.visible && <HelpDeskModal modal={helpDeskModal} />}
      <HeaderSearch className={`${styles.action} ${styles.search}`} placeholder="Search" />
      <Tooltip title="Help">
        <Button
          type="text"
          icon={
            <QuestionCircleOutlined
              style={{ fontSize: '14px' }}
              onClick={() => {
                helpDeskModal.show();
              }}
            />
          }
        />
      </Tooltip>
      <NotificationDropdown />
      <AvatarDropdown />
    </div>
  );
};
export default RightContent;
