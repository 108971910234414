import { FC } from 'react';
import { Button, Empty, Typography } from 'antd';
import { noAccessIcon } from 'assets';
import { NoAccessProps } from './typings';
import styles from './index.module.less';

const NoAccessStateDashboard: FC<NoAccessProps> = ({ companyName, onRequestAccessButtonClick }) => (
  <Empty
    image={noAccessIcon}
    description={
      <>
        <Typography.Text>Request access to view information from</Typography.Text>
        <br />
        <Typography.Text>{companyName}</Typography.Text>
      </>
    }
    className={styles.emptycontainer}
  >
    <Button type="primary" shape="round" ghost onClick={onRequestAccessButtonClick} disabled>
      Request Access
    </Button>
  </Empty>
);

export default NoAccessStateDashboard;
