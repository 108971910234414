import React from 'react';
import UniversalSearch from './UniversalSearch';
import AvatarDropdown from './AvatarDropdown';
import styles from './index.module.less';
import NotificationDropdown from '../NotificationDropdown';
import { RightContentProps } from './typings';

export type SiderTheme = 'light' | 'dark';

const RightContent: React.FC<RightContentProps> = ({ settings }) => {
  const { navTheme, layout } = settings;
  let className = styles.right;

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right} ${styles.dark}`;
  }

  return (
    <div className={className}>
      <UniversalSearch className={`${styles.action} ${styles.search}`} placeholder="Search" />
      <NotificationDropdown />
      <AvatarDropdown />
    </div>
  );
};
export default RightContent;
