/* eslint-disable no-nested-ternary */
import { Modal, Button, Typography, Checkbox, Space, Spin, message } from 'antd';
import { useRef, useCallback, FC, useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import errorHandler from 'utils/errorHandler';
import { yearFirstFormat } from 'utils/helpers';
import { useAPIAuditReports } from 'services/api/Endorsers';
import { useDownloadReports, useDownloadFirstPageReport } from 'services/api/useEndorsers';
import AuditReportsTable from './AuditReportsTable';
import { DownloadReportProps } from './typings';

const { Title } = Typography;

const DownloadReport: FC<DownloadReportProps> = ({ modal, selectedBap }) => {
  const modalFormRef = useRef<any>();
  const queryClient = useQueryClient();
  const downloadReport = useDownloadReports(queryClient);
  const downloadFirstPageReport = useDownloadFirstPageReport(queryClient);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Array<CheckboxValueType>>([]);
  const [selectedReportId, setSelectedReportId] = useState<any>('');

  const onCloseModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    modal.hide();
  }, [modal]);

  const onChangeGroup = (checkedValues: CheckboxValueType[]) => {
    setSelectedOption(checkedValues);
  };

  const [auditReports, setAuditReports] = useState<any[]>([]);
  const { data: auditReportsResponse, isLoading: isAuditReportsLoading } = useAPIAuditReports({
    pageSize: 50,
    pageNumber: 1,
    bapNumber: selectedBap,
  });
  useEffect(() => {
    setAuditReports(
      auditReportsResponse?.results?.map((item) => ({
        key: item.reportId,
        reportId: item.reportId,
        bapNumber: item.bapNumber,
        facilityName: item.facilityName,
        status: item.status,
        completedDate: yearFirstFormat(item.submittedDate),
      })) || [],
    );
  }, [auditReportsResponse?.results]);

  const onSuccess = async () => {
    setLoading(true);
    if (selectedOption.length === 0 && selectedReportId === '') {
      message.error('Please select the audit report you would like to download');
      setLoading(false);
      return;
    }
    if (selectedOption.length >= 1 && selectedReportId === '') {
      message.error('Please select the audit you would like to download');
      setLoading(false);
      return;
    }
    if (selectedOption.length === 0 && selectedReportId !== '') {
      message.error('Please select at least one report you would like to download');
      setLoading(false);
    }
    if (selectedOption?.includes('completeReport') && selectedOption?.includes('firstPageReport')) {
      try {
        const pdfResponse = await downloadReport.mutateAsync({
          reportId: selectedReportId || '',
          bapNumber: selectedBap || '',
          params: {
            summary: true,
            fullReport: true,
          },
        });
        const blobBody = await pdfResponse?.blobBody;
        const url = URL.createObjectURL(blobBody as any);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${pdfResponse?.xFileName}`);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);

        // download firstPage report
        const firstPageReportResponse = await downloadFirstPageReport.mutateAsync({
          reportId: selectedReportId || '',
          bapNumber: selectedBap || '',
        });
        const firstPageBlobBody = await firstPageReportResponse?.blobBody;
        const firstPageURL = URL.createObjectURL(firstPageBlobBody as any);
        const firstPageLink = document.createElement('a');
        firstPageLink.href = firstPageURL;
        firstPageLink.setAttribute('download', `${firstPageReportResponse?.xFileName}`);
        document.body.appendChild(firstPageLink);
        firstPageLink.click();
        firstPageLink?.parentNode?.removeChild(firstPageLink);
        setLoading(false);
        modal.hide();
      } catch (error: any) {
        message.error(errorHandler(error));
        setLoading(false);
      }
    } else if (selectedOption?.includes('completeReport')) {
      try {
        // download complete Report only
        const pdfResponse = await downloadReport.mutateAsync({
          reportId: selectedReportId || '',
          bapNumber: selectedBap || '',
          params: {
            summary: true,
            fullReport: true,
          },
        });
        const blobBody = await pdfResponse?.blobBody;
        const url = URL.createObjectURL(blobBody as any);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${pdfResponse?.xFileName}`);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
        setLoading(false);
        modal.hide();
      } catch (error: any) {
        message.error(errorHandler(error));
        setLoading(false);
      }
    } else if (selectedOption?.includes('firstPageReport')) {
      try {
        // download firstPage report
        const firstPageReportResponse = await downloadFirstPageReport.mutateAsync({
          reportId: selectedReportId || '',
          bapNumber: selectedBap || '',
        });
        const firstPageBlobBody = await firstPageReportResponse?.blobBody;
        const firstPageURL = URL.createObjectURL(firstPageBlobBody as any);
        const firstPageLink = document.createElement('a');
        firstPageLink.href = firstPageURL;
        firstPageLink.setAttribute('download', `${firstPageReportResponse?.xFileName}`);
        document.body.appendChild(firstPageLink);
        firstPageLink.click();
        firstPageLink?.parentNode?.removeChild(firstPageLink);
        setLoading(false);
        modal.hide();
      } catch (error: any) {
        message.error(errorHandler(error));
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <div style={{ position: 'fixed', left: '50%', top: '50%', zIndex: '1500' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Modal
      visible={modal.visible}
      title={<Title level={5}>Download Audit Report</Title>}
      closable
      onCancel={onCloseModal}
      footer={
        <Button
          onClick={onSuccess}
          type="primary"
          shape="round"
          style={{
            color: '#0a5f7a',
            borderColor: '#bcd530',
            background: '#bcd530',
          }}
        >
          Download
        </Button>
      }
      width="45%"
      bodyStyle={{ height: '60%', borderRadius: '0px' }}
      centered
    >
      <Space direction="vertical" size="small">
        <Typography.Text strong>Select the report you would like to download</Typography.Text>
        <Checkbox.Group onChange={onChangeGroup} value={selectedOption}>
          <Space direction="vertical" size="small">
            <Checkbox value="completeReport">Complete Report</Checkbox>
            <Checkbox value="firstPageReport">First Page Report</Checkbox>
          </Space>
        </Checkbox.Group>
      </Space>
      <div style={{ marginTop: '15px' }} />
      <Space direction="vertical" size="small">
        <Typography.Text strong>Select the audit you would like to download</Typography.Text>
        <AuditReportsTable
          auditReports={auditReports}
          isAuditReportsLoading={isAuditReportsLoading}
          setSelectedReportId={setSelectedReportId}
        />
      </Space>
    </Modal>
  );
};

export default DownloadReport;
