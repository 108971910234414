import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTableSearchFilter } from 'hooks';
import { ListEventsOptionalParams, EventProductDTO } from 'services/api/client/src';
import { useAllEventsList } from 'services/api/useTrace';
import useTraceStore from './useTraceStore';

const useAllEventsListFilters = (initParams?: ListEventsOptionalParams) => {
  const [eventsListParams, setEventsListParams] = useState<ListEventsOptionalParams>({
    ...initParams,
  });

  const { data: allEventsListResponse, isLoading: isAllEventsListLoading } =
    useAllEventsList(eventsListParams);

  const search = useTraceStore((state) => state.search);

  useEffect(() => {
    setEventsListParams({
      ...eventsListParams,
      company: search.company?.length === 0 ? [] : (search.company as any),
      lotIds: search.lot?.length === 0 ? [] : (search.lot as any),
      locationName: search.location?.length === 0 ? [] : (search.location as any),
      state: search.state?.length === 0 ? [] : (search.state as any),
      country: search.country?.length === 0 ? [] : (search.country as any),
      species: search.species?.length === 0 ? [] : (search.species as any),
      sscc: search.sscc?.length === 0 ? [] : (search.sscc as any),
      productIdentifier:
        search.productIdentifier?.length === 0 ? [] : (search.productIdentifier as any),
      startDate:
        search.startDate !== undefined
          ? (moment(search.startDate).format('yyyy-MM-DD') as any)
          : undefined,
      endDate:
        search.endDate !== undefined
          ? (moment(search.endDate).format('yyyy-MM-DD') as any)
          : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const allEventsList: EventProductDTO[] = useMemo(
    () => allEventsListResponse?.events || [],
    [allEventsListResponse],
  );

  const { filters: facilityNameSearchFilter } = useTableSearchFilter({
    title: 'Facility Name',
  });

  const { filters: bapNumberSearchFilter } = useTableSearchFilter({
    title: 'BAP Number',
  });

  return {
    allEventsList,
    isAllEventsListLoading,
    eventsListParams,
    setEventsListParams,
    filters: [facilityNameSearchFilter, bapNumberSearchFilter],
  };
};

export default useAllEventsListFilters;
