/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ProFormColumnsType } from '@ant-design/pro-form';
import { Checkbox, Typography, Space, Radio, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { RadioChangeEvent } from 'antd';
import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';

const rowProps = { gutter: 32 };

export type DataItem = {
  name: string | ReactNode;
  state: string;
};

type ColumnsType = {
  t: TFunction<'pages', 'endorsers_partners.view_company.grant_access_modal'>;
  onChangeGroup: (checkedValues: CheckboxValueType[]) => void;
  onLevelChange: (e: RadioChangeEvent) => void;
  levelValue: string;
  companyName: string;
  categoryValue: Array<CheckboxValueType>;
};

export const RequestAccessColumns = ({
  t,
  onChangeGroup,
  onLevelChange,
  levelValue,
  categoryValue,
  companyName,
}: ColumnsType): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['connectionType'],
    },
    rowProps,
    columns: () => [
      {
        renderFormItem: () => (
          <Typography.Text>{t('request_access_modal_heading', { companyName })}</Typography.Text>
        ),
      },
    ],
  },
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['email'],
    },
    columns: () => [
      {
        dataIndex: 'desiredCategory',
        valueType: 'text',
        renderFormItem: () => (
          <Space direction="vertical" size="small" style={{ marginTop: '24px' }}>
            <Typography.Text>
              Select desired{' '}
              <Typography.Text strong underline>
                categories
              </Typography.Text>{' '}
              of access
            </Typography.Text>
            <Checkbox.Group onChange={onChangeGroup} value={categoryValue}>
              <Checkbox value="auditInformation">{t('audit_information')}</Checkbox>
            </Checkbox.Group>
          </Space>
        ),
      },
      {
        dataIndex: 'desiredLevel',
        valueType: 'text',
        renderFormItem: () => (
          <Space direction="vertical" size="small" style={{ marginTop: '24px' }}>
            <Typography.Text>
              Select desired{' '}
              <Typography.Text strong underline>
                level
              </Typography.Text>{' '}
              of access
            </Typography.Text>
            <Radio.Group onChange={onLevelChange} value={levelValue}>
              <Space direction="vertical" size="small">
                <Radio value="view">{t('view_only')}</Radio>
                {/* <Radio value="share">
                  {t('share')}{' '}
                  <Tooltip title={t('share_tooltip')} placement="bottom">
                    <ExclamationCircleOutlined style={{ fontSize: '14px', opacity: '0.5' }} />
                  </Tooltip>
                </Radio> */}
              </Space>
            </Radio.Group>
          </Space>
        ),
      },
    ],
  },
];
