import { ProColumns } from '@ant-design/pro-table';
import { Button } from 'antd';
import GTable from 'components/GTable';
import useModalVisibility from 'hooks/useModalVisibility';
import { UseModalVisibilityReturnType } from 'hooks/useModalVisibility/typings';
import { FC, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { EventListAPIFields } from 'services/api';
import { yearFirstFormat } from 'utils/helpers';
import styles from '../index.module.less';
import ListModal from './Modal';
import { TraceListProps } from './typings';

type ActionItemsProps = {
  t: TFunction<'pages', 'trace.trace_list'>;
  listModal: UseModalVisibilityReturnType;
  setSelectedLotData: any;
};

const columns = ({
  t,
  listModal,
  setSelectedLotData,
}: ActionItemsProps): Array<ProColumns<EventListAPIFields>> => [
  {
    title: t('lot'),
    dataIndex: 'lot',
    ellipsis: true,
    fixed: 'left',
    render: (text: any, record: any) => (
      <Button
        type="link"
        onClick={() => {
          setSelectedLotData(record);
          listModal.show();
        }}
        style={{ padding: '0px', margin: '0px' }}
      >
        {text}
      </Button>
    ),
  },
  {
    title: t('sscc'),
    dataIndex: 'sscc',
    ellipsis: true,
  },
  {
    title: t('quantity'),
    dataIndex: 'quantity',
    ellipsis: true,
  },
  {
    title: t('company'),
    dataIndex: 'company',
    ellipsis: true,
  },
  {
    title: t('location'),
    dataIndex: 'location',
    ellipsis: true,
  },
  {
    title: t('state'),
    dataIndex: 'state',
    ellipsis: true,
  },
  {
    title: t('country'),
    dataIndex: 'country',
    ellipsis: true,
  },
  {
    title: t('event'),
    dataIndex: 'event',
    ellipsis: true,
  },
  {
    title: t('product_id'),
    dataIndex: 'productIdentifier',
    ellipsis: true,
  },
  {
    title: t('sku'),
    dataIndex: 'sku',
    ellipsis: true,
  },
  {
    title: t('purchase_order'),
    dataIndex: 'purchaseOrder',
  },
  {
    title: t('species'),
    dataIndex: 'species',
    ellipsis: true,
  },
  {
    title: t('date'),
    dataIndex: 'date',
    ellipsis: true,
  },
];

const TraceTable: FC<TraceListProps> = ({
  allEventsList,
  isAllEventsListLoading,
  selectedLotData,
  setSelectedLotData,
}) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'trace.trace_list',
  });
  const listModal = useModalVisibility(false);

  const eventList: any = useMemo(
    () =>
      allEventsList?.map((item, idx: number) => ({
        id: idx,
        lot: item?.lotId,
        sscc: item?.containerId,
        timeZone: item?.timeZoneOffset,
        quantity: item?.quantity,
        company: item.company,
        location: item?.location?.name,
        event: item.eventType,
        productIdentifier: item.shortDescription,
        sku: '',
        purchaseOrder: '',
        date: yearFirstFormat(item.eventTime),
        species: item?.speciesName,
        state: item?.location?.state,
        country: item?.location?.country,
        shortDesc: item?.shortDescription,
        locationDetails: item?.location,
        eventData: item,
      })),
    [allEventsList],
  );

  return (
    <>
      {listModal.visible && <ListModal selectedLotData={selectedLotData} modal={listModal} />}
      <div className={styles.table}>
        <GTable<EventListAPIFields>
          columns={columns({ t, listModal, setSelectedLotData })}
          value={eventList}
          enableRecordCreator
          options={{
            reload: false,
            setting: true,
          }}
          columnsState={{
            defaultValue: {
              sku: {
                show: false,
              },
            },
          }}
          loading={isAllEventsListLoading}
          recordCreatorProps={false}
          pagination={{ defaultPageSize: 20, showSizeChanger: true }}
          scroll={{ y: 620, x: 1800 }}
        />
      </div>
    </>
  );
};

export default TraceTable;
