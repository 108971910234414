import { useCallback, useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TablePaginationConfig } from 'antd';
import { useTableSearchFilter } from 'hooks';
import { useAuditReportTableById, AuditReportProps } from 'services/api/Endorsers';
import { FilterValue } from 'antd/lib/table/interface';
import useAuditReportStore from './useAuditReportStore';

const useAuditReportFilters = () => {
  const auditReportParams = useAuditReportStore((state) => state.auditReportParams);
  const setAuditReportParams = useAuditReportStore((state) => state.setAuditReportParams);
  const auditReportId = useAuditReportStore((state) => state.auditReportId);
  const [firstLoading, setFirstLoading] = useState(true);
  const { reportId, bapNumber } = useParams();

  const { data: auditReportResponse, isLoading: isAuditReportLoading } = useAuditReportTableById(
    reportId || auditReportId,
    bapNumber || '',
    auditReportParams,
  );

  useEffect(() => {
    if (!isAuditReportLoading && firstLoading) {
      setFirstLoading(false);
    }
  }, [firstLoading, isAuditReportLoading]);

  const auditReport: AuditReportProps[] = useMemo(
    () =>
      auditReportResponse?.results?.map((item, index) => ({
        id: index,
        pillar: item.pillar,
        reference: item.referenceNumber,
        cause:
          {
            cause: item.clause,
            explanation: item.explanation,
          } || '',
        conforming: item.answer,
        phase: item.phase,
        bapNumber: item.bapNumber,
      })) || [],
    [auditReportResponse],
  );

  const { filters: referenceSearchFilter } = useTableSearchFilter({
    title: 'Reference',
  });

  const { filters: causeSearchFilter } = useTableSearchFilter({
    title: 'Clause & Explanation',
  });

  const { filters: phaseSearchFilter } = useTableSearchFilter({
    title: 'Phase',
  });

  const { filters: bapSearchFilter } = useTableSearchFilter({
    title: 'BAP Number',
  });

  const onAuditReportTableChange = useCallback(
    (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
      const pillarFilter = filters?.pillar
        ? {
            pillars: filters?.pillar as string[],
          }
        : {};
      const referenceNumber = filters?.reference
        ? {
            referenceNumber: filters?.reference?.[0] as string,
          }
        : {};
      const causeExplanationFilter = filters?.cause
        ? {
            causeExplanation: filters?.cause?.[0] as string,
          }
        : {};
      const conformingFilter = filters?.conforming
        ? {
            answers: filters?.conforming as string[],
          }
        : {};
      setFirstLoading(false);
      setAuditReportParams({
        pageNumber: 1,
        pageSize: 900,
        ...pillarFilter,
        ...referenceNumber,
        ...causeExplanationFilter,
        ...conformingFilter,
      });
    },
    [setAuditReportParams],
  );

  return {
    auditReport,
    isAuditReportLoading,
    firstLoading,
    auditReportParams,
    setAuditReportParams,
    onAuditReportTableChange,
    filters: [referenceSearchFilter, causeSearchFilter, phaseSearchFilter, bapSearchFilter],
    auditReportResponse,
  };
};

export default useAuditReportFilters;
