import GTraceTable from 'components/GTrace';
import {
  useTraceStore,
  useAllEventsListFilters,
  useMapLocationFilters,
  useDiagramFilters,
} from '../../hooks';

const TraceTable = () => {
  // TopFilters
  const expandedFilters = useTraceStore((state) => state.expandedFilters);
  const toggleExpandedFilters = useTraceStore((state) => state.toggleExpandedFilters);
  const mainSelected = useTraceStore((state) => state.mainSelected);
  const setMainSelected = useTraceStore((state) => state.setMainSelected);

  // Switch Buttons
  const search = useTraceStore((state) => state.search);
  const setSearch = useTraceStore((state) => state.setSearch);
  const traceTab = useTraceStore((state) => state.traceTab);
  const setTraceTab = useTraceStore((state) => state.setTraceTab);

  // All Events
  // all Events List API
  const { allEventsList, isAllEventsListLoading } = useAllEventsListFilters();

  // all Events MAP API
  const { eventMapLocation, isEventMpaLocationLoading } = useMapLocationFilters();

  // all Events Diagram API
  const { eventDiagramNodes, isEventDiagramLoading } = useDiagramFilters();

  // Lot Select
  const selectedLot = useTraceStore((state) => state.selectedLot);
  const setSelectedLot = useTraceStore((state) => state.setSelectedLot);

  // Trace List
  const selectedLotData = useTraceStore((state) => state.selectedLotData);
  const setSelectedLotData = useTraceStore((state) => state.setSelectedLotData);

  // Trace Map
  const selectedMarker = useTraceStore((state) => state.selectedMarker);
  const setSelectedMarker = useTraceStore((state) => state.setSelectedMarker);

  // Trace Diagram
  const selectedNode = useTraceStore((state) => state.selectedNode);
  const setSelectedNode = useTraceStore((state) => state.setSelectedNode);

  return (
    <GTraceTable
      traceTab={traceTab}
      setTraceTab={setTraceTab}
      expandedFilters={expandedFilters}
      toggleExpandedFilters={toggleExpandedFilters}
      search={search}
      mainSelected={mainSelected}
      setMainSelected={setMainSelected}
      selectedLotData={selectedLotData}
      setSelectedLotData={setSelectedLotData}
      selectedNode={selectedNode}
      setSelectedNode={setSelectedNode}
      selectedMarker={selectedMarker}
      setSelectedMarker={setSelectedMarker}
      selectedLot={selectedLot}
      setSearch={setSearch}
      setSelectedLot={setSelectedLot}
      // event list api
      allEventsList={allEventsList}
      isAllEventsListLoading={isAllEventsListLoading}
      // event map api data
      eventMapLocation={eventMapLocation}
      isEventMpaLocationLoading={isEventMpaLocationLoading}
      // event diagram api data
      eventDiagramNodes={eventDiagramNodes}
      isEventDiagramLoading={isEventDiagramLoading}
    />
  );
};

export default TraceTable;
