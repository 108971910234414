import { Checkbox, Typography, Space } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useTranslation } from 'react-i18next';
import useAuditReportStore from '../hooks';

const ModalContent = () => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.audit_report_download_modal',
  });
  const setCheckedBox = useAuditReportStore((state) => state.setCheckedBox);
  const checkedBox = useAuditReportStore((state) => state.checkedBox);
  const onChangeGroup = (checkedValues: CheckboxValueType[]) => {
    setCheckedBox(checkedValues);
  };
  return (
    <Space direction="vertical" size="middle">
      <Typography.Text>{t('choose_download_option')}</Typography.Text>
      <Checkbox.Group onChange={onChangeGroup} value={checkedBox}>
        <Checkbox value="summary">{t('summary')}</Checkbox>
        <br />
        <Checkbox value="auditReport">{t('audit_report')}</Checkbox>
        <br />
        <Checkbox value="firstPageReport">{t('first_page_report')}</Checkbox>
      </Checkbox.Group>
    </Space>
  );
};

export default ModalContent;
