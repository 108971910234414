import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { GoogleMarkerSvg } from 'assets';
import classNames from 'classnames';
import { google } from 'google-maps';
import { FC, useCallback, useState, useEffect } from 'react';
import Polylines from './Polylines';

import styles from './index.module.less';
import { GMapProps } from './typings';

const defaultCenter = {
  lat: 0,
  lng: 0,
};

const newMainCenter = {
  lat: 23.704895,
  lng: -15.943179,
};
const defaultContainerStyle = {
  width: '100%',
  height: '650px',
};

const GMap: FC<GMapProps> = ({
  markers,
  edges,
  renderInfoWindow,
  containerStyle,
  setSelectedMarker,
  showPolylines = true,
  allowFitBounds,
  ...props
}) => {
  const [mapInstance, setMapInstance] = useState<google.maps.Map>();
  const [activeMarker, setActiveMarker] = useState<any>();
  const [InfoWindowOpen, setInfoWindowOpen] = useState(false);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    setTimeout(() => setMapInstance(map), 500);
  }, []);

  const onClickMarker = useCallback(
    (e: google.maps.MapMouseEvent, marker: any) => {
      const { disabled = false } = marker;
      setActiveMarker(marker);
      setInfoWindowOpen(false);
      setSelectedMarker(marker);

      if (marker?.onClick) {
        marker?.onClick(e);
      }
      if (!disabled) {
        setInfoWindowOpen(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapInstance, markers, allowFitBounds],
  );

  const fitMarkerBounds = useCallback(() => {
    if (allowFitBounds && mapInstance && (markers?.length || 0) > 1) {
      const bounds = new window.google.maps.LatLngBounds();
      markers?.forEach((m: any) => {
        // @ts-ignore
        bounds.extend(new window.google.maps.LatLng(m.position?.lat, m.position?.lng));
      });
      mapInstance?.fitBounds(bounds);
    }
  }, [mapInstance, markers, allowFitBounds]);

  useEffect(fitMarkerBounds, [fitMarkerBounds]);

  return (
    <GoogleMap
      mapContainerClassName={classNames(styles.mapcontainer)}
      mapContainerStyle={{ ...defaultContainerStyle, ...containerStyle }}
      center={
        // eslint-disable-next-line no-nested-ternary
        showPolylines
          ? markers?.length >= 1
            ? markers?.[0]?.position
            : defaultCenter
          : newMainCenter
      }
      zoom={showPolylines ? 4 : 3}
      options={{ minZoom: 2, controlSize: 25, streetViewControl: false }}
      onLoad={onMapLoad}
      {...props}
    >
      <>
        {mapInstance &&
          markers?.map((marker: any) => (
            <Marker
              icon={GoogleMarkerSvg}
              key={marker.id}
              position={marker?.position}
              onClick={(e) => {
                onClickMarker(e, marker);
              }}
              zIndex={marker.id}
              label={showPolylines ? { text: marker?.label || '', color: '#fff' } : undefined}
            />
          ))}
        {mapInstance && activeMarker && renderInfoWindow && InfoWindowOpen && (
          <InfoWindow
            position={activeMarker?.position}
            options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
            onCloseClick={() => setInfoWindowOpen(false)}
          >
            {renderInfoWindow(activeMarker)}
          </InfoWindow>
        )}
        {mapInstance && showPolylines && <Polylines markers={markers} edges={edges} />}
      </>
    </GoogleMap>
  );
};
export default GMap;
