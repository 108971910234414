import type { ProFormColumnsType } from '@ant-design/pro-form';
import { Input } from 'antd';
import { EmailTagInput } from 'components';
import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import styles from './index.module.less';

const { TextArea } = Input;

const rowProps = { gutter: 32 };

export type DataItem = {
  name: string | ReactNode;
  state: string;
};

type ColumnsType = {
  emailCount: number;
  setEmails: (prop: Array<string>) => void;
  emails: Array<string>;
  t: TFunction<'pages', 'endorsers_partners.view_company.invite_users_modal'>;
};

export const InviteColumns = ({
  emailCount,
  setEmails,
  emails,
  t,
}: ColumnsType): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['connectionType'],
    },
    rowProps,
    columns: () => [
      {
        title: t('title'),
        dataIndex: 'email',
        valueType: 'select',
        renderFormItem: () => (
          <EmailTagInput
            placeholder={t('email_placeholder')}
            setEmails={setEmails}
            emails={emails}
          />
        ),
      },
    ],
  },
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['email'],
    },
    columns: () => [
      {
        dataIndex: 'Value',
        valueType: 'text',
        renderFormItem: () =>
          emailCount === 0 ? null : (
            <div style={{ marginTop: '16px' }}>
              <TextArea
                className={styles.textinput}
                rows={7}
                placeholder={t('textarea_placeholder')}
              />
            </div>
          ),
        fieldProps: {
          placeholder: t('textarea_placeholder'),
        },
      },
    ],
  },
];
