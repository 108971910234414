/* eslint-disable no-unsafe-optional-chaining */
import { Divider, Space, Typography, message } from 'antd';
import GMap from 'components/GMap';
import { useEffect, useState, useMemo, useCallback } from 'react';
import useModalVisibility from 'hooks/useModalVisibility';
import { dateFormat } from 'utils/helpers';
import { Loading } from 'components';
import MarkerModal from './Modal';
import styles from './TraceMap.module.less';
import { TraceMapProps, MarkerItemPropsAPI } from './typings';

const TraceMap = ({
  selectedLot,
  setTraceTab,
  selectedMarker,
  setSelectedMarker,
  eventMapLocation,
  isEventMpaLocationLoading,
}: TraceMapProps) => {
  const markerModal = useModalVisibility(false);
  const [activeEvent, setActiveEvent] = useState(0);

  useEffect(() => {
    if (eventMapLocation?.nodes && eventMapLocation?.nodes?.length === 0) {
      message.info('No results with the selected filters');
    }
  }, [eventMapLocation?.nodes]);

  const mapNodes = useCallback(
    (el: any) => ({
      id: el.urn,
      position: {
        lat: Number(el.latitude) || 47.116386,
        lng: Number(el.longitude) || -101.299591,
      },
      title: el?.name,
      shortDesc: el?.name,
      longDesc: el?.streetAddress,
      label: String(el?.events?.length),
      events: el.events,
    }),
    [],
  );

  const apiMarkers: Array<MarkerItemPropsAPI> = useMemo(
    () => eventMapLocation?.nodes?.map(mapNodes),
    [eventMapLocation, mapNodes],
  );

  const renderInfoWindow = (activeMarkerValue: any) => (
    <>
      <Space direction="vertical" style={{ width: '250px' }}>
        <Typography.Text strong className={styles.infotitle}>
          {activeMarkerValue?.title || ''}
        </Typography.Text>
        <Typography.Text className={styles.infosubtitle}>
          {activeMarkerValue?.shortDesc || ''}
        </Typography.Text>
        <Typography.Text className={styles.infodesc}>
          {activeMarkerValue?.longDesc || ''}
        </Typography.Text>
      </Space>
      <Divider className={styles.headerdivider} />
      <Space direction="vertical" className={styles.eventscontainer}>
        {activeMarkerValue?.events?.map((event: any, index: number) => (
          <Space
            direction="vertical"
            onClick={() => {
              setActiveEvent(index);
              markerModal.show();
            }}
            className={styles.event}
          >
            <div className={styles.eventtitle}>
              <Typography.Text className={styles.infosubtitle}>{event?.eventType}</Typography.Text>
              <Typography.Text className={styles.infodatebold}>
                {dateFormat(event?.eventTime)}
              </Typography.Text>
            </div>
            <Typography.Text ellipsis className={styles.infodesc}>{`${'Lot'}: ${
              event?.products?.[event?.products?.length - 1].lotId
            }`}</Typography.Text>
            <Typography.Text className={styles.infodesc}>{`${
              event?.products?.[event?.products?.length - 1].quantity
            } ${event?.products?.[event?.products?.length - 1].uom}`}</Typography.Text>
          </Space>
        ))}
      </Space>
    </>
  );

  useEffect(() => {
    if (selectedLot.length === 0) {
      setTraceTab('list');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLot]);

  return (
    <>
      {markerModal.visible && (
        <MarkerModal
          selectedMarker={selectedMarker}
          modal={markerModal}
          activeEvent={activeEvent}
        />
      )}
      {isEventMpaLocationLoading ? (
        <Loading />
      ) : (
        <GMap
          markers={apiMarkers}
          edges={eventMapLocation?.edges}
          renderInfoWindow={renderInfoWindow}
          containerStyle={{ marginTop: '10px' }}
          setSelectedMarker={setSelectedMarker}
          isRestrict
          allowFitBounds
        />
      )}
    </>
  );
};

export default TraceMap;
