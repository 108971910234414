import { SearchOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-table';
import type { InputRef } from 'antd';
import { Button, Input, Space } from 'antd';
import { useCallback, useMemo, useRef } from 'react';
import { FilterDropdownTypes } from '../typings';
import styles from './index.module.less';
import { TableSearchFilterProps, useTableSearchFilterOutput } from './typings';

const useTableSearchFilter = ({
  title = '',
  onSearch,
  onReset,
  bapNumber = '',
}: TableSearchFilterProps): useTableSearchFilterOutput => {
  const inputRef = useRef<InputRef>(null);
  const handleSearch = useCallback(
    (confirm: FilterDropdownTypes['confirm']) => {
      confirm();
      const searchValue = inputRef?.current?.input?.value || bapNumber;
      if (onSearch) {
        onSearch(searchValue);
      }
    },
    [onSearch, bapNumber],
  );
  const handleReset = useCallback(
    (
      clearFilters: FilterDropdownTypes['clearFilters'],
      setSelectedKeys: FilterDropdownTypes['setSelectedKeys'],
    ) => {
      clearFilters({ confirm: true });
      setSelectedKeys([]);
      if (onReset) {
        onReset();
      }
      onSearch?.('');
    },
    [onReset, onSearch],
  );
  const filterIcon = useCallback(
    (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#0a5f7a' : undefined }} />,
    [],
  );

  const onFilterDropdownVisibleChange = useCallback(
    (visible: boolean) => {
      if (visible) {
        setTimeout(() => inputRef?.current?.focus(), 100);
      }
    },
    [inputRef],
  );

  const filters: ProColumns = useMemo(
    () => ({
      onFilterDropdownVisibleChange,
      filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
        <div className={styles.filterdropdown}>
          <Input
            ref={inputRef}
            value={selectedKeys[0]}
            className={styles.filterinput}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            placeholder={`Search ${title}`}
            onPressEnter={() => handleSearch(confirm)}
          />
          <Space>
            <Button
              type="primary"
              className={styles.filterbtn}
              onClick={() => handleSearch(confirm)}
              icon={<SearchOutlined />}
              size="small"
              shape="round"
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, setSelectedKeys)}
              size="small"
              shape="round"
              style={{ width: '90px', color: 'rgb(10 95 122)', border: '1px solid rgb(10 95 122)' }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon,
    }),
    [handleSearch, handleReset, title, filterIcon, onFilterDropdownVisibleChange],
  );

  return {
    filters,
  };
};
export default useTableSearchFilter;
