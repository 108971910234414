/* eslint-disable no-nested-ternary */
import { ProColumns } from '@ant-design/pro-table';
import { Empty, Rate, Tag, Tooltip, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import EndorserActions from 'components/Actions/EndorserActions';
import GTable from 'components/GTable';
import { useGTableContext } from 'components/GTable/contexts';
import EndorserTitlebar from 'components/TitleBar/EndorserTitlebar';
import useModalVisibility from 'hooks/useModalVisibility';
import pluralize from 'pluralize';
import { FC, useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useUserInfo } from 'services/api/useSettings';
import { yearFirstFormat } from 'utils/helpers';
import GrantAccess, { DownloadReportModal, InviteForm } from './Modal';
import useEndorsersListFilters from './hooks';
import styles from './index.module.less';
import {
  ActionMenu,
  EndorsersTableTitleProps,
  FilterArrayProp,
  UpdatedEndorserDTO,
} from './typings';

type ActionItemsProps = {
  t: TFunction<'pages', 'endorsers_partners'>;
  record: UpdatedEndorserDTO;
  loggedInUserData: any;
};

const mainActionItems = ({ selectedRowKeys, t }: ActionMenu): Array<ItemType> => [
  {
    key: 'grantAccess',
    label: t('main_actions.grant_access'),
    disabled: !selectedRowKeys.length,
  },
];

const productActionItems = ({ t, record, loggedInUserData }: ActionItemsProps): Array<ItemType> => [
  {
    key: 'viewCompany',
    label: t('item_actions.view_company'),
  },
  loggedInUserData?.user?.role?.permission?.includes('GRANT_ACCESS') &&
  record?.endorserType === 'ALONE'
    ? {
        key: 'grantAccess',
        label: t('item_actions.grant_access'),
      }
    : null,
  loggedInUserData?.user?.role?.permission?.includes('USER_ADMINISTRATION') &&
  record?.endorserType === 'ALONE'
    ? {
        key: 'inviteUsers',
        label: t('item_actions.invite_users'),
      }
    : null,
  record?.bap
    ? loggedInUserData?.user?.role?.permission?.includes('DOWNLOADING_AUDIT_REPORTS')
      ? {
          key: 'downloadAuditReport',
          label: t('item_actions.download_first_page_report'),
        }
      : null
    : null,
];

type ColumnsType = {
  producerSearchFilter: ProColumns;
  bapNumberSearchFilter: ProColumns;
  onRowActionClick: (actionItemKey: string, actionPayload?: UpdatedEndorserDTO) => void;
  t: TFunction<'pages', 'endorsers_partners'>;
  speciesListFilter: Array<FilterArrayProp>;
  countriesListFilter: Array<FilterArrayProp>;
  loggedInUserData: any;
};

const columns = ({
  producerSearchFilter,
  bapNumberSearchFilter,
  onRowActionClick,
  t,
  speciesListFilter,
  countriesListFilter,
  loggedInUserData,
}: ColumnsType): Array<ProColumns<UpdatedEndorserDTO>> => [
  {
    title: t('endorsers_partners_table.producer'),
    dataIndex: 'producer',
    ellipsis: true,
    ...producerSearchFilter,
    render: (text, record) => (
      <Link className="text-link" to={String(record.accountID)} title={`View ${text}`}>
        {text}
      </Link>
    ),
    width: 270,
  },
  {
    title: t('endorsers_partners_table.species'),
    dataIndex: 'species',
    width: 250,
    filters: speciesListFilter,
    render: (text, records) =>
      records?.id && records?.bap ? (
        <Tooltip
          placement="bottom"
          title={
            records?.species?.length === 0 ? (
              <div>No Species.</div>
            ) : (
              <div>
                {records?.species?.map((item) => (
                  <div>{item?.category}</div>
                ))}
              </div>
            )
          }
        >
          <Tag
            style={{
              borderRadius: '15px',
              fontWeight: '500',
              color: '#0A5F7A',
              border: '1.3px solid #0A5F7A',
              backgroundColor: '#E7F0F2',
            }}
          >
            {records?.species?.length} Species
          </Tag>
        </Tooltip>
      ) : !records?.id ? (
        <div>
          {records?.species?.[0]?.category}{' '}
          <Rate
            disabled
            count={records?.species?.[0]?.stars}
            defaultValue={records?.species?.[0]?.stars}
            style={{ fontSize: '16px' }}
          />
        </div>
      ) : null,
  },
  {
    title: t('endorsers_partners_table.producer_type'),
    dataIndex: 'producerType',
    ellipsis: true,
    filters: [
      {
        text: 'Farm',
        value: 'Farm',
      },
      {
        text: 'Hatchery',
        value: 'Hatchery',
      },
      {
        text: 'Feed Mill',
        value: 'Feed Mill',
      },
      {
        text: 'Supplier',
        value: 'Supplier (manf, importer)',
      },
      {
        text: 'Retail',
        value: 'Retail (supermarket, FDM, e-commerce, specialty)',
      },
      {
        text: 'Corporation',
        value: 'Corporation',
      },
      {
        text: 'Foodservice',
        value: 'Foodservice (dist, merch, rest, hotel, entertnmt, health, airline)',
      },
      {
        text: 'Processor',
        value: 'Processor',
      },
      {
        text: 'Re-processor',
        value: 'Re-processor',
      },
    ],
  },
  {
    title: t('endorsers_partners_table.country'),
    dataIndex: 'country',
    ellipsis: true,
    filters: countriesListFilter,
  },
  {
    title: t('endorsers_partners_table.bap_number'),
    dataIndex: 'bap',
    ellipsis: true,
    ...bapNumberSearchFilter,
  },
  {
    title: t('endorsers_partners_table.expiration'),
    dataIndex: 'expiration',
    ellipsis: true,
    render: (text, record) => (record?.id ? yearFirstFormat(String(record?.expiration)) : null),
    sorter: true,
  },
  {
    title: t('endorsers_partners_table.esa'),
    dataIndex: 'esa',
    ellipsis: true,
    filters: [
      {
        text: 'Yes',
        value: true,
      },
      {
        text: 'No',
        value: false,
      },
    ],
  },
  {
    title: t('endorsers_partners_table.annoucne_type'),
    dataIndex: 'announceType',
    ellipsis: true,
    filters: [
      {
        text: 'Announced',
        value: 'Announced',
      },
      {
        text: 'Unannounced',
        value: 'Unannounced',
      },
    ],
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    render: (text, record) =>
      record?.id ? (
        <EndorserActions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
          }}
          size="small"
          items={productActionItems({ t, record, loggedInUserData })}
          onClick={onRowActionClick}
          actionButtonColor={{
            color: '#0a5f7a',
            borderColor: '#0a5f7a',
            background: 'transparent',
            textShadow: '0px 0px',
            boxShadow: '0px 0px',
          }}
          actionPayload={record}
        />
      ) : null,
    fixed: 'right',
    width: 120,
  },
];

const TableTitle = ({
  grantAccessModal,
  setSelectedRow,
  loggedInUserData,
  setSelectedMainRowData,
}: EndorsersTableTitleProps) => {
  const { selectedRowKeys } = useGTableContext();
  const { t } = useTranslation('pages', { keyPrefix: 'endorsers_partners' });

  useEffect(() => {
    setSelectedRow(
      selectedRowKeys.length === 1
        ? String(selectedRowKeys[0])
        : String(`${selectedRowKeys.length}-`),
    );
    setSelectedMainRowData(selectedRowKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowKeys]);

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'grantAccess':
        grantAccessModal.show();
        break;

      default:
        break;
    }
  };

  return (
    <EndorserTitlebar
      title={t('endorsers_partners_table.title')}
      extra={
        selectedRowKeys.length > 0 && (
          <Typography.Text className="selectiontext">
            {`${selectedRowKeys.length} ${pluralize('Producer', selectedRowKeys.length)} Selected`}
          </Typography.Text>
        )
      }
      actionItems={
        loggedInUserData?.user?.role?.permission?.includes('GRANT_ACCESS')
          ? mainActionItems({ selectedRowKeys, t })
          : undefined
      }
      onActionItemClick={onActionItemClick}
      fontSize
      className={styles.endorsers}
    />
  );
};

const Endorsers: FC = () => {
  const { selectedRowKeys, onRowSelectionChange } = useGTableContext();
  const navigate = useNavigate();
  const grantAccessModal = useModalVisibility(false);
  const inviteModal = useModalVisibility(false);
  const downloadModal = useModalVisibility(false);
  const { data: loggedInUserData } = useUserInfo();
  const [selectedBap, setSelectedBap] = useState('F12523');
  const [companyName, setCompanyName] = useState('');
  const [selectedRowData, setSelectedRowData] = useState<UpdatedEndorserDTO>();
  const [grantAccessCompanyName, setGrantAccessCompanyName] = useState('');
  const [selectedRow, setSelectedRow] = useState('');
  const [selectedMainRowData, setSelectedMainRowData] = useState<any>([]);
  const { t } = useTranslation('pages', { keyPrefix: 'endorsers_partners' });

  // Endorsers List
  const {
    endorsersList,
    isEndorsersListLoading,
    endorsersListParams,
    totalItems,
    onEndorsersListTableChange,
    filters: [producerSearchFilter, bapNumberSearchFilter],
    speciesListResponse,
    countriesListResponse,
    selectedFilter,
  } = useEndorsersListFilters();

  // species list response
  const speciesListFilter: Array<FilterArrayProp> = useMemo(
    () =>
      speciesListResponse?.species?.map((item: string) => ({
        text: item || '',
        value: item || '',
      })) || [],
    [speciesListResponse],
  );

  // countries list response
  const countriesListFilter: Array<FilterArrayProp> = useMemo(
    () =>
      countriesListResponse?.countries?.map((item: string) => ({
        text: item || '',
        value: item || '',
      })) || [],
    [countriesListResponse],
  );

  const ecosystem: Array<UpdatedEndorserDTO> = endorsersList.map((item) => ({
    ...item,
  }));

  const onRowActionClick = (actionItemKey: string, actionPayload?: UpdatedEndorserDTO) => {
    switch (actionItemKey) {
      case 'viewCompany':
        navigate(`${actionPayload?.accountID}`);
        break;
      case 'grantAccess':
        setGrantAccessCompanyName(actionPayload?.producer || '');
        setSelectedMainRowData([actionPayload?.accountID]);
        grantAccessModal.show();
        break;
      case 'inviteUsers':
        setCompanyName(actionPayload?.producer || '');
        setSelectedRowData(actionPayload);
        inviteModal.show();
        break;
      case 'downloadAuditReport':
        setSelectedBap(actionPayload?.bap || '');
        downloadModal.show();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedRow.charAt(selectedRow.length - 1) === '-') {
      setGrantAccessCompanyName(`${selectedRow.replace('-', '')} Producers`);
    } else if (selectedRow !== '') {
      const payload = ecosystem.find((key) => String(key.id) === String(selectedRow));
      setGrantAccessCompanyName(String(payload?.producer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  return (
    <>
      {grantAccessModal.visible && (
        <GrantAccess
          modal={grantAccessModal}
          grantAccessCompanyName={grantAccessCompanyName}
          selectedMainRowData={selectedMainRowData}
        />
      )}
      {inviteModal.visible && (
        <InviteForm
          modal={inviteModal}
          companyName={companyName}
          selectedRowData={selectedRowData}
        />
      )}
      {downloadModal.visible && (
        <DownloadReportModal modal={downloadModal} selectedBap={selectedBap} />
      )}
      <div className={styles.container}>
        <GTable<UpdatedEndorserDTO>
          columns={columns({
            producerSearchFilter,
            bapNumberSearchFilter,
            onRowActionClick,
            t,
            speciesListFilter,
            countriesListFilter,
            loggedInUserData,
          })}
          value={ecosystem}
          headerTitle={
            <TableTitle
              grantAccessModal={grantAccessModal}
              setSelectedRow={setSelectedRow}
              loggedInUserData={loggedInUserData}
              setSelectedMainRowData={setSelectedMainRowData}
            />
          }
          enableRowSelection
          options={{
            setting: false,
            reload: false,
          }}
          loading={isEndorsersListLoading}
          onTableChange={onEndorsersListTableChange}
          pagination={{
            defaultPageSize: endorsersListParams?.pageSize,
            total: totalItems,
            current: endorsersListParams?.pageNumber,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Producers`,
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: onRowSelectionChange,
            columnWidth: 30,
            getCheckboxProps: (record) => ({
              disabled: !record.id,
            }),
          }}
          scroll={{ x: 1800 }}
          noDataContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              className={styles.sktcontainer}
              description={
                <span>No Result{`${selectedFilter}`}. Please search for something else</span>
              }
            />
          }
        />
      </div>
    </>
  );
};

export default Endorsers;
