import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import type { InputRef } from 'antd';
import { AutoComplete, Input, Space, Typography } from 'antd';
import React, { useRef, useState, ChangeEvent } from 'react';
import { useFullSearch } from 'services/api/useDashboard';
import { useTraceStore } from 'pages/Trace/hooks';

import classNames from 'classnames';
import styles from './index.less';

export type HeaderSearchProps = {
  className: string;
  placeholder: string;
};

const HeaderSearch: React.FC<HeaderSearchProps> = ({ className, placeholder }) => {
  const inputRef = useRef<InputRef | null>(null);
  const search = useTraceStore((state) => state.search);
  const setSearch = useTraceStore((state) => state.setSearch);
  const navigate = useNavigate();
  const [params, setParams] = useState({
    term: '',
    pageNumber: 1,
    pageSize: 50,
  });

  const { data: searchList } = useFullSearch(params);

  const options: Array<any> =
    searchList?.results
      ?.filter((i) => i.resultType === 'ENDORSER')
      ?.map((item, index) => ({
        label: (
          <Space size="small">
            <Typography.Text>{item?.endorserName || ''}</Typography.Text>
            <Typography.Text type="secondary">{`(${item?.partnerType})`}</Typography.Text>
          </Space>
        ),
        value: String(index),
        itemProps: item,
      })) || [];
  // searchList?.results?.map((item, index) =>
  //   item.resultType === 'ENDORSER'
  //     ? {
  //         label: (
  //           <Space size="small">
  //             <Typography.Text>{item?.endorserName || ''}</Typography.Text>
  //             <Typography.Text type="secondary">(Endorser)</Typography.Text>
  //           </Space>
  //         ),
  //         value: String(index),
  //         itemProps: item,
  //       }
  //     : item.resultType === 'PRODUCT'
  //     ? {
  //         label: (
  //           <Space>
  //             <Typography.Text>{item?.productDescription || ''}</Typography.Text>
  //             <Typography.Text type="secondary">(Product)</Typography.Text>
  //           </Space>
  //         ),
  //         value: String(index),
  //         itemProps: item,
  //       }
  //     : item.resultType === 'LOT'
  //     ? {
  //         label: (
  //           <Space>
  //             <Typography.Text>{item?.lotId || ''}</Typography.Text>
  //             <Typography.Text type="secondary">(Lot)</Typography.Text>
  //           </Space>
  //         ),
  //         value: String(index),
  //         itemProps: item,
  //       }
  //     : item.resultType === 'COUNTRY'
  //     ? {
  //         label: (
  //           <Space>
  //             <Typography.Text>{item?.country || ''}</Typography.Text>
  //             <Typography.Text type="secondary">(Country)</Typography.Text>
  //           </Space>
  //         ),
  //         value: String(index),
  //         itemProps: item,
  //       }
  //     : {
  //         label: (
  //           <Space>
  //             <Typography.Text>{item?.species || ''}</Typography.Text>
  //             <Typography.Text type="secondary">(Species)</Typography.Text>
  //           </Space>
  //         ),
  //         value: String(index),
  //         itemProps: item,
  //       },
  // ) || [];

  const [value, setValue] = useState('');
  const [searchMode, setSearchMode] = useState(false);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames(className, styles.headerSearch)}
      onClick={() => {
        setSearchMode(true);
        if (!searchMode && inputRef.current) {
          inputRef.current.focus();
        }
      }}
      onTransitionEnd={({ propertyName }) => {
        if (propertyName === 'width' && !searchMode) {
          setSearchMode(false);
        }
      }}
    >
      <SearchOutlined
        key="Icon"
        style={{
          cursor: 'pointer',
        }}
      />
      <AutoComplete
        key="AutoComplete"
        style={
          !searchMode
            ? {
                width: '0',
                minWidth: '0',
                overflow: 'hidden',
                background: 'transparent',
                borderRadius: '2',
                transition: 'width 0.3s, marginLeft 0.3s',
                marginRight: '0px',
              }
            : {
                width: '700px',
                marginLeft: '8px',
                transition: 'width 0.3s, marginLeft 0.3s',
              }
        }
        value={value}
        options={options}
        onChange={(completeValue, newValue) => {
          if (newValue?.itemProps?.resultType === 'ENDORSER') {
            setValue(newValue?.itemProps?.endorserName);
          } else if (newValue?.itemProps?.resultType === 'PRODUCT') {
            setValue(newValue?.itemProps?.productDescription);
          } else if (newValue?.itemProps?.resultType === 'LOT') {
            setValue(newValue?.itemProps?.lotId);
          } else if (newValue?.itemProps?.resultType === 'COUNTRY') {
            setValue(newValue?.itemProps?.country);
          } else if (newValue?.itemProps?.resultType === 'SPECIES') {
            setValue(newValue?.itemProps?.species);
          }
        }}
        onSelect={(completeValue: any, newValue: any) => {
          if (newValue?.itemProps?.resultType === 'ENDORSER') {
            navigate(`/discover/${newValue?.itemProps?.endorserId}`);
          } else if (newValue?.itemProps?.resultType === 'PRODUCT') {
            navigate('/trace');
          } else if (newValue?.itemProps?.resultType === 'LOT') {
            setSearch({
              ...search,
              lot: [newValue?.itemProps?.lotURN],
            });
            navigate('/trace');
          } else if (newValue?.itemProps?.resultType === 'COUNTRY') {
            setSearch({
              ...search,
              country: [newValue?.itemProps?.country],
            });
            navigate('/trace');
          } else if (newValue?.itemProps?.resultType === 'SPECIES') {
            setSearch({
              ...search,
              species: [newValue?.itemProps?.species],
            });
            navigate('/trace');
          }
        }}
      >
        <Input
          size="small"
          ref={inputRef}
          aria-label={placeholder}
          placeholder={placeholder}
          // onKeyDown={(e) => {
          //   if (e.key === 'Enter') {
          //     console.log(value, 'selected value');
          //     setParams((oldParams) => ({
          //       ...oldParams,
          //       term: value,
          //     }));
          //   }
          // }}
          onBlur={() => {
            setSearchMode(false);
            setValue('');
            setParams((oldParams) => ({
              ...oldParams,
              term: '',
            }));
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
            setParams((oldParams) => ({
              ...oldParams,
              term: e.target.value,
            }));
          }}
        />
      </AutoComplete>
    </div>
  );
};

export default HeaderSearch;
