import { Select, Typography } from 'antd';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { useState, useEffect } from 'react';
import { useSpeciesList } from 'services/api/useTrace';
import { SpeciesSelectProps } from './typings';
import styles from './index.module.less';

const SpeciesSelect = ({ search, setSearch, style }: SpeciesSelectProps) => {
  const [speciesList, setSpeciesList] = useState<any>();
  const { data: speciesListResponse, isLoading } = useSpeciesList();

  // we create here the list with the shape the select is expecting for
  const selectOptions: Array<DefaultOptionType> =
    speciesListResponse?.species?.map((item) => ({
      label: String(item),
      value: String(item),
    })) || [];

  useEffect(() => {
    if (search?.species?.length === 0) {
      setSpeciesList(undefined);
    }
  }, [search]);

  const onSpeciesChange = (e: any, record: any) => {
    setSpeciesList(e);
    if (record.length === 0) {
      setSearch({ ...search, species: [] });
    } else {
      setSearch({ ...search, species: record.map((item: any) => item.label) });
    }
    setSearch({ ...search, species: record.map((item: any) => item.label) });
  };

  const onClearButtonClick = () => {
    setSearch({ ...search, species: [] });
  };

  return (
    <Select
      key="speciesselect"
      style={style}
      maxTagCount="responsive"
      mode="multiple"
      size="middle"
      loading={isLoading}
      options={selectOptions}
      className={styles.container}
      placeholder={
        <Typography.Text style={{ opacity: '0.3', fontWeight: '400' }}>Species</Typography.Text>
      }
      allowClear
      showSearch
      value={speciesList}
      filterOption={(input, option) =>
        (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      onChange={onSpeciesChange}
      onClear={onClearButtonClick}
    />
  );
};

export default SpeciesSelect;
