import { ArrowLeftOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { message, Space, Typography } from 'antd';
import { FC, ReactElement, useCallback, useRef, useState } from 'react';
import { useInviteUsers, useUserInfo } from 'services/api/useSettings';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isInvalidEmail } from 'utils/helpers';
import errorHandler from 'utils/errorHandler';
import styles from '../index.module.less';
import { DataItem, InviteColumns } from './Invite.fields';
import { InviteUserProps } from './typings';

const invalidEmailInList = (emails: Array<string>) => {
  if (emails.length !== 0) {
    const flags = emails.map((item) => {
      if (isInvalidEmail(String(item))) {
        return true;
      }
      return false;
    });
    return flags.includes(true);
  }
  return false;
};

const InviteForm: FC<InviteUserProps> = ({ modal, companyName }) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.invite_users_modal',
  });
  const { accountID } = useParams();
  const modalFormRef = useRef<ProFormInstance>();
  const [emails, setEmails] = useState<Array<string>>([]);
  const { data: loggedInUserData } = useUserInfo();
  const queryClient = useQueryClient();
  const inviteUser = useInviteUsers(queryClient);

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    setEmails([]);
    modal.hide();
  }, [modal]);

  const Title: ReactElement = (
    <Space>
      {emails.length === 0 ? null : (
        <ArrowLeftOutlined
          className={styles.arrow}
          onClick={() => {
            setEmails([]);
          }}
        />
      )}
      <Typography.Title level={4} className="mr0">
        {t('invite_users_heading', { companyName })}
      </Typography.Title>
    </Space>
  );

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      // @ts-ignore
      title={Title}
      formRef={modalFormRef}
      columns={InviteColumns({
        emailCount: emails.length,
        setEmails,
        emails,
        t,
      })}
      grid
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        width: '40%',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: emails.length === 0 ? t('done') : t('send'),
        },
        submitButtonProps: {
          shape: 'round',
          style: {
            color: '#0a5f7a',
            background: '#bcd530',
            border: '1px solid #bcd530',
          },
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
        render: (_, defaultDoms) => (emails.length === 0 ? defaultDoms[1] : defaultDoms),
      }}
      initialValues={{
        connectionType: 'Buyer',
      }}
      submitTimeout={2000}
      onFinish={async (formData: any) => {
        if (invalidEmailInList(emails)) {
          message.error(t('invalid_email_message'));
        } else if (emails.length === 0) {
          closeModal();
        } else {
          try {
            await inviteUser.mutateAsync({
              emails,
              accountId: accountID || loggedInUserData?.endorser?.accountID,
              roleId: formData?.role,
              message: formData?.Value,
            });
            message.success(t('invite_success_message', { companyName }));
            closeModal();
          } catch (error) {
            message.error(errorHandler(error));
          }
        }
      }}
    />
  );
};

export default InviteForm;
