import { FC } from 'react';
import { ProDescriptions } from '@ant-design/pro-components';
import { ProColumns } from '@ant-design/pro-table';
import { Anchor, Typography, BackTop } from 'antd';
import GTable from 'components/GTable';
import styles from '../index.module.less';
import {
  GroupMemberTableProps,
  GroupNonConformanceTotalProps,
  GroupSummaryProps,
  OnsiteNonConformanceTotalProps,
} from '../typings';

const groupMemberDetailsColumn = (): Array<ProColumns<GroupMemberTableProps>> => [
  {
    title: 'Group Member BAP #',
    dataIndex: 'groupBapNumber',
  },
  {
    title: 'Facility Name',
    dataIndex: 'facilityName',
  },
  {
    title: 'Latitude',
    dataIndex: 'latitude',
  },
  {
    title: 'Longitude',
    dataIndex: 'longitude',
  },
  {
    title: 'Audit Type',
    dataIndex: 'auditType',
  },
  {
    title: 'Onsite housing provided',
    dataIndex: 'onsiteHousing',
  },
  {
    title: 'Species Scientific Name',
    dataIndex: 'speciesScientificName',
    render: (text, records) => (
      <div>
        {records?.speciesScientificName?.map((item) => (
          <div>{item?.speciesNames}</div>
        ))}
      </div>
    ),
  },
  {
    title: 'Total Production',
    dataIndex: 'totalProduction',
    render: (text, records) => (
      <div>
        {records?.totalProduction?.map((item) => (
          <div>{item?.productionValue}</div>
        ))}
      </div>
    ),
  },
];

const groupNonConformanceTotal = (): Array<ProColumns<GroupNonConformanceTotalProps>> => [
  {
    title: 'Phase',
    dataIndex: 'phase',
  },
  {
    title: 'No - Critical',
    dataIndex: 'noCritical',
  },
  {
    title: 'No - Major',
    dataIndex: 'noMajor',
  },
  {
    title: 'No - Minor',
    dataIndex: 'noMinor',
  },
  {
    title: 'Yes',
    dataIndex: 'yes',
  },
  {
    title: 'N/A',
    dataIndex: 'nothing',
  },
];

const onsiteNonConformanceTotal = (): Array<ProColumns<OnsiteNonConformanceTotalProps>> => [
  {
    title: 'Group Member BAP #',
    dataIndex: 'groupBapNumber',
  },
  {
    title: 'No - Critical',
    dataIndex: 'noCritical',
  },
  {
    title: 'No - Major',
    dataIndex: 'noMajor',
  },
  {
    title: 'No - Minor',
    dataIndex: 'noMinor',
  },
  {
    title: 'Yes',
    dataIndex: 'yes',
  },
  {
    title: 'N/A',
    dataIndex: 'nothing',
  },
];

const { Link } = Anchor;

const GroupSummary: FC<GroupSummaryProps> = ({
  auditReportByIdResponse,
  auditKPI,
  t,
  groupNonConformanceTotalDetails,
  onsiteNonConformanceTotalDetails,
  groupMemberDetails,
}) => (
  <div className={styles.summarycontainer}>
    <div className={styles.summary}>
      <div id="first">
        <ProDescriptions
          title={<Typography.Title level={4}>{t('basic_facility_title')}</Typography.Title>}
          layout="vertical"
          column={{ xxl: 4, xl: 4, lg: 3, md: 2, sm: 2, xs: 1 }}
          colon={false}
          dataSource={{
            facilityCertNumber: auditReportByIdResponse?.auditReport?.bapNumber,
            facilityName: auditKPI?.orgName,
            certificationBody: auditKPI?.cbName,
            auditor: auditKPI?.auditorName,
            address: auditKPI?.address,
            startDate: auditKPI?.auditStartDate,
            endDate: auditKPI?.auditEndDate,
            remoteAudit: auditKPI?.remoteDone ? 'Yes' : 'No',
            groupType: auditKPI?.accountType,
            groupEmail: auditKPI?.email,
            contactName: auditKPI?.auditContactName,
            contactEmail: auditKPI?.auditContactEmail,
            contactPhone: auditKPI?.auditContactPhone,
            groupManagerName: auditReportByIdResponse?.auditReport?.groupManager,
            stdIssue: auditReportByIdResponse?.auditReport?.issueName,
            totalEmployee: auditKPI?.totalEmployees,
          }}
          columns={[
            {
              title: <Typography.Text strong>{t('group_cert_name')}</Typography.Text>,
              key: 'text',
              dataIndex: 'facilityCertNumber',
            },
            {
              title: <Typography.Text strong>{t('group_name')}</Typography.Text>,
              key: 'facilityName',
              dataIndex: 'facilityName',
            },
            {
              title: <Typography.Text strong>{t('name_of_certification_body')}</Typography.Text>,
              key: 'certificationBody',
              dataIndex: 'certificationBody',
            },
            {
              title: <Typography.Text strong>{t('auditor')}</Typography.Text>,
              key: 'auditor',
              dataIndex: 'auditor',
            },
            {
              title: <Typography.Text strong>{t('cmo_address')}</Typography.Text>,
              key: 'address',
              dataIndex: 'address',
              span: 2,
            },
            {
              title: <Typography.Text strong>{t('audit_start_date')}</Typography.Text>,
              key: 'startDate',
              dataIndex: 'startDate',
              valueType: 'date',
              fieldProps: {
                format: 'YYYY-MM-DD',
              },
            },
            {
              title: <Typography.Text strong>{t('audit_end_date')}</Typography.Text>,
              key: 'endDate',
              dataIndex: 'endDate',
              valueType: 'date',
              fieldProps: {
                format: 'YYYY-MM-DD',
              },
            },
            {
              title: <Typography.Text strong>{t('remote_audit')}</Typography.Text>,
              key: 'remoteAudit',
              dataIndex: 'remoteAudit',
            },
            {
              title: <Typography.Text strong>{t('group_type')}</Typography.Text>,
              key: 'groupType',
              dataIndex: 'groupType',
            },
            {
              title: <Typography.Text strong>{t('group_email')}</Typography.Text>,
              key: 'groupEmail',
              dataIndex: 'groupEmail',
            },
            {
              title: <Typography.Text strong>{t('audit_contact_name')}</Typography.Text>,
              key: 'contactName',
              dataIndex: 'contactName',
            },
            {
              title: <Typography.Text strong>{t('audit_contact_email')}</Typography.Text>,
              key: 'contactEmail',
              dataIndex: 'contactEmail',
            },
            {
              title: <Typography.Text strong>{t('audit_contact_phone')}</Typography.Text>,
              key: 'contactPhone',
              dataIndex: 'contactPhone',
            },
            {
              title: <Typography.Text strong>{t('group_manager_name')}</Typography.Text>,
              key: 'groupManagerName',
              dataIndex: 'groupManagerName',
            },
            {
              title: <Typography.Text strong>{t('std_issue')}</Typography.Text>,
              key: 'stdIssue',
              dataIndex: 'stdIssue',
            },
            {
              title: <Typography.Text strong>{t('total_employees')}</Typography.Text>,
              key: 'totalEmployee',
              dataIndex: 'totalEmployee',
            },
          ]}
        />
      </div>
      <div id="second" style={{ marginTop: '64px' }}>
        <ProDescriptions
          title={<Typography.Title level={4}>{t('audit_details')}</Typography.Title>}
          layout="vertical"
          column={{ xxl: 4, xl: 4, lg: 3, md: 2, sm: 2, xs: 1 }}
          colon={false}
          dataSource={{
            auditType: auditKPI?.auditType,
            auditDuration: auditKPI?.auditDurationHour,
            secondaryAuditor: auditKPI?.assigned2NdAuditor,
            auditShadower: auditKPI?.shadower,
            auditWitness: auditKPI?.witness,
            socialAccountability: auditKPI?.socialAccountabilityAuditor,
            auditObserver: auditKPI?.observer,
            auditNotes: auditKPI?.auditNotes,
            siteVisitNotes: auditKPI?.siteVisitNotes,
            previousAuditDate: auditKPI?.lastAuditDate,
            distance: auditReportByIdResponse?.auditReport?.distanceFromFarthestFacility,
            ppm: auditReportByIdResponse?.auditReport?.primaryProductionMethod,
            cageType: auditReportByIdResponse?.auditReport?.cageTypes,
            nonConformanceTotals: (
              <div style={{ width: '50%' }}>
                <GTable<GroupNonConformanceTotalProps>
                  columns={groupNonConformanceTotal()}
                  value={groupNonConformanceTotalDetails}
                  options={{
                    setting: false,
                    reload: false,
                  }}
                  recordCreatorProps={false}
                  scroll={{ x: 600 }}
                  bordered
                />
              </div>
            ),
            onsiteConformanceTotals: (
              <div style={{ width: '50%' }}>
                <GTable<OnsiteNonConformanceTotalProps>
                  columns={onsiteNonConformanceTotal()}
                  value={onsiteNonConformanceTotalDetails}
                  options={{
                    setting: false,
                    reload: false,
                  }}
                  recordCreatorProps={false}
                  scroll={{ x: 600, y: 400 }}
                  bordered
                />
              </div>
            ),
          }}
          columns={[
            {
              title: <Typography.Text strong>{t('audit_type')}</Typography.Text>,
              key: 'auditType',
              dataIndex: 'auditType',
            },
            {
              title: <Typography.Text strong>{t('audit_duration')}</Typography.Text>,
              key: 'auditDuration',
              dataIndex: 'auditDuration',
            },
            {
              title: <Typography.Text strong>{t('previous_audit_date')}</Typography.Text>,
              key: 'previousAuditDate',
              dataIndex: 'previousAuditDate',
              valueType: 'date',
              fieldProps: {
                format: 'YYYY-MM-DD',
              },
              span: 2,
            },
            {
              title: <Typography.Text strong>{t('audit_notes')}</Typography.Text>,
              key: 'auditNotes',
              dataIndex: 'auditNotes',
              span: 4,
            },
            {
              title: <Typography.Text strong>{t('site_visit_notes')}</Typography.Text>,
              key: 'siteVisitNotes',
              dataIndex: 'siteVisitNotes',
              span: 4,
            },
            {
              title: <Typography.Text strong>{t('secondary_auditor')}</Typography.Text>,
              key: 'secondaryAuditor',
              dataIndex: 'secondaryAuditor',
            },
            {
              title: <Typography.Text strong>{t('audit_observer')}</Typography.Text>,
              key: 'auditObserver',
              dataIndex: 'auditObserver',
            },
            {
              title: <Typography.Text strong>{t('audit_witness')}</Typography.Text>,
              key: 'auditWitness',
              dataIndex: 'auditWitness',
            },
            {
              title: <Typography.Text strong>{t('audit_shadower')}</Typography.Text>,
              key: 'auditShadower',
              dataIndex: 'auditShadower',
            },
            {
              title: (
                <Typography.Text strong>{t('distance_between_farthest_facility')}</Typography.Text>
              ),
              key: 'distance',
              dataIndex: 'distance',
            },
            {
              title: <Typography.Text strong>{t('ppm')}</Typography.Text>,
              key: 'ppm',
              dataIndex: 'ppm',
            },
            {
              title: <Typography.Text strong>{t('cage_type')}</Typography.Text>,
              key: 'cageType',
              dataIndex: 'cageType',
              span: 3,
            },
            {
              title: <Typography.Text strong>{t('group_non_conference_totals')}</Typography.Text>,
              key: 'nonConformanceTotals',
              dataIndex: 'nonConformanceTotals',
              span: 4,
            },
            {
              title: <Typography.Text strong>{t('onsite_non_conference_totals')}</Typography.Text>,
              key: 'onsiteConformanceTotals',
              dataIndex: 'onsiteConformanceTotals',
              span: 4,
            },
          ]}
        />
      </div>
      <div id="third" style={{ marginTop: '64px' }}>
        <ProDescriptions
          title={<Typography.Title level={4}>{t('scope_of_audit')}</Typography.Title>}
          layout="vertical"
          column={4}
          colon={false}
        />
      </div>
      <div style={{ margin: '10px 0px' }}>
        <GTable<GroupMemberTableProps>
          columns={groupMemberDetailsColumn()}
          value={groupMemberDetails}
          options={{
            setting: false,
            reload: false,
          }}
          recordCreatorProps={false}
          scroll={{ x: 940, y: 400 }}
          bordered
        />
      </div>
      <BackTop />
    </div>
    <div className={styles.anchors}>
      <Anchor offsetTop={65}>
        <Link href="#first" title="Basic Facility and Audit Information" />
        <Link href="#second" title="Audit Details" />
        <Link href="#third" title="Scope of Audit" />
      </Anchor>
    </div>
  </div>
);

export default GroupSummary;
