import G6 from '@antv/g6';
import { fittingString, getNodeConfig } from './nodeMethods';

const NODE_TYPE = 'modelRect';

const createComplexNodes = (nodeBasicMethod: any) => {
  G6.registerNode(
    NODE_TYPE,
    {
      drawShape: function drawShape(cfg: any, group: any) {
        if (cfg) {
          const config = getNodeConfig(cfg);
          const isRoot = cfg.type === 'root';
          const data = cfg;
          const { nodeError } = data;
          /* Outermost large rectangle */
          const container = nodeBasicMethod.createNodeBox(group, config, 243, 64, isRoot);

          if (data.eventType) {
            /* Type above */
            group.addShape('text', {
              attrs: {
                text: data.eventType,
                x: 3,
                y: -10,
                fontSize: 14,
                textAlign: 'left',
                textBaseline: 'middle',
                fill: 'rgba(0,0,0,0.65)',
              },
              name: 'type-text-event',
            });
          }

          let ipWidth = 0;
          if (data.eventTime) {
            /* eventTime start */
            /* eventTimeBox */
            const ipRect = group.addShape('rect', {
              attrs: {
                fill: nodeError ? null : '#FFF',
                stroke: nodeError ? 'rgba(255,255,255,0.65)' : null,
                radius: 2,
                cursor: 'pointer',
              },
              name: 'ip-container-shape',
            });

            /* eventTime */
            const ipText = group.addShape('text', {
              attrs: {
                text: data.eventTime,
                x: 0,
                y: 19,
                fontSize: 12,
                textAlign: 'left',
                textBaseline: 'middle',
                fill: nodeError ? 'rgba(255,255,255,0.85)' : 'rgba(0,0,0,0.65)',
                cursor: 'pointer',
              },
              name: 'ip-text-shape',
            });

            const ipBBox = ipText.getBBox();
            /* ip text is always 12px from the right */
            ipText.attr({
              x: 224 - 12 - ipBBox.width,
            });
            /* ipBox */
            ipRect.attr({
              x: 224 - 12 - ipBBox.width - 4,
              y: ipBBox.minY - 5,
              width: ipBBox.width + 8,
              height: ipBBox.height + 10,
            });

            /* Overlay a layer of transparency on the IP element to facilitate listening for hover events */
            group.addShape('rect', {
              attrs: {
                stroke: '',
                cursor: 'pointer',
                x: 224 - 12 - ipBBox.width - 4,
                y: ipBBox.minY - 5,
                width: ipBBox.width + 8,
                height: ipBBox.height + 10,
                fill: '#fff',
                opacity: 0,
              },
              name: 'ip-box',
            });

            /* copyIpLine */
            group.addShape('rect', {
              attrs: {
                x: 194,
                y: 7,
                width: 1,
                height: 24,
                fill: '#E3E6E8',
                opacity: 0,
              },
              name: 'ip-cp-line',
            });
            /* copyIpBG */
            group.addShape('rect', {
              attrs: {
                x: 195,
                y: 8,
                width: 22,
                height: 22,
                fill: '#FFF',
                cursor: 'pointer',
                opacity: 0,
              },
              name: 'ip-cp-bg',
            });
            /* copyIpIcon */
            group.addShape('image', {
              attrs: {
                x: 200,
                y: 13,
                height: 12,
                width: 10,
                img: 'https://os.alipayobjects.com/rmsportal/DFhnQEhHyPjSGYW.png',
                cursor: 'pointer',
                opacity: 0,
              },
              name: 'ip-cp-icon',
            });
            /* Place a transparent rectangle on the icon area for easy click monitoring */
            group.addShape('rect', {
              attrs: {
                x: 195,
                y: 8,
                width: 22,
                height: 22,
                fill: '#FFF',
                cursor: 'pointer',
                opacity: 0,
              },
              name: 'ip-cp-box',
              tooltip: '复制IP',
            });

            const ipRectBBox = ipRect.getBBox();
            ipWidth = ipRectBBox.width;
            /* ip end */
          }

          /* name */
          group.addShape('text', {
            attrs: {
              /* Calculate the remaining length for name based on the length of the IP! */
              text: fittingString(
                data?.eventType.includes('Transform')
                  ? data.products?.filter((item: any) => item?.direction === 'OUTPUT')?.[0]
                      ?.shortDescription
                  : data.products?.[0]?.shortDescription,
                224 - ipWidth - 45,
                12,
              ), // data.products.name,
              x: 19,
              y: 19,
              fontSize: 14,
              fontWeight: 700,
              textAlign: 'left',
              textBaseline: 'middle',
              fill: config.fontColor,
              cursor: 'pointer',
            },
            name: 'name-text-shape',
          });

          /* Text below */
          group.addShape('text', {
            attrs: {
              text: fittingString(data.location?.name, 204, 12),
              x: 19,
              y: 45,
              fontSize: 14,
              textAlign: 'left',
              textBaseline: 'middle',
              fill: config.fontColor,
              cursor: 'pointer',
            },
            name: 'bottom-text-shape',
          });

          if (nodeError) {
            group.addShape('image', {
              attrs: {
                x: 191,
                y: 32,
                height: 35,
                width: 35,
                img: '/static/images/warning-circle.svg',
              },
              name: 'image-shape',
            });
          }

          const hasChildren = cfg.children && cfg.children.length > 0;
          if (hasChildren) {
            nodeBasicMethod.createNodeMarker(group, cfg.collapsed, 236, 32);
          }
          return container;
        }
        return nodeBasicMethod.createNodeBox(
          group,
          {
            basicColor: '#0A5F7A',
            fontColor: '#0A5F7A',
            borderColor: '#0A5F7A',
            bgColor: '#E7F0F2',
          },
          171,
          38,
          false,
        );
      },
      afterDraw: nodeBasicMethod.afterDraw,
      setState: nodeBasicMethod.setState,
    },
    'single-node',
  );
};

export default createComplexNodes;
