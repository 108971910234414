/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { ProDescriptions } from '@ant-design/pro-components';
import { PageHeader, Typography } from 'antd';
import { useRef } from 'react';
import { useUserInfo } from 'services/api/useSettings';
import styles from './index.module.less';

const AccountSettings = () => {
  const actionRef = useRef<any>();
  const { data: loggedInUserData } = useUserInfo();
  return (
    <>
      <PageHeader title="Account Settings" className={styles['page-header']} />
      <div>
        <ProDescriptions
          actionRef={actionRef}
          layout="vertical"
          column={5}
          colon={false}
          formProps={{
            onValuesChange: (e, f) => console.log(f, 'Values'),
          }}
          dataSource={{
            companyName: loggedInUserData?.endorser?.facility,
            country: loggedInUserData?.endorser?.country,
            primaryAddress: '-',
            expirationDate: loggedInUserData?.endorser?.expiration,
            bap: loggedInUserData?.endorser?.bapNumber,
          }}
          // editable={{
          //   type: 'single',
          //   deletePopconfirmMessage: 'Are you sure ?',
          //   onlyOneLineEditorAlertMessage: saveChangesFirst,
          //   onSave: () => {
          //     setCompanyName(tempData.companyName);
          //     setPrimaryAddress(tempData.primaryAddress);
          //     return Promise.resolve({ success: true });
          //   },
          // }}
          columns={[
            {
              title: <Typography.Text strong>Company Name</Typography.Text>,
              key: 'companyName',
              dataIndex: 'companyName',
              valueType: 'text',
            },
            {
              title: <Typography.Text strong>Primary Company Address</Typography.Text>,
              key: 'primaryAddress',
              dataIndex: 'primaryAddress',
              valueType: 'text',
            },
            {
              title: <Typography.Text strong>Country</Typography.Text>,
              key: 'country',
              dataIndex: 'country',
            },
            {
              title: <Typography.Text strong>Expiration Date</Typography.Text>,
              key: 'expirationDate',
              dataIndex: 'expirationDate',
              valueType: 'date',
            },
            {
              title: <Typography.Text strong>BAP Number</Typography.Text>,
              key: 'bap',
              dataIndex: 'bap',
              valueType: 'text',
            },
          ]}
        />
      </div>
    </>
  );
};

export default AccountSettings;
