/* eslint-disable @typescript-eslint/no-unused-vars */
import { CloseCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { ColProps, FormInstance, Tag, Tooltip, Input, Grid } from 'antd';
import { isInvalidEmail } from 'utils';
import RoleSelect from 'components/GSelect/RoleSelect';
import AccessList from './AccessList';
import styles from './Invite.module.less';
import { DataItem, InviteFormProps, TagProp } from './typings';
import useInviteStore from './useInviteStore';

const { TextArea } = Input;
const { useBreakpoint } = Grid;

const colProps18: ColProps = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 16 };
const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
const colProps6: ColProps = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 8 };
const EmailTag = ({ prop }: TagProp) => {
  const { label, closable, onClose } = prop;
  return (
    <Tag
      {...prop}
      key={String(`key${label}`)}
      color={isInvalidEmail(String(label)) ? 'error' : 'default'}
      icon={
        isInvalidEmail(String(label)) && (
          <Tooltip placement="bottom" title="Invalid Email">
            <CloseCircleOutlined />
          </Tooltip>
        )
      }
      closable={closable}
      onClose={onClose}
      className={styles.emailtag}
    >
      {label}
    </Tag>
  );
};
const InviteFormFields = ({
  t,
  userPermissionsList,
  onRemoveUser,
  onAssignRole,
  isUsersListLoading,
}: InviteFormProps): ProFormColumnsType<DataItem>[] => {
  const emails = useInviteStore((state) => state.emails);
  const setEmails = useInviteStore((state) => state.setEmails);
  const screens = useBreakpoint();
  return [
    {
      dataIndex: 'emails',
      initialValue: [],
      valueType: 'select',
      fieldProps: (form: FormInstance<DataItem>) => ({
        mode: 'tags',
        placeholder: 'Add Employee Emails',
        onChange: (value: DataItem['emails']) => {
          setEmails(value);
          form?.setFieldsValue({ emails: value });
        },
        tokenSeparators: [',', ' ', ';'],
        tagRender: (tagProps) => <EmailTag prop={tagProps} t={t} />,
        notFoundContent: null,
        style: {
          marginBottom: screens?.xxl ? '8px' : '16px',
        },
      }),
      colProps: emails?.length >= 1 ? colProps18 : colProps24,
    },
    {
      dataIndex: 'role',
      valueType: 'select',
      fieldProps: {
        allowClear: false,
        style: {
          width: '100%',
        },
        placeholder: 'Role',
      },
      formItemProps: {
        rules: [
          {
            required: true,
            message: 'Please Select Role',
          },
        ],
      },
      hideInForm: emails?.length === 0,
      renderFormItem: () => <RoleSelect />,
      colProps: colProps6,
    },
    {
      dataIndex: 'Value',
      valueType: 'text',
      renderFormItem: () =>
        emails?.length === 0 ? null : (
          <div style={{ marginTop: screens?.xxl ? '8px' : '16px' }}>
            <TextArea className={styles.textinput} rows={7} placeholder="Add Message" />
          </div>
        ),
      fieldProps: {
        placeholder: 'Add Message',
      },
    },
    {
      dataIndex: 'access',
      hideInForm: emails?.length !== 0,
      renderFormItem: () => (
        <AccessList
          userPermissionsList={userPermissionsList}
          onRemoveUser={onRemoveUser}
          onAssignRole={onAssignRole}
          isUsersListLoading={isUsersListLoading}
        />
      ),
    },
  ];
};
export default InviteFormFields;
