import ProList, { ProListMetas } from '@ant-design/pro-list';
import { Typography } from 'antd';
import { useAuthenticationContext } from 'contexts';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import EndorserActions from 'components/Actions/EndorserActions';
import { FC } from 'react';
import usePermissionStore from 'layouts/GeneralLayout/components/Settings/UserPermissions/hooks';
import { AccessListProps, ActionItemProps, UserPermissionsList } from './typings';

const ActionItems = ({ record, currentUser }: ActionItemProps): Array<ItemType> => [
  {
    type: 'group',
    key: 'manage',
    label: 'Manage',
    children: [
      {
        key: 'remove',
        label: 'Remove',
        disabled: record.id === currentUser?.id,
      },
    ],
  },
];
const AccessList: FC<AccessListProps> = ({
  userPermissionsList,
  onRemoveUser,
  isUsersListLoading,
}) => {
  const { currentUser } = useAuthenticationContext();
  const setSelectedUserData = usePermissionStore((state) => state.setSelectedUserData);

  const onRowActionClick = async (key: string, payload?: UserPermissionsList) => {
    setSelectedUserData(payload);
    switch (key) {
      case 'remove':
        onRemoveUser({ payload });
        break;

      default:
        break;
    }
  };
  const listMetas: ProListMetas<UserPermissionsList> = {
    title: {
      dataIndex: 'name',
      render: (text, record) => (
        <Typography.Text>
          {record?.name}
          <Typography.Text type="secondary">
            {(record?.id || '') === (currentUser?.id || '') ? ' (Me)' : ''}
          </Typography.Text>
        </Typography.Text>
      ),
    },
    actions: {
      render: (text, record) => (
        <EndorserActions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
          }}
          size="small"
          items={ActionItems({ record, currentUser })}
          onClick={onRowActionClick}
          actionButtonColor={{
            color: '#0a5f7a',
            borderColor: '#0a5f7a',
            background: 'white',
            textShadow: '0px 0px',
            boxShadow: '0px 0px',
          }}
          actionPayload={record}
          actionsText={record?.role}
        />
      ),
    },
  };

  return (
    <div style={{ overflowY: 'scroll', maxHeight: '300px' }}>
      <ProList
        itemLayout="horizontal"
        rowKey="id"
        split
        dataSource={userPermissionsList}
        loading={isUsersListLoading}
        metas={listMetas}
        ghost
      />
    </div>
  );
};
export default AccessList;
