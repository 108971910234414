/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuditReportStore from 'components/GAuditReport/hooks';
import { TradePartnersList, ProductList, AuditReportsProps } from 'services/api/Endorsers';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import useModalVisibility from 'hooks/useModalVisibility';
import GDashboard from 'components/GDashboard';
import useTraceStore from './hooks/useTraceStore';
import {
  useTabStore,
  useAuditReportsFilters,
  useAllEventsListFilters,
  useMapLocationFilters,
  useDiagramFilters,
} from './hooks';

const ProfileTradingPartnersDashboard = () => {
  const { bapNumber } = useParams();
  const [bapValue, setBapValue] = useState('');
  useEffect(() => {
    if (bapNumber) {
      setBapValue(bapNumber);
    } else {
      setBapValue('F12553');
    }
  }, [bapNumber, bapValue]);
  const navigate = useNavigate();
  // invite modal
  const inviteModal = useModalVisibility(false);
  const grantAccessModal = useModalVisibility(false);

  const tab = useTabStore((state) => state.tab);
  const setTab = useTabStore((state) => state.setTab);
  const search = useTabStore((state) => state.auditSearch);
  const setAuditSearch = useTabStore((state) => state.setAuditSearch);
  const setSearch = useTraceStore((state) => state.setSearch);

  // Switch Buttons
  const traceSearch = useTraceStore((state) => state.search);
  const traceTab = useTraceStore((state) => state.traceTab);
  const setTraceTab = useTraceStore((state) => state.setTraceTab);

  // Set Tab
  const backFrom = useAuditReportStore((state) => state.backFrom);
  const setBackFrom = useAuditReportStore((state) => state.setBackFrom);

  // set audit tab
  const toggleAuditReportShow = useTabStore((state) => state.toggleAuditReportShow);

  useEffect(
    () => () => {
      setAuditSearch({ facilityName: '', date: undefined, bapNumber: bapValue, status: '' });
      setSearch({
        company: [],
        lot: [],
        location: [],
        state: [],
        country: [],
        sscc: [],
        event: [],
        productIdentifier: [],
        species: [],
        startDate: undefined,
        endDate: undefined,
      });
      setTraceTab('list');
      toggleAuditReportShow(false);
      if (backFrom === 'auditReport') {
        setTab('auditReports');
        setBackFrom('tradingPartners');
      } else {
        setTab('tradingPartners');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const locationMenuItem = () => [
    {
      label: 'All Locations',
      key: '0',
    },
    {
      label: 'USA',
      key: '1',
    },
    {
      label: 'India',
      key: '2',
    },
  ];

  const onEndorserTitleActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'invite':
        inviteModal.show();
        break;
      case 'requestAccess':
        grantAccessModal.show();
        break;

      default:
        break;
    }
  };

  // Endorser Trace
  const onEndorserTraceActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'viewProduct':
        break;

      default:
        break;
    }
  };

  // Trading partners
  const [params, setParams] = useState({
    page: '1',
    itemsPerPage: 20,
    search: '',
  });

  const tradePartners = [] as Array<TradePartnersList>;

  const onSearch = (text: string) => {
    setParams({
      ...params,
      page: '1',
      search: text,
    });
  };

  const { filters: tracePartnerSearchfilter } = useTableSearchFilter({
    onSearch,
  });
  const onTradingPartnersActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'view':
        console.log('trading partners view clicked');
        break;

      default:
        break;
    }
  };

  const onTradingPartnersRowActionClick = (
    actionItemKey: string,
    actionPayload?: TradePartnersList,
  ) => {
    switch (actionItemKey) {
      case 'viewDashboard':
        if (actionPayload) {
          navigate(`../../${actionPayload.companyName}`, {
            state: { facilityName: actionPayload?.companyName },
          });
        }
        break;

      default:
        break;
    }
  };

  // Products
  const [productParams, setProductParams] = useState({
    page: '1',
    itemsPerPage: 20,
    search: '',
  });

  const products = [] as Array<ProductList>;

  const onProductSearch = (text: string) => {
    setProductParams({
      ...productParams,
      page: '1',
      search: text,
    });
  };

  const { filters: productSearchfilter } = useTableSearchFilter({
    onSearch: onProductSearch,
  });

  const onProductsActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'viewProduct':
        console.log('view product products clicked');
        break;

      default:
        break;
    }
  };

  // all Audit Reports
  const {
    auditReports,
    filters: [facilityNameSearchFilter, bapNumberSearchFilter],
    auditReportsParams,
    setAuditReportsParams,
    totalItems,
    isAuditReportsLoading,
    onAuditReportsTableChange,
  } = useAuditReportsFilters({ bapNumber: bapValue });

  const setModalVisible = useAuditReportStore((state) => state.setModalVisible);
  const setAuditReportId = useAuditReportStore((state) => state.setAuditReportId);
  const setCheckedBox = useAuditReportStore((state) => state.setCheckedBox);

  const onAuditReportsRowActionClick = (
    actionItemKey: string,
    actionPayload?: AuditReportsProps,
  ) => {
    switch (actionItemKey) {
      case 'view':
        navigate(`audit_report/${actionPayload?.bapNumber}/${actionPayload?.reportId}`);
        break;
      case 'download':
        setCheckedBox(['summary', 'auditReport']);
        setAuditReportId(actionPayload?.reportId || '');
        setTimeout(() => {
          setModalVisible(true);
        }, 450);
        break;

      default:
        break;
    }
  };

  // Generic Trace Table
  // TopFilters
  const expandedFilters = useTraceStore((state) => state.expandedFilters);
  const toggleExpandedFilters = useTraceStore((state) => state.toggleExpandedFilters);
  const mainSelected = useTraceStore((state) => state.mainSelected);
  const setMainSelected = useTraceStore((state) => state.setMainSelected);

  // All Events
  // all Events List API
  const { allEventsList, isAllEventsListLoading } = useAllEventsListFilters();

  // all Events MAP API
  const { eventMapLocation, isEventMpaLocationLoading } = useMapLocationFilters();

  // all Events MAP API
  const { eventDiagramNodes, isEventDiagramLoading } = useDiagramFilters();

  // Trace Map
  const selectedLot = useTraceStore((state) => state.selectedLot);

  const selectedMarker = useTraceStore((state) => state.selectedMarker);
  const setSelectedMarker = useTraceStore((state) => state.setSelectedMarker);

  // Trace Diagram
  const selectedNode = useTraceStore((state) => state.selectedNode);
  const setSelectedNode = useTraceStore((state) => state.setSelectedNode);

  // Lot Select
  const setSelectedLot = useTraceStore((state) => state.setSelectedLot);
  return (
    <GDashboard
      onEndorserTitleActionItemClick={onEndorserTitleActionItemClick}
      locationMenuItem={locationMenuItem()}
      inviteModal={inviteModal}
      grantAccessModal={grantAccessModal}
      ownCompany={false}
      backButton
      from="profileTradingPartners"
      // MainTableCOntainer
      tab={tab}
      setTab={setTab}
      auditSearch={search}
      setAuditSearch={setAuditSearch}
      setSearch={setSearch}
      // Endorser Trace
      onEndorserTraceActionItemClick={onEndorserTraceActionItemClick}
      // Trading Partners
      onTradingPartnersActionItemClick={onTradingPartnersActionItemClick}
      onTradingPartnersRowActionClick={onTradingPartnersRowActionClick}
      tradePartners={tradePartners}
      tracePartnerSearchfilter={tracePartnerSearchfilter}
      isTradePartnersLoading={false}
      // Products
      onProductsActionItemClick={onProductsActionItemClick}
      products={products}
      productSearchfilter={productSearchfilter}
      isProductsLoading={false}
      // Audit Reports
      onAuditReportsRowActionClick={onAuditReportsRowActionClick}
      auditReports={auditReports}
      isAuditReportsLoading={isAuditReportsLoading}
      totalItems={totalItems}
      auditReportsParams={auditReportsParams}
      setAuditReportsParams={setAuditReportsParams}
      facilityNameSearchFilter={facilityNameSearchFilter}
      bapNumberSearchFilter={bapNumberSearchFilter}
      onAuditReportsTableChange={onAuditReportsTableChange}
      // G TraceTable
      traceTab={traceTab}
      setTraceTab={setTraceTab}
      expandedFilters={expandedFilters}
      toggleExpandedFilters={toggleExpandedFilters}
      search={traceSearch}
      mainSelected={mainSelected}
      setMainSelected={setMainSelected}
      selectedNode={selectedNode}
      setSelectedNode={setSelectedNode}
      selectedMarker={selectedMarker}
      setSelectedMarker={setSelectedMarker}
      selectedLot={selectedLot}
      setSelectedLot={setSelectedLot}
      // event list api
      allEventsList={allEventsList}
      isAllEventsListLoading={isAllEventsListLoading}
      // event map api data
      eventMapLocation={eventMapLocation}
      isEventMpaLocationLoading={isEventMpaLocationLoading}
      // event diagram api data
      eventDiagramNodes={eventDiagramNodes}
      isEventDiagramLoading={isEventDiagramLoading}
    />
  );
};

export default ProfileTradingPartnersDashboard;
