import { ProFormColumnsType } from '@ant-design/pro-form';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Space } from 'antd';
import PermissionsSelect from 'components/GSelect/PermissionsSelect';
import { DataItem, AddRoleFormFieldsProps } from './typings';

const AddRoleFormFields = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  t,
}: AddRoleFormFieldsProps): ProFormColumnsType<DataItem>[] => [
  {
    dataIndex: 'roleValue',
    title: 'Role Name',
    fieldProps: {
      placeholder: 'Role Name',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Please Enter Role Name',
        },
        {
          type: 'string',
          whitespace: true,
        },
      ],
    },
  },
  {
    dataIndex: 'space',
    renderFormItem: () => <div style={{ marginTop: '40px' }} />,
  },
  {
    dataIndex: 'permissionValue',
    valueType: 'select',
    title: (
      <Space size="small">
        Select Permissions
        <Tooltip title="If you do not see a permission you are looking for, please contact us">
          <InfoCircleOutlined style={{ fontSize: '14px' }} />
        </Tooltip>
      </Space>
    ),
    fieldProps: {
      placeholder: 'Permission',
      mode: 'multiple',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Please Enter Permissions',
        },
      ],
    },
    renderFormItem: () => <PermissionsSelect />,
  },
];
export default AddRoleFormFields;
