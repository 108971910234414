import { FC, useState } from 'react';
import { Modal, Button, Typography, message, Input } from 'antd';
import errorHandler from 'utils/errorHandler';
import { useTranslation } from 'react-i18next';
import { NotifyHelpDeskRequest } from 'services/api/client/src';
import { useNotifyHelpdesk } from 'services/api/Endorsers/useDashboard';
import { HelpDeskProps } from './typings';
import styles from './index.module.less';

const { Title } = Typography;
const { TextArea } = Input;

const InviteModal: FC<HelpDeskProps> = ({ modal }) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'help_desk',
  });
  const [subject, setSubject] = useState('');
  const [mailMsg, setMailMsg] = useState('');
  const [noSubject, setNoSubject] = useState(false);
  const [noMail, setNoMail] = useState(false);
  const [isSendLoading, setIsSendLoading] = useState(false);
  const helpDeskMail = useNotifyHelpdesk();

  const onCloseModal = () => {
    modal.hide();
  };

  const onSuccess = async () => {
    if (subject === '' && mailMsg === '') {
      setNoSubject(true);
      setNoMail(true);
      message.error(t('no_subject_mail'));
      return;
    }
    if (subject === '') {
      setNoSubject(true);
      message.error(t('no_subject'));
      return;
    }
    if (mailMsg === '') {
      setNoMail(true);
      message.error(t('no_mail'));
      return;
    }
    setIsSendLoading(true);
    try {
      const reqBody: NotifyHelpDeskRequest = {
        subject,
        text: mailMsg,
      };
      await helpDeskMail.mutateAsync({ params: reqBody });
      setIsSendLoading(false);
      modal.hide();
      message.success(t('success_msg'));
    } catch (error) {
      message.error(errorHandler(error));
      setIsSendLoading(false);
    }
  };

  return (
    <Modal
      visible={modal.visible}
      title={<Title level={5}>{t('title')}</Title>}
      closable
      onCancel={onCloseModal}
      footer={
        <>
          <Button
            onClick={onCloseModal}
            type="primary"
            shape="round"
            style={{
              color: '#0a5f7a',
              borderColor: '#0a5f7a',
              background: 'white',
              textShadow: '0px 0px',
              boxShadow: '0px 0px',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onSuccess}
            type="primary"
            shape="round"
            style={{
              color: '#0a5f7a',
              borderColor: '#bcd530',
              background: '#bcd530',
              textShadow: '0px 0px',
              boxShadow: '0px 0px',
            }}
            loading={isSendLoading}
          >
            Send
          </Button>
        </>
      }
      width="50%"
      bodyStyle={{ height: '60%', borderRadius: '0px' }}
      centered
    >
      <Typography.Text>{t('modal_info')}</Typography.Text>
      <div className={styles.inputstyle}>
        <Input
          placeholder={t('subject_placeholder')}
          onChange={(e) => {
            setSubject(e.target.value);
          }}
          status={noSubject && subject === '' ? 'error' : undefined}
        />
      </div>
      <div className={styles.inputstyle}>
        <TextArea
          rows={7}
          placeholder={t('mail_placeholder')}
          onChange={(e) => {
            setMailMsg(e.target.value);
          }}
          status={noMail && mailMsg === '' ? 'error' : undefined}
        />
      </div>
    </Modal>
  );
};

export default InviteModal;
