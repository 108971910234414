import { Select, Typography } from 'antd';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { useState, useEffect } from 'react';
import { useLocationsList } from 'services/api/useTrace';
import { LocationSelectProps } from './typings';
import styles from './index.module.less';

const LocationSelect = ({ search, setSearch, style }: LocationSelectProps) => {
  const [locationList, setLocationList] = useState<any>();
  const { data: locationsListResponse, isLoading } = useLocationsList();

  // we create here the list with the shape the select is expecting for
  const selectOptions: Array<DefaultOptionType> =
    locationsListResponse?.locations?.map((item) => ({
      label: String(item),
      value: String(item),
    })) || [];

  useEffect(() => {
    if (search?.location?.length === 0) {
      setLocationList(undefined);
    }
  }, [search]);

  const onLocationChange = (e: any, record: any) => {
    setLocationList(e);
    if (record.length === 0) {
      setSearch({ ...search, location: [] });
    } else {
      setSearch({ ...search, location: record.map((item: any) => item.label) });
    }
    setSearch({ ...search, location: record.map((item: any) => item.label) });
  };

  const onClearButtonClick = () => {
    setSearch({ ...search, location: [] });
  };

  return (
    <Select
      key="locselect"
      maxTagCount="responsive"
      style={style}
      mode="multiple"
      size="middle"
      loading={isLoading}
      options={selectOptions}
      className={styles.container}
      placeholder={
        <Typography.Text style={{ opacity: '0.3', fontWeight: '400' }}>Location</Typography.Text>
      }
      allowClear
      showSearch
      value={locationList}
      filterOption={(input, option) =>
        (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      onChange={onLocationChange}
      onClear={onClearButtonClick}
    />
  );
};

export default LocationSelect;
