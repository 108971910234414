import { FC, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { RadioChangeEvent } from 'antd';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Typography, message, Space, Checkbox, Radio } from 'antd';
import errorHandler from 'utils/errorHandler';
import { useRevokeAccess, useGrantAccess } from 'services/api/Endorsers/useAccess';
import { RevokeAccessRequest } from 'services/api/client/src';
import { EditAccessProps } from './typings';

const EditAccess: FC<EditAccessProps> = ({
  modal,
  selectedRowData,
  companyName,
  selecteMainTableData,
  setManageModalMaskShow,
}) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'settings.account_access_modals',
  });
  const queryClient = useQueryClient();
  const grantAccess = useGrantAccess(queryClient);
  const revokeAccess = useRevokeAccess(queryClient);

  const [levelValue, setLevelValue] = useState('view');
  const [categoryValue, setCategoryValue] = useState<CheckboxValueType[]>(['auditInformation']);
  const [isRemoveAccessLoading, setIsRemoveAccessLoading] = useState<boolean>(false);
  const [isEditAcessLoading, setIsEditAccessLoading] = useState<boolean>(false);
  const [editAccessModalMaskShow, setEditAccessModalMaskShow] = useState(true);

  const onCloseModal = () => {
    setCategoryValue(['auditInformation']);
    setLevelValue('view');
    setManageModalMaskShow(true);
    setEditAccessModalMaskShow(true);
    modal.hide();
  };

  const onDeny = () => {
    setEditAccessModalMaskShow(false);
    Modal.confirm({
      title: `Remove Access`,
      icon: <ExclamationCircleOutlined />,
      zIndex: 1800,
      onOk: async () => {
        setIsRemoveAccessLoading(true);
        const reqBody: RevokeAccessRequest = {
          endorserId: selectedRowData?.id,
          grantedEndorserId: selecteMainTableData?.accountID,
        };
        try {
          await revokeAccess.mutateAsync({ params: reqBody });
          setIsRemoveAccessLoading(false);
          message.success('Successfully Removed access');
          onCloseModal();
        } catch (error) {
          setIsRemoveAccessLoading(false);
          message.error(errorHandler(error));
        }
      },
      content: (
        <div>
          Are you sure you want to remove access from {companyName} to {selectedRowData?.producer} ?
        </div>
      ),
      onCancel: () => {
        setEditAccessModalMaskShow(true);
      },
      cancelText: 'No',
      okText: 'Yes, Remove',
      centered: true,
      okButtonProps: {
        type: 'primary',
        shape: 'round',
        size: 'middle',
        style: {
          color: '#0a5f7a',
          borderColor: '#bcd530',
          background: '#bcd530',
          textShadow: '0px 0px',
          boxShadow: '0px 0px',
        },
        loading: isRemoveAccessLoading,
      },
      cancelButtonProps: {
        shape: 'round',
        size: 'middle',
        style: {
          color: '#0a5f7a',
          borderColor: '#0a5f7a',
          background: 'transparent',
          textShadow: '0px 0px',
          boxShadow: '0px 0px',
        },
      },
    });
  };

  const onSuccess = async () => {
    if (levelValue === '' && categoryValue.length === 0) {
      message.error(t('level_category_not_selected'));
    } else if (levelValue === '') {
      message.error(t('level_not_selected'));
    } else if (categoryValue.length === 0) {
      message.error(t('category_not_selected'));
    } else {
      setIsEditAccessLoading(true);
      try {
        await grantAccess.mutateAsync({
          params: {
            requestedEndorserId: selecteMainTableData?.accountID,
            accessRequests: [
              {
                endorserId: selectedRowData?.id,
                auditReports: true,
              },
            ],
          },
        });
        setIsEditAccessLoading(false);
        message.success(
          t('access_updated_message', {
            grantAccessCompanyName: selectedRowData?.producer,
          }),
        );
        onCloseModal();
      } catch (error) {
        setIsEditAccessLoading(false);
        message.error(errorHandler(error));
      }
    }
  };

  const onLevelChange = (e: RadioChangeEvent) => {
    setLevelValue(e.target.value);
  };

  const onChangeGroup = (checkedValues: CheckboxValueType[]) => {
    setCategoryValue(checkedValues);
  };

  return (
    <Modal
      visible={modal.visible}
      mask={editAccessModalMaskShow}
      title={
        <Typography.Title level={4}>
          {t('edit_access_modal_title', {
            companyName,
            requestedCompanyName: selectedRowData?.producer,
          })}
        </Typography.Title>
      }
      closable
      onCancel={onCloseModal}
      footer={
        <>
          <Button
            onClick={onDeny}
            type="primary"
            shape="round"
            style={{
              color: '#0a5f7a',
              borderColor: '#0a5f7a',
              background: 'white',
              textShadow: '0px 0px',
              boxShadow: '0px 0px',
            }}
          >
            {t('remove_access')}
          </Button>
          <Button
            onClick={onSuccess}
            type="primary"
            shape="round"
            style={{
              color: '#0a5f7a',
              borderColor: '#bcd530',
              background: '#bcd530',
              textShadow: '0px 0px',
              boxShadow: '0px 0px',
            }}
            loading={isEditAcessLoading}
          >
            {t('save')}
          </Button>
        </>
      }
      width="35%"
      bodyStyle={{ height: '60%', borderRadius: '0px' }}
      centered
    >
      <Space direction="vertical">
        <Space direction="vertical" size="small">
          <Typography.Text>
            Select desired{' '}
            <Typography.Text strong underline>
              categories
            </Typography.Text>{' '}
            of access
          </Typography.Text>
          <Checkbox.Group onChange={onChangeGroup} value={categoryValue}>
            <Checkbox value="auditInformation">{t('audit_information')}</Checkbox>
          </Checkbox.Group>
        </Space>
        <Space direction="vertical" size="small" style={{ marginTop: '24px' }}>
          <Typography.Text>
            Select desired{' '}
            <Typography.Text strong underline>
              level
            </Typography.Text>{' '}
            of access
          </Typography.Text>
          <Radio.Group onChange={onLevelChange} value={levelValue}>
            <Space direction="vertical" size="small">
              <Radio value="view">{t('view_only')}</Radio>
            </Space>
          </Radio.Group>
        </Space>
      </Space>
    </Modal>
  );
};

export default EditAccess;
