import G6 from '@antv/g6';
import { useEffect, useCallback, useRef, useState } from 'react';
import { message } from 'antd';
import { dateFormat } from 'utils/helpers';
import {
  createComplexNodes,
  createEdges,
  createSimpleNodes,
  getNodeMethods,
  EXPAND_ICON,
  COLLAPSE_ICON,
} from './DiagramComponents';
import './diagram.css';

const NODE_TYPE = 'modelRect';
const EDGE = 'cubic-horizontal';

const TraceDiagram = ({
  fullscreenView,
  mainGraph,
  setMainGraph,
  modal,
  selectedLot,
  setTraceTab,
  setSelectedNode,
  eventDiagramNodes,
}: any) => {
  const containerRef = useRef<any>();
  // eslint-disable-next-line no-console
  console.log('diagram data', eventDiagramNodes);

  const clearContainer = () => {
    const containerElement = document.getElementById('container');
    if (containerElement) {
      containerElement.innerHTML = '';
    }
  };

  const initializeGraph = useCallback(() => {
    const SCROLL_WIDTH = containerRef.current?.scrollWidth;
    const SCROLL_HEIGHT = containerRef.current?.scrollHeight;
    const minimap = new G6.Minimap({
      size: [SCROLL_WIDTH - 50, 150],
    });

    const graph = new G6.Graph({
      container: document.getElementById('container') || '',
      width: SCROLL_WIDTH - 50,
      height: (SCROLL_HEIGHT && SCROLL_HEIGHT - 150) || 550,
      fitCenter: true,
      fitView: true,
      plugins: [minimap],
      modes: {
        default: [
          {
            type: 'collapse-expand',
            shouldBegin: function shouldBegin(e) {
              /* Click on node to prevent expansion */
              if (e.target.get('name') !== 'collapse-icon') {
                return false;
              }
              return true;
            },
            onChange: function onChange(item: any, collapsed: any) {
              const icon = item
                .get('group')
                .find((element: any) => element.get('name') === 'collapse-icon');

              if (collapsed) {
                icon.attr('symbol', EXPAND_ICON);
              } else {
                icon.attr('symbol', COLLAPSE_ICON);
              }
            },
          },
          'drag-canvas',
          'zoom-canvas',
        ],
      },
      defaultNode: {
        type: NODE_TYPE,
        anchorPoints: [
          [0, 0.5],
          [0.9, 0.5],
        ],
      },
      defaultEdge: {
        type: EDGE,
        style: {
          stroke: '#A3B1BF',
        },
      },
      layout: {
        type: 'dagre',
        rankdir: 'LR',
        nodesepFunc: () => 20,
        ranksepFunc: () => 180,
      },
    });

    graph.on('node:dblclick', async (evt: any) => {
      // eslint-disable-next-line no-underscore-dangle
      setSelectedNode(evt.item._cfg.model);
      modal.show();
    });
    setMainGraph(graph);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMainGraph, setSelectedNode, eventDiagramNodes]);

  useEffect(() => () => clearContainer(), []);
  useEffect(() => {
    if (selectedLot.length === 0) {
      setTraceTab('list');
    }
  }, [selectedLot, setTraceTab]);

  useEffect(() => {
    clearContainer();
    initializeGraph();
  }, [initializeGraph, fullscreenView]);

  useEffect(() => {
    if (eventDiagramNodes?.nodes && eventDiagramNodes?.nodes?.length === 0) {
      message.info('No results with the selected filters');
    }
  }, [eventDiagramNodes?.nodes]);

  // Graph Data
  useEffect(() => {
    const formattedData: any = {
      edges: eventDiagramNodes?.edges,
      nodes: eventDiagramNodes?.nodes?.map((node: any) => ({
        id: node?.urn,
        eventType: node?.eventType,
        eventTime: dateFormat(node?.eventTime),
        colourIndex: 'colour_0',
        created: dateFormat(node?.eventTime),
        location: {
          id: node?.location?.urn,
          name: node?.location?.name,
          urN: node?.location?.urn,
          accountID: node?.location?.accountId,
          accountName: node?.location?.name,
          geoCoordinates: {
            latitude: node?.location?.latitude,
            longitude: node?.location?.longitude,
          },
          address: {
            line1: '',
            line2: '',
            city: node?.location?.city,
            state: node?.location?.state,
            country: node?.location?.country,
            zip: '',
          },
        },
        products: node.products,
        productOwner: node?.productOwner,
        sscc: node?.sscc,
        informationProvider: node?.informationProvider,
        eventData: node,
      })),
    };
    if (mainGraph) {
      mainGraph.data(formattedData);
      mainGraph.fitView();
      mainGraph.fitCenter();
      mainGraph.render();
    }
  }, [mainGraph, selectedLot, eventDiagramNodes]);

  const [isOriginal, setIsOriginal] = useState(true);

  if (mainGraph) {
    mainGraph.on('wheelzoom', () => {
      setIsOriginal(false);
    });
  }

  // rerender graph when window resizes
  window.onresize = () => {
    if (isOriginal) {
      clearContainer();
      initializeGraph();
    }
  };

  const nodeBasicMethod = getNodeMethods(mainGraph);

  // Simple Node
  createSimpleNodes(nodeBasicMethod);

  /* Complex Node */
  createComplexNodes(nodeBasicMethod);

  // Custom Edges
  createEdges(mainGraph);

  return (
    <div
      style={{
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      ref={containerRef}
      id="container"
    />
  );
};

export default TraceDiagram;
