import { Layout, Menu } from 'antd';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { routes } from './common';

import styles from './index.module.less';

import Profile from './Profile';
import Preferences from './Preferences';
import UserPermissions from './UserPermissions';
import AccountSettings from './AccountSettings';

const Settings = () => {
  const [queryParams] = useSearchParams();
  const activeTab = queryParams?.get('tab') || 'profile';

  const [menuItem, setMenuItem] = useState(activeTab);
  const onSwitchComponent = (key: string) => {
    switch (key) {
      case 'profile':
        return <Profile />;
      case 'preferences':
        return <Preferences />;
      case 'users_permission':
        return <UserPermissions />;
      case 'account_settings':
        return <AccountSettings />;

      default:
        return <Profile />;
    }
  };
  return (
    <Layout>
      <Layout.Sider width={200} className={styles.white}>
        <Menu
          mode="inline"
          defaultSelectedKeys={[activeTab]}
          className={styles.menu}
          onClick={(e) => setMenuItem(e.key)}
        >
          {routes.map((route) => (
            <Menu.Item key={route.key}>{route.name}</Menu.Item>
          ))}
        </Menu>
      </Layout.Sider>
      <Layout.Content className={styles.container}>{onSwitchComponent(menuItem)}</Layout.Content>
    </Layout>
  );
};

export default Settings;
