import { useCallback, useState } from 'react';
import { TablePaginationConfig } from 'antd';
import { useTableSearchFilter } from 'hooks';
import { ListRolesOptionalParams } from 'services/api/client/src';
import { useRolesList, usePermissionsList } from 'services/api/useSettings';
import { FilterValue } from 'antd/lib/table/interface';

const useRolesListFilters = (initParams?: ListRolesOptionalParams) => {
  const [rolesListParams, setRolesListParams] = useState<ListRolesOptionalParams>({
    ...initParams,
  });

  const { data: rolesListResponse, isLoading: isRolesListLoading } = useRolesList(rolesListParams);
  const { data: permissionsListResponse } = usePermissionsList();

  const { filters: roleSearchFilter } = useTableSearchFilter({
    title: 'Role',
  });

  const onRolesListTableChange = useCallback(
    (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
      const roleFilter = filters?.role
        ? {
            roleName: filters?.role?.[0] as any,
          }
        : {};
      const permissionsFilter = filters?.permission
        ? {
            permissions: filters?.permission as any,
          }
        : {};

      setRolesListParams({
        ...roleFilter,
        ...permissionsFilter,
      });
    },
    [],
  );

  return {
    rolesListResponse,
    isRolesListLoading,
    rolesListParams,
    setRolesListParams,
    onRolesListTableChange,
    filters: [roleSearchFilter],
    permissionsListResponse,
  };
};

export default useRolesListFilters;
