import create from 'zustand';
import { SelectTabStore } from './typings';

const useTraceStore = create<SelectTabStore>((set) => ({
  traceTab: 'list',
  setTraceTab: (traceTab: string) =>
    set(() => ({
      traceTab,
    })),
  expandedFilters: false,
  toggleExpandedFilters: () =>
    set((state) => ({
      expandedFilters: !state.expandedFilters,
    })),
  search: {
    company: [],
    lot: [],
    location: [],
    state: [],
    country: [],
    sscc: [],
    event: [],
    productIdentifier: [],
    species: [],
  },
  setSearch: (search) =>
    set(() => ({
      search,
    })),
  mainSelected: 'Lot',
  setMainSelected: (mainSelected) => set(() => ({ mainSelected })),
  modalVisible: false,
  setModalVisible: (modalVisible) => set(() => ({ modalVisible })),
  selectedNode: {
    id: '',
    colourIndex: '',
    eventType: '',
    eventTime: '',
    eventTimezoneOffset: '',
    customProperties: [],
    created: '',
    creator: '',
    businessStep: '',
    disposition: '',
    location: {
      id: '',
      name: '',
      facilityType: '',
      accountID: '',
      accountName: '',
      urN: '',
      geoCoordinates: {
        latitude: 0,
        longitude: 0,
      },
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
    },
    products: [
      {
        id: '',
        urN: '',
        productIdentifierType: '',
        productID: '',
        accountID: '',
        accountName: '',
        name: '',
        species: '',
        unitOfMeasure: '',
        quantity: '',
        customAttributes: [],
      },
    ],
  },
  setSelectedNode: (selectedNode) => set(() => ({ selectedNode })),
  selectedMarker: {
    id: '',
    position: {
      lat: 0,
      lng: 0,
    },
  },
  setSelectedMarker: (selectedMarker) => set(() => ({ selectedMarker })),
  selectedLotData: {},
  setSelectedLotData: (selectedLotData) =>
    set(() => ({
      selectedLotData,
    })),
  markerModalVisible: false,
  setMarkerModalVisible: (markerModalVisible) => set(() => ({ markerModalVisible })),
  selectedLot: [],
  setSelectedLot: (selectedLot) =>
    set(() => ({
      selectedLot,
    })),
  selectedActiveEvent: 0,
  setSelectedActiveEvent: (selectedActiveEvent) =>
    set(() => ({
      selectedActiveEvent,
    })),
}));

export default useTraceStore;
