import { useCallback, useState } from 'react';
import { TablePaginationConfig } from 'antd';
import { useTableSearchFilter } from 'hooks';
import { ListEndorserAccessItemsOptionalParams } from 'services/api/client/src';
import { useGetAccessListByEndorserId } from 'services/api/Endorsers/useAccess';
import { FilterValue } from 'antd/lib/table/interface';

const useManageAccessTableFilters = (
  endorserId?: string | number,
  initParams?: ListEndorserAccessItemsOptionalParams,
) => {
  const [accessListParams, setAccessListParams] = useState<ListEndorserAccessItemsOptionalParams>({
    ...initParams,
  });

  const { data: accessListResponse, isLoading: isAccessListLoading } = useGetAccessListByEndorserId(
    endorserId as any,
    accessListParams,
  );

  const { filters: producerSearchFilter } = useTableSearchFilter({
    title: 'Producer',
  });

  const { filters: bapSearchFilter } = useTableSearchFilter({
    title: 'BAP Number',
  });

  const onAccessListTableChange = useCallback(
    (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
      const producerFilter = filters?.producer
        ? {
            endorserName: filters?.producer as string[],
          }
        : {};
      const bapFilter = filters?.bapNumber
        ? {
            bapNumber: filters?.bapNumber as string[],
          }
        : {};

      setAccessListParams({
        ...producerFilter,
        ...bapFilter,
      });
    },
    [],
  );

  return {
    accessListResponse,
    isAccessListLoading,
    onAccessListTableChange,
    filters: [producerSearchFilter, bapSearchFilter],
  };
};

export default useManageAccessTableFilters;
