/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { useCallback, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import { ProColumns } from '@ant-design/pro-table';
import { Tag, Modal, message } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { errorHandler } from 'utils';
import useModalVisibility from 'hooks/useModalVisibility';
import EndorserActions from 'components/Actions/EndorserActions';
import { useDeleteRole, useUserInfo } from 'services/api/useSettings';
import { GTable } from 'components';
import { toTitleCase } from 'utils/helpers';
import usePermissionStore, { useRolesListFilters } from '../hooks';
import EditRole from '../Modals/EditRole';
import { UserRoleItem, FilterArrayProp } from './typings';

const userRoleActionItems = (): Array<ItemType> => [
  {
    key: 'editRole',
    label: 'Edit Role',
  },
  {
    key: 'delete',
    label: 'Delete',
  },
];

type ColumnsType = {
  onRowActionClick: (actionItemKey: string, actionPayload?: UserRoleItem) => void;
  loggedInUserData: any;
  roleSearchFilter: ProColumns;
  permissionsFilterList: FilterArrayProp[];
};

const columns = ({
  onRowActionClick,
  loggedInUserData,
  roleSearchFilter,
  permissionsFilterList,
}: ColumnsType): Array<ProColumns<UserRoleItem>> => [
  {
    title: 'Role',
    dataIndex: 'role',
    ellipsis: true,
    width: 200,
    ...roleSearchFilter,
  },
  {
    title: 'Permissions',
    dataIndex: 'permission',
    ellipsis: true,
    filters: permissionsFilterList,
    render: (text, records) => (
      <Tag
        style={{
          borderRadius: '15px',
        }}
      >
        {records?.permission?.length} Permission
      </Tag>
    ),
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    render: (text, record) =>
      record?.role === 'Admin' ? null : loggedInUserData?.user?.role?.roleName === 'Admin' ? (
        <EndorserActions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
          }}
          size="small"
          items={userRoleActionItems()}
          onClick={onRowActionClick}
          actionButtonColor={{
            color: '#0a5f7a',
            borderColor: '#0a5f7a',
            background: 'transparent',
            textShadow: '0px 0px',
            boxShadow: '0px 0px',
          }}
          actionPayload={record}
        />
      ) : null,
    fixed: 'right',
    width: 120,
  },
];

const RolesTable = () => {
  const queryClient = useQueryClient();
  const deleteRole = useDeleteRole(queryClient);
  const { data: loggedInUserData } = useUserInfo();
  const setSelectedRoleData = usePermissionStore((state) => state.setSelectedRoleData);
  const [selectedRole, setSelectedRole] = useState<UserRoleItem | undefined>({});
  const editRoleModal = useModalVisibility(false);
  const {
    rolesListResponse,
    isRolesListLoading,
    onRolesListTableChange,
    filters: [roleSearchFilter],
    permissionsListResponse,
  } = useRolesListFilters();

  const permissionsFilterList: FilterArrayProp[] =
    permissionsListResponse?.permissions?.map((item) => ({
      text: toTitleCase(item.name || '') || '',
      value: (item.permission as any) || '',
    })) || [];

  const rolesArray: Array<UserRoleItem> =
    rolesListResponse?.roles?.map((role) => ({
      id: role.roleId,
      role: role.roleName,
      permission: role.permission,
    })) || [];

  const onRowActionClick = async (actionItemKey: string, actionPayload?: UserRoleItem) => {
    setSelectedRoleData(actionPayload);
    switch (actionItemKey) {
      case 'editRole':
        setSelectedRole(actionPayload);
        editRoleModal.show();
        break;
      case 'delete':
        Modal.confirm({
          title: `Delete ${actionPayload?.role}`,
          icon: <ExclamationCircleOutlined />,
          zIndex: 1800,
          onOk: async () => {
            try {
              await deleteRole.mutateAsync({
                roleId: actionPayload?.id || '',
              });
              message.success('Successfully deleted role');
            } catch (error: any) {
              if (error?.response?.bodyAsText) {
                const errorJson = JSON.parse(error.response.bodyAsText);
                if (errorJson?.status === 403) {
                  Modal.warning({
                    title: 'Replace role from existing users',
                    content: `The role “${actionPayload?.role}” still has users it is currently assigned to. Replace this role for those users in order to delete it from the Data Center.`,
                    okText: 'Continue',
                    centered: true,
                    okButtonProps: {
                      type: 'primary',
                      shape: 'round',
                      size: 'middle',
                      style: {
                        color: '#0a5f7a',
                        borderColor: '#bcd530',
                        background: '#bcd530',
                        textShadow: '0px 0px',
                        boxShadow: '0px 0px',
                      },
                    },
                  });
                } else {
                  message.error(errorHandler(error));
                }
              } else {
                message.error(errorHandler(error));
              }
            }
          },
          content: (
            <div>
              Deleting this role will remove it from the Seafood Data Center. To add the role back,
              click “Add Role” at the top right corner and select the permissions again. Are you
              sure you want to delete {actionPayload?.role}?
            </div>
          ),
          cancelText: 'Cancel',
          okText: 'Delete',
          centered: true,
          okButtonProps: {
            type: 'primary',
            shape: 'round',
            size: 'middle',
            style: {
              color: '#0a5f7a',
              borderColor: '#bcd530',
              background: '#bcd530',
              textShadow: '0px 0px',
              boxShadow: '0px 0px',
            },
          },
          cancelButtonProps: {
            shape: 'round',
            size: 'middle',
            style: {
              color: '#0a5f7a',
              borderColor: '#0a5f7a',
              background: 'transparent',
              textShadow: '0px 0px',
              boxShadow: '0px 0px',
            },
          },
        });

        break;

      default:
        break;
    }
  };

  const expandedRowRender = useCallback(
    (rowData: UserRoleItem) =>
      rowData?.permission?.map((item) => (
        <div
          style={{
            padding: '8px',
            paddingLeft: '232px',
            borderBottom: '0.5px solid #ededed',
          }}
        >
          {toTitleCase(item)}
        </div>
      )),
    [],
  );

  const rowExpandable = useCallback(() => true, []);

  return (
    <>
      <EditRole modal={editRoleModal} selectedRole={selectedRole} />
      <GTable<UserRoleItem>
        columns={columns({
          onRowActionClick,
          loggedInUserData,
          roleSearchFilter,
          permissionsFilterList,
        })}
        onTableChange={onRolesListTableChange}
        loading={isRolesListLoading}
        value={rolesArray}
        options={{
          setting: false,
          reload: false,
        }}
        expandable={{ expandedRowRender, rowExpandable, columnWidth: 30 }}
        pagination={{
          defaultPageSize: 20,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Roles`,
        }}
      />
    </>
  );
};

export default RolesTable;
