import moment from 'moment';
import Papa from 'papaparse';
import * as js2xmlparser from 'js2xmlparser';
import { MarkerItemProps } from 'components/GMap/typings';

import { MapDiagramEdge } from 'services/api/client/src';

export const copyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);
};

export const camelToTitleCase = (str: string) =>
  str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());

export const dateFormat = (str: Date | string | undefined) => moment(str).format('DD-MM-YYYY');

export const yearFirstFormat = (str: Date | string | undefined) =>
  moment(str).format('YYYY-MM-DD') === 'Invalid date' ? '-' : moment(str).format('YYYY-MM-DD');

export const isInvalidEmail = (value: string) => {
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
  if (emailRegex.test(String(value))) {
    return false;
  }
  return true;
};

export const isValidPassword = (pass: string) => {
  const regex = /^(?!.* )(?=.*\d)(?=.*[A-Z]).{6,16}$/;
  if (regex.test(String(pass))) {
    return true;
  }
  return false;
};

export const formatPhoneNumber = (value: string) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const toSnakeCase = (str = '') => {
  const strArr = str.split(' ');
  const snakeArr = strArr.reduce((acc, val) => acc.concat(val.toLowerCase() as any), []);
  return snakeArr.join('_');
};

export const toTitleCase = (s: string) =>
  toSnakeCase(s).replace(/^_*(.)|_+(.)/g, (st: any, c: any, d: any) =>
    c ? c.toUpperCase() : ' '.concat(d.toUpperCase()),
  );

export const downloadJsonToCSV = (data: any, fileName = 'data') => {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${fileName}.csv`);
  link.click();
};

export const documentJSONtoXML = (data: any, fileName: string = 'document') => {
  const xml = js2xmlparser.parse('document', data);
  const blob = new Blob([xml], { type: 'text/xml;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${fileName}.xml`);
  link.click();
};

// Trace section modals list
export const attributes = [
  'Account Name',
  'Account ID',
  'Time/Date',
  'Timezone Offset',
  'URN',
  'Product',
  'Lot',
  'Quantity',
  'Event Location',
  'Event Geolocation',
  'Purchase Order',
];

/* order nodes based on edges information of array of source and target */
export const orderNodesByEdges = (nodes: MarkerItemProps[], edges: MapDiagramEdge[]) => {
  // return ordered nodes based on edges array containing source and target
  const orderedNodes =
    edges?.length > 0
      ? edges.map((edge: MapDiagramEdge) => {
          const sourceNode = nodes.find((node) => node?.id === edge?.source);
          const targetNode = nodes.find((node) => node?.id === edge?.target);
          return [
            {
              lat: sourceNode?.position?.lat,
              lng: sourceNode?.position?.lng,
            },
            {
              lat: targetNode?.position?.lat,
              lng: targetNode?.position?.lng,
            },
          ];
        }, [])
      : nodes;
  return orderedNodes;
};

// Create pdf download button

export const pdfDownloadCreator = (blobBody: any, pdfResponse: any) => {
  const url = URL.createObjectURL(blobBody as any);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${pdfResponse?.xFileName}`);
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};
