import { useCallback, useState, useMemo } from 'react';
import { TablePaginationConfig } from 'antd';
import { useTableSearchFilter } from 'hooks';
import { EndorserAccess, ListAccountsOptionalParams } from 'services/api/client/src';
import { useSpeciesList, useCountriesList } from 'services/api/useEndorsers';
import { useAccountsList } from 'services/api/useSettings';
import { FilterValue } from 'antd/lib/table/interface';

export interface SpeciesProps {
  category?: string;
  stars?: number;
}

export interface UpdatedEndorserDTO {
  id?: string;
  bap?: string;
  producer?: string;
  producerType?: string;
  country?: string;
  expiration?: Date;
  species?: SpeciesProps[];
  stars?: number;
  accountID?: string;
  accessCount?: number;
  accessFields?: Array<EndorserAccess>;
}

const useAccountsListFilters = (initParams?: ListAccountsOptionalParams) => {
  const [accountsListParams, setAccountsListParams] = useState<ListAccountsOptionalParams>({
    pageNumber: 1,
    pageSize: 50,
    ...initParams,
  });

  const { data: AccountsListResponse, isLoading: isAccountsListLoading } =
    useAccountsList(accountsListParams);

  const [selectedFilter, setSelectedFilter] = useState<string | undefined>('');

  const { data: speciesListResponse } = useSpeciesList();
  const { data: countriesListResponse } = useCountriesList();

  const totalItems = useMemo(() => AccountsListResponse?.totalItems || 0, [AccountsListResponse]);
  const accountsList: UpdatedEndorserDTO[] = useMemo(
    () =>
      AccountsListResponse?.results?.map((item, index) => ({
        id: item.accountID,
        producer: item.facility,
        producerType: item.facilityType === 'Plant' ? item.plantType : item.facilityType,
        country: item.country,
        bap: item.bapNumber,
        expiration: item.expiration,
        species: item.species,
        stars: item.species?.[index]?.stars,
        access: item.accessCount,
        accessFields: item.access,
        ...(item?.access?.[0]?.accessItems?.length && {
          children: [
            ...(item?.access?.[0]?.accessItems?.map((i) => ({
              producer: ' ',
              producerType: ' ',
              country: ' ',
              bap: ' ',
              expiration: ' ',
              species: ' ',
              stars: ' ',
              access: item.accessCount,
              accessFields: [i],
            })) || []),
          ],
        }),
      })) || [],
    [AccountsListResponse],
  );

  const { filters: producerSearchFilter } = useTableSearchFilter({
    title: 'Producer',
  });

  const { filters: bapNumberSearchFilter } = useTableSearchFilter({
    title: 'BAP Number',
  });

  const { filters: accessSearchFilter } = useTableSearchFilter({
    title: 'Access',
  });

  const onAccessListTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: any,
    ) => {
      if (filters?.producer && totalItems !== 0) {
        setSelectedFilter(' for "Producer"');
      } else if (filters?.producerType && totalItems !== 0) {
        setSelectedFilter(' for "Producer Type"');
      } else if (filters?.country && totalItems !== 0) {
        setSelectedFilter(' for "Country"');
      } else if (filters?.bap && totalItems !== 0) {
        setSelectedFilter(' for "BAP Number"');
      } else if (filters?.species && totalItems !== 0) {
        setSelectedFilter(' for "Species & Star Status Capability"');
      } else {
        setSelectedFilter('');
      }
      const paginate = pagination?.current
        ? {
            pageNumber: pagination?.current || 1,
            pageSize: pagination?.pageSize || 50,
          }
        : {};
      const producerFilter = filters?.producer
        ? {
            facility: filters?.producer?.[0] as string,
          }
        : {};
      const accessFilter = filters?.access
        ? {
            childAccessFacility: filters?.access?.[0] as string,
          }
        : {};
      const producerTypeFilter = filters?.producerType
        ? {
            facilityTypes: filters?.producerType as string[],
          }
        : {};
      const countriesFilter = filters?.country
        ? {
            countries: filters?.country as string[],
          }
        : {};
      const bapNumberFilter = filters?.bap
        ? {
            bapNumber: (filters?.bap?.[0] as string).toUpperCase(),
          }
        : {};
      const speciesFilter = filters?.species
        ? {
            species: filters?.species as string[],
          }
        : {};
      const sortDirection =
        sorter?.field && sorter?.order
          ? {
              order: 'Expiration',
              direction: sorter?.order === 'ascend' ? 'Asc' : 'Desc',
            }
          : {};

      setAccountsListParams({
        ...paginate,
        ...producerFilter,
        ...producerTypeFilter,
        ...countriesFilter,
        ...bapNumberFilter,
        ...speciesFilter,
        ...sortDirection,
        ...accessFilter,
      });
    },
    [totalItems],
  );

  return {
    accountsList,
    isAccountsListLoading,
    accountsListParams,
    setAccountsListParams,
    totalItems,
    onAccessListTableChange,
    filters: [producerSearchFilter, bapNumberSearchFilter, accessSearchFilter],
    speciesListResponse,
    countriesListResponse,
    selectedFilter,
  };
};

export default useAccountsListFilters;
