import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useReportTabsContext } from 'contexts/ReportTabsProvider';
import { ListReportsOperationResponse } from 'services/api/client/src';
import EndorserTitlebar from 'components/TitleBar/EndorserTitlebar';
import { Loading } from 'components';
import styles from './index.module.less';

const ReportTab = () => {
  const location = useLocation();
  const { endorsersReportsData } = useReportTabsContext();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const iframeEle = document.getElementById('iframe');
    iframeEle?.addEventListener('load', () => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById('iframe')]);

  const findTab = useCallback(
    (reportsData?: ListReportsOperationResponse) =>
      reportsData?.reports?.find(
        (tab) => tab?.tabName?.toLowerCase() === location?.pathname?.substring(1),
      ),
    [location?.pathname],
  );

  return (
    <>
      <EndorserTitlebar
        title={findTab(endorsersReportsData)?.tabName}
        fontSize
        className={styles.endorsers}
      />
      {loading ? <Loading /> : null}
      <iframe
        id="iframe"
        src={findTab(endorsersReportsData)?.url}
        title={findTab(endorsersReportsData)?.tabName}
        className={styles.iframe}
        width="100%"
      />
    </>
  );
};

export default ReportTab;
