import { InfoCircleOutlined } from '@ant-design/icons';
import { ProPageHeader } from '@ant-design/pro-layout';
import classNames from 'classnames';
import EndorserActions from 'components/Actions/EndorserActions';
import { FC } from 'react';
import styles from './TitleBar.module.less';
import { PageTitleInfoProps, PageTitleProps } from './typings';

const TitleBarInfo: FC<PageTitleInfoProps> = ({ onClick }) => (
  <InfoCircleOutlined className={styles.info} onClick={onClick} size={12} />
);

const EndorserTitlebar: FC<PageTitleProps> = ({
  actionItems,
  className,
  extra,
  extraTitle,
  onActionItemClick,
  onInfoClick,
  title,
  children,
  ghost,
  actionButtonColor,
  fontSize = false,
  selectedRowKeys,
  ...props
}) => {
  const titleComponents = [<span style={{ fontSize: fontSize ? '20px' : '16px' }}>{title}</span>];

  const handleActionClick = (actionItemKey: string) => {
    if (onActionItemClick) {
      onActionItemClick(actionItemKey, selectedRowKeys);
    }
  };

  if (onInfoClick) {
    titleComponents.push(<TitleBarInfo key="info" onClick={onInfoClick} />);
  }

  if (extraTitle) {
    titleComponents.push(<span key="extra">&nbsp;</span>);
    titleComponents.push(
      <span style={{ fontSize: fontSize ? '20px' : '16px' }}>{extraTitle}</span>,
    );
  }

  const extraComponents = [extra];
  if (actionItems && actionItems.length > 0) {
    extraComponents.push(
      <EndorserActions
        key="actions"
        ghost={ghost}
        items={actionItems}
        onClick={handleActionClick}
        actionButtonColor={actionButtonColor}
      />,
    );
  }

  return (
    <ProPageHeader
      className={classNames(styles.container, className)}
      title={titleComponents}
      prefixedClassName="titlebar"
      extra={extraComponents}
      {...props}
    >
      {children}
    </ProPageHeader>
  );
};

export default EndorserTitlebar;
