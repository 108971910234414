/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import { useState, useMemo, FC } from 'react';
import { PageHeader, Button, Tag, Segmented, message, Modal, Typography } from 'antd';
import { AssignRolesRequest } from 'services/api/client/src';
import { SegmentedValue } from 'antd/lib/segmented';
import GTable from 'components/GTable';
import errorHandler from 'utils/errorHandler';
import { ProColumns } from '@ant-design/pro-table';
import useModalVisibility from 'hooks/useModalVisibility';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import EndorserActions from 'components/Actions/EndorserActions';
import {
  useAssignRole,
  useUserInfo,
  useDeleteUser,
  useInviteUsers,
} from 'services/api/useSettings';
import { ChangeRoleModal } from './components/InviteModal';
import AddRole from './Modals';
import usePermissionStore, { useUsersListFilters } from './hooks';
import styles from '../index.module.less';
import InviteModal, { RolesSection } from './components';
import { InviteStatusProps, UserPermissionsList, FilterArrayProp } from './typings';

type RemoveUserProps = {
  payload: UserPermissionsList;
};

const productActionItems = (): Array<ItemType> => [
  {
    key: 'changeRole',
    label: 'Change Role',
  },
  {
    key: 'resend_invite',
    label: 'Resend Invite Link',
  },
];

const coreInvitaionStatus = [
  {
    text: 'Pending',
    value: 'Pending',
  },
  {
    text: 'Accepted',
    value: 'Accepted',
  },
];

const InviteStatus: FC<InviteStatusProps> = ({ status }) => {
  const color = useMemo(() => {
    switch (status) {
      case 'Active':
        return 'success';
      case 'Accepted':
        return 'success';
      case 'Pending':
        return 'warning';
      case 'Invited':
        return 'processing';
      case 'Disabled':
        return 'error';
      default:
        return 'default';
    }
  }, [status]);
  const text = useMemo(() => {
    switch (status) {
      case 'Active':
        return 'Active';
      case 'Accepted':
        return 'Accepted';
      case 'Pending':
        return 'Pending';
      case 'Invited':
        return 'Invited';
      case 'Disabled':
        return 'Disabled';
      default:
        return 'default';
    }
  }, [status]);
  const icon = useMemo(() => {
    switch (status) {
      case 'Active':
        return <CheckCircleOutlined />;
      case 'Accepted':
        return <CheckCircleOutlined />;
      case 'Pending':
        return <ClockCircleOutlined />;
      case 'Invited':
        return <ClockCircleOutlined />;
      case 'Disabled':
        return <CloseCircleOutlined />;
      default:
        return <InfoCircleOutlined />;
    }
  }, [status]);
  return (
    <Tag color={color} key={status} icon={icon}>
      {text}
    </Tag>
  );
};

type ColumnsType = {
  onRowActionClick: (actionItemKey: string, actionPayload?: UserPermissionsList) => void;
  loggedInUserData: any;
  nameSearchFilter: ProColumns;
  emailSearchFilter: ProColumns;
  roleFilterList: FilterArrayProp[];
};

const columns = ({
  onRowActionClick,
  loggedInUserData,
  nameSearchFilter,
  emailSearchFilter,
  roleFilterList,
}: ColumnsType): Array<ProColumns<UserPermissionsList>> => [
  {
    title: 'Name',
    dataIndex: 'name',
    ellipsis: true,
    fixed: 'left',
    ...nameSearchFilter,
    render: (text, record) => (
      <Typography.Text>
        {record?.name}
        <Typography.Text type="secondary">
          {(record?.id || '') === (loggedInUserData?.user?.userId || '') ? ' (Me)' : ''}
        </Typography.Text>
      </Typography.Text>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    ellipsis: true,
    ...emailSearchFilter,
    render: (text, record) => record?.email,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    ellipsis: true,
    filters: roleFilterList,
    render: (text, record) => (record?.role ? <Tag>{text}</Tag> : '-'),
  },
  {
    title: 'Invitation',
    dataIndex: 'status',
    ellipsis: true,
    filters: coreInvitaionStatus,
    onFilter: (value: any, record) => record?.status?.indexOf(value) === 0,
    render: (text, record) =>
      record?.status === '' ? '-' : <InviteStatus status={record.status} />,
  },
  loggedInUserData?.user?.role?.roleName === 'Admin'
    ? {
        dataIndex: 'actions',
        valueType: 'option',
        hideInSetting: true,
        render: (text, record) => (
          <EndorserActions
            className="actions"
            buttonProps={{
              ghost: true,
              size: 'small',
            }}
            size="small"
            items={productActionItems()}
            onClick={onRowActionClick}
            actionButtonColor={{
              color: '#0a5f7a',
              borderColor: '#0a5f7a',
              background: 'transparent',
              textShadow: '0px 0px',
              boxShadow: '0px 0px',
            }}
            actionPayload={record}
          />
        ),
        fixed: 'right',
        width: '150px',
      }
    : {},
];

const UserPermissions = () => {
  const [headName, setHeadName] = useState<string | undefined>();
  const [selectedUser, setSelectedUser] = useState<UserPermissionsList | undefined>();
  const queryClient = useQueryClient();
  const deleteRole = useDeleteUser(queryClient);
  const assignRole = useAssignRole(queryClient);
  const inviteUser = useInviteUsers(queryClient);
  const { data: loggedInUserData } = useUserInfo();
  const inviteModal = useModalVisibility(false);
  const changeRoleModal = useModalVisibility(false);
  const addRoleModal = useModalVisibility(false);

  const setSelectedUserData = usePermissionStore((state) => state.setSelectedUserData);
  const selectedTab = usePermissionStore((state) => state.tab);
  const setSelectedTab = usePermissionStore((state) => state.setTab);
  const sections = ['Users', 'Roles & Permissions'];
  const [activeSection, setActiveSection] = useState<string | number>(sections[0]);

  const onResendInvite = async (actionPayload?: UserPermissionsList) => {
    try {
      await inviteUser.mutateAsync({
        emails: [actionPayload?.email || ''],
        roleId: actionPayload?.roleId || '',
      });
      message.success('Successfully invited user');
    } catch (error) {
      message.error(errorHandler(error));
    }
  };

  const onRowActionClick = async (actionItemKey: string, actionPayload?: UserPermissionsList) => {
    setSelectedUser(actionPayload);
    setSelectedUserData(actionPayload);
    switch (actionItemKey) {
      case 'changeRole':
        setHeadName(actionPayload?.name);
        changeRoleModal.show();
        break;
      case 'resend_invite':
        onResendInvite(actionPayload);
        break;

      default:
        break;
    }
  };

  const {
    usersListResponse,
    rolesListResponse,
    isUsersListLoading,
    onUsersListTableChange,
    filters: [nameSearchFilter, emailSearchFilter],
  } = useUsersListFilters();

  const userPermissionsList: Array<UserPermissionsList> =
    usersListResponse?.users?.map((user) => ({
      id: user?.userId,
      name: user?.name,
      email: user?.email,
      role: user?.role?.roleName,
      status: user?.firstLogin ? 'Pending' : 'Accepted',
      roleId: user?.role?.roleId,
    })) || [];

  const roleFilterList: FilterArrayProp[] =
    rolesListResponse?.roles?.map((role) => ({
      text: role?.roleName || '',
      value: role?.roleName || '',
    })) || [];

  const onInviteClick = () => {
    inviteModal.show();
  };

  const onAddRoleClick = () => {
    addRoleModal.show();
  };

  const onSegmentedButtonClick = (value: SegmentedValue) => {
    setActiveSection(value);
    switch (value) {
      case 'Users':
        setSelectedTab('users');
        break;
      case 'Roles & Permissions':
        setSelectedTab('rolesTab');
        break;

      default:
        break;
    }
  };

  const onAssignRole = async (value: any) => {
    const arr = value.roleValue.split(' - ');
    try {
      const reqData: AssignRolesRequest = {
        roleId: arr[0],
        userId: selectedUser?.id,
      };
      await assignRole.mutateAsync({
        params: reqData,
      });
      message.success(`Successfully changed ${selectedUser?.name}'s role to ${arr[1]}`);
    } catch (error: any) {
      message.error(errorHandler(error));
    }
  };

  const onRemoveUser = ({ payload }: RemoveUserProps) => {
    Modal.confirm({
      title: `Remove ${payload?.name}`,
      icon: <ExclamationCircleOutlined />,
      zIndex: 1800,
      onOk: async () => {
        await deleteRole.mutateAsync({
          userId: payload?.id || '',
        });
        message.success('Successfully deleted user');
      },
      content: (
        <div>
          Removing a user will remove their email and account from the Seafood Data Center. To add
          them back you will need to send another invite. Are you sure you want to remove{' '}
          {payload?.name}?
        </div>
      ),
      cancelText: 'Cancel',
      okText: 'Remove',
      centered: true,
      okButtonProps: {
        type: 'primary',
        shape: 'round',
        size: 'middle',
        style: {
          color: '#0a5f7a',
          borderColor: '#bcd530',
          background: '#bcd530',
          textShadow: '0px 0px',
          boxShadow: '0px 0px',
        },
      },
      cancelButtonProps: {
        shape: 'round',
        size: 'middle',
        style: {
          color: '#0a5f7a',
          borderColor: '#0a5f7a',
          background: 'transparent',
          textShadow: '0px 0px',
          boxShadow: '0px 0px',
        },
      },
    });
  };

  const onInvite = async (modalValues: any) => {
    const arr = modalValues.role.split(' - ');
    try {
      await inviteUser.mutateAsync({
        emails: modalValues.emails,
        roleId: arr[0],
        message: modalValues?.Value,
      });
      message.success('Successfully invited user');
    } catch (error) {
      message.error(errorHandler(error));
    }
  };

  return (
    <div className={styles.table}>
      <InviteModal
        modal={inviteModal}
        userPermissionsList={userPermissionsList}
        onRemoveUser={onRemoveUser}
        onAssignRole={onAssignRole}
        onInvite={onInvite}
        isUsersListLoading={isUsersListLoading}
      />
      <ChangeRoleModal modal={changeRoleModal} name={headName} onAssignRole={onAssignRole} />
      <AddRole modal={addRoleModal} />
      <PageHeader
        title="Users & Permissions"
        className={styles['page-header']}
        subTitle={[
          <Segmented value={activeSection} options={sections} onChange={onSegmentedButtonClick} />,
        ]}
        extra={[
          selectedTab === 'users' && loggedInUserData?.user?.role?.roleName === 'Admin' ? (
            <Button
              type="primary"
              shape="round"
              style={{
                color: '#0a5f7a',
                borderColor: '#bcd530',
                background: '#bcd530',
                textShadow: '0px 0px',
                boxShadow: '0px 0px',
              }}
              onClick={onInviteClick}
            >
              Invite
            </Button>
          ) : selectedTab === 'rolesTab' && loggedInUserData?.user?.role?.roleName === 'Admin' ? (
            <Button
              type="primary"
              shape="round"
              style={{
                color: '#0a5f7a',
                borderColor: '#bcd530',
                background: '#bcd530',
                textShadow: '0px 0px',
                boxShadow: '0px 0px',
              }}
              onClick={onAddRoleClick}
            >
              Add Role
            </Button>
          ) : null,
        ]}
      />
      {activeSection === 'Users' ? (
        <GTable<UserPermissionsList>
          columns={columns({
            onRowActionClick,
            loggedInUserData,
            nameSearchFilter,
            emailSearchFilter,
            roleFilterList,
          })}
          value={userPermissionsList}
          loading={isUsersListLoading}
          options={{
            setting: false,
            reload: false,
          }}
          onTableChange={onUsersListTableChange}
          pagination={{
            defaultPageSize: 20,
            // eslint-disable-next-line react/no-unstable-nested-components
            showTotal: (total, range) => <div>{`${range[0]}-${range[1]} of ${total} Users`}</div>,
          }}
          recordCreatorProps={false}
          scroll={{ x: 800, y: '65vh' }}
        />
      ) : (
        <RolesSection />
      )}
    </div>
  );
};

export default UserPermissions;
