import { FC, useMemo, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-table';
import { TFunction, useTranslation } from 'react-i18next';
import { Modal, Button, Typography, Space } from 'antd';
import useModalVisibility from 'hooks/useModalVisibility';
import GTable from 'components/GTable';
import { EditAccessModal } from './index';
import GrantAccessModal from './GrantAccessModal';
import useManageAccessTableFilters from './hooks';
import { ManageAccessModalProps, AccessListProps } from './typings';

const { Title } = Typography;

type ColumnsType = {
  onRowActionClick: (actionPayload?: AccessListProps) => void;
  t: TFunction<'pages', 'settings.account_access_modals'>;
  producerSearchFilter: ProColumns;
  bapSearchFilter: ProColumns;
};

const columns = ({
  onRowActionClick,
  t,
  producerSearchFilter,
  bapSearchFilter,
}: ColumnsType): Array<ProColumns<AccessListProps>> => [
  {
    title: t('producer'),
    dataIndex: 'producer',
    ellipsis: true,
    width: '40%',
    ...producerSearchFilter,
  },
  {
    title: t('bap_number'),
    dataIndex: 'bapNumber',
    ellipsis: true,
    width: '20%',
    ...bapSearchFilter,
  },
  {
    title: t('level_of_access'),
    dataIndex: 'levelOfAccess',
    ellipsis: true,
    width: '25%',
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    render: (text, record) => (
      <Button
        size="small"
        type="link"
        ghost
        className="editactions"
        icon={<EditOutlined style={{ fontSize: '16px' }} />}
        onClick={() => onRowActionClick(record)}
      />
    ),
    fixed: 'right',
    width: '15%',
  },
];

const ManageAccessModal: FC<ManageAccessModalProps> = ({ modal, selectedRowData }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.account_access_modals' });
  const editAccessModal = useModalVisibility(false);
  const grantAccessModal = useModalVisibility(false);
  const [manageModalMaskShow, setManageModalMaskShow] = useState(true);
  const [selectedData, setSelectedData] = useState<AccessListProps>();

  const {
    accessListResponse,
    isAccessListLoading,
    onAccessListTableChange,
    filters: [producerSearchFilter, bapSearchFilter],
  } = useManageAccessTableFilters(selectedRowData?.id || '');

  const onCloseModal = () => {
    modal.hide();
  };

  const onSuccess = async () => {
    modal.hide();
  };

  const onRowActionClick = (actionPayload?: AccessListProps) => {
    setSelectedData(actionPayload);
    setManageModalMaskShow(false);
    editAccessModal.show();
  };

  const accessList: Array<AccessListProps> = useMemo(
    () =>
      accessListResponse?.results?.map((item) => ({
        id: item.endorserId,
        producer: item.endorserName,
        levelOfAccess: 'View / Audit Information',
        bapNumber: item.bapNumber,
      })) || [],
    [accessListResponse],
  );

  const AddHandler = () => {
    setManageModalMaskShow(false);
    grantAccessModal.show();
  };

  return (
    <>
      <EditAccessModal
        modal={editAccessModal}
        selectedRowData={selectedData}
        companyName={selectedRowData?.producer}
        selecteMainTableData={selectedRowData}
        setManageModalMaskShow={setManageModalMaskShow}
      />
      <GrantAccessModal
        modal={grantAccessModal}
        selecteMainTableData={selectedRowData}
        grantAccessCompanyName={selectedRowData?.producer}
        setManageModalMaskShow={setManageModalMaskShow}
      />
      <Modal
        visible={modal.visible}
        mask={manageModalMaskShow}
        title={<Title level={5}>{t('title', { companyName: selectedRowData?.producer })}</Title>}
        closable
        onCancel={onCloseModal}
        footer={
          <Button
            onClick={onSuccess}
            type="primary"
            shape="round"
            style={{
              color: '#0a5f7a',
              borderColor: '#bcd530',
              background: '#bcd530',
              textShadow: '0px 0px',
              boxShadow: '0px 0px',
            }}
          >
            {t('done_btn')}
          </Button>
        }
        width="48%"
        bodyStyle={{ height: '60%', borderRadius: '0px' }}
        centered
      >
        <Space size="small" direction="vertical">
          <Typography.Text style={{ fontWeight: '500' }}>
            Below are the producers that {selectedRowData?.producer} has access to. Edit to change
            access.
          </Typography.Text>
          <GTable<AccessListProps>
            columns={columns({
              onRowActionClick,
              t,
              producerSearchFilter,
              bapSearchFilter,
            })}
            value={accessList}
            options={{
              setting: false,
              reload: false,
            }}
            scroll={{ y: 200 }}
            loading={isAccessListLoading}
            onTableChange={onAccessListTableChange}
          />
          <div style={{ float: 'right', marginTop: '8px' }}>
            <Button
              type="primary"
              ghost
              className="actions"
              style={{
                color: '#0a5f7a',
                borderColor: '#0a5f7a',
                background: 'transparent',
                textShadow: '0px 0px',
                boxShadow: '0px 0px',
                borderRadius: '20px',
              }}
              onClick={AddHandler}
            >
              Add
            </Button>
          </div>
        </Space>
      </Modal>
    </>
  );
};

export default ManageAccessModal;
