import { useCallback, useState } from 'react';
import { TablePaginationConfig } from 'antd';
import { useTableSearchFilter } from 'hooks';
import { ListUsersOptionalParams } from 'services/api/client/src';
import { useUsersList, useRolesList } from 'services/api/useSettings';
import { FilterValue } from 'antd/lib/table/interface';

const useUsersListFilters = (initParams?: ListUsersOptionalParams) => {
  const [usersListParams, setUsersListParams] = useState<ListUsersOptionalParams>({
    ...initParams,
  });

  const { data: usersListResponse, isLoading: isUsersListLoading } = useUsersList(usersListParams);
  const { data: rolesListResponse } = useRolesList();

  const { filters: nameSearchFilter } = useTableSearchFilter({
    title: 'Name',
  });

  const { filters: emailSearchFilter } = useTableSearchFilter({
    title: 'Email',
  });

  const onUsersListTableChange = useCallback(
    (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
      const nameFilter = filters?.name
        ? {
            name: filters?.name?.[0] as any,
          }
        : {};
      const emailFilter = filters?.email
        ? {
            email: filters?.email?.[0] as any,
          }
        : {};
      const roleFilter = filters?.role
        ? {
            roleName: filters?.role as any,
          }
        : {};

      setUsersListParams({
        ...nameFilter,
        ...emailFilter,
        ...roleFilter,
      });
    },
    [],
  );

  return {
    usersListResponse,
    rolesListResponse,
    isUsersListLoading,
    usersListParams,
    setUsersListParams,
    onUsersListTableChange,
    filters: [nameSearchFilter, emailSearchFilter],
  };
};

export default useUsersListFilters;
