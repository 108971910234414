/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Card, Col, Row, Tabs } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Loading } from 'components';
import { CompletedDateSelect, FacilitySelect } from 'components/GSelect';
import { useGTableContext } from 'components/GTable/contexts';
import GTraceTable from 'components/GTrace';
import EndorserTitlebar from 'components/TitleBar/EndorserTitlebar';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEndorserById } from 'services/api/useEndorsers';
import { useUserInfo } from 'services/api/useSettings';
import AuditReports from './AuditReports';
import ManageAccessStateDashboard from './ManageAccessStateDashboard';
import { RequestAccess } from './Modal';
import Network from './Network';
import NoAccessStateDashboard from './NoAccessStateDashboard';
import styles from './index.module.less';
import { ActionMenu, EndorserTraceTitleProps, MainContainerProps } from './typings';

import './styles.css';

const { TabPane } = Tabs;
const mainActionItems = ({ selectedRowKeys, t }: ActionMenu): Array<ItemType> => [
  {
    key: 'view',
    label: t('main_actions.view'),
  },
  {
    key: 'manage',
    label: t('main_actions.manage'),
    disabled: !selectedRowKeys.length,
  },
];

const EndorserTraceTitle = ({ onEndorserTraceActionItemClick }: EndorserTraceTitleProps) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.tables_container',
  });
  const { selectedRowKeys } = useGTableContext();

  return (
    <EndorserTitlebar
      title={t('title')}
      // actionItems={mainActionItems({ selectedRowKeys, t })}
      className={styles.tracecontainer}
      onActionItemClick={onEndorserTraceActionItemClick}
      ghost
      actionButtonColor={{ color: '#0a5f7a', borderColor: '#0a5f7a', background: 'white' }}
    />
  );
};

const MainContainer: FC<MainContainerProps> = ({
  requestAccessModal,
  manageAccessModal,
  // Top Header
  tab,
  setTab,
  auditSearch,
  setAuditSearch,
  setSearch,
  // Endorser Trace
  onEndorserTraceActionItemClick,
  // Trading Partners
  onTradingPartnersActionItemClick,
  onTradingPartnersRowActionClick,
  tradePartners,
  tracePartnerSearchfilter,
  isTradePartnersLoading,
  from,
  enter,
  // Network
  networkList,
  iseNetworkListLoading,
  networkListParams,
  networkTotalItems,
  onNetworkListChange,
  networkProducerSearchFilter,
  networkBapSearchFilter,
  speciesListResponse,
  countriesListResponse,
  // Access List
  myEndorsersListResponse,
  // Products
  onProductsActionItemClick,
  products,
  productSearchfilter,
  isProductsLoading,
  // Audit Reports
  onAuditReportsRowActionClick,
  auditReports,
  isAuditReportsLoading,
  totalItems,
  auditReportsParams,
  setAuditReportsParams,
  facilityNameSearchFilter,
  bapNumberSearchFilter,
  onAuditReportsTableChange,
  hasProfileAccess,
  // GTrace Table
  traceTab,
  setTraceTab,
  expandedFilters,
  toggleExpandedFilters,
  search,
  mainSelected,
  setMainSelected,
  selectedNode,
  setSelectedNode,
  selectedMarker,
  setSelectedMarker,
  selectedLot,
  selectedLotData,
  setSelectedLotData,
  // Lot Select
  setSelectedLot,
  // event list api
  allEventsList,
  isAllEventsListLoading,
  // event map api data
  eventMapLocation,
  isEventMpaLocationLoading,
  // event diagram api data
  eventDiagramNodes,
  isEventDiagramLoading,
}) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.tables_container',
  });
  if (tab !== 'trace') {
    window.scrollTo(0, 0);
  }

  const { accountID } = useParams();
  const { data: loggedInUserData, isLoading: isLoggedinUserDataLoading } = useUserInfo();
  const { data: endorserByIdResponse, isLoading: isEndorsersDataLoading } = useEndorserById(
    accountID || '',
  );

  const companyName = useMemo(
    () => endorserByIdResponse?.endorser?.facility,
    [endorserByIdResponse],
  );

  const onTabChange = (e: string) => {
    setTab(e);
    setTraceTab('list');
    setSearch({
      company: [],
      lot: [],
      location: [],
      state: [],
      country: [],
      sscc: [],
      event: [],
      productIdentifier: [],
      species: [],
    });
    setAuditSearch({
      facilityName: '',
      date: undefined,
      bapNumber: '',
      status: '',
    });
  };

  const onRequestAccessButtonClick = () => {
    requestAccessModal?.show();
  };

  const onManageAccessButtonClick = () => {
    manageAccessModal?.show();
  };

  if (from === 'profile' && hasProfileAccess) {
    return (
      <div
        style={{
          background: 'white',
          margin: '15px',
          width: '100%',
        }}
      >
        <NoAccessStateDashboard
          companyName={companyName}
          onRequestAccessButtonClick={onRequestAccessButtonClick}
        />
      </div>
    );
  }
  if (
    from === 'profile' &&
    !myEndorsersListResponse?.results?.find((item) => item.endorserId === accountID)?.accepted
  ) {
    return (
      <div
        style={{
          background: 'white',
          margin: '15px',
          width: '100%',
        }}
      >
        <ManageAccessStateDashboard
          companyName={companyName}
          onManageAccessButtonClick={onManageAccessButtonClick}
        />
      </div>
    );
  }

  return (
    <>
      {from === 'profile'
        ? requestAccessModal &&
          requestAccessModal.visible && (
            <RequestAccess modal={requestAccessModal} companyName={companyName} />
          )
        : null}
      {(from === 'endorsersMain' && isLoggedinUserDataLoading) ||
      (from === 'staffEndorsers' && isEndorsersDataLoading) ? (
        <Loading />
      ) : (
        <span className={styles.lefttable}>
          <Tabs
            activeKey={tab}
            tabBarStyle={{ backgroundColor: 'white', padding: '0px 15px', color: '#8C8C8C' }}
            onChange={onTabChange}
          >
            {from !== 'endorsersMain' ? (
              <TabPane tab={t('audit_reports_tab')} key="auditReports">
                {from === 'endorsersMain' || from === 'staffEndorsers' ? (
                  <div style={{ padding: '15px', paddingTop: '0px', backgroundColor: '#f5f5f5' }}>
                    <div style={{ background: 'white', padding: '20px' }}>
                      <Row gutter={[8, 8]}>
                        <Col xl={7} lg={7} md={12} sm={12} xs={24}>
                          <FacilitySelect
                            auditSearch={auditSearch}
                            setSearch={setAuditSearch}
                            auditReportsParams={auditReportsParams}
                            setAuditReportsParams={setAuditReportsParams}
                          />
                        </Col>
                        <Col xl={7} lg={7} md={12} sm={12} xs={24}>
                          <CompletedDateSelect
                            auditSearch={auditSearch}
                            setSearch={setAuditSearch}
                            auditReportsParams={auditReportsParams}
                            setAuditReportsParams={setAuditReportsParams}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : null}
                <div className="site-card-border-less-wrapper">
                  <Card bordered={false} bodyStyle={{ padding: '5px 15px' }}>
                    <AuditReports
                      onAuditReportsRowActionClick={onAuditReportsRowActionClick}
                      auditReports={auditReports}
                      isAuditReportsLoading={isAuditReportsLoading}
                      totalItems={totalItems}
                      auditReportsParams={auditReportsParams}
                      facilityNameSearchFilter={facilityNameSearchFilter}
                      bapNumberSearchFilter={bapNumberSearchFilter}
                      onAuditReportsTableChange={onAuditReportsTableChange}
                      from={from}
                      enter={enter}
                    />
                  </Card>
                </div>
              </TabPane>
            ) : null}
            {from === 'endorsersMain' ? (
              <TabPane tab={t('network_tab')} key="network">
                <div className="site-card-border-less-wrapper">
                  <Card bordered={false} bodyStyle={{ padding: '5px 15px' }}>
                    <Network // Network
                      networkList={networkList}
                      iseNetworkListLoading={iseNetworkListLoading}
                      networkListParams={networkListParams}
                      networkTotalItems={networkTotalItems}
                      onNetworkListChange={onNetworkListChange}
                      networkProducerSearchFilter={networkProducerSearchFilter}
                      networkBapSearchFilter={networkBapSearchFilter}
                      speciesListResponse={speciesListResponse}
                      countriesListResponse={countriesListResponse}
                    />
                  </Card>
                </div>
              </TabPane>
            ) : null}
            {(from === 'endorsersMain' &&
              loggedInUserData?.traceabilityProvider &&
              loggedInUserData?.traceabilityProvider !== 'NONE') ||
            (from === 'staffEndorsers' &&
              endorserByIdResponse?.traceabilityProvider &&
              endorserByIdResponse?.traceabilityProvider !== 'NONE') ? (
              <TabPane tab={t('trace_tab')} key="trace">
                <div className="site-card-border-less-wrapper">
                  <Card bordered={false} bodyStyle={{ padding: '0px 0px' }}>
                    <EndorserTraceTitle
                      onEndorserTraceActionItemClick={onEndorserTraceActionItemClick}
                    />
                    <GTraceTable
                      traceTab={traceTab}
                      setTraceTab={setTraceTab}
                      expandedFilters={expandedFilters}
                      toggleExpandedFilters={toggleExpandedFilters}
                      search={search}
                      mainSelected={mainSelected}
                      setMainSelected={setMainSelected}
                      selectedNode={selectedNode}
                      setSelectedNode={setSelectedNode}
                      selectedMarker={selectedMarker}
                      setSelectedMarker={setSelectedMarker}
                      selectedLot={selectedLot}
                      selectedLotData={selectedLotData}
                      setSelectedLotData={setSelectedLotData}
                      setSearch={setSearch}
                      setSelectedLot={setSelectedLot}
                      // event list api
                      allEventsList={allEventsList}
                      isAllEventsListLoading={isAllEventsListLoading}
                      // event map api data
                      eventMapLocation={eventMapLocation}
                      isEventMpaLocationLoading={isEventMpaLocationLoading}
                      // event diagram api data
                      eventDiagramNodes={eventDiagramNodes}
                      isEventDiagramLoading={isEventDiagramLoading}
                    />
                  </Card>
                </div>
              </TabPane>
            ) : null}
          </Tabs>
        </span>
      )}
    </>
  );
};

export default MainContainer;
