import { FC, useCallback } from 'react';
import { Input, Form, Row, Col, Button, Space, Modal } from 'antd';
import { ResetPasswordProps, CheckMatching } from './typings';

const ResetPassword: FC<ResetPasswordProps> = ({ modal, onResetClick }) => {
  const checkMatching = ({ getFieldValue, fieldName, message: msg }: CheckMatching) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue(fieldName) === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(msg));
    },
  });
  /* reset password */

  const [resetPasswordForm] = Form.useForm();
  const resetPasswordFormRules = {
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    rules: {
      currentPassword: {
        name: 'currentPassword',
        label: 'Current Password',
        placeholder: 'Current Password',
        required: true,
        message: 'Please input current password.',
        wrapperCol: { md: { span: 24 } },
      },
      newPassword: {
        name: 'newPassword',
        label: 'New Password',
        placeholder: 'New Password',
        required: true,
        message: 'Please input new password.',
        wrapperCol: { md: { span: 24 } },
      },
      confirmNewPassword: {
        name: 'confirmNewPassword',
        label: 'Confirm New Password',
        placeholder: 'Confirm New Password',
        required: true,
        message: 'Please confirm new password.',
        wrapperCol: { md: { span: 24 } },
        style: { marginTop: '24px' },
        matchPassword: ({ getFieldValue }: any) =>
          checkMatching({
            getFieldValue,
            fieldName: 'newPassword',
            message: 'Password does not match.',
          }),
      },
    },
  };

  const closeModal = useCallback(() => {
    modal.hide();
  }, [modal]);

  const onSubmitResetPassword = async () => {
    const values = await resetPasswordForm.validateFields();
    onResetClick(values);
    closeModal();
  };
  return (
    <Modal
      visible={modal.visible}
      title="Reset Password"
      footer={
        <Space>
          <Button onClick={closeModal} type="primary" ghost shape="round">
            Cancel
          </Button>
          <Button
            onClick={onSubmitResetPassword}
            type="primary"
            style={{
              color: '#0a5f7a',
              background: '#bcd530',
              border: '1px solid #bcd530',
            }}
            shape="round"
          >
            Reset Password
          </Button>
        </Space>
      }
      onCancel={closeModal}
      width="58%"
      centered
    >
      <Form
        name="reset_password"
        initialValues={resetPasswordFormRules.initialValues}
        layout="vertical"
        form={resetPasswordForm}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              wrapperCol={resetPasswordFormRules.rules.newPassword.wrapperCol}
              name={resetPasswordFormRules.rules.newPassword.name}
              label={resetPasswordFormRules.rules.newPassword.label}
              rules={[resetPasswordFormRules.rules.newPassword]}
            >
              <Input.Password
                placeholder={resetPasswordFormRules.rules.newPassword.placeholder}
                className="b-r2"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              wrapperCol={resetPasswordFormRules.rules.confirmNewPassword.wrapperCol}
              name={resetPasswordFormRules.rules.confirmNewPassword.name}
              label={resetPasswordFormRules.rules.confirmNewPassword.label}
              rules={[
                resetPasswordFormRules.rules.confirmNewPassword,
                resetPasswordFormRules.rules.confirmNewPassword.matchPassword,
              ]}
            >
              <Input.Password
                placeholder={resetPasswordFormRules.rules.confirmNewPassword.placeholder}
                className="b-r2"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ResetPassword;
