/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-await-in-loop */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-table';
import { Spin, Tooltip, Typography } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import EndorserActions from 'components/Actions/EndorserActions';
import DownloadModal from 'components/GAuditReport/Modal/DownloadModal';
import useAuditReportStore from 'components/GAuditReport/hooks';
import GTable from 'components/GTable';
import { useGTableContext } from 'components/GTable/contexts';
import EndorserTitlebar from 'components/TitleBar/EndorserTitlebar';
import pluralize from 'pluralize';
import { FC, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { AuditReportsProps } from 'services/api/Endorsers';
import { ListAuditReportsOptionalParams } from 'services/api/client/src';
import { useDownloadReports } from 'services/api/useEndorsers';
import { useUserInfo } from 'services/api/useSettings';
import { pdfDownloadCreator } from 'utils';
import styles from '../index.module.less';
import { ActionMenu, AuditReportsTitleProps, MainAuditReportsProps } from './typings';

type ActionItemsProps = {
  t: TFunction<'pages', 'endorsers_partners.view_company.audit_reports'>;
  loggedInUserData: any;
  from?: string;
};

const mainActionItems = ({
  loggedInUserData,
  selectedRowKeys,
  t,
  from,
}: ActionMenu): Array<ItemType> => [
  {
    key: 'download',
    label: t('item_actions.download'),
    disabled:
      !selectedRowKeys.length ||
      (from !== 'profile' &&
        from !== 'endorsersMain' &&
        !loggedInUserData?.user?.role?.permission?.includes('DOWNLOADING_AUDIT_REPORTS')),
  },
];

const productActionItems = ({ t, loggedInUserData, from }: ActionItemsProps): Array<ItemType> => [
  {
    key: 'view',
    label: t('item_actions.view'),
  },
  (from !== 'profile' &&
    loggedInUserData?.user?.role?.permission?.includes('DOWNLOADING_AUDIT_REPORTS')) ||
  from === 'profile' ||
  from === 'endorsersMain'
    ? {
        key: 'download',
        label: t('item_actions.download'),
      }
    : null,
];

type ColumnsType = {
  auditReportsParams?: ListAuditReportsOptionalParams;
  totalItems?: number;
  facilityNameSearchFilter?: ProColumns;
  bapNumberSearchFilter?: ProColumns;
  onAuditReportsRowActionClick: (actionItemKey: string, actionPayload?: AuditReportsProps) => void;
  t: TFunction<'pages', 'endorsers_partners.view_company.audit_reports'>;
  from?: string;
  loggedInUserData: any;
};

const columns = ({
  onAuditReportsRowActionClick,
  t,
  facilityNameSearchFilter,
  bapNumberSearchFilter,
  from,
  loggedInUserData,
}: ColumnsType): Array<ProColumns<AuditReportsProps>> => [
  {
    title: t('table.facility_name'),
    dataIndex: 'facilityName',
    width: '22.5%',
    ellipsis: true,
    render: (text, record) => (
      <Link
        className="text-link"
        to={`audit_report/${record.bapNumber}/${record.reportId}`}
        title={`View ${text}`}
      >
        {text}
      </Link>
    ),
    ...facilityNameSearchFilter,
  },
  {
    title: t('table.bap_number'),
    dataIndex: 'bapNumber',
    width: '22.5%',
    ellipsis: true,
    ...(from === 'staffEndorsers' ? { ...bapNumberSearchFilter } : null),
  },
  {
    title: t('table.status'),
    dataIndex: 'status',
    width: '22.5%',
    ellipsis: true,
    filters: [
      {
        text: 'Initialized',
        value: 'Initialized',
      },
      {
        text: 'Ready for CB Assignment',
        value: 'Ready for CB Assignment',
      },
      {
        text: 'Assigned To CB',
        value: 'Assigned To CB',
      },
      {
        text: 'Accepted by CB',
        value: 'Accepted by CB',
      },
      {
        text: 'Assigned to Auditor',
        value: 'Assigned to Auditor',
      },
      {
        text: 'In Technical Review',
        value: 'In Technical Review',
      },
      {
        text: 'Audit Complete',
        value: 'Audit Complete',
      },
      {
        text: 'Dropped/Removed',
        value: 'Dropped/Removed',
      },
    ],
  },
  {
    title: t('table.completed_date'),
    dataIndex: 'submittedDate',
    valueType: 'date',
    ellipsis: true,
    width: '22.5%',
    sorter: true,
  },
  {
    title: t('table.audit_end_date'),
    dataIndex: 'auditEndDate',
    valueType: 'date',
    ellipsis: true,
    width: '22.5%',
    sorter: true,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    align: 'right',
    render: (text, record) => (
      <EndorserActions
        className="actions"
        buttonProps={{
          ghost: true,
          size: 'small',
        }}
        size="small"
        items={productActionItems({ t, loggedInUserData, from })}
        onClick={onAuditReportsRowActionClick}
        actionButtonColor={{
          color: '#0a5f7a',
          borderColor: '#0a5f7a',
          background: 'transparent',
          textShadow: '0px 0px',
          boxShadow: '0px 0px',
        }}
        actionPayload={record}
      />
    ),
    fixed: 'right',
    width: 120,
  },
];

const TableTitle = ({ from, loggedInUserData, setLoading }: AuditReportsTitleProps) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.audit_reports',
  });
  const { selectedRowKeys, selectedRowAllData } = useGTableContext();

  const queryClient = useQueryClient();
  const downloadReport = useDownloadReports(queryClient);

  const onAuditReportsActionItemClick = async (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'download':
        setLoading(true);
        for (let i = 0; i <= selectedRowKeys.length - 1; i += 1) {
          const pdfResponse = await downloadReport.mutateAsync({
            reportId: selectedRowKeys[i] as string,
            bapNumber: selectedRowAllData[i]?.bapNumber as string,
            params: {
              summary: true,
              fullReport: true,
            },
          });
          const blobBody = await pdfResponse?.blobBody;
          pdfDownloadCreator(blobBody, pdfResponse);
        }
        setLoading(false);
        break;

      default:
        break;
    }
  };

  return (
    <EndorserTitlebar
      title={t('table.title')}
      extraTitle={
        from === 'endorsersMain' || from === 'staffEndorsers' ? (
          <Tooltip title={t('table.tooltip_text')}>
            <ExclamationCircleOutlined style={{ opacity: '0.5', fontSize: '12px' }} />
          </Tooltip>
        ) : null
      }
      extra={
        selectedRowKeys.length > 0 && (
          <Typography.Text className="selectiontext">
            {`${selectedRowKeys.length} ${pluralize(
              'Audit Report',
              selectedRowKeys.length,
            )} Selected`}
          </Typography.Text>
        )
      }
      actionItems={mainActionItems({ loggedInUserData, selectedRowKeys, t, from })}
      onActionItemClick={onAuditReportsActionItemClick}
      actionButtonColor={{
        color: '#0a5f7a',
        borderColor: '#0a5f7a',
        background: 'white',
        textShadow: '0px 0px',
        boxShadow: '0px 0px',
      }}
      selectedRowKeys={selectedRowKeys}
    />
  );
};

const AuditReports: FC<MainAuditReportsProps> = ({
  onAuditReportsRowActionClick,
  auditReports,
  isAuditReportsLoading,
  from,
  enter,
  // after api update
  totalItems,
  auditReportsParams,
  facilityNameSearchFilter,
  bapNumberSearchFilter,
  onAuditReportsTableChange,
}) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.audit_reports',
  });
  const { data: loggedInUserData } = useUserInfo();
  const [loading, setLoading] = useState(false);

  const setModalVisible = useAuditReportStore((state) => state.setModalVisible);
  const setCheckedBox = useAuditReportStore((state) => state.setCheckedBox);
  const onCloseModal = () => {
    setCheckedBox(['summary', 'auditReport']);
    setModalVisible(false);
  };

  return loading ? (
    <div style={{ position: 'fixed', left: '50%', top: '50%', zIndex: '1200' }}>
      <Spin size="large" />
    </div>
  ) : (
    <div className={styles.table}>
      <DownloadModal onCloseModal={onCloseModal} from={from} enter={enter} />
      <GTable<AuditReportsProps>
        columns={columns({
          onAuditReportsRowActionClick,
          t,
          facilityNameSearchFilter,
          bapNumberSearchFilter,
          from,
          loggedInUserData,
        })}
        value={auditReports}
        headerTitle={
          <TableTitle from={from} loggedInUserData={loggedInUserData} setLoading={setLoading} />
        }
        enableRowSelection
        loading={isAuditReportsLoading}
        options={{
          setting: false,
          reload: false,
        }}
        onTableChange={onAuditReportsTableChange}
        pagination={{
          defaultPageSize: auditReportsParams?.pageSize,
          total: totalItems,
          current: auditReportsParams?.pageNumber,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Audit Reports`,
          showSizeChanger: true,
        }}
        scroll={{ y: 500, x: 1000 }}
      />
    </div>
  );
};

export default AuditReports;
