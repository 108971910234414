import { Graph } from '@antv/g6';

let ipHideTimer: any;
const ERROR_COLOR = '#F5222D';

export const strLen = (str: string) => {
  let len = 0;
  if (str) {
    for (let i = 0; i < str.length; i += 1) {
      if (str.charCodeAt(i) > 0 && str.charCodeAt(i) < 128) {
        len += 1;
      } else {
        len += 2;
      }
    }
  }
  return len;
};

export const fittingString = (str: string, maxWidth: number, fontSize: number) => {
  const fontWidth = fontSize * 1.3; // Font size + margin
  // eslint-disable-next-line no-param-reassign
  maxWidth *= 2; // Need to adjust to your own project
  const width = strLen(str) * fontWidth;
  const ellipsis = '…';
  if (width > maxWidth) {
    const actualLen = Math.floor((maxWidth - 10) / fontWidth);
    const result = str.substring(0, actualLen) + ellipsis;
    return result;
  }
  return str;
};

export const COLLAPSE_ICON = function COLLAPSE_ICON(x: any, y: any, r: any) {
  return [
    ['M', x - r, y],
    ['a', r, r, 0, 1, 0, r * 2, 0],
    ['a', r, r, 0, 1, 0, -r * 2, 0],
    ['M', x - r + 4, y],
    ['L', x - r + 2 * r - 4, y],
  ];
};
export const EXPAND_ICON = function EXPAND_ICON(x: any, y: any, r: any) {
  return [
    ['M', x - r, y],
    ['a', r, r, 0, 1, 0, r * 2, 0],
    ['a', r, r, 0, 1, 0, -r * 2, 0],
    ['M', x - r + 4, y],
    ['L', x - r + 2 * r - 4, y],
    ['M', x - r + r, y - r + 4],
    ['L', x, y + r - 4],
  ];
};

export const getNodeConfig = function getNodeConfig(node: any) {
  if (node.nodeError) {
    return {
      basicColor: ERROR_COLOR,
      fontColor: '#FFF',
      borderColor: ERROR_COLOR,
      bgColor: '#E66A6C',
    };
  }
  let config = {
    basicColor: '#E3E6E8',
    fontColor: 'rgba(0,0,0,0.85)',
    borderColor: '#E3E6E8',
    bgColor: '#F7F9FA',
  };
  switch (node.colourIndex) {
    case 'root':
    case 'colour_0': {
      config = {
        basicColor: '#0A5F7A',
        fontColor: '#0A5F7A',
        borderColor: '#0A5F7A',
        bgColor: '#E7F0F2',
      };
      break;
    }
    case 'colour_1':
      config = {
        basicColor: '#0A5F7A',
        fontColor: '#0A5F7A',
        borderColor: '#0A5F7A',
        bgColor: '#E7F0F2',
      };
      break;
    case 'colour_2':
      config = {
        basicColor: '#52C41A',
        fontColor: '#52C41A',
        borderColor: '#52C41A',
        bgColor: '#F4FCEB',
      };
      break;
    case 'colour_3':
      config = {
        basicColor: '#FA8C16',
        fontColor: '#FA8C16',
        borderColor: '#FA8C16',
        bgColor: '#FCF4E3',
      };
      break;
    default:
      break;
  }
  return config;
};

export const getNodeMethods = (mainGraph: Graph) => {
  const nodeBasicMethod = {
    createNodeBox: function createNodeBox(
      group: any,
      config: any,
      width: any,
      height: any,
      isRoot: any,
    ) {
      /* Outermost large rectangle */
      const container = group.addShape('rect', {
        attrs: {
          x: 0,
          y: 0,
          width,
          height,
        },
        name: 'container-rect-shape',
      });
      if (!isRoot) {
        /* Little dot on the left */
        group.addShape('circle', {
          attrs: {
            x: 3,
            y: height / 2,
            r: 6,
            fill: config.basicColor,
          },
          name: 'left-dot-shape',
        });
      }
      /* rectangle */
      group.addShape('rect', {
        attrs: {
          x: 3,
          y: 0,
          width: width - 19,
          height,
          fill: config.bgColor,
          stroke: config.borderColor,
          radius: 2,
        },
        name: 'rect-shape',
      });
      /* Thick line on the left */
      group.addShape('rect', {
        attrs: {
          x: 3,
          y: 0,
          width: 3,
          height,
          fill: config.basicColor,
          radius: 1.5,
        },
        name: 'left-border-shape',
      });
      return container;
    },
    /* Marker on spanning tree */
    createNodeMarker: function createNodeMarker(group: any, collapsed: any, x: any, y: any) {
      group.addShape('circle', {
        attrs: {
          x,
          y,
          r: 13,
          fill: 'rgba(47, 84, 235, 0.05)',
          opacity: 0,
          zIndex: -2,
        },
        name: 'collapse-icon-bg',
      });
      group.addShape('marker', {
        attrs: {
          x,
          y,
          r: 7,
          symbol: collapsed ? EXPAND_ICON : COLLAPSE_ICON,
          stroke: 'rgba(0,0,0,0.25)',
          fill: 'rgba(0,0,0,0)',
          lineWidth: 1,
          cursor: 'pointer',
        },
        name: 'collapse-icon',
      });
    },
    afterDraw: function afterDraw(cfg: any, group: any) {
      /* Show marker background color of operation marker */
      const icon = group.find((element: any) => element.get('name') === 'collapse-icon');
      if (icon) {
        const bg = group.find((element: any) => element.get('name') === 'collapse-icon-bg');
        icon.on('mouseenter', () => {
          bg.attr('opacity', 1);
          mainGraph.get('canvas').draw();
        });
        icon.on('mouseleave', () => {
          bg.attr('opacity', 0);
          mainGraph.get('canvas').draw();
        });
      }
      /* ip display */
      const ipBox = group.find((element: any) => element.get('name') === 'ip-box');
      if (ipBox) {
        /* Several elements copied by ip */
        const ipLine = group.find((element: any) => element.get('name') === 'ip-cp-line');
        const ipBG = group.find((element: any) => element.get('name') === 'ip-cp-bg');
        const ipIcon = group.find((element: any) => element.get('name') === 'ip-cp-icon');
        const ipCPBox = group.find((element: any) => element.get('name') === 'ip-cp-box');

        const onMouseEnter = function onMouseEnter() {
          if (ipHideTimer) {
            clearTimeout(ipHideTimer);
          }
          ipLine.attr('opacity', 1);
          ipBG.attr('opacity', 1);
          ipIcon.attr('opacity', 1);
          mainGraph.get('canvas').draw();
        };
        const onMouseLeave = function onMouseLeave() {
          ipHideTimer = setTimeout(() => {
            ipLine.attr('opacity', 0);
            ipBG.attr('opacity', 0);
            ipIcon.attr('opacity', 0);
            mainGraph.get('canvas').draw();
          }, 100);
        };
        ipBox.on('mouseenter', () => {
          onMouseEnter();
        });
        ipBox.on('mouseleave', () => {
          onMouseLeave();
        });
        ipCPBox.on('mouseenter', () => {
          onMouseEnter();
        });
        ipCPBox.on('mouseleave', () => {
          onMouseLeave();
        });
        ipCPBox.on('click', () => {});
      }
    },
    setState: function setState(name: any, value: any, item: any) {
      const hasOpacityClass = [
        'ip-cp-line',
        'ip-cp-bg',
        'ip-cp-icon',
        'ip-cp-box',
        'ip-box',
        'collapse-icon-bg',
      ];
      const group = item.getContainer();
      const childrens = group.get('children');
      mainGraph.setAutoPaint(false);
      if (name === 'emptiness') {
        if (value) {
          childrens.forEach((shape: any) => {
            if (hasOpacityClass.indexOf(shape.get('name')) > -1) {
              return;
            }
            shape.attr('opacity', 0.4);
          });
        } else {
          childrens.forEach((shape: any) => {
            if (hasOpacityClass.indexOf(shape.get('name')) > -1) {
              return;
            }
            shape.attr('opacity', 1);
          });
        }
      }
      mainGraph.setAutoPaint(true);
    },
  };
  return nodeBasicMethod;
};
