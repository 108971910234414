import { Select, Typography } from 'antd';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { useState, useEffect } from 'react';
import { useCountriesList } from 'services/api/useTrace';
import { CountrySelectProps } from './typings';
import styles from './index.module.less';

const CountrySelect = ({ search, setSearch, style }: CountrySelectProps) => {
  const [countryList, setCountryList] = useState<any>();
  const { data: countriesListResponse, isLoading } = useCountriesList();

  // we create here the list with the shape the select is expecting for
  const selectOptions: Array<DefaultOptionType> =
    countriesListResponse?.countries?.map((item) => ({
      label: String(item),
      value: String(item),
    })) || [];

  useEffect(() => {
    if (search?.country?.length === 0) {
      setCountryList(undefined);
    }
  }, [search]);

  const onCountryChange = (e: any, record: any) => {
    setCountryList(e);
    if (record.length === 0) {
      setSearch({ ...search, country: [] });
    } else {
      setSearch({ ...search, country: record.map((item: any) => item.label) });
    }
    setSearch({ ...search, country: record.map((item: any) => item.label) });
  };

  const onClearButtonClick = () => {
    setSearch({ ...search, country: [] });
  };

  return (
    <Select
      key="countryselect"
      maxTagCount="responsive"
      mode="multiple"
      style={style}
      size="middle"
      loading={isLoading}
      options={selectOptions}
      className={styles.container}
      placeholder={
        <Typography.Text style={{ opacity: '0.3', fontWeight: '400' }}>Country</Typography.Text>
      }
      allowClear
      showSearch
      value={countryList}
      filterOption={(input, option) =>
        (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      onChange={onCountryChange}
      onClear={onClearButtonClick}
    />
  );
};

export default CountrySelect;
