import { useCallback, useState, useMemo } from 'react';
import { TablePaginationConfig } from 'antd';
import { useTableSearchFilter } from 'hooks';
import { ListEndorsersOptionalParams } from 'services/api/client/src';
import { useEndorsersList, useSpeciesList, useCountriesList } from 'services/api/useEndorsers';
import { FilterValue } from 'antd/lib/table/interface';
import { UpdatedEndorserDTO } from '../typings';

const useDiscoverListFilters = (initParams?: ListEndorsersOptionalParams) => {
  const [discoverListParams, setDiscoverListParams] = useState<ListEndorsersOptionalParams>({
    pageSize: 50,
    pageNumber: 1,
    hideSelf: true,
    ...initParams,
  });

  const [selectedFilter, setSelectedFilter] = useState<string | undefined>('');

  const { data: discoverListReponse, isLoading: isDiscoverListLoading } =
    useEndorsersList(discoverListParams);

  const { data: speciesListResponse } = useSpeciesList();

  const { data: countriesListResponse } = useCountriesList();

  const totalItems = useMemo(() => discoverListReponse?.totalItems || 0, [discoverListReponse]);
  const discoverList: UpdatedEndorserDTO[] = useMemo(
    () =>
      discoverListReponse?.results?.map((item, index) => ({
        id: item.accountID,
        producer: item.facility,
        producerType: item.facilityType === 'Plant' ? item.plantType : item.facilityType,
        country: item.country,
        bap: item.bapNumber,
        expiration: item.expiration,
        species: item.species,
        stars: item.species?.[index]?.stars,
        accountID: item.accountID,
        accessCount: item.accessCount,
        accessFields: item.access,
        ...(item?.species?.length && {
          children: [
            ...(item?.species?.map((i) => ({
              producer: ' ',
              producerType: ' ',
              country: ' ',
              bap: ' ',
              expiration: ' ',
              species: [
                {
                  category: i?.category,
                  stars: i?.stars,
                },
              ],
              stars: i?.stars,
              access: 0,
              accessFields: {},
            })) || []),
          ],
        }),
      })) || [],
    [discoverListReponse?.results],
  );

  const { filters: producerSearchFilter } = useTableSearchFilter({
    title: 'Producer',
  });

  const { filters: bapNumberSearchFilter } = useTableSearchFilter({
    title: 'BAP Number',
  });

  const onDiscoverListTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: any,
    ) => {
      if (filters?.producer && totalItems !== 0) {
        setSelectedFilter(' for "Producer"');
      } else if (filters?.producerType && totalItems !== 0) {
        setSelectedFilter(' for "Producer Type"');
      } else if (filters?.country && totalItems !== 0) {
        setSelectedFilter(' for "Country"');
      } else if (filters?.bap && totalItems !== 0) {
        setSelectedFilter(' for "BAP Number"');
      } else if (filters?.species && totalItems !== 0) {
        setSelectedFilter(' for "Species & Star Status Capability"');
      } else {
        setSelectedFilter('');
      }
      const paginate = pagination?.current
        ? {
            pageNumber: pagination?.current || 1,
            pageSize: pagination?.pageSize || 50,
          }
        : {};
      const producerFilter = filters?.producer
        ? {
            facility: filters?.producer?.[0] as string,
          }
        : {};
      const producerTypeFilter = filters?.producerType
        ? {
            facilityTypes: filters?.producerType as string[],
          }
        : {};
      const countriesFilter = filters?.country
        ? {
            countries: filters?.country as string[],
          }
        : {};
      const bapNumberFilter = filters?.bap
        ? {
            bapNumber: (filters?.bap?.[0] as string).toUpperCase(),
          }
        : {};
      const speciesFilter = filters?.species
        ? {
            species: filters?.species as string[],
          }
        : {};
      const sortDirection =
        sorter?.field && sorter?.order
          ? {
              order: 'Expiration',
              direction: sorter?.order === 'ascend' ? 'Asc' : 'Desc',
            }
          : {};

      setDiscoverListParams({
        ...paginate,
        ...producerFilter,
        ...producerTypeFilter,
        ...countriesFilter,
        ...bapNumberFilter,
        ...speciesFilter,
        ...sortDirection,
        partnerTypes: ['Facility'],
        hideSelf: true,
      });
    },
    [totalItems],
  );

  return {
    discoverList,
    isDiscoverListLoading,
    discoverListParams,
    setDiscoverListParams,
    totalItems,
    onDiscoverListTableChange,
    filters: [producerSearchFilter, bapNumberSearchFilter],
    speciesListResponse,
    countriesListResponse,
    selectedFilter,
  };
};

export default useDiscoverListFilters;
