import { Select, Typography } from 'antd';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { useState, useEffect } from 'react';
import { useLotsList } from 'services/api/useTrace';
import { LotsSelectProps } from './typings';
import styles from './index.module.less';

const LotSelect = ({ search, setSearch, selectedLot, setSelectedLot, style }: LotsSelectProps) => {
  const [lotList, setLotList] = useState<any>();
  const { data: allLotsListResponse, isLoading } = useLotsList();

  // we create here the list with the shape the select is expecting for
  const selectOptions: Array<DefaultOptionType> =
    allLotsListResponse?.lotIds?.map((item) => ({
      label: String(item?.lotId),
      value: String(item?.lotURN),
    })) || [];

  useEffect(() => {
    if (search?.lot?.length === 0) {
      setLotList(undefined);
    }
  }, [search]);

  const onLotChange = (e: any, record: any) => {
    setLotList(e);
    if (record.length === 0) {
      setSelectedLot([]);
    } else {
      setSelectedLot([...selectedLot, record.map((item: any) => item.label)]);
    }
    setSearch({ ...search, lot: record.map((item: any) => item.value) });
  };

  const onClearButtonClick = () => {
    setSearch({ ...search, lot: [] });
  };

  return (
    <Select
      key="lotselect"
      style={style}
      maxTagCount="responsive"
      mode="multiple"
      size="middle"
      loading={isLoading}
      options={selectOptions}
      className={styles.container}
      placeholder={
        <Typography.Text style={{ opacity: '0.3', fontWeight: '400' }}>Lot</Typography.Text>
      }
      allowClear
      showSearch
      value={lotList}
      filterOption={(input, option) =>
        (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      onChange={onLotChange}
      onClear={onClearButtonClick}
    />
  );
};

export default LotSelect;
