/* eslint-disable no-nested-ternary */
import { Tabs, Space, Badge, Typography, Spin, Button, message } from 'antd';
import { FC, useState, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { errorHandler } from 'utils';
import useModalVisibility from 'hooks/useModalVisibility';
import { useClearNotifications } from 'services/api/useNotifications';
import Notifications, { RecentActivity, InviteModal } from './components';
import useNotificationsFilters from './hooks/useNotificationsFilters';
import styles from './index.module.less';
import { NotificationBoxProps, TabTitleProps, NotificationProps } from './typings';

const TabTitle: FC<TabTitleProps> = ({ title, count, active }) => (
  <Space>
    {title}
    <Badge
      count={count}
      style={{
        color: active ? '#0a5f7a' : 'black',
        backgroundColor: active ? '#E6EFF2' : '#F4f4f4',
      }}
    />
  </Space>
);

const NotificationBox: FC<NotificationBoxProps> = ({
  setVisible,
  notificationListLength,
  recentActivityListLength,
  activeKey,
  setActiveKey,
}) => {
  const [isClearNotificationsLoading, setIsClearNotificationsLoading] = useState(false);
  const [notificationData, setNotificationData] = useState<NotificationProps>({});
  const inviteModal = useModalVisibility(false);
  const { t } = useTranslation('pages', { keyPrefix: 'notifications' });
  const queryClient = useQueryClient();
  const clearNotifications = useClearNotifications(queryClient);
  const onChange = (key: string) => {
    setActiveKey(key);
  };

  // api data
  const {
    disableNotificationsSeeMore,
    disableRecentsSeeMore,
    fetchNotificationNextPage,
    fetchRecentNextPage,
    isFetchingNotificationsNextPage,
    isFetchingRecentNextPage,
  } = useNotificationsFilters();

  const clearReadState = async () => {
    setIsClearNotificationsLoading(true);
    try {
      if (activeKey === 'notification') {
        await clearNotifications.mutateAsync({
          params: { notificationIds: [], recentActivity: false },
        });
        setIsClearNotificationsLoading(false);
      } else if (activeKey === 'recent') {
        await clearNotifications.mutateAsync({
          params: { notificationIds: [], recentActivity: true },
        });
        setIsClearNotificationsLoading(false);
      }
    } catch (error) {
      setIsClearNotificationsLoading(false);
      message.error(errorHandler(error));
    }
  };

  const viewMoreData = useCallback(() => {
    if (activeKey === 'notification') {
      fetchNotificationNextPage();
    } else {
      fetchRecentNextPage();
    }
  }, [activeKey, fetchNotificationNextPage, fetchRecentNextPage]);

  const viewDetailsHandler = () => {
    setVisible(false);
    setTimeout(() => {
      inviteModal.show();
    }, 500);
  };

  return (
    <>
      {inviteModal.visible && (
        <InviteModal modal={inviteModal} notificationData={notificationData} />
      )}
      <Tabs className={styles.tabs} defaultActiveKey="notification" onChange={onChange} centered>
        <Tabs.TabPane
          tab={
            <TabTitle
              title={t('notification_tab')}
              count={notificationListLength}
              active={activeKey === 'notification'}
            />
          }
          key="notification"
        >
          <Notifications
            setVisible={setVisible}
            activeKey={activeKey}
            setNotificationData={setNotificationData}
            viewDetailsHandler={viewDetailsHandler}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <TabTitle
              title={t('recent_tab')}
              count={recentActivityListLength}
              active={activeKey === 'recent'}
            />
          }
          key="recent"
        >
          <RecentActivity />
        </Tabs.TabPane>
      </Tabs>
      <div className={styles.bottombar}>
        <div>
          {isClearNotificationsLoading ? (
            <Spin size="small" />
          ) : (
            <Button
              type="primary"
              style={{
                color: 'black',
                background: 'white',
                border: '0px',
                textShadow: '0px 0px',
                boxShadow: '0px 0px',
                fontWeight: '300',
              }}
              onClick={clearReadState}
            >
              <Typography.Text style={{ fontWeight: '300' }}>
                {t('clear_notifications')}
              </Typography.Text>
            </Button>
          )}
        </div>
        <div>
          {isFetchingNotificationsNextPage || isFetchingRecentNextPage ? (
            <Spin size="small" />
          ) : (
            <Button
              type="primary"
              style={{
                color: 'black',
                background: 'white',
                border: '0px',
                textShadow: '0px 0px',
                boxShadow: '0px 0px',
                fontWeight: '300',
                width: '93%',
              }}
              onClick={viewMoreData}
              disabled={
                activeKey === 'notification'
                  ? disableNotificationsSeeMore
                  : activeKey === 'recent'
                  ? disableRecentsSeeMore
                  : false
              }
            >
              <Typography.Text
                style={
                  (
                    activeKey === 'notification'
                      ? disableNotificationsSeeMore
                      : activeKey === 'recent'
                      ? disableRecentsSeeMore
                      : false
                  )
                    ? { opacity: '0.5', fontWeight: '300' }
                    : { fontWeight: '300' }
                }
              >
                {t('see_more')}{' '}
              </Typography.Text>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
export default NotificationBox;
